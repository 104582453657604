import PropTypes from "prop-types";
import { memo, useEffect, useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function LongDescriptionEditor({ formik }) {
  const editorRef = useRef(null);

  useEffect(() => {
    editorRef.current.getEditor().getLength() > 1 &&
      editorRef.current.getEditor().getLength < 81 &&
      formik.setErrors({
        longDescription: "Description must be at least 80 characters long",
      });
  }, [formik]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: "1" }, { header: "2" }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link"],
        ],
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    }),
    []
  );

  return (
    <ReactQuill
      ref={editorRef}
      value={formik.values.longDescription}
      onChange={(value) => {
        formik.setFieldValue("longDescription", value);
      }}
      theme="snow"
      placeholder="Write description here..."
      formats={[
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
      ]}
      modules={modules}
    />
  );
}

LongDescriptionEditor.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(LongDescriptionEditor);
