import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import yalabidCar from "assets/images/yalabidCar.svg";

export default function AuthSideContent() {
  // const pagePathName = useLocation().pathname;
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const isLedScreen = useMediaQuery((theme) => theme.breakpoints.down("xxl"));
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Box display="flex" justifyContent="center" pb={2}>
        <img
          src={yalabidCar}
          alt="Girl holding laptop for online shopping"
          height="auto"
          width={
            isSmall
              ? "280px"
              : isMedium
              ? "400px"
              : isLarge
              ? "520px"
              : isLedScreen
              ? "780px"
              : "780px"
          }
        />
      </Box>
      <Typography
        component="h3"
        color="white"
        fontSize={{
          sm: "1.25rem",
          md: "2rem",
          lg: "2.5rem",
          xl: "3rem",
        }}
        lineHeight={1}
      >
        WELCOME TO YALLABID
      </Typography>
      <Typography
        variant="h1"
        color="white"
        fontSize={{
          sm: "2.8rem",
          md: "4.5rem",
          lg: "5.5rem",
          xl: "6.5rem",
        }}
        fontWeight="bold"
        lineHeight={1}
      >
        Control Center
      </Typography>
      {/* {!pagePathName.includes("verification") && (
        <Box
          pt={2}
          sx={{
            minWidth: { sm: "250px", md: "450px", lg: "400px", xl: "535px" },
          }}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size={isSmall ? "small" : "large"}
            component={Link}
            to={pagePathName.includes("signup") ? "/login" : "/signup"}
            sx={{
              fontWeight: "bold",
              fontSize: {
                sm: "0.75rem",
                md: "1.2rem",
                xl: "1.25rem",
              },
              color: "primary.main",
              backgroundColor: "white",
              "&:hover": {
                backgroundColor: "white",
              },
            }}
          >
            {pagePathName.includes("signup") ? "LOGIN" : "SIGN UP"}
          </Button>
        </Box>
      )} */}
    </Stack>
  );
}
