import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import BannerImagePlaceholder from "assets/icons/BannerImagePlaceholder.svg";
import PropTypes from "prop-types";
import { memo, useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setImageValidationError,
  setIsImageValidationDialogOpen,
} from "store/slices/uiSlice";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import validateImageAspectRatio from "utils/helpers/validateImageAspectRatio";

function AuctionMediaSection({ formik }) {
  const dispatch = useDispatch();
  const auctionImageInputRef = useRef(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const [auctionImage, setAuctionImage] = useState(null);

  const auctionImageHandler = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        // function params
        // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
        validateImageAspectRatio(image, 300, 200, true, true, 3 / 2, "3 / 2")
          .then((img) => {
            formik.setFieldValue("auctionImage", img);
            setAuctionImage(URL.createObjectURL(img));
          })
          .catch((error) => {
            auctionImageInputRef.current.value = "";
            dispatch(setIsImageValidationDialogOpen(true));
            dispatch(setImageValidationError(error.message));
          });
      }
    },
    [dispatch, formik]
  );

  return (
    <StyledMuiCard
      title="Media Section"
      subTitle="Upload image relevant to the auction"
    >
      <CardContent>
        <Box display="flex" flexWrap="wrap" gap={4}>
          {/* Auction Main Image */}
          <Stack direction="column" gap={2}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Main Image
            </Typography>
            <Box>
              <img
                src={
                  auctionImage ||
                  formik.values?.auctionImage ||
                  BannerImagePlaceholder
                }
                alt="Auction Main Media"
                height="auto"
                width={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                style={{ cursor: "pointer" }}
                onClick={() => auctionImageInputRef.current.click()}
              />
              <input
                type="file"
                ref={auctionImageInputRef}
                style={{ display: "none" }}
                id="update-auction-image"
                hidden
                onChange={auctionImageHandler}
                accept="image/x-png,image/png,image/jpeg,image/jpg"
              />
              {Boolean(formik.errors.auctionImage) && (
                <Typography color="#ef5350">
                  {formik.errors.auctionImage}*
                </Typography>
              )}
            </Box>
          </Stack>
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
}

AuctionMediaSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(AuctionMediaSection);
