import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PropTypes from "prop-types";
import { memo } from "react";

function MobileDateTime(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDateTimePicker
        placeHolder={"MM/DD/YYYY HH:MM AA"}
        openTo="day"
        onChange={props?.onChange}
        disabled={props?.disabled}
        slotProps={{
          textField: {
            ...props,
            sx: {
              "& .MuiFormHelperText-root": {
                fontSize: "0.9rem",
                marginLeft: 0,
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}

MobileDateTime.propTypes = {
  props: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
export default memo(MobileDateTime);
