import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MUIStyledTextField from "components/MUIStyledComponents/MUIStyledTextField";
import PropTypes from "prop-types";
import { memo, useCallback, useState } from "react";
import StyledTextField from "utils/helpers/styledTextField";

function SpecificationsForm({ specification, handleSave }) {
  const [name, setName] = useState(specification?.name ?? "");
  const [dataType, setDataType] = useState(specification?.dataType ?? "");
  const [specs, setSpecs] = useState(specification?.specs ?? false);
  const [min, setMin] = useState(specification?.min ?? "");
  const [max, setMax] = useState(specification?.max ?? "");
  const [units, setUnits] = useState(specification?.units ?? []);
  const [error, setError] = useState({
    name: "",
    dataType: "",
    specs: "",
    min: "",
    max: "",
    units: "",
  });
  const [toValidate, setToValidate] = useState(false);

  const handleNameChange = useCallback(
    (e) => {
      const value = e.target.value;
      setName(value.trim());
      if (toValidate)
        if (!value) {
          setError({ ...error, name: "Name is required" });
        } else if (value.length < 3) {
          setError({
            ...error,
            name: "Name must be at least 3 characters long",
          });
        } else if (value.length > 50) {
          setError({
            ...error,
            name: "Name must be at most 50 characters long",
          });
        } else {
          setError({ ...error, name: "" });
        }
    },
    [error, toValidate]
  );

  const handleDataTypeChange = useCallback(
    (value) => {
      setDataType(value);
      if (toValidate)
        if (!value) {
          setError({ ...error, dataType: "Data type is required" });
        }
    },
    [error, toValidate]
  );

  const handleSpecsChange = useCallback((value) => {
    setSpecs(value);
  }, []);

  const handleMinChange = useCallback(
    (e) => {
      const value = e.target.value;
      setMin(value);
      if (toValidate)
        if (!value) {
          setError({ ...error, min: "Minimum value is required" });
        } else if (value.toString().length < 1) {
          setError({
            ...error,
            min: "Minimum value must be at least 1",
          });
        } else if (value.toString().length > 10) {
          setError({
            ...error,
            min: "Minimum value must not be more than 10 digits long",
          });
        } else {
          setError({ ...error, min: "" });
        }
    },
    [error, toValidate]
  );

  const handleMaxChange = useCallback(
    (e) => {
      const value = e.target.value;
      setMax(value);
      if (toValidate)
        if (!value) {
          setError({ ...error, max: "Maximum value is required" });
        } else if (value.toString().length < 1) {
          setError({
            ...error,
            max: "Maximum value must be at least 1",
          });
        } else if (value.toString().length > 10) {
          setError({
            ...error,
            max: "Maximum value must not be more than 10 digits long",
          });
        } else {
          setError({ ...error, max: "" });
        }
    },
    [error, toValidate]
  );

  const handleUnitsChange = useCallback((value) => {
    setUnits(value);
  }, []);

  const handleSubmit = useCallback(() => {
    const _error = { ...error };
    // Name validation
    if (!name) _error.name = "Name is required";
    else if (name.length < 3)
      _error.name = "Name must be at least 3 characters long";
    else if (name.length > 50)
      _error.name = "Name must be at most 50 characters long";
    else _error.name = "";

    // Data type validation
    if (!dataType) _error.dataType = "Data type is required";
    else _error.dataType = "";

    // Min Value validation
    if (!min) _error.min = "Minimum value is required";
    else if (min.toString().length < 1)
      _error.min = "Minimum value must be at least 1";
    else if (min.toString().length > 10)
      _error.min = "Minimum value must not be more than 10 digits long";
    else _error.min = "";

    // Max Value validation
    if (!max) _error.max = "Maximum value is required";
    else if (max.toString().length < 1)
      _error.max = "Maximum value must be at least 1";
    else if (max.toString().length > 10)
      _error.max = "Maximum value must not be more than 10 digits long";
    else _error.max = "";

    if (!Object.values(_error).every((value) => !value)) {
      setToValidate(true);
      setError(_error);
      return;
    }

    const _specification = {
      name: name.toLowerCase(),
      dataType,
      specs,
      min,
      max,
      units,
    };

    handleSave(_specification);
  }, [dataType, error, handleSave, max, min, name, specs, units]);

  return (
    <Grid container spacing={3} pb={2}>
      <Grid item xs={12} md={4}>
        <Stack direction="column" gap={1}>
          <Typography
            variant="body1"
            fontWeight="600"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Name
          </Typography>
          <StyledTextField
            fullWidth
            type="text"
            name="name"
            value={name}
            onChange={handleNameChange}
            error={Boolean(error?.name)}
            helperText={error?.name ? error?.name : "Enter a descriptive name"}
            sx={{
              "& .MuiFormHelperText-root": {
                ml: 0,
              },
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack direction="column" gap={1}>
          <Typography
            variant="body1"
            fontWeight="600"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Type
          </Typography>
          <Autocomplete
            options={[
              "string",
              "number",
              // "boolean"
            ]}
            value={dataType}
            onChange={(_, bodyType) => handleDataTypeChange(bodyType)}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <MUIStyledTextField
                fullWidth
                {...params}
                name="bodyType"
                error={Boolean(error?.dataType)}
                helperText={
                  error?.dataType ? error?.dataType : "Select a data type"
                }
              />
            )}
            renderOption={(props, option) => (
              <li
                {...props}
                key={option}
                style={{
                  textTransform: "capitalize",
                }}
              >
                {option}
              </li>
            )}
            sx={{
              "& .MuiAutocomplete-input": {
                textTransform: "capitalize",
              },
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack direction="column" gap={1}>
          <Typography
            variant="body1"
            fontWeight="600"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Required
          </Typography>
          <Autocomplete
            options={[true, false]}
            value={specs}
            onChange={(_, value) => handleSpecsChange(value)}
            getOptionLabel={(option) => option.toString()}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <MUIStyledTextField
                fullWidth
                {...params}
                name="specs"
                error={Boolean(error?.specs)}
                helperText={error?.specs ? error?.specs : "Select an option"}
              />
            )}
            renderOption={(props, option) => (
              <li
                {...props}
                key={option}
                style={{
                  textTransform: "capitalize",
                }}
              >
                {option.toString()}
              </li>
            )}
            sx={{
              "& .MuiAutocomplete-input": {
                textTransform: "capitalize",
              },
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack direction="column" gap={1}>
          <Typography
            variant="body1"
            fontWeight="600"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Min
          </Typography>
          <StyledTextField
            fullWidth
            type="number"
            name="min"
            value={min}
            onChange={handleMinChange}
            error={Boolean(error?.min)}
            helperText={error?.min ? error?.min : "Enter minimum value"}
            sx={{
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  display: "none",
                },
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
              "& .MuiFormHelperText-root": {
                ml: 0,
              },
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack direction="column" gap={1}>
          <Typography
            variant="body1"
            fontWeight="600"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Max
          </Typography>
          <StyledTextField
            fullWidth
            type="number"
            name="max"
            value={max}
            onChange={handleMaxChange}
            error={Boolean(error?.max)}
            helperText={error?.max ? error?.max : "Enter maximum value"}
            sx={{
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  display: "none",
                },
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
              "& .MuiFormHelperText-root": {
                ml: 0,
              },
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack direction="column" gap={1}>
          <Typography
            variant="body1"
            fontWeight="600"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Unit
          </Typography>
          <Autocomplete
            multiple
            value={units}
            options={["cm", "m", "ft", "sqm", "sqft", "in", "ct", "g", "kg"]}
            onChange={(_, units) => handleUnitsChange(units)}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <MUIStyledTextField
                fullWidth
                {...params}
                name="bodyType"
                error={Boolean(error?.units)}
                helperText={
                  error?.units ? error?.units : "You can add multiple units"
                }
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option}>
                {option}
              </li>
            )}
            sx={{
              "& .MuiAutocomplete-input": {
                textTransform: "capitalize",
              },
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" gap={2} justifyContent="flex-end">
          <Button
            variant="contained"
            size="large"
            onClick={handleSubmit}
            sx={{
              minWidth: { md: 175, xl: 200 },
              minHeight: {
                xs: 50,
                xl: 55,
              },
            }}
          >
            {specification ? "Update" : "Add"}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

SpecificationsForm.propTypes = {
  specification: PropTypes.object,
  handleSave: PropTypes.func.isRequired,
};

export default memo(SpecificationsForm);
