import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { setSelectProductCategoryDialogState } from "store/slices/uiSlice";
import SelectProductCategoryDialog from "./SelectProductCategoryDialog";

function AddActions() {
  const dispatch = useDispatch();

  const handleDialog = useCallback(
    () => dispatch(setSelectProductCategoryDialogState(true)),
    [dispatch]
  );

  return (
    <>
      <Button
        sx={{
          minWidth: 170,
          maxWidth: 180,
          minHeight: 44,
        }}
        disableElevation
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleDialog}
      >
        Add Product
      </Button>
      <SelectProductCategoryDialog />
    </>
  );
}

export default memo(AddActions);
