import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { useSelector } from "react-redux";
import EmailVerification from "./EmailVerification";
import PhoneVerification from "./PhoneVerification";

function VerificationInputForm() {
  const isPhoneNumberVerified = useSelector(
    (state) => state.auth.data.user.isPhoneVarified
  );
  const isEmailVerified = useSelector(
    (state) => state.auth.data.user.isEmailVarified
  );

  return (
    <Container>
      <Stack
        direction="column"
        alignContent="center"
        justifyContent="center"
        minHeight="100vh"
        maxWidth={{ sm: "250px", md: "450px", lg: "400px", xl: "535px" }}
        m="auto"
        py={2}
        spacing={{ xs: 1.75, md: 2.4, lg: 3, xl: 6 }}
      >
        <Box>
          <Typography
            variant="h3"
            component="h2"
            fontWeight="bold"
            fontSize={{ xs: "1rem", sm: "1.5rem", md: "2.75rem", lg: "4rem" }}
          >
            Verification
          </Typography>
          <Typography
            variant="body1"
            color="primary"
            fontSize={{ lg: "1rem", xl: "1.33rem" }}
          >
            Please verify your phone number and email address
          </Typography>
        </Box>

        {!isPhoneNumberVerified && <PhoneVerification />}
        {!isEmailVerified && <EmailVerification />}
      </Stack>
    </Container>
  );
}

export default memo(VerificationInputForm);
