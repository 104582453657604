import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SubscriptionUpdateDialog from "components/Dialog/SubscriptionUpdateDialog";
import MUIStyledTextField from "components/MUIStyledComponents/MUIStyledTextField";
import { Form, Formik } from "formik";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "store/configureStore";
import {
  getSubscription,
  resetSubscriptionsState,
  submitSubscriptions,
  updateSubscription,
} from "store/slices/subscriptionsSlice";
import { setSubscriptionUpdateDialogState } from "store/slices/uiSlice";
import {
  CHARGES_TYPES,
  SUBCRIPTION_ROLE,
  SUBSCRIPTION_TYPE,
} from "utils/constants/SubscriptionsServices";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import isValidNumber from "utils/helpers/isValidNumber";
import reduceDepthObject from "utils/helpers/reduceDepthObjects";
import { getSubscriptionFormValues } from "utils/helpers/subscriptionFormHelpers";
import * as Yup from "yup";
import CheckboxList from "./CheckBoxList";
import CommissionForm from "./CommissionForm";

function SubscriptionsForm() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscriptions = useSelector(
    (state) => state.subscriptions.selectedSubscriptions ?? {}
  );
  const loading = useSelector(
    (state) => state.subscriptions.subscriptionsLoading ?? false
  );
  const [updateFormValues, setUpdateFormValues] = useState(null);

  useEffect(() => {
    !params.id && dispatch(resetSubscriptionsState());
  }, [dispatch, params.id]);

  const initialValues = useMemo(
    () => ({
      subType: subscriptions?.subType ?? "free",
      bidCommission: subscriptions?.bidCommission ?? 0,
      price: subscriptions?.charges?.price ?? 0,
      chargesType: subscriptions?.charges?.chargesType ?? "monthly",
      subscriptionRole: subscriptions?.subscriptionRole ?? "user",
      minDeposit: subscriptions?.minDeposit ?? 0,
      depositMultiplier: subscriptions?.depositMultiplier ?? 0,
      view: subscriptions?.view ?? false,
      auction: subscriptions?.auction ?? false,
      watchlist: subscriptions?.watchlist ?? false,
      alerts: subscriptions?.alerts ?? false,
      bidEvent: subscriptions?.bidEvent ?? false,
      commission: subscriptions?.bidCancelation?.commission ?? 0,
      yalaBidShare: subscriptions?.bidCancelation?.yalaBidShare ?? 0,
      listerShare: subscriptions?.bidCancelation?.listerShare ?? 0,
    }),
    [subscriptions]
  );

  const handleCreateSubscription = useCallback(
    (values) => {
      const formValues = getSubscriptionFormValues(values);
      dispatch(submitSubscriptions(formValues))
        .unwrap()
        .then(() => navigate("/subscriptions"));
    },
    [dispatch, navigate]
  );

  const handleUpdateSubscription = useCallback(
    (isUpdateAll = false) => {
      const formValues = { ...updateFormValues };
      if (isUpdateAll) {
        formValues.updateAll = true;
      }
      const updatedValues = getSubscriptionFormValues(formValues, true);
      dispatch(
        updateSubscription({
          id: params?.id,
          updatedValues,
        })
      )
        .unwrap()
        .then(() => navigate("/subscriptions"));
    },
    [dispatch, navigate, params?.id, updateFormValues]
  );

  const handleSubmit = (values) => {
    if (params?.id) {
      const formValues = reduceDepthObject(values, initialValues);
      if (!Object.values(formValues).length) {
        return navigate("/subscriptions");
      } else {
        setUpdateFormValues(() => ({ ...formValues }));
        dispatch(setSubscriptionUpdateDialogState(true));
      }
    } else {
      handleCreateSubscription(values);
    }
  };

  return (
    <>
      <Box px={1}>
        <Typography
          variant="h1"
          fontWeight="600"
          color="primary.main"
          fontSize={{ lg: "2.6rem", sm: "2rem" }}
          my={2}
        >
          {params?.id ? "Update Subscriptions" : "Add Subscriptions"}
        </Typography>
        <Formik
          enableReinitialize={true}
          validateOnBlur={true}
          validateOnChange={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              {/* Fee */}
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <StyledMuiCard
                    title={
                      params?.id ? subscriptions?.subType : "Basic Information"
                    }
                    subTitle="Please enter the basic information of the subscription"
                  >
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Subscription Type
                            </Typography>
                            <MUIStyledTextField
                              fullWidth
                              select
                              type="text"
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  textTransform: "capitalize",
                                },
                              }}
                              {...formik.getFieldProps("subType")}
                              error={Boolean(
                                formik.touched.subType && formik.errors.subType
                              )}
                              helperText={
                                formik.touched.subType &&
                                !!formik.errors.subType
                                  ? formik.errors.subType
                                  : "Select charges type"
                              }
                            >
                              {SUBSCRIPTION_TYPE.map((name) => (
                                <MenuItem
                                  key={name}
                                  value={name}
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                            </MUIStyledTextField>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Subscription Charges
                            </Typography>
                            <MUIStyledTextField
                              fullWidth
                              name="price"
                              value={formik.values.price}
                              onChange={(e) => {
                                isValidNumber(e.target.value) &&
                                  formik.setFieldValue(
                                    "price",
                                    +e.target.value
                                  );
                              }}
                              onBlur={formik.handleBlur}
                              InputProps={{
                                startAdornment: (
                                  <Typography
                                    color="text.disabled"
                                    sx={{
                                      fontSize: "1.25rem",
                                      borderRight: "1px solid #00000038",
                                      paddingRight: "1rem",
                                    }}
                                  >
                                    AED
                                  </Typography>
                                ),
                              }}
                              error={Boolean(
                                formik.touched.price && formik.errors.price
                              )}
                              helperText={
                                formik.touched.price && !!formik.errors.price
                                  ? formik.errors.price
                                  : "Enter charges of the subscription"
                              }
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  fontSize: "1.25rem",
                                  textAlign: "right",
                                  padding: "14px",
                                },
                              }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Bid Commission
                            </Typography>
                            <MUIStyledTextField
                              fullWidth
                              name="bidCommission"
                              value={formik?.values?.bidCommission}
                              onChange={(e) => {
                                isValidNumber(e.target.value) &&
                                  formik.setFieldValue(
                                    "bidCommission",
                                    +e.target.value
                                  );
                              }}
                              onBlur={formik.handleBlur}
                              InputProps={{
                                startAdornment: (
                                  <Typography
                                    color="text.disabled"
                                    sx={{
                                      fontSize: "1.25rem",
                                      borderRight: "1px solid #00000038",
                                      paddingRight: "1rem",
                                    }}
                                  >
                                    AED
                                  </Typography>
                                ),
                              }}
                              error={Boolean(
                                formik.touched.bidCommission &&
                                  formik.errors.bidCommission
                              )}
                              helperText={
                                formik.touched.bidCommission &&
                                !!formik.errors.bidCommission
                                  ? formik.errors.bidCommission
                                  : "Enter a Bid commission of the subscription in AED"
                              }
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  fontSize: "1.25rem",
                                  textAlign: "right",
                                  padding: "14px",
                                },
                              }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} xl={3}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Charges Type
                            </Typography>
                            <MUIStyledTextField
                              fullWidth
                              select
                              type="text"
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  textTransform: "capitalize",
                                },
                              }}
                              {...formik.getFieldProps("chargesType")}
                              error={Boolean(
                                formik.touched.chargesType &&
                                  formik.errors.chargesType
                              )}
                              helperText={
                                formik.touched.chargesType &&
                                !!formik.errors.chargesType
                                  ? formik.errors.chargesType
                                  : "Select charges type"
                              }
                            >
                              {CHARGES_TYPES.map((name) => (
                                <MenuItem
                                  key={name}
                                  value={name}
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                            </MUIStyledTextField>
                          </Stack>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} xl={3}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Subscription Role
                            </Typography>
                            <MUIStyledTextField
                              fullWidth
                              select
                              type="text"
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  textTransform: "capitalize",
                                },
                              }}
                              {...formik.getFieldProps("subscriptionRole")}
                              error={Boolean(
                                formik.touched.subscriptionRole &&
                                  formik.errors.subscriptionRole
                              )}
                              helperText={
                                formik.touched.subscriptionRole &&
                                !!formik.errors.subscriptionRole
                                  ? formik.errors.subscriptionRole
                                  : "Select charges type"
                              }
                            >
                              {SUBCRIPTION_ROLE.map((name) => (
                                <MenuItem
                                  key={name}
                                  value={name}
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                            </MUIStyledTextField>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} xl={3}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Minimum Deposit
                            </Typography>
                            <MUIStyledTextField
                              fullWidth
                              name="minDeposit"
                              value={formik.values.minDeposit}
                              onChange={(e) => {
                                isValidNumber(e.target.value) &&
                                  formik.setFieldValue(
                                    "minDeposit",
                                    +e.target.value
                                  );
                              }}
                              onBlur={formik.handleBlur}
                              InputProps={{
                                startAdornment: (
                                  <Typography
                                    color="text.disabled"
                                    sx={{
                                      fontSize: "1.25rem",
                                      borderRight: "1px solid #00000038",
                                      paddingRight: "1rem",
                                    }}
                                  >
                                    AED
                                  </Typography>
                                ),
                              }}
                              error={Boolean(
                                formik.touched.minDeposit &&
                                  formik.errors.minDeposit
                              )}
                              helperText={
                                formik.touched.minDeposit &&
                                !!formik.errors.minDeposit
                                  ? formik.errors.minDeposit
                                  : "Enter a minimum deposit of the subscription in AED"
                              }
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  fontSize: "1.25rem",
                                  textAlign: "right",
                                  padding: "14px",
                                },
                              }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} xl={3}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Bid Deposit Multiple
                            </Typography>
                            <MUIStyledTextField
                              fullWidth
                              name="depositMultiplier"
                              value={formik.values.depositMultiplier}
                              onChange={(e) => {
                                isValidNumber(e.target.value) &&
                                  formik.setFieldValue(
                                    "depositMultiplier",
                                    +e.target.value
                                  );
                              }}
                              onBlur={formik.handleBlur}
                              InputProps={{
                                startAdornment: (
                                  <Typography
                                    color="text.disabled"
                                    sx={{
                                      fontSize: "1.25rem",
                                      borderRight: "1px solid #00000038",
                                      paddingRight: "1rem",
                                    }}
                                  >
                                    AED
                                  </Typography>
                                ),
                              }}
                              error={Boolean(
                                formik.touched.depositMultiplier &&
                                  formik.errors.depositMultiplier
                              )}
                              helperText={
                                formik.touched.depositMultiplier &&
                                !!formik.errors.depositMultiplier
                                  ? formik.errors.depositMultiplier
                                  : "Enter the bid deposit multiple value here"
                              }
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  fontSize: "1.25rem",
                                  textAlign: "right",
                                  padding: "14px",
                                },
                              }}
                            />
                          </Stack>
                        </Grid>
                        {/* checkbox list  */}
                        <Grid item xs={12} lg={12}>
                          <Stack direction="column" gap={1}>
                            <CheckboxList formik={formik} />
                          </Stack>
                        </Grid>
                        {/* Commision form */}
                        <Grid item xs={12} lg={12}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h4"
                              fontSize={{ xs: "1.5rem", sm: "2rem" }}
                              fontWeight={600}
                              color="#131313"
                            >
                              Bid Cancelation
                            </Typography>
                            <CommissionForm formik={formik} />
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </StyledMuiCard>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={12} my={2}>
                <Grid item xs={12}>
                  <Stack direction="row" gap={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      onClick={formik.handleSubmit}
                      disableElevation
                      sx={{
                        fontWeight: "bold",
                        minWidth: { md: 100, lg: 175, xl: 250 },
                        height: { xs: 50, xl: 55 },
                      }}
                    >
                      {params?.id ? "Update" : "Submit"}
                    </Button>
                    <Button
                      disabled={formik.isSubmitting || loading}
                      onClick={() => formik.resetForm()}
                      variant="outlined"
                      color="primary"
                      sx={{
                        fontWeight: "bold",
                        minWidth: { md: 100, lg: 175, xl: 250 },
                        height: { xs: 50, xl: 55 },
                      }}
                    >
                      Reset
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
      <SubscriptionUpdateDialog
        handleUpdateSubscription={handleUpdateSubscription}
      />
    </>
  );
}

export default memo(SubscriptionsForm);

const validationSchema = Yup.object().shape({
  subType: Yup.string().required("Required*"),
  price: Yup.string("Required*")
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(8, "Charges are too long"),
  bidCommission: Yup.number()
    .required("Required*")
    .max(99, "Bid commision must be between 0 and 100"),
  chargesType: Yup.string().trim().required("Required*"),
  minDeposit: Yup.string()
    .required("Required*")
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(8, "Minimum Deposit is too long"),
  depositMultiplier: Yup.string()
    .required("Required*")
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(8, "Minimum Deposit is too long"),
  view: Yup.boolean(),
  auction: Yup.boolean(),
  watchlist: Yup.boolean(),
  alerts: Yup.boolean(),
  bidEvent: Yup.boolean(),
  commission: Yup.number()
    .typeError("Invalid number")
    .max(10000, "Number must be at most 10000 digits long"),
  yalaBidShare: Yup.number()
    .required("Required*")
    .max(100, "Share commission should should not exceed 100"),
  // listerShare: Yup.number()
  //   .required("Required*")
  //   .max(100, "Lister should should not exceed 100"),
});

export const loadSubscription = ({ params }) => {
  store.dispatch(getSubscription(params?.id));
  return null;
};
