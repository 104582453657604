import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadPageImage } from "services/pageServices";
import { getErrorMessage, getResponseData } from "utils/helpers/httpRequests";
import { toast } from "utils/hooks/useToast";
import { httpPages } from "utils/httpRequest/http";

export const getPages = createAsyncThunk(
  "pages/getPages",
  async (params = {}, { rejectWithValue }) => {
    try {
      const response = await httpPages.get("/cms-pages/admin", {
        params: {
          ...params,
          page: params.page ?? 1,
          limit: params.limit ?? 20,
        },
      });
      const responseData = getResponseData(response);

      responseData &&
        responseData?.results.forEach((item, index) => {
          const pageStart = (responseData?.page - 1) * responseData?.limit;
          item.serialNumber = pageStart + index + 1;
        });

      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getPage = createAsyncThunk(
  "pages/getPage",
  async (id, { getState, fulfillWithValue, rejectWithValue }) => {
    const results = getState().pages.results;
    const page = results.find((data) => data.id === id);
    if (page) {
      return fulfillWithValue(page);
    }
    try {
      const response = await httpPages.get(`/cms-pages/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const submitPage = createAsyncThunk(
  "pages/submitPage",
  async (data = {}, { rejectWithValue }) => {
    try {
      const response = await httpPages.post(`/cms-pages`, {
        title: data?.title,
        slug: data?.slug,
        content: data?.content,
      });

      const responseData = getResponseData(response);

      if (!responseData?.id) {
        throw new Error("Something went wrong!");
      }

      if (data?.image && typeof data?.image === "object") {
        await uploadPageImage(responseData?.id, data?.image);
      }

      toast.success("Page Created Successfully");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deletePage = createAsyncThunk(
  "pages/deletePage",
  async (pageId, { getState, rejectWithValue }) => {
    const results = getState().pages.results;
    try {
      const response = await httpPages.delete(`/cms-pages/${pageId}`);
      if (response.status === 200) {
        const returnedData = results.filter((data) => data.id !== pageId);
        toast.success("Page Deleted Successfully");
        return returnedData;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updatePage = createAsyncThunk(
  "pages/updatePage",
  async (values = {}, { rejectWithValue }) => {
    try {
      const { id = "", data = {} } = values;
      const response = await httpPages.patch(`/cms-pages/${id}`, {
        ...(data?.title && { title: data?.title }),
        ...(data?.slug && { slug: data?.slug }),
        ...(data?.content && { content: data?.content }),
      });
      if (data?.image && typeof data?.image === "object") {
        await uploadPageImage(id, data?.image);
      }
      toast.success("Page Updated successfully");
      return response?.data;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: true,
  pageLoading: true,
  selectedPage: null,
  results: [],
  page: 1,
  limit: 20,
  totalPages: 0,
  totalResults: 0,
  error: "",
  pageError: "",
};

const pagesSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    // Actions
    resetPagesState: () => initialState,
    resetPageState: (state) => {
      state.loading = false;
      state.selectedPage = null;
      state.pageError = "";
    },
    setPage: (state, action) => {
      state.selectedPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPages.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getPages.fulfilled, (state, action) => {
        state.results = action.payload.results;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
        state.loading = false;
      })
      .addCase(getPages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deletePage.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deletePage.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
        state.error = "";
      })
      .addCase(deletePage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(submitPage.pending, (state) => {
        state.pageLoading = true;
        state.pageError = "";
      })
      .addCase(submitPage.fulfilled, (state, action) => {
        state.pageLoading = false;
        state.page = action.payload;
        state.pageError = "";
      })
      .addCase(submitPage.rejected, (state, action) => {
        state.pageLoading = false;
        state.pageError = action.payload;
      })
      .addCase(updatePage.pending, (state) => {
        state.pageLoading = true;
        state.pageError = "";
      })
      .addCase(updatePage.fulfilled, (state, action) => {
        state.pageLoading = false;
        state.page = action.payload;
      })
      .addCase(updatePage.rejected, (state, action) => {
        state.pageLoading = false;
        state.pageError = action.payload;
      })
      .addCase(getPage.pending, (state) => {
        state.pageLoading = true;
        state.pageError = "";
      })
      .addCase(getPage.fulfilled, (state, action) => {
        state.selectedPage = action.payload;
        state.pageLoading = false;
      })
      .addCase(getPage.rejected, (state, action) => {
        state.pageLoading = false;
        state.pageError = action.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetPagesState, setPage, resetPageState } = pagesSlice.actions;

// Exporting default reducer
export default pagesSlice.reducer;
