import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Description from "components/SellWithUs/Description";
import MediaSection from "components/SellWithUs/Media";
import {
  getSellWithUsById,
  resetState,
  updateSellWithUs,
} from "store/slices/sellWithUsSlice";

import { uploadSellWithUsGalleryImages } from "services/sellWithUsService";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import { getErrorMessage } from "utils/helpers/httpRequests";
import parseEditorValue from "utils/helpers/parseEditorValue";
import reduceObject from "utils/helpers/reduceObject";
import StyledTextField from "utils/helpers/styledTextField";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";

function SellWithUs() {
  const params = useParams();
  const dispatch = useDispatch();
  const sellWithUs = useSelector((state) => state.sellWithUs.results);
  const loading = useSelector((state) => state.sellWithUs.loading);

  useEffect(
    () => () => {
      dispatch(resetState());
    },
    [dispatch]
  );

  useEffect(() => {
    const sellId = "65b23450b8277f3e5d75350b";
    const timeout = setTimeout(() => {
      dispatch(getSellWithUsById(sellId));
    }, 100);
    return () => clearTimeout(timeout);
  }, [dispatch]);

  const initialValues = useMemo(
    () => ({
      id: sellWithUs?.id,
      title: sellWithUs?.title ?? "",
      shortDescription: sellWithUs?.shortDescription
        ? parseEditorValue(sellWithUs?.shortDescription)
        : "",
      longDescription: sellWithUs?.longDescription
        ? parseEditorValue(sellWithUs?.longDescription)
        : "",

      sellWithUsGallery: sellWithUs?.gallery ?? [],
      videos: sellWithUs?.videos ?? [],
    }),
    [sellWithUs]
  );

  const handleSubmit = useCallback(
    (values) => {
      let formValues = {
        title: values.title,
        shortDescription: values?.shortDescription,
        longDescription: values?.longDescription,
        videos: values?.videos,
      };
      let sellWithUsValues = {
        title: sellWithUs.title,
        shortDescription: sellWithUs?.shortDescription,
        longDescription: sellWithUs?.longDescription,
        videos: sellWithUs?.videos,
      };
      let updatedValues = reduceObject(formValues, sellWithUsValues);

      const images = values.sellWithUsGallery.filter(
        (image) => typeof image === "object"
      );
      if (images.length > 0 && Object.keys(updatedValues).length > 0) {
        uploadSellWithUsGalleryImages("65b23450b8277f3e5d75350b", images);
        dispatch(
          updateSellWithUs({
            sellId: "65b23450b8277f3e5d75350b",
            ...updatedValues,
          })
        )
          .unwrap()
          .then(() => {
            toast.success("Updated successfully");
            dispatch(resetState());
            dispatch(getSellWithUsById(sellWithUs?.id));
          });
      } else if (images.length > 0) {
        uploadSellWithUsGalleryImages("65b23450b8277f3e5d75350b", images)
          .then(() => {
            toast.success("Updated successfully");
            dispatch(resetState());
            dispatch(getSellWithUsById(sellWithUs?.id));
          })
          .catch((error) => {
            toast.error(getErrorMessage(error));
          });
      } else if (Object.keys(updatedValues).length > 0) {
        dispatch(
          updateSellWithUs({
            sellId: "65b23450b8277f3e5d75350b",
            ...updatedValues,
          })
        )
          .unwrap()
          .then(() => {
            toast.success("Updated successfully");
            dispatch(resetState());
            dispatch(getSellWithUsById(sellWithUs?.id));
          });
      }
    },
    [sellWithUs, dispatch]
  );

  return (
    <Box px={1}>
      <Typography
        variant="h1"
        fontWeight="600"
        color="primary.main"
        fontSize={{ lg: "2.6rem", sm: "2rem" }}
        my={2}
      >
        {params?.id ? "Update Auction" : "Sell With Us Now"}
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <StyledMuiCard
                  title="Basic Information"
                  subTitle="Enter the basic information"
                >
                  <CardContent>
                    <Stack direction="row" gap={3} p={{ xs: 1, md: 1.5 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Title
                            </Typography>
                            <StyledTextField
                              fullWidth
                              type="text"
                              {...formik.getFieldProps("title")}
                              error={Boolean(
                                formik.touched.title && formik.errors.title
                              )}
                              helperText={
                                formik.touched.title && !!formik.errors.title
                                  ? formik.errors.title
                                  : "Enter a descriptive title here"
                              }
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  </CardContent>
                </StyledMuiCard>
              </Grid>
              {/*Description*/}
              <Grid item xs={12} lg={12}>
                <Description formik={formik} />
              </Grid>
              {/*Media Section */}
              <Grid item xs={12} lg={12}>
                <MediaSection formik={formik} />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" gap={2}>
                  <Button
                    disabled={loading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={formik.handleSubmit}
                    disableElevation
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    {params?.id ? "Update" : `Submit`}
                  </Button>
                  <Button
                    disabled={formik.isSubmitting}
                    onClick={() => formik.resetForm()}
                    variant="outlined"
                    color="primary"
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, lg: 175, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    Reset
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(SellWithUs);

const validationSchema = Yup.object().shape({
  id: Yup.string(),
  title: Yup.string()
    .trim()
    .required("Required*")
    .min(10, "Title should be at least 10 characters long")
    .max(100, "Title is too long"),
  shortDescription: Yup.string()
    .trim()
    .required("Required*")
    .min(10, "Description must be at least 10 characters long")
    .max(200, "Description is too long"),
  longDescription: Yup.string()
    .trim()
    .required("Required*")
    .min(10, "Description must be at least 10 characters long")
    .max(1300, "Description is too long"),
  sellWithUsGallery: Yup.mixed().required("Gallery Images are required"),
  videos: Yup.array()
    .of(Yup.string().required("Videos urls are required"))
    .min(1, "At least one video url is required"),
});
