import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ProductBasicInformation from "components/Products/ProductForm/Basic";
import ProductMedia from "components/Products/ProductForm/Media";
import ProductReports from "components/Products/ProductForm/Reports";
import PageHeader from "layout/PageHeader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { store } from "store/configureStore";
import { resetProductFormFilters } from "store/slices/filtersSlice";
import { getProduct, resetProductState } from "store/slices/productsSlice";
import { PRODUCT_FORM_STATUSES } from "utils/constants/product";
import ProductFormStatusButtons from "./ProductFormStatusButtons";

function ProductForm() {
  const params = useParams();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.products.productLoading);
  const status = useSelector((state) => state.products.productFormStatus);

  /* TODO: Should be uncomment when the live environment is ready */
  useEffect(
    () => () => {
      dispatch(resetProductState());
      dispatch(resetProductFormFilters()); // Filters State
    },
    [dispatch]
  );

  return (
    <Box px={1} py={2}>
      <PageHeader title={`${params?.id ? "Edit" : "Add"} Product`} />
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="10vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <ProductFormStatusButtons />
          {status === PRODUCT_FORM_STATUSES.BASIC && (
            <ProductBasicInformation />
          )}
          {status === PRODUCT_FORM_STATUSES.MEDIA && <ProductMedia />}
          {status === PRODUCT_FORM_STATUSES.REPORTS && <ProductReports />}
        </>
      )}
    </Box>
  );
}

export default ProductForm;

export function loadProduct({ params }) {
  store.dispatch(getProduct(params.id));
  return null;
}
