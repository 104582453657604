import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import StyledTextField from "utils/helpers/styledTextField";

function BasicInformationSection({ formik }) {
  const params = useParams;
  return (
    <StyledMuiCard
      title="Basic Information"
      subTitle="Please enter the basic information of the blog."
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{
            "& .MuiFormHelperText-root": {
              ml: 0,
            },
          }}
        >
          <Grid item xs={6} lg={6}>
            <Stack direction="column" gap={1}>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Title
              </Typography>
              <StyledTextField
                fullWidth
                name="title"
                type="text"
                value={formik.values.title}
                onChange={(e) => {
                  formik.setFieldValue("title", e.target.value);
                  !params?.id &&
                    formik.setFieldValue(
                      "slug",
                      e.target.value.replace(/\s+/g, "-").toLowerCase()
                    );
                }}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.title && formik.errors.title)}
                helperText={
                  formik.touched.title && !!formik.errors.title
                    ? formik.errors.title
                    : "Enter a descriptive title of the blog"
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={6} lg={6}>
            <Stack direction="column" gap={1}>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Meta Title
              </Typography>
              <StyledTextField
                fullWidth
                type="text"
                {...formik.getFieldProps("metaTitle")}
                error={Boolean(
                  formik.touched.metaTitle && formik.errors.metaTitle
                )}
                helperText={
                  formik.touched.metaTitle && !!formik.errors.metaTitle
                    ? formik.errors.metaTitle
                    : "Enter a descriptive meta title of the blog"
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={6} lg={6}>
            <Stack direction="column" gap={1}>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Slug
              </Typography>
              <StyledTextField
                fullWidth
                disabled={!params?.id}
                {...formik.getFieldProps("slug")}
                error={Boolean(formik.touched.slug && formik.errors.slug)}
                helperText={
                  formik.touched.slug &&
                  !!formik.errors.slug &&
                  formik.errors.slug
                }
              />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </StyledMuiCard>
  );
}

BasicInformationSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default BasicInformationSection;
