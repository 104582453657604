import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import queryString from "query-string";
import { memo, useCallback, useLayoutEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import USER_ROLE from "utils/constants/userRole";
import UserSearch from "./UserSearch";

function Filters() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const [value, setValue] = useState("all");
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleChange = useCallback(
    (_, value) => {
      setValue(value);
      // Necessary for performance
      // eslint-disable-next-line no-unused-vars
      const { role, ...rest } = query;
      let params = { ...rest };
      switch (value) {
        case USER_ROLE.USER:
          params.role = USER_ROLE.USER;
          break;
        case USER_ROLE.AGENT:
          params.role = USER_ROLE.AGENT;
          break;
        case USER_ROLE.INFLUENCER:
          params.role = USER_ROLE.INFLUENCER;
          break;
        case USER_ROLE.ADMIN:
          params.role = USER_ROLE.ADMIN;
          break;
        case USER_ROLE.SUPER_ADMIN:
          params.role = USER_ROLE.SUPER_ADMIN;
          break;
        default:
          break;
      }
      navigate({
        pathname: "/users",
        search: createSearchParams(params).toString(),
      });
    },
    [navigate, query]
  );

  useLayoutEffect(() => {
    if (query.role) {
      setValue(query.role);
    }
  }, [query.role]);

  return (
    <Box py={1} sx={containerStyles}>
      <Box
        sx={{
          backgroundColor: "white",
          marginBottom: { xs: 2, md: 0 },
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Product status filters"
        >
          <Tab label="All Users" value="all" />
          <Tab label="User" value={USER_ROLE.USER} />
          <Tab label="Agent" value={USER_ROLE.AGENT} />
          <Tab label="Influencer" value={USER_ROLE.INFLUENCER} />
          <Tab label="Admin" value={USER_ROLE.ADMIN} />
          <Tab label="Super Admin" value={USER_ROLE.SUPER_ADMIN} />
        </Tabs>
      </Box>
      <Box display={isSmall ? "grid" : "flex"}>
        <UserSearch />
      </Box>
    </Box>
  );
}

export default memo(Filters);

const containerStyles = {
  display: { xs: "grid", md: "flex" },
  justifyContent: "space-between",
  alignItems: "baseline",
  "& .styled-button": {
    borderRadius: "1px",
    padding: "10px",
  },
  "& .MuiOutlinedInput-root": { borderRadius: "20px" },
  "& .MuiTab-root": { textTransform: "capitalize" },
};
