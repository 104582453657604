import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setObsDataDialogState } from "store/slices/uiSlice";
import { toast } from "utils/hooks/useToast";

function ObsDataDialog() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.UI.isObsDataDialogOpen);
  const obsData = useSelector((state) => state.liveStreaming.obsData);

  const handleClose = useCallback(() => {
    dispatch(setObsDataDialogState(false));
  }, [dispatch]);

  const handleCopyKey = useCallback((name, key) => {
    navigator.clipboard.writeText(key ?? "").then(() => {
      toast.success(`${name} copied to clipboard`);
    });
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": { minWidth: { lg: "1000px" } },
      }}
      PaperProps={{
        elevation: 0,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={5}
        bgcolor="primary.main"
        sx={{
          padding: "0.25rem 0 0.25rem 2rem",
        }}
      >
        <Stack direction="column">
          <Typography variant="h5" component="h1" color="white">
            OBS Keys
          </Typography>
          <Typography variant="body2" color="white">
            Select the product category to add relevant details of the products
          </Typography>
        </Stack>
        <IconButton onClick={handleClose} size="large">
          <CancelIcon sx={{ color: "white", fontSize: "2.5rem" }} />
        </IconButton>
      </Stack>
      <Stack
        direction="column"
        gap={2}
        minHeight={"200px"}
        pt={2}
        px={4}
        pb={4}
      >
        <Typography variant="h5" component="p" color="primary" fontWeight="500">
          RTMP URL
        </Typography>
        <Stack direction="row" gap={2} alignItems="center">
          <TextField
            variant="outlined"
            value={obsData?.rtmpUrl ?? ""}
            fullWidth
            size="medium"
          />
          <ContentCopyIcon
            sx={{ cursor: "pointer" }}
            onClick={() => handleCopyKey("RTMP Url", obsData?.rtmpUrl)}
          />
        </Stack>
        <Typography
          variant="h5"
          component="p"
          color="primary"
          fontWeight="500"
          size="medium"
        >
          Secret Key
        </Typography>
        <Stack direction="row" gap={2} alignItems="center">
          <TextField
            variant="outlined"
            value={obsData?.secretKey ?? ""}
            fullWidth
          />
          <ContentCopyIcon
            sx={{ cursor: "pointer" }}
            onClick={() => handleCopyKey("Secret Key", obsData?.secretKey)}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
}

export default ObsDataDialog;
