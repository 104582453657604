import styled from "@mui/material/styles/styled";
import TextField from "@mui/material/TextField";
import { memo } from "react";

const MUIStyledTextField = styled(TextField)({
  "& .MuiFormHelperText-root": {
    fontSize: "0.8rem",
    margin: "4px 0 0 0",
  },
});

export default memo(MUIStyledTextField);
