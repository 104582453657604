import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import deleteIcon from "assets/icons/deleteIcon.svg";
import WritingIcon from "assets/icons/WritingIcon.svg";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledDataGrid from "components/StyledGrid";
import StyledSwitch from "components/ToggleButton";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteSubBanner,
  getSubBanners,
  updateSubBannerStatus,
} from "store/slices/bannersSlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import useQueryParams from "utils/hooks/useQueryParams";

export default function DataGrid() {
  const { id, device } = useParams();
  const params = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { results, loading, page } = useSelector((state) => state.banners);

  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  const get_sub_banners = useCallback(() => {
    dispatch(getSubBanners({ params, id }));
  }, [dispatch, id, params]);

  const onChange = useCallback(
    (row) => {
      dispatch(
        updateSubBannerStatus({
          id: row.id,
          status: !row.status,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      get_sub_banners();
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, get_sub_banners]);

  const handleClickOpen = useCallback(
    (bannerId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(bannerId));
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 40,
        flex: 0.2,
        filterable: false,
      },
      {
        field: "image",
        headerName: "Image",
        type: "actions",
        minWidth: 90,
        flex: 0.5,
        renderCell: (params) => (
          <Box>
            <img
              src={params?.row?.image}
              height={
                isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
              }
              width={
                isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
              }
              style={{
                borderRadius: "10px",
                marginTop: 4,
                objectFit: "contain",
              }}
              alt={params?.row?.name}
            />
          </Box>
        ),
      },
      {
        field: "name",
        headerName: "Name",
        minWidth: 180,
        flex: 0.8,
        editable: true,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.name}
          </Typography>
        ),
      },
      device === "web" && {
        field: "url",
        headerName: "Url",
        minWidth: 200,
        flex: 1,
        editable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">{params?.row?.url}</Typography>
        ),
      },
      device === "mobile" && {
        field: "linkId",
        headerName: "Link Id",
        minWidth: 230,
        flex: 1,
        editable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.linkId}
          </Typography>
        ),
      },
      device === "mobile" && {
        field: "type",
        headerName: "Link Type",
        minWidth: 80,
        flex: 1,
        editable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.type}
          </Typography>
        ),
      },
      {
        field: "active",
        headerName: "Active",
        type: "actions",
        minWidth: 90,
        flex: 0.8,
        editable: true,
        renderCell: (params) => (
          <FormControlLabel
            control={
              <StyledSwitch
                checked={params?.row?.status}
                onChange={() => onChange(params.row)}
              />
            }
          />
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 90,
        flex: 0.6,
        renderCell: (params) => (
          <Box display="flex" gap={1}>
            <IconButton
              size="small"
              onClick={() => navigate(`${params.id}/update`)}
            >
              <img src={WritingIcon} alt="edit" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => handleClickOpen(params?.id)}
            >
              <img src={deleteIcon} alt="delete" />
            </IconButton>
          </Box>
        ),
      },
    ],
    [device, isTablet, isLaptop, isLed, onChange, navigate, handleClickOpen]
  );
  return (
    <>
      <StyledDataGrid
        rows={results}
        columns={columns}
        loading={loading}
        totalPages={page}
      />
      <ConfirmDelete deleteAction={deleteSubBanner} />
    </>
  );
}
