import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/httpRequests";
import { toast } from "utils/hooks/useToast";
import { httpPages } from "utils/httpRequest/http";

export const getSubscriptions = createAsyncThunk(
  "subscriptions/getSubscriptions",
  async (params = {}, { rejectWithValue }) => {
    try {
      const response = await httpPages.get("/subscriptions/admin", {
        params: {
          ...params,
          page: params.page ?? 1,
          limit: params.limit ?? 20,
        },
      });
      const responseData = getResponseData(response);

      responseData &&
        responseData?.results.forEach((item, index) => {
          const pageStart = (responseData?.page - 1) * responseData?.limit;
          item.serialNumber = pageStart + index + 1;
        });

      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getSubscription = createAsyncThunk(
  "subscriptions/getSubscription",
  async (id, { getState, fulfillWithValue, rejectWithValue }) => {
    const results = getState().pages.results;
    const page = results.find((data) => data.id === id);
    if (page) {
      return fulfillWithValue(page);
    }
    try {
      const response = await httpPages.get(`/subscriptions/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const submitSubscriptions = createAsyncThunk(
  "subscriptions/submitSubscriptions",
  async (data = {}, { rejectWithValue }) => {
    try {
      const response = await httpPages.post(`/subscriptions`, data);

      const responseData = getResponseData(response);

      if (!responseData?.id) {
        throw new Error("Something went wrong!");
      }

      toast.success("Subscription successfully created");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteSubscription = createAsyncThunk(
  "subscriptions/deleteSubscription",
  async (subscriptionId, { getState, rejectWithValue }) => {
    const results = getState().subscriptions.results;
    try {
      const response = await httpPages.delete(
        `/subscriptions/${subscriptionId}`
      );
      if (response.status === 200) {
        const returnedData = results.filter(
          (data) => data.id !== subscriptionId
        );
        toast.success("Subscription Deleted Successfully");
        return returnedData;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateSubscription = createAsyncThunk(
  "subscriptions/updateSubscription",
  async (values = {}, { rejectWithValue }) => {
    try {
      const { id = "", updatedValues = {} } = values;
      const response = await httpPages.patch(`/subscriptions/${id}`, {
        ...updatedValues,
      });
      if (response.status === 200) {
        toast.success("Subscription Updated successfully");
      }
      return response?.data;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: true,
  subscriptionsLoading: false,
  selectedSubscriptions: null,
  results: [],
  page: 1,
  limit: 20,
  totalPages: 0,
  totalResults: 0,
  error: "",
  pageError: "",
};

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    // Actions
    resetSubscriptionsState: () => initialState,
    resetSubscriptions: (state) => {
      state.loading = false;
      state.selectedSubscriptions = null;
      state.pageError = "";
    },
    setSubscriptions: (state, action) => {
      state.selectedSubscriptions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptions.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getSubscriptions.fulfilled, (state, action) => {
        state.results = action.payload.results;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
        state.loading = false;
      })
      .addCase(getSubscriptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteSubscription.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
        state.error = "";
      })
      .addCase(deleteSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(submitSubscriptions.pending, (state) => {
        state.subscriptionsLoading = true;
        state.pageError = "";
      })
      .addCase(submitSubscriptions.fulfilled, (state, action) => {
        state.subscriptionsLoading = false;
        state.page = action.payload;
        state.pageError = "";
      })
      .addCase(submitSubscriptions.rejected, (state, action) => {
        state.subscriptionsLoading = false;
        state.pageError = action.payload;
      })
      .addCase(updateSubscription.pending, (state) => {
        state.subscriptionsLoading = true;
        state.pageError = "";
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        state.subscriptionsLoading = false;
        state.page = action.payload;
      })
      .addCase(updateSubscription.rejected, (state, action) => {
        state.subscriptionsLoading = false;
        state.pageError = action.payload;
      })
      .addCase(getSubscription.pending, (state) => {
        state.loading = true;
        state.pageError = "";
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.selectedSubscriptions = action.payload;
        state.loading = false;
      })
      .addCase(getSubscription.rejected, (state, action) => {
        state.loading = false;
        state.pageError = action.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetSubscriptionsState, setSubscriptions, resetSubscriptions } =
  subscriptionsSlice.actions;

// Exporting default reducer
export default subscriptionsSlice.reducer;
