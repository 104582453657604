import Stack from "@mui/material/Stack";
import ProductDataGrid from "components/Products/ProductDataGrid";
import ProductFilters from "components/Products/ProductFilters";
import ProductForm from "components/Products/ProductForm";
import ProductStatusesButtons from "components/Products/ProductStatusesButtons";
import PageHeader from "layout/PageHeader";
import PropTypes from "prop-types";
import { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetState } from "store/slices/productsSlice";

function Products({ create, edit }) {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(resetState());
  }, [dispatch]);

  if (create || edit) {
    return <ProductForm />;
  }
  return (
    <Stack direction="column" py={3} px={1} spacing={2}>
      <PageHeader title="Products" />
      <ProductFilters />
      <ProductStatusesButtons />
      <ProductDataGrid />
    </Stack>
  );
}

Products.propTypes = {
  create: PropTypes.bool,
  edit: PropTypes.bool,
};

Products.defaultProps = {
  create: false,
  edit: false,
};

export default memo(Products);
