import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import AddActions from "./AddActions";
import DataGrid from "./DataGrid";

function SubscriptionsDataGrid() {
  return (
    <Box sx={{ flexGrow: 1, py: 3, px: 1 }}>
      <Typography
        variant="h4"
        fontWeight="600"
        mb={1}
        sx={{ fontSize: { md: "1.5rem", lg: "2rem" } }}
      >
        SUBSCRIPTIONS
      </Typography>
      <Box my={2}>
        <AddActions />
      </Box>
      <DataGrid />
    </Box>
  );
}

export default memo(SubscriptionsDataGrid);
