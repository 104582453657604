import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import grey from "@mui/material/colors/grey";
import PropTypes from "prop-types";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "store/slices/filtersSlice";
import StyledMuiCard from "utils/helpers/StyledMuiCard";

const Services = ({ formik }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.filters.users);
  const loading = useSelector((state) => state.filters.loading.services);
  const services = useSelector((state) => state.filters.services);
  const product = useSelector((state) => state.products.product);
  const category = useSelector((state) => state.filters.category);

  const handleChange = (event) => {
    if (event.target.checked) {
      formik.setFieldValue("services", [
        ...formik.values.services,
        event.target.name,
      ]);
      if (formik.values.removeServices.includes(event.target.name))
        formik.setFieldValue(
          "removeServices",
          formik.values.removeServices.filter(
            (service) => service !== event.target.name
          )
        );
    } else if (!event.target.checked) {
      if (formik.initialValues.services.includes(event.target.name)) {
        formik.setFieldValue("removeServices", [
          ...formik.values.removeServices,
          event.target.name,
        ]);
      }
      if (formik.values.services.includes(event.target.name)) {
        formik.setFieldValue(
          "services",
          formik.values.services.filter(
            (service) => service !== event.target.name
          )
        );
      }
    }
  };

  useEffect(() => {
    const id = setTimeout(() => {
      if (formik.values?.ownerId && category) {
        const user = users.find((user) => user?.id === formik.values?.ownerId);
        dispatch(
          getServices({
            serviceRole: user?.role === "agent" ? "agent" : "individual",
            category: category?.id ?? "",
          })
        );
      }
    }, 500);
    return () => clearTimeout(id);
  }, [formik.values.ownerId, users, category, dispatch]);

  useEffect(() => {
    formik.setFieldValue(
      "services",
      product?.purchasedService?.map((service) => service?.id) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.ownerId, product?.purchasedServicesId]);

  return (
    <StyledMuiCard
      title="Select Services"
      subTitle="Select services you want for your product"
    >
      <CardContent>
        <Box px={2}>
          {formik.values.ownerId &&
            category &&
            !loading &&
            !services.length && (
              <Typography
                fontSize="1.2rem"
                fontWeight={500}
                color={grey[700]}
                textAlign="center"
              >
                No services available
              </Typography>
            )}

          {!loading && services.length && formik.values.ownerId && category ? (
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                {services.map((service) => (
                  <Stack
                    key={service.id}
                    direction="row"
                    alignItems="center"
                    sx={{
                      "& .MuiFormControlLabel-root": {
                        marginRight: 0,
                      },
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "2rem",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={
                            service?.required ||
                            formik.values.services.includes(service.id)
                          }
                          disabled={service?.required}
                          onChange={handleChange}
                          name={service.id}
                        />
                      }
                      // label={`${service?.title} | ${service?.currency} ${service?.price}`}
                    />
                    <Typography
                      component="p"
                      fontSize="1.2rem"
                      fontWeight={500}
                      color={grey[700]}
                    >
                      {`${service?.title} | `}
                      <span
                        style={{
                          color: grey[500],
                        }}
                      >{`${service?.currency} ${service?.price}`}</span>
                    </Typography>
                  </Stack>
                ))}
              </FormGroup>
            </FormControl>
          ) : null}

          {!formik.values.ownerId && (
            <Typography
              component="p"
              fontSize="1.2rem"
              fontWeight={500}
              color={grey[700]}
              textAlign="center"
            >
              Select user to view services
            </Typography>
          )}

          {!category && (
            <Typography
              component="p"
              fontSize="1.2rem"
              fontWeight={500}
              color={grey[700]}
              textAlign="center"
            >
              Product category is unavailable
            </Typography>
          )}
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
};

Services.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(Services, arePropsEqual);

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.formik.initialValues === nextProps.formik.initialValues &&
    prevProps.formik.values.services === nextProps.formik.values.services &&
    prevProps.formik.values.ownerId === nextProps.formik.values.ownerId
  );
}
