import Box from "@mui/material/Box";
import AddCategoryActionButton from "components/Category/CategoryHeaderComponents/AddCategoryActionButton";
function index() {
  return (
    <Box py={2}>
      <AddCategoryActionButton />
    </Box>
  );
}

export default index;
