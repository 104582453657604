import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CurrencyFormatter from "components/CurrencyFormatter";
import ObsDataDialog from "components/Dialog/ObsDataDialog";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  endAuction,
  getLiveAuctionVideo,
  getObsKeys,
  getPreviousBids,
  placeSuccessfulBid,
  startAuction,
  startVideoStreaming,
} from "store/slices/liveStreamingSlice";
import {
  setIsExtendTimerDialogOpen,
  setObsDataDialogState,
} from "store/slices/uiSlice";
import auctionStatuses from "utils/constants/auctionStatuses";
import { removeDefaultDatabaseProperties } from "utils/helpers/removeDefaultDatabaseProperties";
import { splitProductSpecString } from "utils/helpers/splitProductSpecString";
import CircularTimeProgress from "./CircularTimeProgress";
import ExtendTimeDialog from "./ExtendTimeDialog";
import PreviousBidTile from "./PreviousBidTile";
import ProductDescriptionTiles from "./ProductDescriptionTiles";

function Product({ socket }) {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.liveStreaming.loading);
  const bids = useSelector((state) => state.liveStreaming.bids);
  const video = useSelector((state) => state.liveStreaming?.video);
  const auction = useSelector((state) => state.liveStreaming.auction);
  const product = useSelector((state) => state.liveStreaming.product);
  const products = useSelector((state) => state.liveStreaming.products);
  const obsData = useSelector((state) => state.liveStreaming.obsData);
  const [isExtendTimeButtonDisabled, setIsExtendTimeButtonDisabled] =
    useState(false);
  const [isAuctionDisabled, setIsAuctionDisabled] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleDialogState = useCallback(
    (value) => dispatch(setIsExtendTimerDialogOpen(value)),
    [dispatch]
  );

  const handleEndAuction = useCallback(() => {
    if (video) {
      product &&
        dispatch(placeSuccessfulBid(product?.id ?? product?._id ?? ""));
      dispatch(endAuction(video?.slug))
        .unwrap()
        .then(() => {
          navigate("/live-streaming");
        });
    }
  }, [dispatch, navigate, product, video]);

  const handleStartAuction = useCallback(() => {
    dispatch(startAuction(auction?.id ?? auction?._id ?? "")).then(() => {
      dispatch(getLiveAuctionVideo(params?.id ?? ""));
    });
  }, [dispatch, auction, params]);

  const startStreaming = useCallback(() => {
    dispatch(startVideoStreaming(video?.id ?? video?._id ?? ""))
      .unwrap()
      .then(() => {
        dispatch(setObsDataDialogState(true));
      });
  }, [dispatch, video]);

  const getOBSKeys = useCallback(() => {
    dispatch(getObsKeys(video?.slug || ""));
  }, [dispatch, video]);

  const openOBSKeysDialog = useCallback(() => {
    !obsData && getOBSKeys();
    dispatch(setObsDataDialogState(true));
  }, [dispatch, getOBSKeys, obsData]);

  const specifications = useMemo(() => {
    const specifications = removeDefaultDatabaseProperties(
      product?.productSpecs ?? {}
    );

    if (!Object.keys(specifications).length) return [];

    return Object.entries({ ...specifications });
  }, [product?.productSpecs]);

  useEffect(() => {
    if (socket && product) {
      socket.emit("bidRoom", {
        productId: product?.id ?? product?._id ?? "",
      });
      return () => {
        socket.emit("leaveBidRoom", {
          productId: product?.id ?? product?._id ?? "",
        });
      };
    }
  }, [socket, product]);

  useEffect(() => {
    if (isAuctionDisabled) {
      const id = setInterval(() => {
        if (
          video &&
          auction &&
          dayjs().isAfter(dayjs(auction?.startDate).subtract(10, "m"))
        ) {
          setIsAuctionDisabled(false);
        } else setIsAuctionDisabled(true);
      }, 1000);
      return () => {
        clearInterval(id);
      };
    }
  }, [auction, isAuctionDisabled, video]);

  useEffect(() => {
    if (product) setSelectedProduct(product);
    else if (products.length) {
      setSelectedProduct(products[0]);
      dispatch(getPreviousBids(products[0]?.id ?? products[0]?._id ?? ""));
    }
  }, [auction?.auctionStatus, dispatch, product, products, video?.slotStatus]);

  return (
    <>
      <Stack
        height="75vh"
        width="100%"
        direction="column"
        justifyContent={product && "space-between"}
        borderRadius="10px"
        bgcolor="white"
        boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.16)"
      >
        <Box
          px={2}
          py={1}
          bgcolor="#f9f9f9"
          boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.15)"
          sx={{
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
          }}
        >
          <Typography
            variant="h3"
            color="primary.main"
            fontWeight="bold"
            textTransform="capitalize"
          >
            On the Block
          </Typography>
        </Box>

        {selectedProduct ? (
          <>
            <Box px={2} py={1}>
              <Typography variant="h4" color="text.secondary" fontWeight="bold">
                {selectedProduct?.productName ?? "N/A"}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Lot#{" "}
                <span style={{ fontWeight: "bold" }}>
                  {selectedProduct?.lotNumber}
                </span>
              </Typography>
            </Box>

            <Divider />

            <Stack
              direction="column"
              justifyContent="center"
              px={2}
              py={1}
              gap={1}
              flex="1"
              sx={{ overflowY: "auto" }}
            >
              {specifications?.length
                ? specifications.map((specification, index) => (
                    <ProductDescriptionTiles
                      key={index}
                      name={splitProductSpecString(specification[0])}
                      value={specification[1]}
                    />
                  ))
                : null}
            </Stack>
            <Divider />

            <Stack
              px={2}
              py={{ xs: 1, xl: 2 }}
              bgcolor="white"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={0.5}
            >
              <Stack direction="column" gap={1}>
                <Typography
                  variant="body1"
                  color="text.disabled"
                  fontWeight="bold"
                >
                  Current Bid
                </Typography>
                <CurrencyFormatter
                  fontWeight="bold"
                  color="text.secondary"
                  fontSize={{ xs: "0.9rem", lg: "1.1rem" }}
                  number={
                    bids?.currentBid?.bidAmount ??
                    selectedProduct?.currentBid?.bidAmount ??
                    product?.reservePrice ??
                    0
                  }
                />
              </Stack>
              <CircularTimeProgress
                socket={socket}
                setIsExtendTimeButtonDisabled={setIsExtendTimeButtonDisabled}
              />
            </Stack>
            <Divider />

            <Stack
              px={2}
              py={{ xs: 1, xl: 2 }}
              bgcolor="white"
              direction="column"
              gap={1.5}
            >
              <Typography
                variant="body1"
                color="text.disabled"
                fontWeight="bold"
                lineHeight={1}
                mt={1}
              >
                Previous Bids
              </Typography>
              {bids?.previousBids?.length ? (
                <Stack
                  direction="row"
                  gap={1.5}
                  sx={{
                    overflowX: "auto",
                  }}
                >
                  {bids?.previousBids.map((bid, index) => (
                    <PreviousBidTile
                      key={index}
                      name="Bidder Name 01"
                      bid={bid?.bidAmount}
                    />
                  ))}
                </Stack>
              ) : (
                <Typography
                  variant="body1"
                  color="text.disabled"
                  textAlign="center"
                  lineHeight={1}
                  mb={2}
                >
                  No previous bids
                </Typography>
              )}
            </Stack>
          </>
        ) : (
          <Box flex={1} />
        )}

        <Divider />

        <Stack
          px={2}
          py={1.5}
          bgcolor="white"
          direction="row"
          justifyContent="space-between"
          gap={2}
          sx={{
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}
        >
          <Box flex={1}>
            {video?.slotStatus === "scheduled" && (
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                disableElevation
                disabled={
                  !auction || !video || isAuctionDisabled || loading?.obsData
                }
                onClick={startStreaming}
                sx={{
                  minHeight: "50px",
                }}
              >
                Get OBS Keys
              </Button>
            )}
            {video && video?.slotStatus === "inUse" && (
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={openOBSKeysDialog}
                disabled={loading?.obsData}
                sx={{
                  minHeight: "50px",
                }}
              >
                View OBS Keys
              </Button>
            )}
          </Box>
          <Box flex={1}>
            {product && (
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                sx={{
                  minHeight: "50px",
                }}
                disabled={isExtendTimeButtonDisabled}
                onClick={() => handleDialogState(true)}
              >
                Extend Time
              </Button>
            )}
          </Box>
          <Box flex={1}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              sx={{
                minHeight: "50px",
              }}
              onClick={
                auction?.auctionStatus === auctionStatuses.ACTIVE
                  ? handleEndAuction
                  : auction?.auctionStatus === auctionStatuses.SCHEDULED
                  ? handleStartAuction
                  : null
              }
              disabled={
                !video ||
                !auction ||
                isAuctionDisabled ||
                loading?.startAuction ||
                loading?.endAuction ||
                video?.slotStatus === "scheduled"
              }
            >
              {auction?.auctionStatus === auctionStatuses.ACTIVE
                ? "End Auction"
                : "Start Auction"}
            </Button>
          </Box>
        </Stack>
      </Stack>
      <ExtendTimeDialog socket={socket} />
      <ObsDataDialog />
    </>
  );
}

Product.propTypes = {
  socket: PropTypes.object,
};

export default memo(Product);
