import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SettingSideTabs from "components/Settings/SettingSideTabs";
import { memo } from "react";

function Settings() {
  return (
    <Box py={2} px={1}>
      <Typography
        variant="h4"
        fontWeight="600"
        mb={2}
        sx={{ fontSize: { md: "1.5rem", lg: "2rem", margin: "2rem 0 1.1rem" } }}
      >
        SETTINGS
      </Typography>
      <SettingSideTabs />
    </Box>
  );
}

export default memo(Settings);
