import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Product from "components/LiveStreaming/Product";
import ProductsList from "components/LiveStreaming/ProductsList";
import VideoPlayerPlaceholder from "components/LiveStreaming/VideoPlayerPlaceholder";
import ReactVideoPlayer from "components/ReactVideoPlayer";
import PageHeader from "layout/PageHeader";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getNewAccessToken } from "services/authServices";
import { io } from "socket.io-client";
import {
  getAuction,
  getAuctionProduct,
  getAuctionVideo,
  getLiveAuctionVideo,
  getPreviousBids,
  placeSuccessfulBid,
  resetState,
  setAuctionProducts,
  setBids,
} from "store/slices/liveStreamingSlice";
import {
  setIsExtendTimerDialogOpen,
  setPushProductDialogState,
} from "store/slices/uiSlice";
import auctionStatuses from "utils/constants/auctionStatuses";
import isTokenExpired from "utils/helpers/isTokenExpired";

function LiveStreamingVideo() {
  const params = useParams();
  const dispatch = useDispatch();
  const videoId = useSelector(
    (state) => state.liveStreaming?.auction?.videoId ?? ""
  );
  const product = useSelector((state) => state.liveStreaming.product);
  const products = useSelector((state) => state.liveStreaming.products);
  const auction = useSelector((state) => state.liveStreaming.auction);
  const video = useSelector((state) => state.liveStreaming?.video ?? null);
  const [socket, setSocket] = useState(null);

  const getProduct = useCallback(
    (id) => {
      dispatch(getAuctionProduct(id))
        .unwrap()
        .then(() => {
          dispatch(setPushProductDialogState(false));
          dispatch(
            setAuctionProducts(products.filter((p) => (p._id || p.id) !== id))
          );
          dispatch(getPreviousBids(id));
        });
    },
    [dispatch, products]
  );

  /* Socket Start*/
  const socketInitializer = useCallback(async () => {
    if (isTokenExpired()) {
      await getNewAccessToken();
    }

    const _socket = io(process.env.REACT_APP_SOCKET_URL + "/comment", {
      reconnectionAttempts: 3,
      reconnectionDelay: 1000 * 60,
      reconnectionDelayMax: 1000 * 60,
      reconnection: true,
      transports: ["websocket"],
      query: {
        roomId: videoId,
      },
      auth: {
        token: localStorage.getItem("token"),
      },
    });

    //socket events
    _socket.on("connect", () => {
      _socket.on("pushProduct", (response) => {
        if (product) {
          dispatch(placeSuccessfulBid(product?.id ?? product?._id ?? ""))
            .unwrap()
            .then(() => {
              getProduct(response?.data?.product);
            });
        } else {
          getProduct(response?.data?.product);
        }
      });

      _socket.on("newBid", (response) => {
        dispatch(setBids(response?.data));
      });

      _socket.on("addTime", (response) => {
        dispatch(getAuctionProduct(response?.data?.productId));
        dispatch(setIsExtendTimerDialogOpen(false));
      });
    });
    setSocket(() => _socket);
  }, [dispatch, getProduct, product, videoId]);

  useEffect(() => {
    !socket && videoId && socketInitializer();
    return () => {
      if (socket) {
        socket.disconnect();
        setSocket(null);
      }
    };
  }, [socket, socketInitializer, videoId]);
  /* Socket End */

  useEffect(() => {
    if (params?.id) {
      const id = setTimeout(() => {
        dispatch(getAuction(params?.id ?? ""))
          .unwrap()
          .then((response) => {
            if (response?.auctionStatus === auctionStatuses.ACTIVE) {
              dispatch(getLiveAuctionVideo(params?.id ?? ""));
            } else dispatch(getAuctionVideo(params?.id ?? ""));

            if (response?.currentProductId) {
              dispatch(getAuctionProduct(response.currentProductId ?? ""));
              dispatch(getPreviousBids(response.currentProductId ?? ""));
            }
          });
      }, 200);
      return () => {
        clearTimeout(id);
      };
    }
  }, [dispatch, params?.id]);

  useEffect(() => {
    return () => dispatch(resetState());
  }, [dispatch]);

  return (
    <Stack direction="column" pt={3} px={2} gap={2}>
      <PageHeader title="Live Streaming" />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={5} xxl={3}>
          {video?.slotStatus === "inUse" &&
          auction?.auctionStatus === auctionStatuses.ACTIVE ? (
            <ReactVideoPlayer />
          ) : (
            <VideoPlayerPlaceholder />
          )}
        </Grid>
        <Grid item xs={12} lg={7} xxl={5}>
          <Product socket={socket} />
        </Grid>
        <Grid item xs={12} xxl={4}>
          <ProductsList socket={socket} />
        </Grid>
      </Grid>
    </Stack>
  );
}

export default memo(LiveStreamingVideo);
