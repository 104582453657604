export const PRIMARY_COLOR = "#ed4928";
export const PRIMARY_LIGHT = "#e3f2fd";
export const LINK_TEXT = "#0266d6";
export const FOOTER_ICON_COLOR = "#ea5455";
export const SEARCH_ICON_COLOR = "#a8a8a8";
export const BACKGROUND = "#fbfbfb";
export const CARD_HEADER_BACKGROUND = "#f6f6f6";
export const ACTIVE_COLOR = "#00b850";
export const INACTIVE_COLOR = "#707070";
export const FILTERS_BORDER_COLOR = "primary.main";
export const TWITTER = "#00ACEE";
export const WHATSAPP = "#5FFC7B";
export const FACEBOOK = "#3B5998";
export const YOUTUBE = "#FF0000";
export const BUTTON_HOVER = "#edf8fd";
export const BUTTON_HOVER_BORDER_COLOR = "#02b4fffc";
export const KEY_NUMBERS_BACKGROUND_COLOR = "#eafafd";
export const STATS_NUMBER_FORMATTER_COLOR = "#707070";

export const FILTERS_BACKGROUND_COLOR = "#ffffff";
export const STATS_PRIMARY_ORANGE = "#ed4928";
export const DASHBOARD_CHART_ROW_COLOR = "#f3f3f3";
export const STATS_PRIMARY_GREY = "#82868b";
export const STATS_GREEN = "#04e38a";
export const FILTER_TEXT_COLOR = "#707070";
