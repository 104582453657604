import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import { STATS_NUMBER_FORMATTER_COLOR } from "styles/colors";

export default function CheckboxList({ formik }) {
  return (
    <FormControl sx={containerStyles}>
      <List>
        <Grid container spacing={2}>
          <Grid item md={4} lg={3} xl={2.4}>
            <FormControlLabel
              value={formik?.values?.view}
              checked={formik?.values?.view}
              control={<Checkbox />}
              label="View"
              onChange={() =>
                formik.setFieldValue("view", !formik?.values?.view)
              }
            />
          </Grid>
          <Grid item md={4} lg={3} xl={2.4}>
            <FormControlLabel
              value={formik?.values?.auction}
              checked={formik?.values?.auction}
              control={<Checkbox />}
              label="Auction"
              onChange={() =>
                formik.setFieldValue("auction", !formik?.values?.auction)
              }
            />
          </Grid>
          <Grid item md={4} lg={3} xl={2.4}>
            <FormControlLabel
              value={formik?.values?.watchlist}
              checked={formik?.values?.watchlist}
              control={<Checkbox />}
              label="Watchlist"
              onChange={() =>
                formik.setFieldValue("watchlist", !formik?.values?.watchlist)
              }
            />
          </Grid>
          <Grid item md={4} lg={3} xl={2.4}>
            <FormControlLabel
              value={formik?.values?.alerts}
              checked={formik?.values?.alerts}
              control={<Checkbox />}
              label="Alerts"
              onChange={() =>
                formik.setFieldValue("alerts", !formik?.values?.alerts)
              }
            />
          </Grid>
          <Grid item md={4} lg={3} xl={2.4}>
            <Tooltip title="Enabling this will remove the subscription requirement for the bidder">
              <FormControlLabel
                value={formik?.values?.bidEvent}
                checked={formik?.values?.bidEvent}
                control={<Checkbox />}
                label="Allow Bid"
                onChange={() =>
                  formik.setFieldValue("bidEvent", !formik?.values?.bidEvent)
                }
              />
            </Tooltip>
          </Grid>
        </Grid>
      </List>
    </FormControl>
  );
}

CheckboxList.propTypes = {
  formik: PropTypes.object.isRequired,
};

const containerStyles = {
  "& .MuiTypography-root": {
    fontSize: "1.3rem !important",
    color: STATS_NUMBER_FORMATTER_COLOR,
  },
};
