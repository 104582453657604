/* eslint-disable jsx-a11y/img-redundant-alt */
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import forest from "assets/icons/activity/forest.svg";
import jorge from "assets/icons/activity/jorge.svg";
import marayse from "assets/icons/activity/marayse.svg";
import ophellia from "assets/icons/activity/ophellia.svg";
import stokes from "assets/icons/activity/stokes.svg";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";
const BidderActivity = ({ title, auctions, amount, index }) => {
  const imageIcon = useCallback((title) => {
    switch (title) {
      case "Stokes Lincoln":
        return stokes;
      case "Forrest Rohan":
        return forest;
      case "Dr. Jorge Bins":
        return jorge;
      case "Maryse Lehner":
        return marayse;
      case "Ophelia Mann":
        return ophellia;
      default:
        return;
    }
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      p={1}
      sx={{
        background: index % 2 === 0 ? "#fff5eb" : "rgba(224, 250, 236, 0.7)",
        marginTop: "5.3px",
      }}
    >
      <Grid container spacing={3} alignItems="center" className="gridContainer">
        <Grid item lg={6} sm={6} display="flex" alignItems="center">
          <Box
            height="40px"
            width="40px"
            borderRadius="50%"
            mr={1}
            sx={{
              backgroundImage: `url(${imageIcon(title)})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contained",
              backgroundPosition: "center",
            }}
          />
          <Typography fontSize={{ xl: "0.875rem", sm: "0.675rem" }}>
            {title}
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3}>
          <Typography fontSize={{ xl: "0.875rem", sm: "0.675rem" }}>
            {auctions}
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3}>
          <Typography fontSize={{ xl: "0.875rem", sm: "0.675rem" }}>
            {amount}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export default memo(BidderActivity);

BidderActivity.propTypes = {
  title: PropTypes.string.isRequired,
  auctions: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number.isRequired,
};

BidderActivity.defaultProps = {
  title: "N/A",
  auctions: "N/A",
  amount: "N/A",
  index: 0,
};
