import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { memo } from "react";
function PageHeader({ title }) {
  return (
    <Typography
      variant="h1"
      component="h1"
      color="text.primary"
      fontWeight={600}
      textTransform="upperCase"
    >
      {title ?? "N/A"}
    </Typography>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default memo(PageHeader);
