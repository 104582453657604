import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { memo, useCallback, useState } from "react";
import {
  BUTTON_HOVER,
  BUTTON_HOVER_BORDER_COLOR,
  FILTERS_BORDER_COLOR,
} from "styles/colors";
import CardTitle from "../CardTitle";
import ItemsAuctioned from "./ItemsAuctionedDetails";

const AuctionItems = () => {
  const [value, setValue] = useState("1");

  const handleChange = useCallback((_, value) => {
    setValue(value);
  }, []);

  const categories = [
    { category: "Convertible", num_cars: 720 },
    { category: "Coupe", num_cars: 450 },
    { category: "Hatchback", num_cars: 380 },
    { category: "Minivan", num_cars: 250 },
    { category: "Bike", num_cars: 150 },
    { category: "Van", num_cars: 100 },
    { category: "SUV", num_cars: 45 },
  ];
  return (
    <Card
      sx={{ p: "1.2rem", boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" }}
    >
      <Box sx={containerStyles}>
        <CardTitle title="Category Wise" color="#707070" />
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textTransform="capitalize"
          >
            <Tab value="1" label="Products" />
            <Tab value="2" label="Revenue" />
          </Tabs>
        </Box>
      </Box>
      {value === "1" ? (
        <>
          {categories.map((item) => {
            return (
              <ItemsAuctioned
                title={item.category}
                cars={item.num_cars}
                key={item.num_cars}
              />
            );
          })}
        </>
      ) : (
        <Typography>No record found </Typography>
      )}
    </Card>
  );
};
export default memo(AuctionItems);
const containerStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  "& .MuiOutlinedInput-root": {
    height: "44px",
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
    },
    "&:hover": {
      backgroundColor: BUTTON_HOVER,
      "& fieldset": {
        borderColor: BUTTON_HOVER_BORDER_COLOR,
      },
    },
  },
  "& .MuiButtonBase-root": {
    textTransform: "none",
    minWidth: 70,
    width: 70,
    fontSize: "0.875rem",
  },
};
