import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPushProductDialogState } from "store/slices/uiSlice";
import ProductCard from "./ProductCard";
import PushProductDialog from "./PushProductDialog";

function ProductsList({ socket }) {
  const dispatch = useDispatch();
  // const socket = useRef(null);
  const isDialogOpen = useSelector((state) => state.UI.isPushProductDialogOpen);
  const products = useSelector((state) => state.liveStreaming.products);
  // const video = useSelector((state) => state.liveStreaming?.video);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleCloseDialog = useCallback(() => {
    setSelectedProduct(null);
    dispatch(setPushProductDialogState(false));
  }, [dispatch]);

  // TODO: pending on backend to get the products if we push a product
  // const fetchProducts = useCallback(() => {
  //   dispatch(getAuctionProducts(params?.id ?? ""));
  // }, [dispatch, params]);

  // Socket
  const pushProduct = useCallback(
    (id) => {
      if (socket) {
        socket.emit("pushProduct", {
          product: id,
          token: localStorage.getItem("token"),
        });
      }
    },
    [socket]
  );
  // Socket End

  useEffect(() => {
    selectedProduct && dispatch(setPushProductDialogState(true));
  }, [dispatch, selectedProduct]);

  useEffect(() => {
    if (!isDialogOpen) {
      setSelectedProduct(null);
    }
  }, [isDialogOpen]);

  return (
    <>
      <Stack
        height="75vh"
        width="100%"
        bgcolor="white"
        direction="column"
        borderRadius="10px"
        boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.16)"
      >
        <Box
          px={2}
          py={1}
          mb={2}
          bgcolor="#f9f9f9"
          boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.15)"
          sx={{
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
          }}
        >
          <Typography
            variant="h3"
            color="primary.main"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {`Upcoming Cars ( ${products?.length ?? 0} )`}
          </Typography>
        </Box>
        <Stack
          direction="column"
          spacing={2}
          px={2}
          pb={2}
          bgcolor="white"
          overflow="auto"
          sx={{
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}
        >
          {products?.map((product, index) => (
            <ProductCard
              key={product?.id ?? product?._id}
              number={index + 1}
              product={product}
              setSelectedProduct={setSelectedProduct}
            />
          ))}
        </Stack>
      </Stack>
      <PushProductDialog
        isOpen={isDialogOpen}
        selectedProduct={selectedProduct}
        handleCloseDialog={handleCloseDialog}
        pushProduct={pushProduct}
      />
    </>
  );
}

ProductsList.propTypes = {
  socket: PropTypes.object,
};

export default memo(ProductsList);
