const setAuthState = (state, action) => {
  state.loading = false;
  state.error = null;
  state.isLoggedIn = true;
  state.token = action?.payload?.data?.tokens?.access.token;
  state.id = action?.payload?.data?.user?.id;
  state.fullName = action?.payload?.data?.user?.fullname;
  state.userRole = action?.payload?.data?.user?.role;
  state.data = action?.payload?.data;
  localStorage?.setItem("id", action?.payload?.data?.user?.id);
  localStorage.setItem("token", action.payload?.data?.tokens?.access?.token);
  localStorage.setItem(
    "refresh-token",
    action.payload?.data?.tokens?.refresh?.token
  );
};

export default setAuthState;
