import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import WritingIcon from "assets/icons/WritingIcon.svg";
import deleteIcon from "assets/icons/deleteIcon.svg";
import placeholderImg from "assets/images/imagePlaceholder.png";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledGrid from "components/StyledGrid";
import StyledSwitch from "components/ToggleButton";
import { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateBlogStatus } from "services/blogServices";
import { deleteBlog, getBlogs } from "store/slices/blogsSlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import { toast } from "utils/hooks/useToast";

function DataGrid() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const { results, loading, totalPages } = useSelector((state) => state.blogs);

  const handleUpdateBlog = useCallback(
    (id) => {
      navigate(`${id}/update`);
    },
    [navigate]
  );

  const handleClickOpen = useCallback(
    (blogId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(blogId));
    },
    [dispatch]
  );

  const handleActiveStatus = useCallback(
    (blog) => {
      updateBlogStatus(blog?.id, !blog.active).then(() => {
        toast.success("Blog status updated successfully");
        dispatch(getBlogs());
      });
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        headerAlign: "center",
        align: "center",
        minWidth: 50,
        flex: 0.1,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "image",
        headerName: "Image",
        headerAlign: "center",
        align: "center",
        minWidth: 120,
        flex: 0.25,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => (
          <Box>
            <img
              src={params.row.image ? params.row.image : placeholderImg}
              height={
                isTablet
                  ? "80px"
                  : isLaptop
                  ? "90px"
                  : isDesktop
                  ? "95px"
                  : "113px"
              }
              width={
                isTablet
                  ? "80px"
                  : isLaptop
                  ? "90px"
                  : isDesktop
                  ? "95px"
                  : "113px"
              }
              style={{
                borderRadius: "10px",
                marginTop: 4,
                objectFit: "contain",
              }}
              alt={params.row.title}
            />
          </Box>
        ),
      },
      {
        field: "title",
        headerName: "Title",
        minWidth: 250,
        sortable: false,
        flex: 0.75,
        renderCell: (params) => <Typography>{params.row.title}</Typography>,
      },
      {
        field: "slug",
        headerName: "Slug",
        minWidth: 250,
        flex: 1.5,
        sortable: false,
        renderCell: (params) => (
          <Box>
            <Typography>{params.row.slug}</Typography>
          </Box>
        ),
      },
      {
        field: "active",
        headerName: "Active",
        headerAlign: "center",
        align: "center",
        minWidth: 100,
        flex: 0.25,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => (
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <StyledSwitch
                checked={params?.row?.active}
                onChange={() => handleActiveStatus(params.row)}
              />
            }
          />
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 150,
        flex: 0.25,
        headerAlign: "right",
        sortable: false,
        align: "right",
        renderHeader: () => (
          <Typography variant="h6" pr={2}>
            Actions
          </Typography>
        ),
        renderCell: (params) => (
          <Box display="flex" sx={actionStyles}>
            <Tooltip title="Update">
              <Button
                variant="text"
                className="button-styles"
                onClick={() => handleUpdateBlog(params.row.id)}
              >
                <img src={WritingIcon} alt="delete" className="icon-size" />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                variant="text"
                sx={{
                  minWidth: "20px",
                }}
                onClick={() => handleClickOpen(params?.id)}
              >
                <img src={deleteIcon} alt="edit" className="icon-size" />
              </Button>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [
      isTablet,
      isLaptop,
      isDesktop,
      handleActiveStatus,
      handleUpdateBlog,
      handleClickOpen,
    ]
  );

  return (
    <>
      <StyledGrid
        columns={columns}
        loading={loading}
        rows={results}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteBlog} />
    </>
  );
}

export default memo(DataGrid);

const actionStyles = {
  pr: 1,
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "25px", width: "25px" },
};
