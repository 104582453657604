import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Actions from "components/Users/Actions";
import DataGrid from "components/Users/DataGrid";
import Filters from "components/Users/Filters";
import UserForm from "components/Users/UserForm";
import DateFilters from "components/Users/UserForm/DateFilters";
import PropTypes from "prop-types";
import { memo } from "react";

const Users = ({ create, update }) => {
  if (create || update) {
    return <UserForm />;
  }

  return (
    <Box sx={{ flexGrow: 1, py: 3, px: 1 }}>
      <Typography
        variant="h4"
        fontWeight="600"
        mb={1}
        sx={{ fontSize: { md: "1.5rem", lg: "2rem" } }}
      >
        USERS
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Actions />
        <DateFilters />
      </Box>
      <Filters />
      <DataGrid />
    </Box>
  );
};

export default memo(Users);

Users.propTypes = {
  create: PropTypes.bool,
  update: PropTypes.bool,
};

Users.defaultProps = {
  create: false,
  update: false,
};
