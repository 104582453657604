import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import ImagePlaceholder from "assets/icons/ProductAddImagePlaceholder.svg";
import PropTypes from "prop-types";
import { memo, useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  setImageValidationError,
  setIsImageValidationDialogOpen,
} from "store/slices/uiSlice";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import validateImageAspectRatio from "utils/helpers/validateImageAspectRatio";

function BannerImageForm({ formik }) {
  const isMobile = useParams().device === "mobile";
  const dispatch = useDispatch();
  const thumbnailInputRef = useRef(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));

  const [banner, setBanner] = useState("");

  const imageHandler = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        // function params
        // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
        setBanner(URL.createObjectURL(image));
        formik.setFieldValue("bannerImage", image);
        validateImageAspectRatio(
          image,
          1600,
          isMobile ? 600 : 600,
          true,
          true,
          isMobile ? 2 / 1 : 2.67 / 1,
          isMobile ? "2 / 1" : "2.67 / 1"
        )
          .then((img) => {
            setBanner(URL.createObjectURL(img));
            formik.setFieldValue("bannerImage", img);
            e.target.value = "";
          })
          .catch((error) => {
            thumbnailInputRef.current.value = "";
            dispatch(setIsImageValidationDialogOpen(true));
            dispatch(setImageValidationError(error.message));
          });
      }
    },
    [dispatch, formik, isMobile]
  );

  return (
    <StyledMuiCard title="Banner Image" subTitle="Upload Banner Image">
      <CardContent>
        <Stack direction="column" gap={2}>
          <Typography
            variant="h3"
            fontWeight="bold"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Image
          </Typography>
          <Box display="flex" gap={4}>
            <Box>
              <img
                src={ImagePlaceholder}
                alt="Upload"
                height={isSmall ? "85px" : isLarge ? "185px" : "285px"}
                width={isSmall ? "100px" : isLarge ? "200px" : "300px"}
                style={{ cursor: "pointer", objectFit: "contain" }}
                onClick={() => {
                  thumbnailInputRef.current.click();
                }}
              />
              <input
                type="file"
                ref={thumbnailInputRef}
                style={{ display: "none" }}
                hidden
                accept="image/x-png,image/png,image/jpeg,image/jpg"
                onChange={imageHandler}
              />
              {Boolean(formik.errors.bannerImage) && (
                <Typography color="#ef5350">
                  {formik.errors.bannerImage}*
                </Typography>
              )}
            </Box>
            {formik.values.bannerImage && (
              <Box>
                <img
                  src={banner ? banner : formik.values.bannerImage}
                  alt="bannerImage"
                  height={isSmall ? "85px" : isLarge ? "185px" : "285px"}
                  width="auto"
                  style={{ objectFit: "cover" }}
                />
              </Box>
            )}
          </Box>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

BannerImageForm.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(BannerImageForm);
