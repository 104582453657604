export default function flatCategoriesArray(categories) {
  let flatArray = [];
  categories.forEach((level_0) => {
    flatArray.push({
      value: level_0.id,
      label: level_0.name,
      categoryTree: [level_0.id],
    });
    if (level_0.subCategories) {
      level_0.subCategories.forEach((level_1) => {
        flatArray.push({
          value: level_1.id,
          categoryTree: [level_0.id, level_1.id],
          label: `${level_0.name} --> ${level_1.name}`,
        });
        if (level_1.subCategories) {
          level_1.subCategories.forEach((level_2) => {
            flatArray.push({
              value: level_2.id,
              categoryTree: [level_0.id, level_1.id, level_2.id],
              label: `${level_0.name} --> ${level_1.name} --> ${level_2.name}`,
            });
          });
        }
      });
    }
  });
  return flatArray;
}
