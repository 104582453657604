import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/httpRequests";
import { toast } from "utils/hooks/useToast";
import { httpMain, httpVideo } from "utils/httpRequest/http";

export const getAuctions = createAsyncThunk(
  "liveStreaming/getAuctions",
  async (data = {}, { rejectWithValue }) => {
    try {
      const response = await httpMain.get("/auctions/admin", {
        params: {
          ...data,
          upcoming: true,
        },
      });
      const responseData = getResponseData(response);
      responseData &&
        responseData?.results.forEach((item, index) => {
          const pageStart = (responseData?.page - 1) * responseData?.limit;
          item.serialNumber = pageStart + index + 1;
        });
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getAuction = createAsyncThunk(
  "liveStreaming/getAuction",
  async (id = "", { rejectWithValue }) => {
    try {
      const response = await httpMain.get(`/auctions/` + id);
      return getResponseData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getAuctionVideo = createAsyncThunk(
  "liveStreaming/getAuctionVideo",
  async (auctionId = "", { rejectWithValue }) => {
    try {
      const response = await httpVideo.get("/admin", {
        params: {
          auctionId,
        },
      });
      return getResponseData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getLiveAuctionVideo = createAsyncThunk(
  "liveStreaming/getLiveAuctionVideo",
  async (auctionId = "", { rejectWithValue }) => {
    try {
      const response = await httpVideo.get("/live", {
        params: {
          auctionId,
        },
      });
      return getResponseData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getAuctionProducts = createAsyncThunk(
  "liveStreaming/getAuctionProducts",
  async (id = "", { rejectWithValue }) => {
    try {
      const response = await httpMain.get(`/auctions/` + id);
      return getResponseData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getAuctionProduct = createAsyncThunk(
  "liveStreaming/getAuctionProduct",
  async (id = "", { rejectWithValue }) => {
    try {
      const response = await httpMain.get(`/products/${id}`);
      return getResponseData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getPreviousBids = createAsyncThunk(
  "liveStreaming/getPreviousBids",
  async (id = "", { rejectWithValue }) => {
    try {
      const response = await httpMain.get(`/bid/${id}/previous`);
      return getResponseData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const placeSuccessfulBid = createAsyncThunk(
  "liveStreaming/placeSuccessfulBid",
  async (id = "", { rejectWithValue }) => {
    try {
      const response = await httpMain.get(`/bid/${id}/bid`);
      return getResponseData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const startAuction = createAsyncThunk(
  "liveStreaming/startAuction",
  async (id = "", { rejectWithValue }) => {
    try {
      const response = await httpMain.get(`/auctions/${id}/status`);
      return getResponseData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const endAuction = createAsyncThunk(
  "liveStreaming/endAuction",
  async (slug = "", { rejectWithValue }) => {
    try {
      const response = await httpVideo.patch(`/${slug}/stop`);
      return getResponseData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const startVideoStreaming = createAsyncThunk(
  "liveStreaming/startVideoStreaming",
  async (id = "", { rejectWithValue }) => {
    try {
      const response = await httpVideo.patch(`/${id}/live`);
      return getResponseData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getObsKeys = createAsyncThunk(
  "liveStreaming/getObsKeys",
  async (slug = "", { rejectWithValue }) => {
    try {
      const response = await httpVideo.get("cloudfare/admin", {
        params: { scheduleId: slug },
      });
      return getResponseData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: {
    auctions: true,
    auction: true,
    video: true,
    products: true,
    product: true,
    bids: true,
    startAuction: false,
    endAuction: false,
    obsData: false,
  },
  auctions: {
    results: [],
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
  },
  auction: null,
  products: [],
  product: null,
  video: null,
  bids: null,
  obsData: null,
  error: {
    auctions: "",
    auction: "",
    products: "",
    product: "",
    video: "",
    bids: "",
    startAuction: "",
    endAuction: "",
    obsData: "",
  },
};

const liveStreamingSlice = createSlice({
  name: "liveStreaming",
  initialState,
  reducers: {
    resetState: () => initialState,
    setBids: (state, action) => {
      state.bids = action.payload;
    },
    setAuctionProducts: (state, action) => {
      state.products = action.payload;
    },
    resetProduct: (state) => {
      state.product = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuctions.pending, (state) => {
        state.loading.auctions = true;
        state.error.auctions = "";
      })
      .addCase(getAuctions.fulfilled, (state, action) => {
        state.loading.auctions = false;
        state.auctions = action.payload;
      })
      .addCase(getAuctions.rejected, (state, action) => {
        state.loading.auctions = false;
        state.error.auctions = action.payload;
      })
      .addCase(getAuction.pending, (state) => {
        state.loading.auction = true;
        state.error.auction = "";
      })
      .addCase(getAuction.fulfilled, (state, action) => {
        state.loading.auction = false;
        state.auction = action.payload;
        state.products = action.payload.products.filter(
          (product) => product.status !== "sold"
        );
      })
      .addCase(getAuction.rejected, (state, action) => {
        state.loading.auction = false;
        state.error.auction = action.payload;
      })
      .addCase(getAuctionVideo.pending, (state) => {
        state.loading.video = true;
        state.error.video = "";
      })
      .addCase(getAuctionVideo.fulfilled, (state, action) => {
        state.loading.video = false;
        state.video = action?.payload[0] ?? null;
      })
      .addCase(getAuctionVideo.rejected, (state, action) => {
        state.loading.video = false;
        state.error.video = action.payload;
      })
      .addCase(getLiveAuctionVideo.pending, (state) => {
        state.loading.video = true;
        state.error.video = "";
      })
      .addCase(getLiveAuctionVideo.fulfilled, (state, action) => {
        state.loading.video = false;
        state.video = action?.payload?.result[0] ?? null;
      })
      .addCase(getLiveAuctionVideo.rejected, (state, action) => {
        state.loading.video = false;
        state.error.video = action.payload;
      })
      .addCase(getAuctionProducts.pending, (state) => {
        state.loading.products = true;
        state.error.products = "";
      })
      .addCase(getAuctionProducts.fulfilled, (state, action) => {
        state.loading.products = false;
        state.products = action.payload.products.filter(
          (product) => product.status !== "sold"
        );
      })
      .addCase(getAuctionProducts.rejected, (state, action) => {
        state.loading.products = false;
        state.error.products = action.payload;
      })
      .addCase(getAuctionProduct.pending, (state) => {
        state.loading.product = true;
        state.error.product = "";
      })
      .addCase(getAuctionProduct.fulfilled, (state, action) => {
        state.loading.product = false;
        state.product =
          action.payload?.status !== "sold" ? action.payload : null;
      })
      .addCase(getAuctionProduct.rejected, (state, action) => {
        state.loading.product = false;
        state.error.product = action.payload;
      })
      .addCase(getPreviousBids.pending, (state) => {
        state.loading.bids = true;
        state.error.bids = "";
      })
      .addCase(getPreviousBids.fulfilled, (state, action) => {
        state.loading.bids = false;
        state.bids = action.payload;
      })
      .addCase(getPreviousBids.rejected, (state, action) => {
        state.loading.bids = false;
        state.error.bids = action.payload;
      })
      .addCase(placeSuccessfulBid.pending, (state) => {
        state.loading.bids = true;
        state.error.bids = "";
      })
      .addCase(placeSuccessfulBid.fulfilled, (state) => {
        state.loading.bids = false;
        state.product = null;
        state.bids = null;
      })
      .addCase(placeSuccessfulBid.rejected, (state, action) => {
        state.loading.bids = false;
        state.error.bids = action.payload;
      })
      .addCase(startAuction.pending, (state) => {
        state.loading.startAuction = true;
        state.error.startAuction = "";
      })
      .addCase(startAuction.fulfilled, (state, action) => {
        state.loading.startAuction = false;
        state.auction = action?.payload ?? null;
      })
      .addCase(startAuction.rejected, (state, action) => {
        state.loading.startAuction = false;
        state.error.startAuction = action.payload;
      })
      .addCase(endAuction.pending, (state) => {
        state.loading.endAuction = true;
        state.error.endAuction = "";
      })
      .addCase(endAuction.fulfilled, (state) => {
        state.loading.endAuction = false;
      })
      .addCase(endAuction.rejected, (state, action) => {
        state.loading.endAuction = false;
        state.error.endAuction = action.payload;
      })
      .addCase(startVideoStreaming.pending, (state) => {
        state.loading.obsData = true;
        state.error.obsData = "";
      })
      .addCase(startVideoStreaming.fulfilled, (state, action) => {
        state.loading.obsData = false;
        state.video = action?.payload?.streaming ?? null;
        state.obsData = action?.payload?.slot ?? null;
      })
      .addCase(startVideoStreaming.rejected, (state, action) => {
        state.loading.obsData = false;
        state.error.obsData = action.payload;
      })
      .addCase(getObsKeys.pending, (state) => {
        state.loading.obsData = true;
        state.error.obsData = "";
      })
      .addCase(getObsKeys.fulfilled, (state, action) => {
        state.loading.obsData = false;
        state.obsData = action.payload ?? null;
        state.video.slotStatus = "inUse";
      })
      .addCase(getObsKeys.rejected, (state, action) => {
        state.loading.obsData = false;
        state.error.obsData = action.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState, setBids, setAuctionProducts, resetProduct } =
  liveStreamingSlice.actions;

// Exporting default reducer
export default liveStreamingSlice.reducer;
