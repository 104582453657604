import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PRODUCT_FORM_STATUSES } from "utils/constants/product";
import USER_ROLE from "utils/constants/userRole";
import { getErrorMessage, getResponseData } from "utils/helpers/httpRequests";
import { getProductFormStatus } from "utils/helpers/productForm";
import { toast } from "utils/hooks/useToast";
import { httpMain } from "utils/httpRequest/http";

export const getProducts = createAsyncThunk(
  "products/getProducts",
  async (data, { getState, rejectWithValue }) => {
    const { userRole } = getState().auth;
    const { page, limit } = getState().products;
    const url = [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN].includes(userRole)
      ? "/products/admin"
      : `/products`;
    try {
      const response = await httpMain.get(url, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          sortBy: "-createdAt",
          ...data,
        },
      });
      const responseData = getResponseData(response);

      responseData &&
        responseData?.results.forEach((item, index) => {
          const pageStart = (responseData?.page - 1) * responseData?.limit;
          item.serialNumber = pageStart + index + 1;
        });

      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getAuctionProducts = createAsyncThunk(
  "products/getAuctionProducts",
  async (data, { getState, rejectWithValue }) => {
    const { userRole } = getState().auth;
    const { page, limit } = getState().products;
    const url = [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN].includes(userRole)
      ? "/products/admin"
      : `/products`;
    try {
      const response = await httpMain.get(url, {
        params: {
          page: data?.page ?? page,
          limit: data?.limit ?? limit,
          sortBy: "-createdAt",
          status: "active",
          ...data,
        },
      });
      const responseData = getResponseData(response);

      responseData &&
        responseData?.results.forEach((item, index) => {
          const pageStart = (responseData?.page - 1) * responseData?.limit;
          item.serialNumber = pageStart + index + 1;
        });

      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getProduct = createAsyncThunk(
  "products/getProduct",
  async (productId, { rejectWithValue }) => {
    try {
      const response = await httpMain.get(`/products/${productId}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const addFeaturedProduct = createAsyncThunk(
  "products/addFeaturedProduct",
  async (id, { rejectWithValue }) => {
    try {
      const response = await httpMain.patch(`/products/${id}/feature`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const removeFeaturedProduct = createAsyncThunk(
  "products/removeFeaturedProduct",
  async (id, { rejectWithValue }) => {
    try {
      const response = await httpMain.patch(`/products/${id}/feature`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const addProduct = createAsyncThunk(
  "products/addProduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await httpMain.post(`/products`, data);
      return response?.data;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async ({ productId = "", data = {} }, { rejectWithValue }) => {
    try {
      const response = await httpMain.patch(`/products/${productId}`, data);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteAuctionProduct = createAsyncThunk(
  "products/deleteAuctionProduct",
  async ({ productId = "", data = {} }, { rejectWithValue }) => {
    try {
      await httpMain.patch(`/products/${productId}`, data);
      toast.success("Auction product removed successfully");
      return true;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "products/deleteProduct",
  async (productId, { getState, rejectWithValue }) => {
    const { results, page, limit } = getState().products;
    try {
      await httpMain.delete(`/products/${productId}`);
      toast.success("Deleted successfully");
      let returnedProducts = results.filter(
        (product) => product.id !== productId
      );
      if (returnedProducts) {
        returnedProducts = returnedProducts.map((item, index) => {
          const pageStart = (page - 1) * limit;
          return {
            ...item,
            serialNumber: pageStart + index + 1,
          };
        });
      }
      return returnedProducts;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: true,
  product: null,
  productLoading: false,
  isProductSubmitting: false,
  productFormStatus: PRODUCT_FORM_STATUSES.BASIC,
  results: [],
  page: 1,
  limit: 20,
  totalPages: 0,
  totalResults: 0,
  error: "",
  productError: "",
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    resetState: () => initialState,
    resetProductState: (state) => {
      state.product = null;
      state.productError = "";
      state.productLoading = false;
      state.isProductSubmitting = false;
      state.productFormStatus = PRODUCT_FORM_STATUSES.BASIC;
    },
    setProductBrandId: (state, action) => {
      state.product = { ...state.product, brandId: action.payload };
    },
    setProductCategoryId: (state, action) => {
      state.product = { ...state.product, category: action.payload };
    },
    setProductFormStatus: (state, action) => {
      state.productFormStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
        state.results = action.payload.results;
        state.totalPages = action.payload.totalPages;
        state.totalResults = action.payload.totalResults;
      })
      .addCase(getProducts.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getAuctionProducts.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAuctionProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
        state.results = action.payload.results;
        state.totalPages = action.payload.totalPages;
        state.totalResults = action.payload.totalResults;
      })
      .addCase(getAuctionProducts.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(addProduct.pending, (state) => {
        state.isProductSubmitting = true;
        state.productError = "";
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.isProductSubmitting = false;
        state.product = action.payload;
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.isProductSubmitting = false;
        state.productError = action.payload;
      })
      .addCase(updateProduct.pending, (state) => {
        state.productLoading = true;
        state.productError = "";
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.productLoading = false;
        state.product = action.payload;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.productLoading = false;
        state.productError = action.payload;
      })
      .addCase(deleteAuctionProduct.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteAuctionProduct.fulfilled, (state) => {
        state.loading = false;
        state.error = "";
      })
      .addCase(deleteAuctionProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.results = action.payload;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(getProduct.pending, (state) => {
        state.productLoading = true;
        state.productError = "";
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.productLoading = false;
        state.product = action.payload;
        state.productFormStatus = getProductFormStatus(action.payload);
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.productLoading = false;
        state.productError = action.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const {
  resetState,
  resetProductState,
  setProductBrandId,
  setProductCategoryId,
  setProductFormStatus,
} = productsSlice.actions;

// Exporting default reducer
export default productsSlice.reducer;
