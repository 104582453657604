import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AuctionForm from "components/Auctions/AuctionForm";
import AuctionsDataGrid from "components/Auctions/AuctionsDataGrid";
import AuctionsFilters from "components/Auctions/AuctionsFilters";
import AuctionsStatusesButtons from "components/Auctions/AuctionsStatusesButtons";
import PropTypes from "prop-types";

function Auctions({ create, edit }) {
  if (create || edit) {
    return <AuctionForm />;
  }

  return (
    <Box sx={{ flexGrow: 1, py: 3, px: 1 }}>
      <Typography
        variant="h4"
        fontWeight="600"
        mb={1}
        sx={{ fontSize: { md: "1.5rem", lg: "2rem" } }}
      >
        AUCTIONS
      </Typography>
      <AuctionsFilters />
      <AuctionsStatusesButtons />
      <AuctionsDataGrid />
    </Box>
  );
}

Auctions.propTypes = {
  create: PropTypes.bool,
  edit: PropTypes.bool,
  addProducts: PropTypes.bool,
};

Auctions.defaultProps = {
  create: false,
  edit: false,
};

export default Auctions;
