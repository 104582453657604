import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import * as React from "react";
import { PRIMARY_LIGHT } from "styles/colors";
import {
  doNots,
  responsibilities,
  rules,
} from "utils/constants/termsAndConditionsList";

function TermsAndConditionsDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        color: "#000",
        fontSize: "2rem",
        fontWeight: 600,
        backgroundColor: PRIMARY_LIGHT,
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="primary"
          sx={{
            height: "47px",
            width: "47px",
            position: "absolute",
            right: 12,
            color: "#fff",
            backgroundColor: "#058dce !important",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

TermsAndConditionsDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Typography
        variant="body1"
        color="primary"
        onClick={handleClickOpen}
        sx={{
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        Terms & Conditions
      </Typography>
      <TermsAndConditionsDialog
        fullWidth={true}
        onClose={handleClose}
        maxWidth="100vh"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <TermsAndConditionsDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Terms & Conditions
        </TermsAndConditionsDialogTitle>
        <DialogContent id="terms-conditions" dividers fontSize="1rem">
          <Typography component="div">
            For bidding on
            <b> yallabid.com, </b>
            you acknowledge and agree that:
          </Typography>
          <Box
            component="ul"
            sx={{
              marginBlockStart: 0,
              paddingLeft: "16px",
            }}
          >
            <Typography component="li">
              <b>Yallabid </b>
              Yallabid is a marketplace which provides an online location for
              sellers to sell and buyers to purchase products.
            </Typography>
            <Typography component="li">
              We shall accept binding sales, on behalf of sellers, but
              <b> Yallabid </b>
              is not a party to the direct transaction between the seller and
              the buyer.
            </Typography>
            <Typography component="li">
              All the terms and conditions, and policy guidelines are binding on
              <b> Yallabid </b>
              sellers.
            </Typography>
          </Box>
          <Typography fontSize="1.5rem" fontWeight="bold" mt={1} mb={1}>
            Do&apos;s & Don&apos;ts
          </Typography>
          <Typography sx={title}>{"1- Do's"}</Typography>
          <Typography>
            The BPs are encouraged to open, promote and market their products of
            any nature and kind unless prohibited explicitly under the terms of
            offering.
          </Typography>
          <Typography sx={title} mt={1.5}>
            2- Don&apos;ts
          </Typography>
          <Typography fontSize="1.rem" fontWeight="bold">
            As a Seller, You are agree
          </Typography>
          <Box
            component="ul"
            sx={{
              marginBlockStart: 0,
              paddingLeft: "16px",
            }}
          >
            {React.Children.map(doNots, (child) => (
              <Typography component="li" fontSize="1rem">
                {child}
              </Typography>
            ))}
          </Box>
          <Box mt={2} mb={2}>
            {React.Children.map(rules, (child) => (
              <Typography fontSize="1rem">{child}</Typography>
            ))}
          </Box>
          <Typography fontSize="1.5rem" fontWeight="bold" mt={1} mb={1}>
            Responsibilities
          </Typography>
          <Typography sx={title}>BPs are solely responsible for</Typography>
          <Box
            component="ul"
            sx={{
              marginBlockStart: 0,
              paddingLeft: "16px",
            }}
          >
            {React.Children.map(responsibilities, (child) => (
              <Typography component="li" fontSize="1rem">
                {child}
              </Typography>
            ))}
          </Box>
        </DialogContent>
      </TermsAndConditionsDialog>
    </Box>
  );
}

const TermsAndConditionsDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const title = {
  fontSize: "1rem",
  fontWeight: "bold",
};
