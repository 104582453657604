import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CurrencyFormatter from "components/CurrencyFormatter";
import PropTypes from "prop-types";
import { memo } from "react";
import ProductDescriptionTiles from "./ProductDescriptionTiles";

function ProductDetailsCard({ product }) {
  return (
    <Stack
      direction="row"
      spacing={2}
      bgcolor={"#99a0a30f"}
      sx={{
        borderRadius: "10px",
      }}
    >
      <img
        src={product?.mainImage}
        height="150px"
        width="200px"
        alt={product?.title ?? "N/A"}
        style={{ objectFit: "cover", borderRadius: "10px" }}
      />
      <Stack
        direction="column"
        justifyContent="center"
        spacing={1}
        width="100%"
        overflow="hidden"
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          color="text.secondary"
        >
          {product?.productName ?? "N/A"}
        </Typography>
        <Stack direction="row" justifyContent="space-between" pt={1}>
          <Stack direction="column">
            <Typography
              lineHeight={1}
              variant="subtitle2"
              color="text.secondary"
            >
              Current Bid
            </Typography>
            <CurrencyFormatter
              fontWeight="bold"
              color="text.secondary"
              fontSize={{ xs: "0.9rem", lg: "1.1rem" }}
              number={
                product?.currentBid?.bidAmount ?? product?.reservePrice ?? 0
              }
            />
          </Stack>
          <Stack direction="column" pr={1}>
            <Typography
              lineHeight={1}
              variant="subtitle2"
              color="text.secondary"
            >
              Lot#
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              fontWeight="bold"
              fontSize={{ xs: "0.9rem", lg: "1.1rem" }}
            >
              {product?.lotNumber ?? "N/A"}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="column" py={1} gap={1}>
          <ProductDescriptionTiles
            name="Make"
            value={product?.productSpecs?.make}
          />
          <ProductDescriptionTiles
            name="Model"
            value={product?.productSpecs?.model}
          />
          <ProductDescriptionTiles
            name="Vehicle Type"
            value={product?.productSpecs?.bodyStyle}
          />
          <ProductDescriptionTiles
            name="Year"
            value={product?.productSpecs?.year}
          />
          <ProductDescriptionTiles
            name="Mileage"
            value={product?.productSpecs?.mileage}
          />
          <ProductDescriptionTiles
            name="Transmission"
            value={product?.productSpecs?.transmission}
          />
          <ProductDescriptionTiles
            name="Trim"
            value={product?.productSpecs?.trim}
          />
          <ProductDescriptionTiles
            name="Country"
            value={product?.productSpecs?.country}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

ProductDetailsCard.propTypes = {
  product: PropTypes.object.isRequired,
};

ProductDetailsCard.defaultProps = {
  product: {},
};

export default memo(ProductDetailsCard);
