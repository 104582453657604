import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import { memo, useCallback, useState } from "react";
import SpecificationsForm from "./SpecificationsForm";

function AddSpecification({ formik }) {
  const [isAdding, setIsAdding] = useState(false);

  const handleAdd = () => {
    setIsAdding(!isAdding);
  };

  const handleSave = useCallback(
    (specification) => {
      formik.setFieldValue("specifications", [
        ...formik.values.specifications,
        specification,
      ]);
      setIsAdding(false);
    },
    [formik]
  );

  return (
    <Box p={1} border={isAdding && "1px solid #00000038"} borderRadius="5px">
      {isAdding ? (
        <Box textAlign="right">
          <Tooltip title="Cancel">
            <IconButton size="large" onClick={handleAdd}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <Box textAlign="right">
          <Button
            variant="contained"
            color="primary"
            onClick={handleAdd}
            sx={{
              minWidth: { md: 175, xl: 200 },
              minHeight: {
                xs: 50,
                xl: 55,
              },
            }}
          >
            Add Specification
          </Button>
        </Box>
      )}
      <Collapse
        in={isAdding}
        timeout="auto"
        unmountOnExit
        sx={{
          "& .MuiCollapse-wrapper": {
            pt: 2,
            px: 2,
          },
        }}
      >
        <SpecificationsForm formik={formik} handleSave={handleSave} />
      </Collapse>
    </Box>
  );
}

AddSpecification.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(AddSpecification);
