import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import placeholderImg from "assets/images/imagePlaceholder.png";
import NumberFormatter from "components/NumberFormatter";
import StyledDataGrid from "components/StyledGrid";
import StyledSwitch from "components/ToggleButton";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  addFeaturedVideo,
  deleteVideo,
  getVideos,
  removeFeaturedVideo,
  resetState,
} from "store/slices/videosSlice";
import { FACEBOOK, TWITTER, WHATSAPP, YOUTUBE } from "styles/colors";
import convertSlugToUrl from "utils/helpers/convertSlugToUrl";
import useQueryParams from "utils/hooks/useQueryParams";
import WritingIcon from "../../assets/icons/WritingIcon.svg";
import deleteIcon from "../../assets/icons/deleteIcon.svg";

export default function VideoDataGrid() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  const { results, loading, totalPages } = useSelector((state) => state.videos);

  const fetchVideos = useCallback(() => {
    dispatch(getVideos(params));
  }, [dispatch, params]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchVideos();
    }, 500);

    return () => clearTimeout(timeOut);
  }, [dispatch, fetchVideos]);

  const deleteVideosRow = useCallback(
    (videoId) => {
      dispatch(deleteVideo(videoId));
    },
    [dispatch]
  );

  const updateVideosRow = useCallback(
    (slug) => {
      navigate(`/videos/${slug}/update`);
    },
    [navigate]
  );

  const handleChange = useCallback(
    (row) => {
      if (row.featured === false) {
        dispatch(
          addFeaturedVideo({
            id: row.id,
            featured: true,
          })
        )
          .unwrap()
          .then(() => fetchVideos());
      } else {
        dispatch(
          removeFeaturedVideo({
            id: row.id,
            featured: false,
          })
        )
          .unwrap()
          .then(() => fetchVideos());
      }
    },
    [dispatch, fetchVideos]
  );

  useEffect(() => () => dispatch(resetState()), [dispatch]);

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        headerAlign: "center",
        align: "center",
        minWidth: 50,
        flex: 0.1,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "thumbnail",
        headerName: "Image",
        headerAlign: "center",
        align: "center",
        minWidth: 125,
        flex: 0.25,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => (
          <Box>
            <img
              src={
                params?.row?.thumbnail ? params?.row?.thumbnail : placeholderImg
              }
              height={
                isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
              }
              width={
                isTablet
                  ? "70px"
                  : isLaptop
                  ? "80px"
                  : isDesktop
                  ? "85px"
                  : "113px"
              }
              style={{
                borderRadius: "10px",
                marginTop: 4,
                objectFit: "contain",
              }}
              alt={params?.row?.title}
            />
          </Box>
        ),
      },
      {
        field: "title",
        headerName: "Name/Details",
        minWidth: 300,
        flex: 1.5,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => (
          <Box>
            <Typography
              sx={{
                textTransform: "capitalize",
              }}
            >
              {params?.row?.title}
            </Typography>
          </Box>
        ),
      },
      {
        field: "totalViews",
        headerName: "Views",
        headerAlign: "center",
        align: "center",
        minWidth: 110,
        flex: 0.25,
        editable: true,
        renderCell: (params) => (
          <NumberFormatter number={params?.row?.totalViews ?? 0} />
        ),
      },
      {
        field: "featured",
        headerName: "Featured",
        headerAlign: "center",
        align: "center",
        minWidth: 150,
        flex: 0.25,
        sortable: false,
        editable: true,
        renderCell: (params) => (
          <Box>
            <StyledSwitch
              checked={params?.row?.featured}
              onChange={() => handleChange(params?.row)}
            />
          </Box>
        ),
      },
      {
        field: "Share",
        headerAlign: "center",
        align: "center",
        headerName: "Share",
        minWidth: 200,
        sortable: false,
        flex: 0.4,
        renderCell: (params) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              gap: 2,
              pt: 1,
              borderRadius: "100px",
              backgroundColor: "white",
            }}
          >
            <TwitterShareButton
              url={convertSlugToUrl(params?.row?.slug)}
              title={params?.row?.title}
            >
              <TwitterIcon
                sx={{
                  background: TWITTER,
                  padding: "3px",
                  color: "white",
                  borderRadius: "12px",
                }}
              />
            </TwitterShareButton>
            <FacebookShareButton
              url={convertSlugToUrl(params?.row?.slug)}
              quote={params?.row?.title}
              href={params?.row?.thumbnail}
            >
              <FacebookIcon
                sx={{
                  background: FACEBOOK,
                  padding: "3px",
                  color: "white",
                  borderRadius: "12px",
                }}
              />
            </FacebookShareButton>
            <WhatsappShareButton
              url={convertSlugToUrl(params?.row?.slug)}
              title={params?.row?.title}
              media={params?.row?.thumbnail}
            >
              <WhatsAppIcon
                sx={{
                  background: WHATSAPP,
                  padding: "3px",
                  color: "white",
                  borderRadius: "12px",
                }}
              />
            </WhatsappShareButton>
            <Box
              component="a"
              href={convertSlugToUrl(params?.row?.slug)}
              target="_blank"
              rel="noreferrer"
            >
              <YouTubeIcon
                sx={{
                  background: YOUTUBE,
                  padding: "3px",
                  color: "white",
                  borderRadius: "12px",
                }}
              />
            </Box>
          </Box>
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        align: "center",
        minWidth: 120,
        sortable: false,
        flex: 0.25,
        renderHeader: () => (
          <Typography variant="h6" pr={2}>
            Actions
          </Typography>
        ),
        renderCell: (params) => (
          <Box display="flex" sx={actionStyles}>
            <Button
              variant="text"
              className="button-styles"
              onClick={() => updateVideosRow(params?.row?.slug)}
            >
              <img src={WritingIcon} alt="edit" className="icon-size" />
            </Button>
            <Button
              variant="text"
              sx={{
                minWidth: "20px",
              }}
              onClick={() => deleteVideosRow(params?.id)}
            >
              <img src={deleteIcon} alt="edit" className="icon-size" />
            </Button>
          </Box>
        ),
      },
    ],
    [
      isTablet,
      isLaptop,
      isLed,
      isDesktop,
      handleChange,
      updateVideosRow,
      deleteVideosRow,
    ]
  );

  return (
    <StyledDataGrid
      rows={results}
      columns={columns}
      loading={loading}
      totalPages={totalPages}
    />
  );
}

const actionStyles = {
  pr: 1,
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "25px", width: "25px" },
};
