import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import { memo } from "react";
import StyledCardContent from "../StyledCardContent";
import KeyNumberTile from "./KeyNumberTile";

const KeyNumbers = ({ target, title, currentMonth, lastMonth }) => {
  return (
    <Card
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
        minHeight: { md: "190px", lg: "190px", xxl: "190px" },
      }}
    >
      <StyledCardContent>
        <KeyNumberTile
          title={title}
          target={target}
          currentMonth={currentMonth}
          lastMonth={lastMonth}
        />
      </StyledCardContent>
    </Card>
  );
};
export default memo(KeyNumbers);
KeyNumbers.propTypes = {
  title: PropTypes.string.isRequired,
  currentMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  target: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  lastMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

KeyNumbers.defaultProps = {
  title: "N/A",
  target: 0,
  thisMonth: "N/A",
  lastMonth: "N/A",
};
