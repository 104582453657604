import { combineReducers } from "@reduxjs/toolkit";
import auctionsReducer from "store/slices/auctionsSlice";
import authReducer from "store/slices/authSlice";
import bannersReducer from "store/slices/bannersSlice";
import categoriesReducer from "store/slices/categoriesSlice";
import productsReducer from "store/slices/productsSlice";
import uiReducer from "store/slices/uiSlice";
import videosReducer from "store/slices/videosSlice";
import bidReducer from "./slices/bidsSlice";
import blogsReducer from "./slices/blogsSlice";
import filtersReducer from "./slices/filtersSlice";
import liveStreamingReducer from "./slices/liveStreamingSlice";
import notificationsReducer from "./slices/notificationsSlice";
import pagesReducer from "./slices/pagesSlice";
import sellWithUsReducer from "./slices/sellWithUsSlice";
import servicesReducer from "./slices/servicesSlice";
import subscriptionsReducer from "./slices/subscriptionsSlice";
import usersReducer from "./slices/usersSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  UI: uiReducer,
  products: productsReducer,
  auctions: auctionsReducer,
  videos: videosReducer,
  categories: categoriesReducer,
  notifications: notificationsReducer,
  pages: pagesReducer,
  banners: bannersReducer,
  bids: bidReducer,
  users: usersReducer,
  filters: filtersReducer,
  subscriptions: subscriptionsReducer,
  liveStreaming: liveStreamingReducer,
  services: servicesReducer,
  blogs: blogsReducer,
  sellWithUs: sellWithUsReducer,
});

export default rootReducer;
