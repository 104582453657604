import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsImageValidationDialogOpen } from "store/slices/uiSlice";

function ImageValidationDialog() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.UI.isImageValidationDialogOpen);
  const message = useSelector((state) => state.UI.imageValidationError);

  const handleClose = useCallback(() => {
    dispatch(setIsImageValidationDialogOpen(false));
  }, [dispatch]);

  return (
    <Dialog open={isOpen} keepMounted onClose={handleClose}>
      <DialogTitle>{message ?? ""}</DialogTitle>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ImageValidationDialog);
