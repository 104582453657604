import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LiveIcon from "assets/icons/LiveVideo.svg";
import { useCallback, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";

function ReactVideoPlayer() {
  const playerRef = useRef(null);
  const video = useSelector((state) => state.liveStreaming?.video ?? null);
  const [isStart, setIsStart] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  // const [duration, setDuration] = useState(0);
  // const [played, setPlayed] = useState(0);
  const [isError, setIsError] = useState(null);

  const handleOnStart = useCallback(() => {
    // console.log("Video Started");
    setIsStart(true);
  }, []);

  const handleOnEnd = useCallback(() => {
    // console.log("Video Ended");
  }, []);

  const handleError = useCallback((error) => {
    // console.log("Video Error", error);
    setIsError(error);
  }, []);

  const handleOnPlay = useCallback(() => {
    // console.log("onPlay");
    // setIsPlaying(false);
  }, []);

  const handleOnPause = useCallback(() => {
    // console.log("onPause");
    // setIsPlaying(false);
  }, []);

  // const handleProgress = useCallback((duration) => {
  // console.log("onDuration", duration);
  // setDuration(duration);
  // }, []);

  return (
    <Box
      height="75vh"
      width="100%"
      m="auto"
      borderRadius="10px"
      maxWidth="400px"
      position="relative"
      border="1px solid rgba( 255, 255, 255, 0.18 )"
      boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.16)"
      bgcolor="white"
    >
      <ReactPlayer
        ref={playerRef}
        url={video?.slotStatus === "inUse" ? video?.hls : video?.streamingUrl}
        width="100%"
        height="100%"
        // controls={true}
        playing={isPlaying}
        muted={isMuted}
        stopOnUnmount={true}
        onError={handleError}
        onStart={handleOnStart}
        onEnded={handleOnEnd}
        onPause={handleOnPause}
        onPlay={handleOnPlay}
        // onProgress={handleProgress}
      />
      {isStart ? (
        <Stack
          width="100%"
          bgcolor="grey"
          position="absolute"
          bottom={0}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          p={1}
          pr={2}
          zIndex={1}
          borderRadius="10px"
          sx={{
            background: "rgba( 255, 255, 255, 0.25 )",
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
            "backdrop-filter": "blur( 4px )",
            "-webkit-backdrop-filter": "blur( 4px )",
            borderRadius: "10px",
            border: "1px solid rgba( 255, 255, 255, 0.18 )",
          }}
        >
          <Stack direction="row" gap={1} alignItems="center">
            {isPlaying ? (
              <IconButton
                onClick={() => {
                  setIsPlaying(false);
                }}
              >
                <PauseIcon color="white" />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  setIsPlaying(true);
                }}
              >
                <PlayArrowIcon />
              </IconButton>
            )}
            {isMuted ? (
              <IconButton
                onClick={() => {
                  setIsMuted(false);
                }}
              >
                <VolumeOffIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  setIsMuted(true);
                }}
              >
                <VolumeUpIcon />
              </IconButton>
            )}
          </Stack>
          <img src={LiveIcon} alt="Live Icon" height="30px" width="30px" />
        </Stack>
      ) : (
        isError && (
          <Box
            position="absolute"
            top="0"
            height="100%"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h6"
              component="p"
              color="textSecondary"
              textAlign="center"
            >
              Something went wrong. Please check your live stream connection and
              try reloading the page
            </Typography>
          </Box>
        )
      )}
    </Box>
  );
}

export default ReactVideoPlayer;
