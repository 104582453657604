export default function removeDuplicateObjectsById(array) {
  return array.filter((obj, index, self) => {
    return (
      index ===
      self.findIndex((t) => {
        return t.id === obj.id;
      })
    );
  });
}
