import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeProfilePassword, resetErrorState } from "store/slices/authSlice";
import StyledTextField from "utils/helpers/styledTextField";
import * as Yup from "yup";

function UserPasswordReset() {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const error = useSelector((state) => state.auth.error);

  const initialValues = useMemo(
    () => ({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    }),
    []
  );
  const handleSubmit = useCallback(
    (values) => {
      let data = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      dispatch(changeProfilePassword(data));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => dispatch(resetErrorState());
  }, [dispatch]);

  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Stack direction="row" gap={3} p={{ xs: 1, md: 1.5 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <Stack direction="column" gap={1}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Old Password
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        {...formik.getFieldProps("oldPassword")}
                        error={Boolean(
                          formik.touched.oldPassword &&
                            formik.errors.oldPassword
                        )}
                        helperText={
                          formik.touched.oldPassword &&
                          !!formik.errors.oldPassword
                            ? formik.errors.oldPassword
                            : "Enter old password here"
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockOutlinedIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Stack direction="column" gap={1}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        New Password
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        {...formik.getFieldProps("newPassword")}
                        error={Boolean(
                          formik.touched.newPassword &&
                            formik.errors.newPassword
                        )}
                        helperText={
                          formik.touched.newPassword &&
                          !!formik.errors.newPassword
                            ? formik.errors.newPassword
                            : "Enter the new password here"
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockOutlinedIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Stack direction="column" gap={1}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Confirm New Password
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        {...formik.getFieldProps("confirmPassword")}
                        error={Boolean(
                          formik.touched.confirmPassword &&
                            formik.errors.confirmPassword
                        )}
                        helperText={
                          formik.touched.confirmPassword &&
                          !!formik.errors.confirmPassword
                            ? formik.errors.confirmPassword
                            : "Confirm password by typing again here"
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockOutlinedIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Stack direction="row" gap={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={
                    formik.isSubmitting || !formik.isValid || !formik.dirty
                  }
                  onClick={formik.handleSubmit}
                  disableElevation
                  sx={{
                    fontWeight: "bold",
                    minWidth: { md: 100, xl: 250 },
                    height: { xs: 50, xl: 55 },
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={formik.resetForm}
                  sx={{
                    fontWeight: "bold",
                    minWidth: { md: 100, lg: 175, xl: 250 },
                    height: { xs: 50, xl: 55 },
                  }}
                >
                  Reset
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              {error ? (
                <Typography color="error" fontSize={{ lg: "1.15rem" }} mb={1}>
                  {error ?? ""}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default memo(UserPasswordReset);

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("Required*")
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
      "Password must contain at least one uppercase and one lowercase letter and one number and a special character"
    )
    .min(8, "Password must be at least 8 characters"),
  confirmPassword: Yup.string()
    .required("Required*")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must be match"),
});
