import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DataGridSearch from "components/DataGridSearch";
import UploadVideo from "components/Videos/UploadVideo";
import UploadVideoForm from "components/Videos/UploadVideoForm";
import VideoDataGrid from "components/Videos/VideoDataGrid";
import EditVideoForm from "components/Videos/VideoForm/EditVideoForm";
import PropTypes from "prop-types";
function Videos({ upload, update }) {
  if (upload) {
    return <UploadVideoForm />;
  }
  if (update) {
    return <EditVideoForm />;
  }
  return (
    <Box sx={{ flexGrow: 1, py: 3, px: 1 }}>
      <Typography
        variant="h4"
        fontWeight="600"
        mb={1}
        sx={{ fontSize: { md: "1.5rem", lg: "2rem" } }}
      >
        VIDEOS
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb={2}
      >
        <UploadVideo />
        <DataGridSearch
          searchKeyParam="name"
          searchKeyParamValue="title"
          searchQueryParam="value"
        />
      </Box>
      <VideoDataGrid />
    </Box>
  );
}
Videos.propTypes = {
  upload: PropTypes.bool,
  update: PropTypes.bool,
};

Videos.defaultProps = {
  upload: false,
  update: false,
};
export default Videos;
