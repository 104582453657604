import { loadBannerSet } from "components/BannerSet/BannerSetForm";
import CategoryForm from "components/Category/CategoryForm";
import { loadProduct } from "components/Products/ProductForm";
import { loadService } from "components/Services/ServiceForm";
import { loadBanner } from "components/SubBanners/BannerForm";
import { loadSubscription } from "components/Subscriptions/SubscriptionsForm";
import { loadUser } from "components/Users/UserForm";
import Layout from "layout";
import NotFound from "pages/404";
import Auctions from "pages/Auctions";
import BannerSet from "pages/BannerSet";
import Banners from "pages/Banners";
import Bids from "pages/Bids";
import Blogs, { loadBlogs } from "pages/Blogs";
import Categories, { loadCategory } from "pages/Categories";
import Dashboard from "pages/Dashboard";
import LoginPage from "pages/Login";
import Pages, { loadPages } from "pages/Pages";
import PendingApproval from "pages/PendingApproval";
import Products from "pages/Products";
import RecoverPassword from "pages/RecoverPassword";
import ResetPassword from "pages/ResetPassword";
import SellWithUs from "pages/SellWithUs";
import Services from "pages/Services";
import Settings from "pages/Settings";
import SignupPage from "pages/Signup";
import Subscriptions, { loadSubscriptions } from "pages/Subscriptions";
import Users from "pages/Users";
import Verification from "pages/Verification";
import Videos from "pages/Videos";
import LiveStreaming from "pages/liveStreaming";
import LiveStreamingVideo from "pages/liveStreaming/LiveStreamingVideo";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import AuthRoute from "routes/AuthRoute";
import ProtectedRoute from "routes/ProtectedRoute";
import VerificationRoute from "routes/VerificationRoute";
import AdminRoute from "./AdminRoute";
import SuperAdminRoute from "./SuperAdminRoute";

const routes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />

          <Route path="products">
            <Route index element={<Products />} />
            <Route path="create" element={<Products create />} />
            <Route
              path="edit/:id"
              element={<Products edit />}
              loader={loadProduct}
            />
          </Route>

          <Route path="auctions">
            <Route index element={<Auctions />} />
            <Route path="create" element={<Auctions create />} />
            <Route path="update/:id" element={<Auctions edit />} />
            {/* <Route path="add/:id" element={<Auctions addProducts />} /> */}
          </Route>

          <Route path="categories">
            <Route index element={<Categories />} />
            <Route path="create" element={<CategoryForm create />} />
            <Route
              path=":id/update"
              element={<Categories edit />}
              loader={loadCategory}
            />
            <Route
              path=":parentId/subcategory"
              element={<Categories addSubCategory />}
            />
            <Route path=":lvl2" element={<Categories title="Categories" />} />
            <Route
              path=":lvl2/:lvl3"
              element={<Categories title="Categories" />}
            />
            <Route
              path=":lvl2/:lvl3/:lvl4"
              element={<Categories title="Categories" />}
            />
          </Route>

          <Route path="videos">
            <Route index element={<Videos />} />
            <Route path="upload" element={<Videos upload />} />
            <Route path=":slug/update" element={<Videos update />} />
          </Route>

          <Route path="upload" element={<Videos upload />} />

          <Route path="live-streaming" element={<AdminRoute />}>
            <Route index element={<LiveStreaming />} />
            <Route path=":id" element={<LiveStreamingVideo />} />
          </Route>

          <Route path="pages">
            <Route index element={<Pages />} loader={loadPages} />
            <Route path="create" element={<Pages create />} />
            <Route path=":id/update" element={<Pages update />} />
          </Route>

          <Route path="bids">
            <Route index element={<Bids />} />
          </Route>

          <Route path="subscriptions">
            <Route
              index
              element={<Subscriptions />}
              loader={loadSubscriptions}
            />
            <Route path="create" element={<Subscriptions create />} />
            <Route
              path=":id/update"
              element={<Subscriptions update />}
              loader={loadSubscription}
            />
          </Route>

          <Route path="users">
            <Route index element={<Users />} />
            <Route path="create" element={<Users create />} />
            <Route
              path=":id/update"
              element={<Users update />}
              loader={loadUser}
            />
          </Route>

          <Route path="services">
            <Route index element={<Services />} />
            <Route path="create" element={<Services create />} />
            <Route
              path="edit/:id"
              element={<Services update />}
              loader={loadService}
            />
          </Route>

          <Route path="banner-set">
            <Route index element={<BannerSet title="Banner Set" />} />
            <Route path="create" element={<BannerSet create />} />
            <Route
              path=":id/update"
              element={<BannerSet update />}
              loader={loadBannerSet}
            />
            <Route path=":id/banners/:device" element={<Banners />} />
            <Route path=":id/banners">
              <Route index element={<Banners />} />
              <Route
                path=":device/create/:device"
                element={<Banners create />}
              />
              <Route
                path=":device/:id/update"
                element={<Banners update />}
                loader={loadBanner}
              />
            </Route>
          </Route>

          <Route path="blogs">
            <Route index element={<Blogs />} loader={loadBlogs} />
            <Route path="create" element={<Blogs create />} />
            <Route path=":id/update" element={<Blogs update />} />
          </Route>

          <Route path="sell-with-us">
            <Route index element={<SellWithUs />} />
            <Route path="create" element={<SellWithUs create />} />
            <Route path=":id/update" element={<SellWithUs update />} />
          </Route>

          <Route path="/settings" element={<Settings />} />
        </Route>
      </Route>

      <Route element={<AuthRoute />}>
        <Route path="login" element={<LoginPage />} />
      </Route>

      <Route path="signup" element={<SuperAdminRoute />}>
        <Route index element={<SignupPage />} />
      </Route>

      <Route element={<VerificationRoute />}>
        <Route index path="verification" element={<Verification />} />
      </Route>

      <Route path="pending-approval" element={<PendingApproval />} />

      <Route path="recover-password" element={<RecoverPassword />} />

      <Route path="reset-password" element={<ResetPassword />} />

      <Route path="*" element={<NotFound />} />
    </>
  )
);

export default routes;
