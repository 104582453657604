import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DataGridSearch from "components/DataGridSearch";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { BUTTON_HOVER } from "styles/colors";

function Actions() {
  const navigate = useNavigate();
  return (
    <Box py={1} sx={containerStyles}>
      <Button
        size="large"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => navigate("create")}
      >
        Add New
      </Button>
      <DataGridSearch
        searchKeyParam="name"
        searchKeyParamValue="bannerName"
        searchQueryParam="value"
      />
    </Box>
  );
}

export default memo(Actions);

const containerStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .MuiOutlinedInput-root": {
    "&:hover": {
      backgroundColor: BUTTON_HOVER,
    },
  },
  "& .MuiSelect-select": { height: "29px !important" },
  "& .Filter-toggle-btn": {
    height: 44,
    textTransform: "none",
    fontSize: "1rem",
  },
};
