import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PendingApprovalImage from "assets/images/PendingApprovalImage.png";
import { useNavigate } from "react-router-dom";

function PendingApproval() {
  const navigate = useNavigate();
  return (
    <Stack
      height="100vh"
      width="100%"
      justifyContent="center"
      alignItems="center"
      bgcolor="#f8f8f8"
    >
      <Container maxWidth="md">
        <Paper elevation={4} sx={{ p: 3, bgcolor: "#f8f8f8" }}>
          <Stack spacing={3} justifyContent="center" alignItems="center">
            <img
              src={PendingApprovalImage}
              alt="Pending Approval"
              width={200}
              height="auto"
            />
            <Typography
              variant="h5"
              component="h1"
              align="center"
              color="text.secondary"
              textAlign="justify"
            >
              Your request to become a seller is pending. Our representative
              will give you a call within 48 hours to confirm your registration.
              You can also call our Vendor management team on 0301-1171430. For
              any further assistance.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                localStorage.clear();
                navigate("/login");
              }}
            >
              Go Back
            </Button>
          </Stack>
        </Paper>
      </Container>
    </Stack>
  );
}

export default PendingApproval;
