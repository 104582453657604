import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSubscriptionUpdateDialogState } from "store/slices/uiSlice";

function SubscriptionUpdateDialog({ handleUpdateSubscription }) {
  const dispatch = useDispatch();
  const isOpen = useSelector(
    (state) => state.UI.isSubscriptionUpdateDialogOpen
  );

  const handleClose = useCallback(() => {
    dispatch(setSubscriptionUpdateDialogState(false));
  }, [dispatch]);

  const handleClick = useCallback(
    (boolean) => {
      handleUpdateSubscription(boolean);
      handleClose();
    },
    [handleClose, handleUpdateSubscription]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": { minWidth: { lg: "1000px" } },
      }}
      PaperProps={{
        elevation: 0,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={5}
        bgcolor="primary.main"
        sx={{
          padding: "0.25rem 0 0.25rem 2rem",
        }}
      >
        <Stack direction="column">
          <Typography variant="h5" component="h1" color="white">
            Select Update Type
          </Typography>
        </Stack>
        <IconButton onClick={handleClose} size="large">
          <CancelIcon sx={{ color: "white", fontSize: "2.5rem" }} />
        </IconButton>
      </Stack>
      <Box display="flex" flexDirection="column" mt={1} p={3}>
        <Stack direction="row" gap={2}>
          <Box flex={1} display="flex" flexDirection="column" gap={2}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              onClick={() => handleClick(true)}
              sx={{
                height: 50,
              }}
            >
              Update All
            </Button>
            <Typography variant="body1" color="text.muted">
              {`* This action will update the subscription plan of all subscribers, including previous members too.`}
            </Typography>
          </Box>
          <Box flex={1}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              onClick={() => handleClick(false)}
              sx={{
                height: 50,
              }}
            >
              Update
            </Button>
          </Box>
        </Stack>
      </Box>
    </Dialog>
  );
}

SubscriptionUpdateDialog.propTypes = {
  handleUpdateSubscription: PropTypes.func.isRequired,
};

export default memo(SubscriptionUpdateDialog);
