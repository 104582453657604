import { PRODUCT_FORM_STATUSES } from "utils/constants/product";
import * as Yup from "yup";

export const getCreateProductApiDataValues = (
  data = {},
  specifications = []
) => {
  return {
    ...(data?.category && { category: data?.category }),
    ...(data?.brandId && { brandId: data?.brandId }),
    ...(data?.ownerId && { ownerId: data?.ownerId }),
    ...(data?.chassisNumber && { vin: data?.chassisNumber }),
    ...(data?.productName && { productName: data?.productName }),
    ...createSpecificationValues(specifications, data, true),
    ...(data?.bodyType && { bodyType: data?.bodyType }),
    /* Vehicle Price */
    ...(data?.price && { evaluationPrice: data?.price }),
    /* Auction Info */
    ...(data?.lotNumber && { lotNumber: data?.lotNumber }),
    ...(data?.auctionId && { auctionId: data?.auctionId }),
    /* Services */
    ...(data?.services?.length && { services: data?.services }),
    /* Product Details */
    ...(data?.description && { description: data?.description }),
    /* Extra */
    ...(data?.bidEnd && { bidEnd: data?.bidEnd }),
    ...(data?.removeServices?.length && {
      removeServices: data?.removeServices,
    }),
  };
};

export const getProductFormStatus = (product) => {
  if (product?.status === "draft" && product?.draftStatus === "new")
    return PRODUCT_FORM_STATUSES.BASIC;
  if (product?.status === "draft" && product?.draftStatus === "reviewed")
    return PRODUCT_FORM_STATUSES.MEDIA;
  if (product?.status === "draft" && product?.draftStatus === "media")
    return PRODUCT_FORM_STATUSES.REPORTS;
  return PRODUCT_FORM_STATUSES.BASIC;
};

export const createSpecificationValues = (
  categorySpecifications = [],
  productSpecifications = {},
  isApiData = false
) => {
  const specifications = {};
  categorySpecifications?.forEach((c_spec) => {
    if (
      Object.prototype.hasOwnProperty.call(productSpecifications, c_spec?.name)
    ) {
      specifications[c_spec.name] = productSpecifications[c_spec.name] ?? "";
    } else {
      specifications[c_spec.name] = "";
    }
  });
  if (isApiData) {
    const apiData = {};
    // code to truthy values only
    Object.entries(specifications).forEach(([key, value]) => {
      if (value) {
        apiData[key] = value;
      }
    });
    if (Object.keys(apiData).length === 0) return {};
    return { productSpecs: apiData };
  }

  return specifications;
};

export const createSpecificationSchema = (specifications = []) => {
  const schema = {};
  specifications?.forEach((spec) => {
    let validationSchema = Yup.string().trim(); // Default to string type

    if (spec?.dataType === "number") {
      validationSchema = Yup.number();
    }

    if (spec?.specs === true) {
      validationSchema = validationSchema.required("Required");
    }
    if (spec?.min === spec?.max) {
      if (spec?.dataType === "number") {
        validationSchema = validationSchema.test(
          schema[spec?.name] ?? "",
          `Value should be of ${spec?.min} digits`,
          (val) => val.toString().length === spec?.min
        );
      } else {
        validationSchema = validationSchema.length(
          spec?.min,
          `Value should be ${spec?.min} in length`
        );
      }
    } else {
      if (spec?.min) {
        validationSchema = validationSchema.min(
          spec.min,
          spec.dataType === "number"
            ? `Min value should be ${spec?.min}`
            : `Min length should be ${spec?.min}`
        );
      }

      if (spec?.max) {
        validationSchema = validationSchema.max(
          spec.max,
          spec.dataType === "number"
            ? `Max value should be ${spec?.max}`
            : `Max length should be ${spec?.max}`
        );
      }
    }

    schema[spec?.name] = validationSchema;
  });
  return schema;
};
