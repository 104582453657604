import { createSlice } from "@reduxjs/toolkit";
import removeDuplicateObjectsById from "utils/helpers/removeDuplicateObjectsById";

const initialState = {
  loading: true,
  results: [],
  page: 0,
  limit: 10,
  totalPages: 0,
  totalResults: 0,
  error: "",
  unreadNotificationsCount: 0,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    resetState: () => initialState,
    setLoadingState: (state, action) => {
      state.loading = action.payload;
    },
    setUnreadNotificationsCount: (state, action) => {
      state.unreadNotificationsCount = action.payload;
    },
    setNotificationsData: (state, action) => {
      if (state.page < action?.payload?.page) {
        state.results = removeDuplicateObjectsById([
          ...state.results,
          ...action.payload.results,
        ]);
        state.page = action.payload?.page;
      }
      state.loading = false;
      state.limit = action.payload?.limit;
      state.totalPages = action.payload?.totalPages;
      state.totalResults = action.payload?.totalResults;
    },
    prependNotification: (state, action) => {
      state.results = [action.payload, ...state.results];
    },
  },
});

export const {
  resetState,
  setLoadingState,
  setUnreadNotificationsCount,
  setNotificationsData,
  prependNotification,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
