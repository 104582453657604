import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ProductAddImagePlaceholder from "assets/icons/ProductAddImagePlaceholder.svg";
import PropTypes from "prop-types";
import { memo, useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setImageValidationError,
  setIsImageValidationDialogOpen,
} from "store/slices/uiSlice";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import validateImageAspectRatio from "utils/helpers/validateImageAspectRatio";

function CategoryMediaSection({ formik }) {
  const dispatch = useDispatch();
  const mainImageInputRef = useRef(null);
  // const bannerImageRef = useRef(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  // const [bannerImage, setBannerImage] = useState(
  //   formik.values.bannerImage ?? []
  // );
  const [mainImage, setMainImage] = useState(formik.values.mainImage ?? []);

  const mainImageHandler = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        // function params
        // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
        validateImageAspectRatio(image, 500, 500, false, true, 1 / 1, "1 / 1")
          .then((img) => {
            formik.setFieldValue("mainImage", img);
            setMainImage(URL.createObjectURL(img));
            mainImageInputRef.current.value = "";
          })
          .catch((error) => {
            mainImageInputRef.current.value = "";
            dispatch(setIsImageValidationDialogOpen(true));
            dispatch(setImageValidationError(error.message));
          });
      }
    },
    [dispatch, formik]
  );

  // const bannerImageHandler = useCallback(
  //   (e) => {
  //     formik.setFieldValue("bannerImage", e.target.files[0]);
  //     setBannerImage(URL.createObjectURL(e.target.files[0]));
  //     e.target.value = "";
  //   },
  //   [formik]
  // );

  return (
    <StyledMuiCard
      title="Media Section"
      subTitle="Upload images and videos relevant to the product"
    >
      <CardContent>
        <Box display="flex" flexWrap="wrap" gap={4}>
          {/* Category Main Image */}
          <Stack direction="column" gap={2}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Main Image
            </Typography>
            <Box>
              <img
                src={
                  formik.values.mainImage
                    ? mainImage
                    : ProductAddImagePlaceholder
                }
                alt="Category Main Media"
                height="auto"
                width={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                style={{ cursor: "pointer" }}
                onClick={() => mainImageInputRef.current.click()}
              />
              <input
                type="file"
                ref={mainImageInputRef}
                style={{ display: "none" }}
                id="update-category-image"
                hidden
                onChange={mainImageHandler}
                accept="image/x-png,image/png,image/jpeg,image/jpg"
              />
              {Boolean(formik.errors.mainImage) && (
                <Typography color="#ef5350">
                  {formik.errors.mainImage}*
                </Typography>
              )}
            </Box>
          </Stack>

          {/* Category Banner Image */}
          {/* <Stack
            direction="column"
            gap={2}
            height="100%"
            justifyContent="space-between"
          >
            <Stack direction="column" gap={2}>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Banner
              </Typography>
              <Box display="flex" flexDirection="row" gap={2}>
                <img
                  ref={bannerImageRef}
                  src={ProductAddImagePlaceholder}
                  alt="Add Category Media Placeholder"
                  height="auto"
                  width="150px"
                  style={{ cursor: "pointer" }}
                  onClick={() => bannerImageRef.current.click()}
                />
                {!bannerImage.length ? (
                  <Box>
                    <CategoryImagePlaceholder />
                  </Box>
                ) : (
                  <Stack direction="row" alignItems="flex-start">
                    <img
                      src={
                        formik.values.bannerImage
                          ? bannerImage
                          : CategoryImagePlaceholder
                      }
                      alt="bannerImageForCategory"
                      height="auto"
                      width="150px"
                    />
                  </Stack>
                )}
              </Box>
              <input
                type="file"
                style={{ display: "none" }}
                id="upload-product-gallery-images"
                hidden
                ref={bannerImageRef}
                accept="image/x-png,image/png,image/jpeg,image/jpg"
                onChange={bannerImageHandler}
              />
              {Boolean(formik.errors.bannerImage) && (
                <Typography color="#ef5350">
                  {formik.errors.bannerImage}*
                </Typography>
              )}
            </Stack>
          </Stack> */}
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
}

CategoryMediaSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(CategoryMediaSection);
