function validateImageAspectRatio(
  image,
  requiredWidth = 0,
  requiredHeight = 0,
  fixedDimensions = false,
  ratioValidation = false,
  requiredAspectRatio = 0,
  aspectRatioInString = ""
) {
  // const allowedImageTypes = ["image/png", "image/jpeg", "image/jpg"];

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(image);

    img.onload = () => {
      const { width, height } = img;
      const imageAspectRatio = width / height;
      if (
        fixedDimensions &&
        (width !== requiredWidth || height !== requiredHeight)
      ) {
        reject(
          new Error(
            `Image size is not correct. Image should be exact of size ${requiredWidth}×${requiredHeight} px.`
          )
        );
      } else if (width < requiredWidth || height < requiredHeight) {
        reject(
          new Error(
            `Image size is not correct. Image should be at least ${requiredWidth}×${requiredHeight} px.`
          )
        );
      } else if (
        ratioValidation &&
        Math.abs(requiredAspectRatio - imageAspectRatio) !== 0
      ) {
        reject(
          new Error(
            `Incorrect image aspect ratio. Image aspect ratio should be ${aspectRatioInString}`
          )
        );
      } else {
        resolve(image);
      }
    };

    img.onerror = () => {
      reject(new Error("Failed to load the image."));
    };
  });
}

export default validateImageAspectRatio;
