import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import productPlaceholder from "assets/images/imagePlaceholder.png";
import CurrencyFormatter from "components/CurrencyFormatter";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledDataGrid from "components/StyledGrid";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteBanner } from "store/slices/bannersSlice";
import {
  bidderCancelation,
  getBids,
  listerCancelation,
} from "store/slices/bidsSlice";
import useQueryParams from "utils/hooks/useQueryParams";

export default function DataGrid() {
  const params = useQueryParams();
  const dispatch = useDispatch();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const { results, loading, totalPages } = useSelector((state) => state.bids);

  const fetchData = useCallback(() => {
    dispatch(getBids(params));
  }, [dispatch, params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = useCallback(
    (data) => {
      if (data.bidderCancelation) {
        dispatch(bidderCancelation(data.productId));
      }
      if (data.listerCancelation) {
        dispatch(listerCancelation(data.productId));
      }
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 50,
        flex: 0.1,
        filterable: false,
      },
      {
        field: "image",
        headerName: "Image",
        headerAlign: "center",
        align: "center",
        minWidth: 115,
        flex: 0.4,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => (
          <Box>
            <img
              src={
                params?.row?.productImage
                  ? params?.row?.productImage
                  : productPlaceholder
              }
              height={
                isTablet
                  ? "70px"
                  : isLaptop
                  ? "80px"
                  : isLed
                  ? "100px"
                  : "113px"
              }
              width={
                isTablet
                  ? "70px"
                  : isLaptop
                  ? "80px"
                  : isLed
                  ? "100px"
                  : "113px"
              }
              style={{
                borderRadius: "10px",
                marginTop: 6,
                objectFit: "contain",
              }}
              alt="product_image"
            />
          </Box>
        ),
      },
      {
        field: "productName",
        headerName: "Product Name",
        minWidth: 200,
        flex: 1,
        sortable: false,
        editable: true,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.productName}
          </Typography>
        ),
      },
      {
        field: "lastbidAmount",
        headerName: "Last Bid Amount",
        minWidth: 150,
        flex: 0.5,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <CurrencyFormatter number={params?.row?.lastbidAmount ?? 0} />
        ),
      },
      {
        field: "bidAmount",
        headerName: "Bid Amount",
        minWidth: 150,
        flex: 0.5,
        sortable: false,
        editable: true,
        renderCell: (params) => (
          <CurrencyFormatter number={params?.row?.bidAmount ?? 0} />
        ),
      },
      {
        field: "status",
        headerName: "Status",
        minWidth: 120,
        flex: 0.3,
        sortable: false,
        editable: true,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.status}
          </Typography>
        ),
      },
      {
        field: "createdAt",
        headerName: "Date & Time",
        minWidth: 170,
        flex: 0.5,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        renderCell: (params) => (
          <Typography>
            {dayjs(params?.row?.createdAt).format("DD/MM/YY [At] h:mm A")}
          </Typography>
        ),
      },
      {
        field: "Action",
        headerName: "Action",
        type: "actions",
        headerAlign: "center",
        align: "center",
        minWidth: 160,
        flex: 0.1,
        sortable: false,
        editable: true,
        renderCell: (params) => (
          <Button
            variant="contained"
            onClick={() => handleChange(params?.row)}
            disabled={
              params?.row?.bidderCancelation === false ||
              params?.row?.listerCancelation ||
              params?.row?.status === "canceled"
            }
          >
            Cancel Bid
          </Button>
        ),
      },
    ],
    [handleChange, isLaptop, isLed, isTablet]
  );
  return (
    <>
      <StyledDataGrid
        rows={results}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteBanner} />
    </>
  );
}
