import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCategoryBySlug } from "store/slices/filtersSlice";
import {
  addProduct,
  resetProductState,
  updateProduct,
} from "store/slices/productsSlice";
import { PRODUCT_FORM_STATUSES } from "utils/constants/product";
import parseEditorValue from "utils/helpers/parseEditorValue";
import {
  createSpecificationSchema,
  createSpecificationValues,
  getCreateProductApiDataValues,
} from "utils/helpers/productForm";
import reduceDepthObject from "utils/helpers/reduceDepthObjects";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";
import AuctionInfo from "./AuctionInfo";
import BasicInformation from "./BasicInformation";
import PriceSection from "./PriceSection";
import ProductDetails from "./ProductDetails";
import Services from "./Services";
import UserInformation from "./UserInformation";

function ProductBasicInformation() {
  const id = useParams()?.id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.products.product);
  const loading = useSelector((state) => state.filters.loading.category);
  const categories = useSelector(
    (state) => state.filters.productFlattenedCategories
  );
  const category = useSelector((state) => state.filters.category);
  const isSubmitting = useSelector(
    (state) => state.products.isProductSubmitting
  );

  const initialValues = useMemo(
    () => ({
      id: product?.id ?? "",
      /* User Information */
      ownerId: product?.ownerId?.id ?? "",
      /* Vehicle Information */
      productName: product?.productName ?? "",
      brandId: product?.brand?.id ?? product?.brandId ?? "", // Second tier category id
      category: product?.category ?? "", // nth tier category id

      /* Dynamic Specs */
      ...createSpecificationValues(
        category?.specifications ?? [],
        product?.productSpecs ?? {}
      ),
      /* Vehicle Price */
      price: product?.evaluationPrice || "",
      reservePrice: product?.reservePrice || "",
      /* Auction Info */
      lotNumber: product?.lotNumber || "",
      auctionId: product?.auctionId?.id ?? "",
      /* Services */
      services: product?.purchasedService?.map((service) => service?.id) ?? [],
      /* Product Details */
      description: product?.description
        ? parseEditorValue(product?.description)
        : "",
      /* Extra */
      bidEnd: product?.bidEnd ?? "",
      status: product?.status ?? "",
      draftStatus: product?.draftStatus ?? "",
      removeServices: [],
    }),
    [category, product]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        id: Yup.string(),
        /* User Information */
        ownerId: Yup.string().required("Required"),
        /* Vehicle Information */
        productName: Yup.string()
          .trim()
          .required("Required")
          .min(4, "Too Short")
          .max(100, "Too Long"),
        brandId: Yup.string().required("Required"),
        category: Yup.string().required("Required"),

        /* Dynamic Specs */
        ...createSpecificationSchema(category?.specifications ?? []),
        /* Vehicle Price */
        price: Yup.number().required("Required"),
        reservePrice: Yup.number().when("id", {
          is: (id) => !!id,
          then: () => Yup.number().required("Required"),
          otherwise: () => Yup.number(),
        }),
        /* Auction Info */
        lotNumber: Yup.string()
          .required("Required")
          .length(6, "Lot Number must be of 6 digits"),
        auctionId: Yup.string(),
        /* Services */
        services: Yup.array().of(Yup.string()),
        /* Product Details */
        description: Yup.string()
          .required("Required")
          .min(80, "Description must be at least 80 characters long")
          .max(2000, "Description too long"),
        /* Extra */
        bidEnd: Yup.date().when("draftStatus", {
          is: (status) => status === PRODUCT_FORM_STATUSES.COMPLETED,
          then: () =>
            Yup.date().min(
              new Date(),
              "Start date must be greater than current date."
            ),
          otherwise: () => Yup.date(),
        }),
        status: Yup.string(),
        draftStatus: Yup.string(),
        removeServices: Yup.array().of(Yup.string()),
      }),
    [category]
  );

  const handleSubmit = useCallback(
    (values) => {
      if (id) {
        const data = reduceDepthObject(values, initialValues);
        if (typeof data.services === "object") {
          data.services = Object.values(data.services);
        }
        if (typeof data.removeServices === "object") {
          data.removeServices = Object.values(data.removeServices);
        }
        const _data = getCreateProductApiDataValues(
          data,
          category?.specifications ?? []
        );
        if (initialValues?.draftStatus === "new") {
          _data.draftStatus = "reviewed";
        }
        if (!Object.entries(_data).length) {
          dispatch(resetProductState());
          if (initialValues?.draftStatus === "completed") {
            return navigate(`/products`);
          } else if (initialValues?.draftStatus !== "reviewed") {
            return navigate(`/products/edit/${id}`);
          }
        }
        dispatch(updateProduct({ productId: id, data: _data }))
          .unwrap()
          .then(() => {
            toast.success("Product Updated Successfully");
            dispatch(resetProductState());
            if (initialValues?.draftStatus === "completed") {
              return navigate(`/products`);
            } else navigate(`/products/edit/${id}`);
          });
      } else {
        const data = getCreateProductApiDataValues(
          values,
          category?.specifications ?? []
        );
        dispatch(addProduct(data))
          .unwrap()
          .then((response) => {
            dispatch(resetProductState());
            if (initialValues?.draftStatus === "completed") {
              return navigate(`/products`);
            } else navigate(`/products/edit/${response?.data?.id}`);
          });
      }
    },
    [category, dispatch, id, initialValues, navigate]
  );

  useEffect(() => {
    const id = setTimeout(() => {
      if (
        !categories.length &&
        loading &&
        product &&
        product?.categories?.lvl0
      ) {
        dispatch(
          getCategoryBySlug(product?.categories?.lvl0?.toLowerCase() ?? "")
        );
      }
    }, 100);
    return () => clearTimeout(id);
  }, [dispatch, loading, categories, product]);

  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={8}
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <UserInformation formik={formik} />
              <BasicInformation formik={formik} />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <PriceSection formik={formik} />
              <AuctionInfo formik={formik} />
              <Services formik={formik} />
            </Grid>

            <Grid item xs={12}>
              <ProductDetails formik={formik} />
            </Grid>

            {/* Action Buttons */}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                onClick={formik.handleSubmit}
                disabled={isSubmitting}
                sx={{
                  fontWeight: "bold",
                  minWidth: { md: 100, xl: 250 },
                  height: { xs: 50, xl: 55 },
                }}
              >
                {isSubmitting
                  ? "Submitting..."
                  : id && formik.values.draftStatus === "completed"
                  ? "Save"
                  : id
                  ? "review"
                  : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default memo(ProductBasicInformation);
