const convertStringNumbersToNumbers = (obj) => {
  for (const key in obj) {
    if (typeof obj[key] === "string") {
      if (!isNaN(+obj[key]) && obj[key].trim() === obj[key]) {
        obj[key] = +obj[key];
      }
    } else if (typeof obj[key] === "object") {
      convertStringNumbersToNumbers(obj[key]);
    }
  }
};
export default convertStringNumbersToNumbers;
