import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ProductVideoPlaceholder from "assets/icons/ProductVideoPlaceholder.svg";
import addFilePlaceholder from "assets/icons/addFilePlaceholder.svg";
import pdfFilePlaceholder from "assets/icons/pdfFilePlaceholder.svg";
import pdfPlaceholder from "assets/icons/pdfPlaceholder.svg";
import PropTypes from "prop-types";
import { memo, useRef } from "react";
import StyledMuiCard from "utils/helpers/StyledMuiCard";

function Reports({ formik, isSubmitting }) {
  const inspectionPDdfRef = useRef(null);
  const evaluationPDdfRef = useRef(null);
  const valuationPDdfRef = useRef(null);
  const inspectionVideoRef = useRef(null);

  const handlePdfs = (e, name) => {
    const report = e.target.files[0];
    if (report) {
      formik.setFieldValue(name, report);
    }
  };

  const handleVideo = (e) => {
    const video = e.target.files[0];
    if (video) {
      formik.setFieldValue("inspectionVideo", video);
    }
  };

  return (
    <StyledMuiCard
      title="Product Reports"
      subTitle="Upload images and videos relevant to the product"
    >
      <CardContent>
        <Grid container spacing={2}>
          {/* Inspection Report */}
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <Typography variant="h4" color="text.secondary" fontWeight={600}>
              Inspection Report
            </Typography>
            <Box display="flex" flexDirection="row" gap={2}>
              <img
                ref={inspectionPDdfRef}
                src={addFilePlaceholder}
                alt="Add Product Gallery Placeholder"
                height="175px"
                width="175px"
                style={{ cursor: "pointer" }}
                onClick={() => inspectionPDdfRef.current.click()}
              />
              {formik.values?.inspection ? (
                <img
                  src={pdfFilePlaceholder}
                  alt=""
                  height="175px"
                  width="175px"
                />
              ) : (
                <img
                  src={pdfPlaceholder}
                  alt="Product Gallery Placeholder"
                  height="175px"
                  width="175px"
                />
              )}
            </Box>
            <input
              type="file"
              ref={inspectionPDdfRef}
              style={{ display: "none" }}
              id="Inspection Report"
              hidden
              accept="application/pdf"
              onChange={(e) => handlePdfs(e, "inspection")}
              disabled={isSubmitting}
            />
            <Typography variant="subtitle1" color="text.secondary">
              Report should be in PDF format and the size should be less than
              100MB
            </Typography>
          </Grid>

          {/* Evaluation Report */}
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <Typography variant="h4" color="text.secondary" fontWeight={600}>
              Evaluation Report
            </Typography>
            <Box display="flex" flexDirection="row" gap={2}>
              <img
                ref={evaluationPDdfRef}
                src={addFilePlaceholder}
                alt="Add Product Gallery Placeholder"
                height="175px"
                width="175px"
                style={{ cursor: "pointer" }}
                onClick={() => evaluationPDdfRef.current.click()}
              />
              {formik.values?.evaluation ? (
                <img
                  src={pdfFilePlaceholder}
                  alt=""
                  height="175px"
                  width="175px"
                />
              ) : (
                <img
                  src={pdfPlaceholder}
                  alt="Product Gallery Placeholder"
                  height="175px"
                  width="175px"
                />
              )}
            </Box>
            <input
              type="file"
              ref={evaluationPDdfRef}
              style={{ display: "none" }}
              id="Evaluation Report"
              hidden
              accept="application/pdf"
              onChange={(e) => handlePdfs(e, "evaluation")}
              disabled={isSubmitting}
            />
            <Typography variant="subtitle1" color="text.secondary">
              Report should be in PDF format and the size should be less than
              100MB
            </Typography>
          </Grid>

          {/* Valuation Report */}
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <Typography variant="h4" color="text.secondary" fontWeight={600}>
              Valuation Report
            </Typography>
            <Box display="flex" flexDirection="row" gap={2}>
              <img
                ref={valuationPDdfRef}
                src={addFilePlaceholder}
                alt="Add Product Gallery Placeholder"
                height="175px"
                width="175px"
                style={{ cursor: "pointer" }}
                onClick={() => valuationPDdfRef.current.click()}
              />
              {formik.values?.valuation ? (
                <img
                  src={pdfFilePlaceholder}
                  alt=""
                  height="175px"
                  width="175px"
                />
              ) : (
                <img
                  src={pdfPlaceholder}
                  alt="Product Gallery Placeholder"
                  height="175px"
                  width="175px"
                />
              )}
            </Box>
            <input
              type="file"
              ref={valuationPDdfRef}
              style={{ display: "none" }}
              id="Valuation Report"
              hidden
              accept="application/pdf"
              onChange={(e) => handlePdfs(e, "valuation")}
              disabled={isSubmitting}
            />
            <Typography variant="subtitle1" color="text.secondary">
              Report should be in PDF format and the size should be less than
              100MB
            </Typography>
          </Grid>

          {/* Inspection Video */}
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <Typography variant="h4" color="text.secondary" fontWeight={600}>
              Inspection Video
            </Typography>
            <Box display="flex" flexDirection="row" gap={2}>
              <img
                src={addFilePlaceholder}
                alt="Add Product Video Placeholder"
                height="175px"
                width="175px"
                style={{ cursor: "pointer" }}
                onClick={() => inspectionVideoRef.current.click()}
              />
              {!formik.values?.inspectionVideo ? (
                <img
                  src={ProductVideoPlaceholder}
                  alt="Product Video Placeholder"
                  height="175px"
                  width="175px"
                />
              ) : (
                <video
                  src={formik.values?.inspectionVideo}
                  alt="Product Video"
                  height="175px"
                  width="175px"
                  controls
                />
              )}
            </Box>
            <input
              type="file"
              ref={inspectionVideoRef}
              style={{ display: "none" }}
              id="update-product-video"
              hidden
              accept="video/mp4,video/x-m4v,video/*"
              onChange={handleVideo}
              disabled={isSubmitting}
            />
            <Typography variant="subtitle1" color="text.secondary">
              Video should be in MP4 format and the size should be less than
              350MB
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </StyledMuiCard>
  );
}

Reports.propTypes = {
  formik: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setIsSubmitting: PropTypes.func.isRequired,
};

export default memo(Reports);
