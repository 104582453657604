import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { BACKGROUND } from "styles/colors";

let theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#ed4928",
    },
    secondary: {
      main: "#F5F5FB",
    },
    background: {
      default: BACKGROUND,
    },
  },
  typography: {
    fontFamily: "Inter,sans-serif",
    h1: {
      fontSize: "2rem",
    },
    h2: {
      fontSize: "1.75rem",
    },
    h3: {
      fontSize: "1.50rem",
    },
    h4: {
      fontSize: "1.25rem",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1400,
      xxl: 1600,
      xxxl: 1920,
    },
  },
  shape: {
    borderRadius: 6,
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiPaginationItem: {
      root: {
        borderRadius: "0% !important",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          textTransform: "capitalize",
        },
      },
    },
  },
  overrides: {
    MuiButton: {
      root: {
        height: 50,
      },
    },
    props: {
      MuiTooltip: {
        arrow: true,
      },
    },
    shape: {
      borderRadius: 6,
    },
  },
});

theme = responsiveFontSizes(theme, {
  breakpoints: ["xs", "sm", "md", "lg", "xl", "xxl", "xxl"],
});

export default theme;
