import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { memo } from "react";
import {
  BUTTON_HOVER,
  BUTTON_HOVER_BORDER_COLOR,
  FILTERS_BORDER_COLOR,
} from "styles/colors";
import CardTitle from "../CardTitle";
import RedialChart from "./RedialChart";

const Pledges = () => {
  return (
    <Card
      sx={{
        p: "1.2rem",
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
      }}
    >
      <Box sx={containerStyles}>
        <CardTitle title="Number of Pledges" color="#707070" />
      </Box>
      <RedialChart />
    </Card>
  );
};
export default memo(Pledges);
const containerStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: "50px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
    },
    "&:hover": {
      backgroundColor: BUTTON_HOVER,
      "& fieldset": {
        borderColor: BUTTON_HOVER_BORDER_COLOR,
      },
    },
  },
};
