export const SERVICE_TYPE = {
  INDIVIDUAL: "individual",
  AGENT: "agent",
};

export const AMOUNT_TYPE = {
  AMOUNT: "amount",
  PERCENTAGE: "percentage",
};

export const EVENT_TYPE = {
  LISTING: "listing",
  ROLLOVER: "rollOver",
  COMMISSION: "commission",
  CANCELATION: "cancelation",
};
