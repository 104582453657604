import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Convertible from "assets/icons/auctionedItems/Convertible.svg";
import Coupe from "assets/icons/auctionedItems/Coupe.svg";
import SUV from "assets/icons/auctionedItems/SUV.svg";
import bike from "assets/icons/auctionedItems/bike.svg";
import hatchback from "assets/icons/auctionedItems/hatchback.svg";
import minivan from "assets/icons/auctionedItems/minivan.svg";
import van from "assets/icons/auctionedItems/van.svg";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";

const ItemsAuctioned = ({ title, cars }) => {
  const imageIcon = useCallback((title) => {
    switch (title) {
      case "Convertible":
        return Convertible;
      case "Coupe":
        return Coupe;
      case "Hatchback":
        return hatchback;
      case "Minivan":
        return minivan;
      case "Bike":
        return bike;
      case "Van":
        return van;
      case "SUV":
        return SUV;
      default:
        return;
    }
  }, []);

  const imageBackground = useCallback((title) => {
    switch (title) {
      case "Convertible":
        return "#fff5eb";
      case "Coupe":
        return "#fdeaea";
      case "Hatchback":
        return "#e5f8ed";
      case "Minivan":
        return "#efedee";
      case "Bike":
        return "#fff3e8";
      case "Van":
        return "#e0f9fd";
      case "SUV":
        return "#fdeaea";
      default:
        return;
    }
  }, []);

  return (
    <Grid container mt={1} alignItems="center">
      <Grid item lg={2} xl={2} sm={2}>
        <Box
          height="40px"
          width="40px"
          borderRadius="20%"
          sx={{
            backgroundImage: `url(${imageIcon(title)})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contained",
            backgroundPosition: "center",
            backgroundColor: imageBackground(title),
          }}
        />
      </Grid>
      <Grid item lg={8} xl={8} sm={8}>
        <Typography margin="auto">{title}</Typography>
      </Grid>
      <Grid item lg={2} xl={2} sm={2}>
        <Typography>{cars}</Typography>
      </Grid>
    </Grid>
  );
};
export default memo(ItemsAuctioned);

ItemsAuctioned.propTypes = {
  title: PropTypes.string.isRequired,
  cars: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ItemsAuctioned.defaultProps = {
  title: "N/A",
  cars: "N/A",
};
