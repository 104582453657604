import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import productPlaceholder from "assets/images/imagePlaceholder.png";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledGrid from "components/StyledGrid";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteAuctions, getAuctions } from "store/slices/auctionsSlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import { STATS_PRIMARY_ORANGE } from "styles/colors";
import statusBadges from "utils/helpers/statusBadge";
import useQueryParams from "utils/hooks/useQueryParams";
import WritingIcon from "../../assets/icons/WritingIcon.svg";
import deleteIcon from "../../assets/icons/deleteIcon.svg";
let localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export default function AuctionsDataGrid() {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const navigate = useNavigate();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const { results, loading, totalPages } = useSelector(
    (state) => state.auctions
  );

  const fetchData = useCallback(() => {
    dispatch(getAuctions(params));
  }, [dispatch, params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClickOpen = useCallback(
    (auctionId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(auctionId));
    },
    [dispatch]
  );

  const UpdateAuctionRow = useCallback(
    (auctionId) => {
      navigate(`/auctions/update/${auctionId}`);
    },
    [navigate]
  );

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 60,
        flex: 0.2,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        sortable: false,
      },
      {
        field: "image",
        headerName: "Image",
        headerAlign: "center",
        align: "center",
        minWidth: 120,
        flex: 0.25,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Box>
            <img
              src={
                params?.row?.auctionImage
                  ? params?.row?.auctionImage
                  : productPlaceholder
              }
              height={
                isTablet
                  ? "70px"
                  : isLaptop
                  ? "80px"
                  : isLed
                  ? "100px"
                  : "113px"
              }
              width={
                isTablet
                  ? "70px"
                  : isLaptop
                  ? "80px"
                  : isLed
                  ? "100px"
                  : "113px"
              }
              style={{
                borderRadius: "10px",
                marginTop: 6,
                objectFit: "contain",
              }}
              alt="auction_image"
            />
          </Box>
        ),
      },
      {
        field: "title",
        headerName: "Name/Details",
        minWidth: 300,
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => (
          <Box pr={2}>
            <Typography
              sx={{
                textTransform: "capitalize",
                color: "#000000",
                textOverflow: "ellipsis",
                overflow: "hidden",
                // Addition lines for 2 line or multiline ellipsis
                display: "-webkit-box !important",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                whiteSpace: "normal",
              }}
            >
              {params?.row?.title}
            </Typography>
            <Box display="flex">
              <Typography color={STATS_PRIMARY_ORANGE} mr={1}>
                Auction ID #
              </Typography>
              <Typography>{params?.row?.lotNumber ?? "N/A"}</Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "productCount",
        headerName: "Vehicles",
        minWidth: 150,
        flex: 0.5,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        sortable: true,
      },
      {
        field: "startDate",
        headerName: "Date",
        minWidth: 200,
        flex: 0.5,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        renderCell: (params) => (
          <Typography>
            {dayjs(params?.row?.startDate).format("DD/MM/YY [At] h:mm A")}
          </Typography>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        type: "actions",
        minWidth: 150,
        flex: 0.5,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Chip
            sx={{
              color: statusBadges(params?.row?.auctionStatus).color,
              bgcolor: statusBadges(params?.row?.auctionStatus).bgColor,
              borderRadius: "5px",
              height: "26px",
              textTransform: "capitalize",
            }}
            variant="contained"
            label={params?.row?.auctionStatus}
          />
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "right",
        align: "right",
        minWidth: 150,
        flex: 0.5,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <Typography variant="h6" pr={2}>
            Actions
          </Typography>
        ),
        renderCell: (params) => (
          <Box display="flex" sx={actionStyles}>
            <Tooltip title="Edit AUCTION" arrow placement="top">
              <Button
                variant="text"
                className="button-styles"
                onClick={() => UpdateAuctionRow(params.id)}
              >
                <img src={WritingIcon} alt="edit" className="icon-size" />
              </Button>
            </Tooltip>
            <Tooltip title="Delete Auction" arrow placement="top">
              <Button
                variant="text"
                sx={{
                  minWidth: "20px",
                }}
                onClick={() => handleClickOpen(params?.id)}
              >
                <img src={deleteIcon} alt="edit" className="icon-size" />
              </Button>
            </Tooltip>
            {/* <Tooltip title="Add Products" arrow placement="top">
              <Button
                variant="text"
                className="button-styles"
                sx={{
                  borderRadius: "50%",
                }}
                onClick={() => navigate(`add/${params.id}`)}
              >
                <img src={PlusIcon} alt="view" className="icon-size" />
              </Button>
            </Tooltip> */}
          </Box>
        ),
      },
    ],
    [isTablet, isLaptop, isLed, UpdateAuctionRow, handleClickOpen]
  );
  return (
    <>
      <StyledGrid
        rows={results}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteAuctions} />
    </>
  );
}
const actionStyles = {
  pr: 1,
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "22px", width: "22px" },
  "& .product-price": { fontSize: "1rem" },
};
