export default function statusBadges(status) {
  let color = "";
  let bgColor = "";
  switch (status) {
    case StatusOptions.ACTIVE:
      color = "#00b850";
      bgColor = "#ccffcc";
      break;
    case StatusOptions.SCHEDULED:
      color = "#FF9800";
      bgColor = "#ffe0b2";
      break;
    case StatusOptions.EXPIRED:
      color = "#9E9E9E";
      bgColor = "#e0e0e0";
      break;
    case StatusOptions.FINISHED:
      color = "#0000ff";
      bgColor = "#ccccff";
      break;
    case StatusOptions.CANCELED:
      color = "#ff0000";
      bgColor = "#ffcccc";
      break;
    default:
      break;
  }
  return { color, bgColor };
}

const StatusOptions = {
  ACTIVE: "active",
  SCHEDULED: "scheduled",
  EXPIRED: "expired",
  FINISHED: "finished",
  CANCELED: "canceled",
};
