import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetDeleteDialogState } from "store/slices/uiSlice";

function ConfirmDelete({ deleteAction }) {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.UI.deleteId);
  const auctionId = useSelector((state) => state.UI.auctionId);
  const isOpen = useSelector((state) => state.UI.isConfirmDeletionDialogOpen);

  const handleClose = useCallback(() => {
    dispatch(resetDeleteDialogState());
  }, [dispatch]);

  const handleDelete = useCallback(() => {
    auctionId
      ? dispatch(deleteAction({ id, auctionId }))
      : dispatch(deleteAction(id));
    dispatch(resetDeleteDialogState());
  }, [auctionId, dispatch, deleteAction, id]);

  return (
    <Dialog open={isOpen} keepMounted onClose={handleClose}>
      <DialogTitle>Are you sure you want to delete this?</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>No</Button>
        <Button onClick={handleDelete}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ConfirmDelete);

ConfirmDelete.propTypes = {
  deleteAction: PropTypes.func.isRequired,
};
