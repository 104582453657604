import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import {
  setSideBarClose,
  setSideBarOpen,
  setSideBarOpenedByUser,
} from "store/slices/uiSlice";
import Footer from "./Footer";
import Header from "./Header";
import SideBar from "./SideBar";

const Layout = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const isOpenedByUser = useSelector((state) => state.UI.isSidebarOpenedByUser);
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));

  const handleDrawerOpen = useCallback(() => {
    isLarge && !isOpen && !isOpenedByUser
      ? dispatch(setSideBarOpenedByUser())
      : dispatch(setSideBarOpen());
  }, [dispatch, isLarge, isOpen, isOpenedByUser]);

  const handleDrawerClose = useCallback(() => {
    dispatch(setSideBarClose());
  }, [dispatch]);

  useEffect(() => {
    isOpen && !isOpenedByUser && isLarge && dispatch(setSideBarClose());
    !isOpen && !isLarge && dispatch(setSideBarOpen());
  }, [isLarge, dispatch, isOpen, isOpenedByUser]);

  return (
    <Box
      m="auto"
      maxWidth="1920px"
      position="relative"
      display="flex"
      backgroundColor="#fbfbfb"
    >
      <Header handleDrawerOpen={handleDrawerOpen} />
      <SideBar
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: { xl: "87px", md: "87px", sm: "82.5px" },
          px: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflowX: "auto",
        }}
      >
        <Box>
          <Outlet />
        </Box>
        <Footer isOpen={isOpen} />
      </Box>
    </Box>
  );
};

export default Layout;
