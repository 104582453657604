import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadCategoryImage } from "services/Category";
import { getErrorMessage, getResponseData } from "utils/helpers/httpRequests";
import { toast } from "utils/hooks/useToast";
import { httpMain } from "utils/httpRequest/http";

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async (params, { rejectWithValue }) => {
    try {
      const response = await httpMain.get(`/categories`, {
        params,
      });
      const responseData = getResponseData(response);
      if (responseData?.results) {
        responseData?.results.forEach((item, index) => {
          const pageStart = (responseData?.page - 1) * responseData?.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getSubCategories = createAsyncThunk(
  "categories/getSubCategories",
  async (id = "", { rejectWithValue, getState }) => {
    const { page, limit } = getState().categories;
    try {
      const response = await httpMain.get(`/categories/${id}`, {
        params: {
          limit: 100,
        },
      });
      if (response?.data?.data?.results) {
        response?.data?.data?.results.forEach((item, index) => {
          const pageStart =
            (response.data?.data?.page - 1) * response?.data?.data?.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      if (id) {
        if (response?.data?.data?.subCategories) {
          response?.data?.data?.subCategories.forEach((item, index) => {
            const pageStart = (page - 1) * limit;
            item.serialNumber = pageStart + index + 1;
          });
        }
        return { results: response?.data?.data?.subCategories };
      } else {
        return response?.data?.data;
      }
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCategory = createAsyncThunk(
  "categories/getCategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await httpMain.get(`/categories/${id}`);
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const submitCategory = createAsyncThunk(
  "categories/submitCategory",
  async (values = {}, { rejectWithValue }) => {
    try {
      const data = {
        name: values.name,
        mainCategory: values?.mainCategoryId,
      };
      const response = await httpMain.post(`/categories`, data);
      if (!response?.data?.data?.id) {
        throw new Error("Something went wrong!");
      }
      await uploadCategoryImage(
        response?.data?.data?.id,
        values.mainImage,
        "mainImage"
      );
      // await uploadCategoryImage(
      //   response?.data?.data?.id,
      //   values.bannerImage,
      //   "bannerImage"
      // );
      toast.success("Category Created Successfully");
      return response?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.data?.message ??
          error?.message ??
          "Something went wrong, please try again later"
      );
      return rejectWithValue(error?.response?.data?.data);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "deleteCategory",
  async (categoryId, { getState, rejectWithValue }) => {
    const { results } = getState().categories;
    try {
      const response = await httpMain.delete(`/categories/${categoryId}`);
      if (response.status === 204) {
        const returnedData = results.filter((data) => data.id !== categoryId);
        return returnedData;
      }
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "updateCategory",
  async (values = {}, { getState, rejectWithValue }) => {
    const category = getState().categories.category;
    try {
      const { id, ...data } = values;
      let _data = {
        ...(data.name && { name: data.name }),
        ...(data.mainCategoryId && { mainCategory: data.mainCategoryId }),
        ...(category &&
          category?.type === "main" &&
          data?.specifications?.length && {
            specifications: data?.specifications,
          }),
      };
      const response = await httpMain.patch(`/categories/${id}`, _data);
      if (typeof values.mainImage === "object") {
        await uploadCategoryImage(values.id, values.mainImage, "mainImage");
      }
      // if (typeof values.bannerImage === "object") {
      //   await uploadCategoryImage(values.id, values.bannerImage, "bannerImage");
      // }
      toast.success("Category Updated successfully");
      return response?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ??
          error?.message ??
          "Something went wrong, please try again later"
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState = {
  loading: true,
  categoryLoading: false,
  category: null,
  results: [],
  queryParams: {},
  page: 1,
  limit: 20,
  totalPages: 0,
  totalResults: 0,
  error: "",
  categoryError: "",
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    // Actions
    resetState: () => initialState,
    resetCategory: (state) => {
      state.loading = false;
      state.category = null;
      state.categoryError = "";
    },
    addQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.results = action.payload.results;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSubCategories.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getSubCategories.fulfilled, (state, action) => {
        state.results = action.payload.results;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getSubCategories.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(deleteCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
      })
      .addCase(deleteCategory.rejected, (state) => {
        state.loading = false;
      })
      .addCase(submitCategory.pending, (state) => {
        state.categoryLoading = true;
      })
      .addCase(submitCategory.fulfilled, (state, action) => {
        state.categoryLoading = false;
        state.category = action.payload;
      })
      .addCase(submitCategory.rejected, (state, action) => {
        state.categoryLoading = false;
        state.categoryError = action.payload;
      })
      .addCase(updateCategory.pending, (state) => {
        state.categoryLoading = true;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.categoryLoading = false;
        state.category = action.payload;
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.categoryLoading = false;
        state.error = action.payload;
      })
      .addCase(getCategory.pending, (state) => {
        state.loading = true;
        state.categoryError = false;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.category = action.payload;
        state.loading = false;
      })
      .addCase(getCategory.rejected, (state) => {
        state.loading = false;
        state.categoryError = true;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState, addQueryParams, setCategory, resetCategory } =
  categoriesSlice.actions;

// Exporting default reducer
export default categoriesSlice.reducer;
