import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CurrencyFormatter from "components/CurrencyFormatter";
import PropTypes from "prop-types";
import { memo } from "react";

function PreviousBidTile({ name, bid }) {
  return (
    <Stack direction="column" flex={1}>
      <CurrencyFormatter
        number={bid}
        variant="body1"
        fontWeight="bold"
        color="text.secondary"
        fontSize={{ xs: "0.9rem", lg: "1.1rem" }}
      />
      <Typography variant="body1" color="primary.main" display="none">
        {name}
      </Typography>
    </Stack>
  );
}

PreviousBidTile.propTypes = {
  bid: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

PreviousBidTile.defaultProps = {
  bid: 0,
  name: "N/A",
};

export default memo(PreviousBidTile);
