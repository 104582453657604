import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CurrencyFormatter from "components/CurrencyFormatter";
import MUIStyledTextField from "components/MUIStyledComponents/MUIStyledTextField";
import PropTypes from "prop-types";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "store/slices/productsSlice";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import useDebounce from "utils/hooks/useDebounceValue";

function ProductDetailSection({ formik }) {
  const dispatch = useDispatch();
  const { loading, results } = useSelector((state) => state?.products);
  const [searchValue, setSearchValue] = useState("");
  const deferredValue = useDebounce(searchValue, 700);

  useEffect(() => {
    if (!formik?.values?.product && !deferredValue) {
      dispatch(getProducts({ status: "active" }));
    }
  }, [deferredValue, dispatch, formik?.values?.product]);

  useEffect(() => {
    if (deferredValue) {
      const data = {};
      data.status = "active";
      data.name = "productName";
      data.value = deferredValue;

      dispatch(getProducts(data));
    }
  }, [deferredValue, dispatch]);

  return (
    <StyledMuiCard
      title="Select Products"
      subTitle="You can select your products against the video category , please select products as a showcase for video"
    >
      <CardContent>
        <Box p={{ xs: 1, md: 1.5 }}>
          <Autocomplete
            options={results}
            value={formik?.values?.product ?? null}
            loading={loading}
            onChange={(_, option) => {
              formik.setFieldValue("product", option ?? "");
            }}
            inputValue={searchValue}
            onInputChange={(_, value) => {
              setSearchValue(value);
            }}
            onBlur={formik.handleBlur}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(option) => option?.productName ?? ""}
            renderInput={(params) => (
              <MUIStyledTextField
                fullWidth
                {...params}
                label="Product"
                name="product"
                error={Boolean(
                  formik.touched?.product && formik.errors?.product
                )}
                helperText={
                  formik.touched?.product && !!formik.errors?.product
                    ? formik.errors?.product
                    : "Please enter the product name"
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option?.id}>
                <Stack direction="row" gap={1} alignItems="center">
                  <img
                    src={option?.mainImage}
                    alt={option?.productName}
                    height={75}
                    width={75}
                    style={{ borderRadius: 6 }}
                  />
                  <Stack direction="column" gap={1}>
                    <Typography
                      variant="body1"
                      fontWeight={700}
                      color="text.secondary"
                    >
                      {option?.productName}
                    </Typography>
                    <Stack direction="row" gap={1}>
                      <Typography
                        variant="body2"
                        fontWeight={700}
                        color="text.secondary"
                      >
                        {`Bid Value: `}
                      </Typography>
                      <CurrencyFormatter
                        number={
                          option?.currentBid?.bidAmount ??
                          option?.reservePrice ??
                          0
                        }
                        variant="body2"
                        fontWeight={600}
                        color="text.secondary"
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </li>
            )}
          />
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
}

ProductDetailSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(ProductDetailSection, arePropsEqual);

function arePropsEqual(oldProps, newProps) {
  return (
    oldProps.formik.values.product === newProps.formik.values.product &&
    oldProps.formik.errors.product === newProps.formik.errors.product &&
    oldProps.formik.touched.product === newProps.formik.touched.product
  );
}
