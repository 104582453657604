const reduceDepthObject = (firstObj, secondObj) => {
  const updatedObject = Object.entries(firstObj).reduce(
    (index, [key, value]) => {
      if (typeof value === "object" && typeof secondObj[key] === "object") {
        const reducedObj = reduceDepthObject(value, secondObj[key]);
        if (Object.keys(reducedObj).length > 0) {
          index[key] = reducedObj;
        }
      } else if (JSON.stringify(value) !== JSON.stringify(secondObj[key])) {
        index[key] = firstObj[key];
      }
      return index;
    },
    {}
  );

  return updatedObject;
};

export default reduceDepthObject;
