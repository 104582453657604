import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MUIStyledTextField from "components/MUIStyledComponents/MUIStyledTextField";
import PropTypes from "prop-types";
import { memo } from "react";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import isValidNumber from "utils/helpers/isValidNumber";

function PriceSection({ formik }) {
  return (
    <StyledMuiCard
      title="Price"
      subTitle="Enter price and reserve price of the product"
    >
      <CardContent>
        <Stack direction="column" gap={2} px={2} py={1}>
          <Stack direction="column" gap={1}>
            <Typography variant="h4" color="text.secondary" fontWeight={600}>
              Price
            </Typography>
            <MUIStyledTextField
              fullWidth
              name="price"
              value={formik.values.price}
              onChange={(e) => {
                if (+e.target.value === 0) {
                  formik.setFieldValue("price", "");
                } else {
                  isValidNumber(e.target.value) &&
                    formik.setFieldValue("price", +e.target.value);
                }
              }}
              onBlur={formik.handleBlur}
              InputProps={{
                startAdornment: (
                  <Typography
                    color="text.disabled"
                    sx={{
                      fontSize: "1.25rem",
                      borderRight: "1px solid #00000038",
                      paddingRight: "1rem",
                    }}
                  >
                    AED
                  </Typography>
                ),
              }}
              error={Boolean(formik.touched.price && formik.errors.price)}
              helperText={
                formik.touched.price && !!formik.errors.price
                  ? formik.errors.price
                  : "Please enter the product price"
              }
              sx={{
                "& .MuiOutlinedInput-input": {
                  fontSize: "1.25rem",
                  textAlign: "right",
                  padding: "14px",
                },
              }}
            />
          </Stack>

          <Stack direction="column" gap={1}>
            <Typography variant="h4" color="text.secondary" fontWeight={600}>
              Reserve Price
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Enable this option if you want to put product on sales.
            </Typography>
            <MUIStyledTextField
              fullWidth
              name="reservePrice"
              value={formik.values.reservePrice}
              onChange={(e) => {
                isValidNumber(e.target.value) &&
                  formik.setFieldValue("reservePrice", +e.target.value);
              }}
              onBlur={formik.handleBlur}
              disabled={!formik.values?.id}
              InputProps={{
                startAdornment: (
                  <Typography
                    color="text.disabled"
                    sx={{
                      fontSize: "1.25rem",
                      borderRight: "1px solid #00000038",
                      paddingRight: "1rem",
                    }}
                  >
                    AED
                  </Typography>
                ),
              }}
              error={Boolean(
                formik.touched.reservePrice && formik.errors.reservePrice
              )}
              helperText={
                formik.touched.reservePrice && !!formik.errors.reservePrice
                  ? formik.errors.reservePrice
                  : "Reserve price must be 70% of the regular price"
              }
              sx={{
                "& .MuiOutlinedInput-input": {
                  fontSize: "1.25rem",
                  padding: "14px",
                  textAlign: "right",
                },
              }}
            />
          </Stack>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

PriceSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(PriceSection, arePropsEqual);

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.formik.values.price === nextProps.formik.values.price &&
    prevProps.formik.values.reservePrice ===
      nextProps.formik.values.reservePrice &&
    prevProps.formik.touched.price === nextProps.formik.touched.price &&
    prevProps.formik.errors.price === nextProps.formik.errors.price
  );
}
