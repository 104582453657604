import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Actions from "components/SubBanners/Actions";
import BannerForm from "components/SubBanners/BannerForm";
import DataGrid from "components/SubBanners/DataGrid";
import PropTypes from "prop-types";
import { memo } from "react";

const Banners = ({ create, update }) => {
  if (create || update) {
    return <BannerForm />;
  }

  return (
    <Box px={1} py={2}>
      <Typography
        variant="h4"
        fontWeight="600"
        mb={1}
        sx={{ fontSize: { md: "1.5rem", lg: "2rem" } }}
      >
        BANNERS
      </Typography>
      <Actions />
      <DataGrid />
    </Box>
  );
};

export default memo(Banners);

Banners.propTypes = {
  create: PropTypes.bool,
  update: PropTypes.bool,
};

Banners.defaultProps = {
  create: false,
  update: false,
};
