import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import DataGrid from "./DataGrid";
import Header from "./Header";

function PagesDataGrid() {
  return (
    <Box sx={{ flexGrow: 1, py: 3, px: 1 }}>
      <Typography
        variant="h4"
        fontWeight="600"
        mb={1}
        sx={{ fontSize: { md: "1.5rem", lg: "2rem" } }}
      >
        PAGES
      </Typography>
      <Header />
      <DataGrid />
    </Box>
  );
}

export default memo(PagesDataGrid);
