import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { memo } from "react";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();

  const handleCreate = () => {
    navigate("create");
  };

  return (
    <Box sx={containerStyles}>
      <Button
        sx={{
          textTransform: "capitalize",
        }}
        size="large"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleCreate}
      >
        Add New
      </Button>
    </Box>
  );
}

export default memo(Header);

const containerStyles = {
  display: "flex",
  py: 1,
};
