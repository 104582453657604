import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import DataGridSearch from "components/DataGridSearch";
import queryString from "query-string";
import { memo, useCallback, useLayoutEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { SERVICE_TYPE } from "utils/constants/services";

function ServiceTypeButtons() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const [value, setValue] = useState("all");

  const handleChange = useCallback(
    (_, value) => {
      setValue(value);
      // Necessary for performance
      // eslint-disable-next-line no-unused-vars
      const { serviceRole, ...rest } = query;
      let params = { ...rest };
      switch (value) {
        case SERVICE_TYPE.INDIVIDUAL:
          params.serviceRole = value;
          break;
        case SERVICE_TYPE.AGENT:
          params.serviceRole = value;
          break;
        default:
          break;
      }
      navigate({
        pathname: "/services",
        search: createSearchParams(params).toString(),
      });
    },
    [navigate, query]
  );

  useLayoutEffect(() => {
    query?.type && setValue(query.type);
  }, [query]);

  return (
    <Stack
      pt={1}
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      gap={3}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Product status filters"
        sx={{
          "& .MuiTabs-flexContainer": {
            flexWrap: "wrap",
          },
          "& .MuiTab-root": { textTransform: "capitalize", bgcolor: "white" },
        }}
      >
        <Tab label="All" value="all" />
        <Tab label="Individual" value={SERVICE_TYPE.INDIVIDUAL} />
        <Tab label="Agent" value={SERVICE_TYPE.AGENT} />
      </Tabs>
      <Box textAlign="right">
        <DataGridSearch
          searchKeyParam="name"
          searchKeyParamValue="title"
          searchQueryParam="value"
        />
      </Box>
    </Stack>
  );
}

export default memo(ServiceTypeButtons);
