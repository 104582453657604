import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CurrencyFormatter from "components/CurrencyFormatter";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledGrid from "components/StyledGrid";
import dayjs from "dayjs";
import { memo, useCallback, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteService, getServices } from "store/slices/servicesSlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import { AMOUNT_TYPE } from "utils/constants/services";
import useQueryParams from "utils/hooks/useQueryParams";
import WritingIcon from "../../assets/icons/WritingIcon.svg";
import deleteIcon from "../../assets/icons/deleteIcon.svg";
let localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

function ServiceDataGrid() {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.services.loading.services);
  const results = useSelector((state) => state.services.results);
  const totalPages = useSelector((state) => state.services.totalPages);

  const fetchData = useCallback(() => {
    dispatch(getServices(params));
  }, [dispatch, params]);

  useLayoutEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClickOpen = useCallback(
    (serviceId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(serviceId));
    },
    [dispatch]
  );

  const UpdateServiceRow = useCallback(
    (serviceId) => {
      navigate(`/services/edit/${serviceId}`);
    },
    [navigate]
  );

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 60,
        flex: 0.1,
        headerAlign: "center",
        align: "center",
        filterable: false,
      },
      {
        field: "title",
        headerName: "Name/Details",
        minWidth: 300,
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => <Typography>{params?.row?.title}</Typography>,
      },
      {
        field: "price",
        headerName: "Amount",
        minWidth: 225,
        flex: 0.5,
        renderCell: (params) =>
          params.row?.amountTypes === AMOUNT_TYPE.AMOUNT ? (
            <CurrencyFormatter number={params.row?.price ?? 0} />
          ) : params.row?.amountTypes === AMOUNT_TYPE.PERCENTAGE ? (
            <Typography>{`${params?.row?.price} %`}</Typography>
          ) : (
            <Typography>N/A</Typography>
          ),
      },
      {
        field: "serviceRole",
        headerName: "Package",
        minWidth: 175,
        flex: 0.25,
        editable: true,
        renderCell: (params) => (
          <Typography>{params?.row?.serviceRole ?? "N/A"}</Typography>
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "right",
        align: "right",
        minWidth: 150,
        flex: 0.25,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <Typography variant="h6" pr={2}>
            Actions
          </Typography>
        ),
        renderCell: (params) => (
          <Box display="flex" sx={actionStyles}>
            <Tooltip title="Edit service" arrow placement="top">
              <Button
                variant="text"
                className="button-styles"
                onClick={() => UpdateServiceRow(params.id)}
              >
                <img src={WritingIcon} alt="edit" className="icon-size" />
              </Button>
            </Tooltip>
            <Tooltip title="Delete service" arrow placement="top">
              <Button
                variant="text"
                sx={{
                  minWidth: "20px",
                }}
                onClick={() => handleClickOpen(params?.id)}
              >
                <img src={deleteIcon} alt="edit" className="icon-size" />
              </Button>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [UpdateServiceRow, handleClickOpen]
  );
  return (
    <>
      <StyledGrid
        rows={results}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteService} />
    </>
  );
}

export default memo(ServiceDataGrid);

const actionStyles = {
  pr: 1,
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "22px", width: "22px" },
  "& .service-price": { fontSize: "1rem" },
};
