import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { useSelector } from "react-redux";
import getInitialLetters from "utils/helpers/getInitialLetters";

function NotificationsContainer() {
  const notifications = useSelector(
    (state) => state?.notifications?.results ?? []
  );

  if (!notifications?.length) return null;

  return (
    <>
      {notifications.map((notification) => (
        <Box key={notification?.id}>
          <Box display="flex" gap={2} py={1}>
            <Avatar
              sx={{
                height: "3.5rem",
                width: "3.5rem",
                bgcolor: "primary.light",
              }}
            >
              {getInitialLetters(notification?.fullname ?? "") ?? "N/A"}
            </Avatar>
            <Box display="flex" flexDirection="column" gap={0.5} width="auto">
              <Typography fontSize="1.25rem" lineHeight={1.1}>
                {notification?.description}
              </Typography>
            </Box>
          </Box>
          <Divider />
        </Box>
      ))}
    </>
  );
}

export default memo(NotificationsContainer);
