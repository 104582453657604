import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import PropTypes from "prop-types";
import { memo } from "react";
import { CARD_HEADER_BACKGROUND } from "styles/colors";

const StyledMuiCard = ({ title, subTitle, children, action }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 1.5,
      }}
    >
      <CardHeader
        action={action}
        title={title}
        subheader={subTitle}
        sx={{
          pl: 2,
          bgcolor: CARD_HEADER_BACKGROUND,
          "& .MuiCardHeader-content": {
            pl: 2,
          },
          "& .MuiCardHeader-title": {
            textTransform: "capitalize",
          },
          "& .MuiCardHeader-subheader": {
            fontSize: "0.9rem",
          },
          ".MuiCardHeader-action": {
            margin: "auto",
          },
        }}
      />
      {children}
    </Card>
  );
};

StyledMuiCard.propTypes = {
  children: PropTypes.node.isRequired,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.element,
};

export default memo(StyledMuiCard);
