import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";
import numberFormatter from "utils/helpers/numberFormatter";

function NumberFormatter({ number, ...rest }) {
  const formattedNumber = useMemo(() => {
    return numberFormatter(number);
  }, [number]);
  return <Typography {...rest}>{formattedNumber}</Typography>;
}

export default memo(NumberFormatter);
NumberFormatter.propTypes = {
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

NumberFormatter.defaultProps = {
  number: 0,
};
