import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";
import formatCurrency from "utils/helpers/formatCurrency";

function CurrencyFormatter({ number, ...rest }) {
  const formattedNumber = useMemo(() => {
    return formatCurrency(number);
  }, [number]);
  return <Typography {...rest}>{formattedNumber}</Typography>;
}

export default memo(CurrencyFormatter);
CurrencyFormatter.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

CurrencyFormatter.defaultProps = {
  number: 0,
};
