import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

function VideoPlayerPlaceholder() {
  const loading = useSelector((state) => state.liveStreaming.loading.video);

  return (
    <Box
      height="75vh"
      width="100%"
      m="auto"
      borderRadius="10px"
      maxWidth="400px"
      border="1px solid rgba( 255, 255, 255, 0.18 )"
      boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.16)"
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor="white"
    >
      {!loading && (
        <Typography
          variant="h4"
          component="p"
          color="text.disabled"
          fontWeight="bold"
          mt={5}
        >
          Auction has not started yet
        </Typography>
      )}
    </Box>
  );
}

export default VideoPlayerPlaceholder;
