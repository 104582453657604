export const doNots = [
  "Not to sell Prohibited Items (Alcohol, pornography, adult material and toys, Drugs banned under the local laws and regulations).",
  "Not to sell Explosives, Fireworks of any nature, type, or size.",
  "Not to sell Weapons and Arms. Not to use this Platform for dating or any other prohibited purpose.",
  "Not to publish, communicate, market any Indecent Content.",
  "Not to perform any spamming activities.",
  "Not to use, misuse, or otherwise share any customer details obtained during the booking/ordering process.",
  "Not to share their personal details and information to the customer.",
  "Not to introduce fake discounts and high prices.",
  "Not to involve in any practice with the objective of defrauding the customers.",
  "Not to use the platform that it will transmit or introduce code, files, scripts, agents, or programs intended to harm BG or its Services, which includes but is not limited to viruses, worms, time bombs, Trojan horses.",
];
export const rules = [
  "BG strictly prohibits the BPs to share the information of their customer(s) with any third party",
  "BPs are encouraged to give full customer support and give a description that is detailed enough for customers to make a better decision.",
  "BPs shall encourage the customers to shift to the application to enhance user experience and thus profiting their business.",
  "All items lost in transit will be covered under insurance and will be paid within a maximum timeline of 30 days from the shipment date.",
];
export const responsibilities = [
  " Quality Assurance of the Product.",
  "Showcase, market, warrant, proper, and correct product description.",
  "Proper packaging of the shipment.",
  "Checking the product before shipment.",
  "Follow the handling period guidelines.",
  "Maintain up to date stock levels.",
  "Compliance with the T&amp;C of the Platform.",
  " Replying and responding to Questions, comments, queries within the defined time frame.",
  "Uploading right and proper Picture of products.",
];
