import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Auction from "assets/icons/upcoming_Auction.svg";
import { memo } from "react";
import { useSelector } from "react-redux";
import {
  BUTTON_HOVER,
  BUTTON_HOVER_BORDER_COLOR,
  FILTERS_BORDER_COLOR,
} from "styles/colors";
import CardTitle from "../CardTitle";
import AuctionDetails from "./auctionDetails";

const UpcomingAutions = () => {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  return (
    <Card
      sx={{ p: "1.2rem", boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" }}
    >
      <Box sx={containerStyles}>
        <Box display="contents" alignItems="baseline">
          <CardTitle title="Upcoming Auctions" color="#707070" />
          <Link href="#">View all</Link>
        </Box>
      </Box>
      <Box textAlign="center">
        <img
          src={Auction}
          alt="auction"
          height={isOpen ? 225 : 200}
          width="auto"
        />
      </Box>
      <AuctionDetails
        day={"FRI"}
        date={24}
        title={"Cars Auctions"}
        description={"Auction will be live on Thursday 02:00 PM"}
      />
      <AuctionDetails
        day={"SAT"}
        date={25}
        title={"Number Plate Auction"}
        description={"Auction will be live on Friday 02:00 PM"}
      />
    </Card>
  );
};
export default memo(UpcomingAutions);
const containerStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  "& .MuiOutlinedInput-root": {
    height: "44px",
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
    },
    "&:hover": {
      backgroundColor: BUTTON_HOVER,
      "& fieldset": {
        borderColor: BUTTON_HOVER_BORDER_COLOR,
      },
    },
  },
};
