import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { memo } from "react";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import ContentEditor from "./ContentEditor";

function ContentSection({ formik }) {
  return (
    <StyledMuiCard
      title="Content"
      subTitle="Enter blog content. Tip: Use bullet points for easy viewing"
    >
      <CardContent>
        <Stack direction="column" justifyContent="center" gap={2}>
          {/* Blog Content */}
          <Typography
            variant="h3"
            fontWeight="bold"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Blog Content
          </Typography>
          <Box>
            <Card>
              <ContentEditor formik={formik} />
            </Card>
            {formik.errors.content && (
              <Typography color="error.light" pt={1} fontSize="0.75rem">
                {formik.errors.content}
              </Typography>
            )}
          </Box>

          {/* Meta Description */}
          <Typography
            variant="h3"
            fontWeight="bold"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Meta Description
          </Typography>
          <TextField
            multiline
            minRows={5}
            placeholder="Write meta description here"
            {...formik.getFieldProps("metaDescription")}
            error={Boolean(
              formik.touched.metaDescription && formik.errors.metaDescription
            )}
            helperText={
              formik.touched.metaDescription &&
              !!formik.errors.metaDescription &&
              formik.errors.metaDescription
            }
            sx={{
              "& .MuiFormHelperText-root": {
                ml: 0,
              },
            }}
          />
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

ContentSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(ContentSection);
