import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import AuthPageEllipse from "assets/icons/AuthPageEllipse.svg";
import AuthSideContent from "components/authentication/AuthSideContent";
import ResetPasswordForm from "components/authentication/ResetPasswordForm";

function ResetPassword() {
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  return (
    <Grid container>
      <Grid item xs={6} position="relative" backgroundColor="#ed4928">
        <Box
          fontWeight="bold"
          fontSize="1.625rem"
          color="white"
          sx={{
            position: "absolute",
            top: isMedium ? "1rem" : "2rem",
            left: isMedium ? "1rem" : "2rem",
          }}
        >
          YallaBid
        </Box>
        <AuthSideContent />
      </Grid>
      <Grid item xs={6} position="relative" overflow="hidden">
        <img
          src={AuthPageEllipse}
          alt="Ellipse"
          height={isMedium ? 175 : isLarge ? 225 : 300}
          width={isMedium ? 175 : isLarge ? 225 : 300}
          style={{
            position: "absolute",
            top: isMedium ? -100 : isLarge ? -115 : -150,
            right: isMedium ? -100 : isLarge ? -115 : -150,
          }}
        />
        <ResetPasswordForm />
      </Grid>
    </Grid>
  );
}

export default ResetPassword;
