import Autocomplete from "@mui/material/Autocomplete";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MUIStyledTextField from "components/MUIStyledComponents/MUIStyledTextField";
import MobileDateTime from "components/Pickers/MobileDateTime";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { memo, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuctions } from "store/slices/filtersSlice";
import { PRODUCT_FORM_STATUSES } from "utils/constants/product";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import useDebounce from "utils/hooks/useDebounceValue";

function AuctionInfo({ formik }) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.filters?.loading?.auctions);
  const auctions = useSelector((state) => state.filters?.auctions);
  const product = useSelector((state) => state.products.product);
  const [searchValue, setSearchValue] = useState("");
  const [option, setOption] = useState(null);
  const deferredValue = useDebounce(searchValue, 700);

  useEffect(() => {
    const data = {};
    if (deferredValue) {
      data.name = "title";
      data.value = deferredValue;
    }
    const id = setTimeout(() => {
      formik.values.status === "active" && dispatch(getAuctions(data));
    }, 500);
    return () => clearTimeout(id);
  }, [deferredValue, dispatch, formik.values.status]);

  useEffect(() => {
    if (auctions.length && formik.values.auctionId) {
      const auction = auctions.find(
        (auction) => auction.id === formik.values.auctionId
      );
      setOption(auction);
    }
  }, [formik.values.auctionId, auctions]);

  useLayoutEffect(() => {
    if (!auctions.length && loading) {
      dispatch(getAuctions());
    }
  }, [dispatch, product, auctions, loading]);

  return (
    <StyledMuiCard
      title="Auction Info"
      subTitle="Select lot no and auction of the product"
    >
      <CardContent>
        <Stack direction="column" gap={2} px={2}>
          <Stack direction="column" gap={1}>
            <Typography variant="h4" color="text.secondary" fontWeight={600}>
              Lot No
            </Typography>
            <MUIStyledTextField
              fullWidth
              label="Lot No"
              {...formik.getFieldProps("lotNumber")}
              error={Boolean(
                formik.touched.lotNumber && formik.errors.lotNumber
              )}
              helperText={
                formik.touched.lotNumber && !!formik.errors.lotNumber
                  ? formik.errors.lotNumber
                  : "Please select deal type of the vehicle"
              }
            />
          </Stack>

          <Stack direction="column" gap={1}>
            <Typography variant="h4" color="text.secondary" fontWeight={600}>
              Auction
            </Typography>
            <Autocomplete
              options={auctions}
              name="auctionId"
              value={option ?? null}
              loading={loading}
              onChange={(_, option) => {
                formik.setFieldValue("auctionId", option?.id);
              }}
              inputValue={searchValue}
              onInputChange={(_, value) => {
                setSearchValue(value);
              }}
              onBlur={formik.handleBlur}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option.title}
              disabled={formik.values.status !== "active"}
              renderInput={(params) => (
                <MUIStyledTextField
                  fullWidth
                  {...params}
                  label="Auction"
                  error={Boolean(
                    formik.touched.auctionId && formik.errors.auctionId
                  )}
                  helperText={
                    formik.touched.auctionId && !!formik.errors.auctionId
                      ? formik.errors.auctionId
                      : "Please select the auction"
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.title}
                </li>
              )}
            />
          </Stack>
          <Stack direction="column" gap={1}>
            <Typography variant="h4" color="text.secondary" fontWeight={600}>
              Bid End Date & Time
            </Typography>
            <MobileDateTime
              fullWidth
              name="bidEnd"
              value={dayjs(formik.values.bidEnd)}
              error={Boolean(formik.touched.bidEnd && formik.errors.bidEnd)}
              onBlur={formik.handleBlur}
              disabled={
                formik.values?.draftStatus !==
                  PRODUCT_FORM_STATUSES.COMPLETED ||
                Boolean(formik.values?.auctionId)
              }
              onChange={(value) =>
                formik.setFieldValue("bidEnd", value.toString())
              }
              helperText={
                formik.touched.bidEnd && !!formik.errors.bidEnd
                  ? formik.errors.bidEnd
                  : "Please select bid end date & time"
              }
            />
          </Stack>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

AuctionInfo.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(AuctionInfo, arePropsEqual);

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.formik.values.lotNumber === nextProps.formik.values.lotNumber &&
    prevProps.formik.values.auctionId === nextProps.formik.values.auctionId &&
    prevProps.formik.values.draftStatus ===
      nextProps.formik.values.draftStatus &&
    prevProps.formik.values.status === nextProps.formik.values.status &&
    prevProps.formik.touched.lotNumber === nextProps.formik.touched.lotNumber &&
    prevProps.formik.touched.auctionId === nextProps.formik.touched.auctionId &&
    prevProps.formik.errors.lotNumber === nextProps.formik.errors.lotNumber &&
    prevProps.formik.errors.auctionId === nextProps.formik.errors.auctionId &&
    prevProps.formik.errors.bidEnd === nextProps.formik.errors.bidEnd &&
    prevProps.formik.values.bidEnd === nextProps.formik.values.bidEnd
  );
}
