import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import auctionStatuses from "utils/constants/auctionStatuses";
import ProductCard from "./ProductCard";
import ProductDetailsCard from "./ProductDetailsCard";

function PushProductDialog({
  isOpen,
  handleCloseDialog,
  selectedProduct,
  pushProduct,
}) {
  const auction = useSelector((state) => state.liveStreaming.auction);
  const product = useSelector((state) => state.liveStreaming.product);
  const [isDetailsView, setIsDetailsView] = useState(false);

  const liveHandler = useCallback(() => {
    pushProduct(selectedProduct?.id ?? selectedProduct?._id);
  }, [pushProduct, selectedProduct]);

  useEffect(() => {
    setIsDetailsView(false);
  }, [selectedProduct]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      maxWidth="md"
      sx={{
        ".MuiDialog-paper": {
          minWidth: "625px",
        },
      }}
      aria-labelledby="extend-time-dialog"
    >
      {/* Dialog Header */}
      <Stack direction="column">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          py={1.5}
          bgcolor="primary.main"
        >
          <Box>
            <Typography variant="h3" color="white" fontWeight="bold">
              Push Product
            </Typography>
            <Typography variant="body2" color="white">
              You can extend time of the product in auction
            </Typography>
          </Box>
          <IconButton
            size="small"
            onClick={handleCloseDialog}
            sx={{
              padding: "0 !important",
              margin: "0 !important",
            }}
          >
            <CancelIcon sx={{ color: "white", fontSize: "3rem" }} />
          </IconButton>
        </Stack>
      </Stack>

      {/* Product Card */}
      <Box p={2}>
        {isDetailsView ? (
          <ProductDetailsCard product={selectedProduct} />
        ) : (
          <ProductCard product={selectedProduct} isDialogCard />
        )}
      </Box>

      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        pr={2}
        pb={3}
        width="100%"
      >
        <Button
          variant="outlined"
          color="primary"
          sx={{
            height: { lg: "55px" },
            width: { lg: "180px" },
          }}
          onClick={() => setIsDetailsView(!isDetailsView)}
        >
          {isDetailsView ? "Hide" : "View"} Details
        </Button>
        <Button
          disableElevation
          color="primary"
          variant="contained"
          onClick={liveHandler}
          sx={{
            height: { lg: "55px" },
            width: { lg: "180px" },
          }}
          disabled={
            Boolean(product && dayjs().isBefore(dayjs(product?.auctionEnd))) ||
            auction?.auctionStatus !== auctionStatuses?.ACTIVE
          }
        >
          Set Live
        </Button>
      </Stack>
    </Dialog>
  );
}

PushProductDialog.propTypes = {
  handleCloseDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  pushProduct: PropTypes.func,
  selectedProduct: PropTypes.object,
};

export default PushProductDialog;
