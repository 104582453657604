import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { memo } from "react";
import { useNavigate } from "react-router-dom";

function UploadVideo() {
  const navigate = useNavigate();

  return (
    <Button
      size="large"
      variant="contained"
      startIcon={<AddIcon />}
      onClick={() => navigate("upload")}
    >
      Upload Video
    </Button>
  );
}

export default memo(UploadVideo);
