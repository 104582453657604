import RemoveCircle from "@mui/icons-material/RemoveCircle";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddImagePlaceholder from "assets/icons/addImagePlaceholder.svg";
import PropTypes from "prop-types";
import { memo, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { removeBlogBannerImage, removeBlogImage } from "services/blogServices";
import {
  setImageValidationError,
  setIsImageValidationDialogOpen,
} from "store/slices/uiSlice";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import validateImageAspectRatio from "utils/helpers/validateImageAspectRatio";
import { toast } from "utils/hooks/useToast";

function MediaSection({ id, formik }) {
  const dispatch = useDispatch();
  const imageInputRef = useRef(null);
  const bannerImageInputRef = useRef(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));

  const handleImageChange = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        // function params
        // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
        validateImageAspectRatio(image, 500, 500, false, true, 1 / 1, "1 / 1")
          .then((img) => {
            formik.setFieldValue("image", img);
            bannerImageInputRef.current.value = "";
          })
          .catch((error) => {
            bannerImageInputRef.current.value = "";
            dispatch(setIsImageValidationDialogOpen(true));
            dispatch(setImageValidationError(error.message));
          });
      }
    },
    [dispatch, formik]
  );

  const handleBannerImageChange = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        // function params
        // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
        validateImageAspectRatio(image, 1200, 400, true, true, 3 / 1, "3 / 1")
          .then((img) => {
            formik.setFieldValue("bannerImage", img);
            bannerImageInputRef.current.value = "";
          })
          .catch((error) => {
            bannerImageInputRef.current.value = "";
            dispatch(setIsImageValidationDialogOpen(true));
            dispatch(setImageValidationError(error.message));
          });
      }
    },
    [dispatch, formik]
  );

  const handleRemoveImage = useCallback(() => {
    if (typeof formik.values.image === "object") {
      formik.setFieldValue("image", "");
      imageInputRef.current.value = "";
    } else if (typeof formik.values.image === "string") {
      removeBlogImage(id, formik.values.image).then(() => {
        formik.setFieldValue("image", "");
        imageInputRef.current.value = "";
        toast.success("Image removed successfully");
      });
    }
  }, [formik, id]);

  const handleRemoveBannerImage = useCallback(() => {
    if (typeof formik.values.bannerImage === "object") {
      formik.setFieldValue("bannerImage", "");
      bannerImageInputRef.current.value = "";
    } else if (typeof formik.values.bannerImage === "string") {
      removeBlogBannerImage(id, formik.values.bannerImage).then(() => {
        formik.setFieldValue("bannerImage", "");
        bannerImageInputRef.current.value = "";
        toast.success("Banner image removed successfully");
      });
    }
  }, [formik, id]);

  return (
    <StyledMuiCard
      title="Media Section"
      subTitle="Upload relevant images for the blog"
    >
      <CardContent>
        <Stack direction="column" gap={2}>
          {/* Main Image */}
          <Typography
            variant="h3"
            fontWeight="bold"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Image
          </Typography>
          <Box>
            <Stack direction="row" alignItems="flex-start">
              <img
                src={
                  typeof formik.values.image === "object"
                    ? URL.createObjectURL(formik.values.image)
                    : formik.values.image &&
                      typeof formik.values.image === "string"
                    ? formik.values.image
                    : AddImagePlaceholder
                }
                alt="Blog Media"
                height={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                width="auto"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  !formik.values.image && imageInputRef.current.click()
                }
              />
              {formik.values.image && (
                <IconButton onClick={() => handleRemoveImage()}>
                  <RemoveCircle />
                </IconButton>
              )}
            </Stack>
            <input
              type="file"
              name="mainImage"
              ref={imageInputRef}
              style={{ display: "none" }}
              id="main-image"
              hidden
              accept="image/x-png,image/png,image/jpeg,image/jpg"
              onChange={handleImageChange}
            />
            <Typography
              variant="subtitle1"
              color="error"
              mt={0.5}
              fontSize="0.75rem"
            >
              {formik.errors.image}
            </Typography>
          </Box>

          {/* Banner Image */}
          <Typography
            variant="h3"
            fontWeight="bold"
            fontSize="1.25rem"
            color="text.secondary"
            mt={2}
          >
            Banner Image
          </Typography>
          <Box>
            <Stack direction="row" alignItems="flex-start">
              <img
                src={
                  formik.values.bannerImage &&
                  typeof formik.values.bannerImage === "object"
                    ? URL.createObjectURL(formik.values.bannerImage)
                    : formik.values.bannerImage &&
                      typeof formik.values.bannerImage === "string"
                    ? formik.values.bannerImage
                    : AddImagePlaceholder
                }
                alt="Blog Banner"
                height={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                width="auto"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  !formik.values.bannerImage &&
                  bannerImageInputRef.current.click()
                }
              />
              {formik.values.bannerImage && (
                <IconButton onClick={() => handleRemoveBannerImage()}>
                  <RemoveCircle />
                </IconButton>
              )}
            </Stack>
            <input
              type="file"
              name="bannerImage"
              ref={bannerImageInputRef}
              style={{ display: "none" }}
              id="banner-image"
              hidden
              accept="image/x-png,image/png,image/jpeg,image/jpg"
              onChange={handleBannerImageChange}
            />
            <Typography
              variant="subtitle1"
              color="error"
              mt={0.5}
              fontSize="0.75rem"
            >
              {formik.errors.bannerImage}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

MediaSection.propTypes = {
  id: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
};

export default memo(MediaSection);
