import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getBlog,
  resetBlogState,
  resetBlogsState,
  submitBlog,
  updateBlog,
} from "store/slices/blogsSlice";
import parseEditorValue from "utils/helpers/parseEditorValue";
import reduceDepthObject from "utils/helpers/reduceDepthObjects";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";
import BasicInformationSection from "./BasicInformationSection";
import ContentSection from "./ContentSection";
import MediaSection from "./MediaSection";

function BlogsForm() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const blog = useSelector((state) => state.blogs.selectedBlog ?? {});
  const [loading, setLoading] = useState(false);

  const initialValues = useMemo(
    () => ({
      id: blog?.id ?? "",
      title: blog?.title ?? "",
      slug: blog?.slug ?? "",
      metaTitle: blog?.metaTitle ?? "",
      content: blog?.content ? parseEditorValue(blog?.content) : "",
      metaDescription: blog?.metaDescription ?? "",
      image: blog?.image ?? "",
      bannerImage: blog?.bannerImage ?? "",
    }),
    [blog]
  );

  const handleSubmit = useCallback(
    (values) => {
      setLoading(true);
      const data = reduceDepthObject(values, initialValues);
      if (params?.id) {
        if (Object.keys(data).length === 0) {
          toast.info("No changes were made");
          return navigate("/blogs");
        }
        dispatch(
          updateBlog({
            id: params?.id,
            data,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(resetBlogsState());
            navigate("/blogs");
          });
      } else
        dispatch(submitBlog(data))
          .unwrap()
          .then(() => {
            dispatch(resetBlogsState());
            navigate("/blogs");
          });
    },
    [initialValues, dispatch, navigate, params?.id]
  );

  useEffect(() => {
    if (params?.id) {
      const id = setTimeout(() => {
        dispatch(getBlog(params?.id))
          .unwrap()
          .then(() => setLoading(false));
      }, 500);
      return () => {
        id && clearTimeout(id);
        dispatch(resetBlogState());
      };
    }
  }, [dispatch, params?.id]);

  return (
    <Box px={1}>
      <Typography
        variant="h1"
        fontWeight="600"
        color="primary.main"
        fontSize={{ lg: "2.6rem", sm: "2rem" }}
        my={2}
      >
        {params?.id ? "Update Blog" : "Add Blog"}
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            {/* Basic Information */}
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <BasicInformationSection formik={formik} />
              </Grid>

              {/* Content Section */}
              <Grid item xs={12} lg={12}>
                <ContentSection formik={formik} />
              </Grid>

              {/*Auction Media Section */}
              <Grid item xs={12} lg={12}>
                <MediaSection id={blog?.id ?? ""} formik={formik} />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" gap={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={formik.handleSubmit}
                    disableElevation
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    disabled={formik.isSubmitting || loading}
                    onClick={() => formik.resetForm()}
                    variant="outlined"
                    color="primary"
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, lg: 175, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    Reset
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(BlogsForm);

const validationSchema = Yup.object().shape({
  id: Yup.string(),
  title: Yup.string()
    .trim()
    .required("Required*")
    .min(4, "Title should be at least 4 characters long")
    .max(100, "Title is too long"),
  metaTitle: Yup.string()
    .trim()
    .required("Required*")
    .min(4, "Meta title should be at least 4 characters long")
    .max(100, "Meta title is too long"),
  slug: Yup.string().when("id", {
    is: (id) => id,
    then: () =>
      Yup.string()
        .trim()
        .required("Required*")
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9-]*$/, "Invalid slug")
        .min(4, "Slug should be at least 4 characters long")
        .max(100, "Slug is too long"),
    otherwise: () => Yup.string().trim().required("Required*"),
  }),
  content: Yup.string()
    .trim()
    .required("Required*")
    .min(25, "Content is too short"),
  metaDescription: Yup.string()
    .trim()
    .required("Required*")
    .min(20, "Meta description must be at least 20 characters long")
    .max(250, "Meta description is too long"),
  image: Yup.mixed().required("Required*"),
  bannerImage: Yup.mixed().required("Required*"),
});
