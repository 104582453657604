import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import grey from "@mui/material/colors/grey";
import PropTypes from "prop-types";
import { memo } from "react";

function ProductDescriptionTiles({ name, value }) {
  return (
    <Stack direction="row" width="100%">
      <Box width={{ xs: "75%", md: "60%", lg: "65%" }}>
        <Typography variant="body1" color={grey[400]} fontWeight={500}>
          {name ?? "N/A"}
        </Typography>
      </Box>
      <Box width={{ xs: "25%", md: "40%", lg: "35%" }}>
        <Typography variant="body1" color={grey[600]} fontWeight={500}>
          {value ?? "N/A"}
        </Typography>
      </Box>
    </Stack>
  );
}

ProductDescriptionTiles.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default memo(ProductDescriptionTiles);
