import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import AddSpecification from "./AddSpecification";
import Specification from "./Specification";

function SpecificationsSection({ formik }) {
  return (
    <StyledMuiCard
      title="Product Specifications"
      subTitle="Please add the product specifications accordingly"
    >
      <CardContent>
        <Stack direction="column" gap={2}>
          {formik.values?.specifications.map((specification, index) => (
            <Specification
              key={index}
              formik={formik}
              index={index}
              specification={specification}
            />
          ))}
          <AddSpecification formik={formik} />
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

SpecificationsSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default SpecificationsSection;
