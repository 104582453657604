import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsExtendTimerDialogOpen } from "store/slices/uiSlice";
import { PRIMARY_COLOR } from "styles/colors";
import isValidNumber from "utils/helpers/isValidNumber";

function ExtendTimeDialog({ socket }) {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.UI.isExtendTimerDialogOpen);
  const product = useSelector((state) => state.liveStreaming.product);
  const [selectedTime, setSelectedTime] = useState("");
  const [valueError, setValueError] = useState("");

  const handleDialogState = useCallback(
    (value) => dispatch(setIsExtendTimerDialogOpen(value)),
    [dispatch]
  );

  const handleTimeChange = useCallback((value) => {
    if (value === 0) {
      return setSelectedTime("");
    }
    setSelectedTime(value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (+selectedTime > 180 || +selectedTime < 1) {
      setValueError("Time value should be between 1 and 180 seconds.");
      return;
    } else {
      setValueError("");
      socket.emit("addTime", {
        productId: product?.id ?? product?._id ?? "",
        time: +selectedTime,
      });
    }
  }, [product?._id, product?.id, selectedTime, socket]);

  useEffect(() => {
    return () => {
      setSelectedTime("");
      setValueError("");
    };
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleDialogState(false)}
      maxWidth="md"
      sx={{
        ".MuiDialog-paper": {
          minWidth: { xs: "400px", lg: "625px" },
        },
      }}
      aria-labelledby="extend-time-dialog"
    >
      {/* Dialog Header */}
      <Stack direction="column">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          py={1}
          bgcolor="primary.main"
        >
          <Box>
            <Typography variant="h3" color="white" fontWeight="bold">
              Extending Time
            </Typography>
            <Typography variant="body2" color="white">
              You can extend time of the product in auction
            </Typography>
          </Box>
          <IconButton size="small" onClick={() => handleDialogState(false)}>
            <CancelIcon sx={{ color: "white", fontSize: "3rem" }} />
          </IconButton>
        </Stack>
      </Stack>

      {/* Timer Circles */}
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        py={{ xs: 2, lg: 4 }}
      >
        {[...new Array(3)].map((value, index) => (
          <Box
            key={index}
            height="140px"
            width="140px"
            padding="4px"
            border={`4px solid #e9ecef`}
            borderRadius="50%"
          >
            <Box
              height="100%"
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              border={`4px solid ${PRIMARY_COLOR}`}
              bgcolor={selectedTime === (index + 1) * 30 ? PRIMARY_COLOR : ""}
              onClick={() => handleTimeChange((index + 1) * 30)}
            >
              <Typography
                variant="h3"
                color={
                  selectedTime === (index + 1) * 30 ? "white" : "text.secondary"
                }
              >
                {`${(index + 1) * 30} Sec`}
              </Typography>
            </Box>
          </Box>
        ))}
      </Stack>
      <Stack direction="row" spacing={2} p={2} width="100%">
        <TextField
          fullWidth
          label="Enter Time"
          variant="outlined"
          value={selectedTime}
          onChange={(e) =>
            isValidNumber(+e.target.value) && handleTimeChange(+e.target.value)
          }
          error={valueError !== ""}
          helperText={
            valueError ||
            "You can extend time up to 180 seconds. Time value should be in seconds."
          }
          sx={{
            "& .MuiFormHelperText-root": {
              marginLeft: 0,
              textAlign: "justify",
            },
          }}
        />
        <Button
          disableElevation
          color="primary"
          variant="contained"
          size="large"
          sx={{
            width: "100%",
            maxWidth: "180px",
            height: "55px",
          }}
          disabled={Boolean(!selectedTime || valueError)}
          onClick={handleSubmit}
        >
          Extend Time
        </Button>
      </Stack>
    </Dialog>
  );
}

ExtendTimeDialog.propTypes = {
  socket: PropTypes.object,
};

export default ExtendTimeDialog;
