import Box from "@mui/material/Box";
import { memo, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import {
  BUTTON_HOVER,
  BUTTON_HOVER_BORDER_COLOR,
  DASHBOARD_CHART_ROW_COLOR,
  FILTERS_BORDER_COLOR,
} from "styles/colors";
import CardTitle from "../CardTitle";

function LineChart() {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const [render, setRender] = useState(false);
  let format = "day";

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const ordersData = [
    {
      x: {
        year: 2022,
        month: 12,
        date: 10,
      },
      y: 8,
    },
    {
      x: {
        year: 2023,
        month: 1,
        date: 11,
      },
      y: 10,
    },
    {
      x: {
        year: 2023,
        month: 2,
        date: 13,
      },
      y: 11,
    },
    {
      x: {
        year: 2023,
        month: 3,
        date: 2,
      },
      y: 9,
    },
    {
      x: {
        year: 2023,
        month: 4,
        date: 2,
      },
      y: 8,
    },
    {
      x: {
        year: 2023,
        month: 5,
        date: 2,
      },
      y: 7,
    },
    {
      x: {
        year: 2023,
        month: 6,
        date: 2,
      },
      y: 5,
    },
    {
      x: {
        year: 2023,
        month: 7,
        date: 2,
      },
      y: 7,
    },
    {
      x: {
        year: 2023,
        month: 8,
        date: 2,
      },
      y: 6,
    },
    {
      x: {
        year: 2023,
        month: 9,
        date: 2,
      },
      y: 8,
    },
    {
      x: {
        year: 2023,
        month: 10,
        date: 2,
      },
      y: 9,
    },
    {
      x: {
        year: 2023,
        month: 11,
        date: 2,
      },
      y: 11,
    },
    {
      x: {
        year: 2023,
        month: 12,
        date: 2,
      },
      y: 13,
    },
  ];

  const orders = ordersData.map((val) => val.y);
  const dailyOrders = ordersData.map((item) => {
    const { year, month, date } = item.x;
    const dateObj = new Date(year, month, date); // month is zero-indexed
    const options = { month: "short", day: "numeric" };
    return dateObj.toLocaleString("en-US", options);
  });

  const monthlyOrders = ordersData.map((val) => val.x.month);
  const monthNames = monthlyOrders.map((index) => months[index]);

  const series = [
    {
      name: "Orders",
      data: orders,
    },
  ];

  const options = {
    chart: {
      type: "line",
      stacked: true,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#ed4928"],
    markers: {
      size: 5,
      colors: "#fff",
      strokeColors: "#ed4928",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    grid: {
      row: {
        colors: [DASHBOARD_CHART_ROW_COLOR, "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: format === "day" ? dailyOrders : monthNames,
    },
  };

  useEffect(() => {
    if (isOpen === !render) {
      const timeOut = setTimeout(() => {
        setRender(isOpen);
      }, 500);
      return () => clearTimeout(timeOut);
    }
  }, [isOpen, render]);

  return (
    <Box
      id="chart"
      p="1rem"
      sx={{
        background: "#fff",
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
      }}
    >
      <Box sx={containerStyles}>
        <Box>
          <CardTitle title="Auction Participated" color="#707070" />
        </Box>
      </Box>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={isOpen ? 328 : 301}
        sx={{ color: "black" }}
      />
    </Box>
  );
}

export default memo(LineChart);

const containerStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  "& .MuiOutlinedInput-root": {
    height: "44px",
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
    },
    "&:hover": {
      backgroundColor: BUTTON_HOVER,
      "& fieldset": {
        borderColor: BUTTON_HOVER_BORDER_COLOR,
      },
    },
  },
};
