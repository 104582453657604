import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CategoryMediaSection from "components/Category/CategoryForm/CategoryMediaSection";
import { Form, Formik } from "formik";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  resetCategory,
  resetState,
  submitCategory,
  updateCategory,
} from "store/slices/categoriesSlice";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import reduceObject from "utils/helpers/reduceObject";
import StyledTextField from "utils/helpers/styledTextField";
import * as Yup from "yup";
import SpecificationsSection from "./SpecificationsSection";

function CategoryForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const category = useSelector((state) => state.categories.category);
  const loading = useSelector((state) => state.categories.categoryLoading);

  useEffect(() => {
    if (!params.id) {
      dispatch(resetCategory());
    }
  }, [dispatch, params.id]);

  const initialValues = useMemo(
    () => ({
      name: category?.name ?? "",
      mainImage: category?.mainImage ?? "",
      // bannerImage: category?.bannerImage ?? "",
      specifications: category?.specifications?.length
        ? category?.specifications?.map((specification) => {
            // eslint-disable-next-line no-unused-vars
            const { _id = "", id = "", ...rest } = specification;
            return rest;
          })
        : [],
      id: category?.id ?? "",
    }),
    [category]
  );

  const handleSubmit = useCallback(
    (values) => {
      if (params?.id) {
        let updatedValues = reduceObject(values, initialValues);
        dispatch(updateCategory({ ...updatedValues, id: params.id }))
          .unwrap()
          .then(() => {
            dispatch(resetState());
            navigate("/categories");
          });
      } else if (params?.parentId) {
        let subCategoryData = {
          name: values.name,
          mainCategoryId: params?.parentId,
          mainImage: values.mainImage,
          // bannerImage: values.bannerImage,
          id: values.id,
        };
        dispatch(submitCategory(subCategoryData))
          .unwrap()
          .then(() => {
            dispatch(resetState());
            navigate("/categories");
          });
      } else {
        dispatch(submitCategory(values))
          .unwrap()
          .then(() => {
            dispatch(resetState());
            navigate("/categories");
          });
      }
    },
    [dispatch, initialValues, navigate, params.id, params?.parentId]
  );

  return (
    <Box px={1}>
      <Typography
        variant="h1"
        fontWeight="600"
        color="primary.main"
        fontSize={{ lg: "2.6rem", sm: "2rem" }}
        my={2}
      >
        {params?.id
          ? "Update Category"
          : params?.parentId
          ? "Add Sub Category"
          : "Add Category"}
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <StyledMuiCard
                  title="Basic Information"
                  subTitle="Please enter the basic information of the product such as name and description"
                >
                  <CardContent>
                    <Stack direction="row" gap={3} p={{ xs: 1, md: 1.5 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Name
                            </Typography>
                            <StyledTextField
                              fullWidth
                              type="text"
                              {...formik.getFieldProps("name")}
                              error={Boolean(
                                formik.touched.name && formik.errors.name
                              )}
                              helperText={
                                formik.touched.name && !!formik.errors.name
                                  ? formik.errors.name
                                  : "Enter a descriptive name of the category here"
                              }
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  </CardContent>
                </StyledMuiCard>
              </Grid>

              {/* Category Specifications Section */}
              {((params?.id && category && category?.type === "main") ||
                (!params?.id && !params?.parentId)) && (
                <Grid item xs={12} lg={12}>
                  <SpecificationsSection formik={formik} />
                </Grid>
              )}

              {/* Category Media Section */}

              <Grid item xs={12} lg={12}>
                <CategoryMediaSection formik={formik} />
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" gap={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={formik.handleSubmit}
                    disabled={loading}
                    disableElevation
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    {loading && params?.id
                      ? "Updating"
                      : loading && !params.id
                      ? "Saving"
                      : !loading && params?.id
                      ? "Update"
                      : "Save"}
                  </Button>
                  {/* <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, lg: 175, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                    onClick={() => formik.resetForm()}
                  >
                    Reset
                  </Button> */}
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(CategoryForm);

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Required*")
    .min(2, "Name should be at least 10 characters long")
    .max(100, "Name is too long"),
  mainImage: Yup.mixed().required("Main Image is required"),
  specifications: Yup.array().of(Yup.object()),
  // bannerImage: Yup.mixed().required("Banner Image is required"),
});
