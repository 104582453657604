import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSignature, saveUploadVideo } from "store/slices/videosSlice";
import TcVod from "vod-js-sdk-v6";
import * as Yup from "yup";
import BasicInformationSection from "./BasicInformationSection";
import ProductDetailSection from "./ProductDetailSection";
import VideoMediaSection from "./VideoMediaSection";

function UploadVideoForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(
    (state) => state?.videos?.videoUploading ?? false
  );

  const initialValues = useMemo(
    () => ({
      title: "",
      thumbnail: "",
      video: "",
      product: null,
    }),
    []
  );

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const tcVod = new TcVod({
          getSignature: async () => {
            try {
              const result = await dispatch(getSignature()).unwrap();
              return result;
            } catch (error) {
              return null;
            }
          },
        });

        const uploader = tcVod.upload({
          mediaFile: values?.video,
        });

        uploader.on("media_progress", () => {
          // Progress
        });

        const doneResult = await uploader.done();
        const formData = new FormData();
        if (values?.product?.id) {
          const product = values.product;
          formData.append(`productData[0][id]`, product?.id.toString());
          formData.append(`productData[0][slug]`, product?.slug);
          formData.append(`productData[0][name]`, product?.productName);
        }
        formData.append("title", values?.title);
        formData.append("photo", values?.thumbnail);
        formData.append("streamingUrl", doneResult?.video?.url);
        formData.append("fileId", doneResult?.fileId);

        dispatch(saveUploadVideo(formData))
          .unwrap()
          .then(() => {
            return navigate("/videos");
          });
      } catch (error) {
        console.error("error while uploading video", error);
      }
    },
    [dispatch, navigate]
  );

  return (
    <Box px={1}>
      <Typography
        variant="h1"
        fontWeight="600"
        color="primary.main"
        fontSize={{ lg: "2.6rem", sm: "2rem" }}
        my={2}
      >
        Upload Video
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={2}>
              {/* Video Basic Information */}
              <Grid item xs={12} lg={12}>
                <BasicInformationSection formik={formik} />
              </Grid>
              {/* Video Data Grid Section */}
              <Grid item xs={12} lg={12}>
                <ProductDetailSection formik={formik} />
              </Grid>
              {/* Video Media Section */}
              <Grid item xs={12} lg={12}>
                <VideoMediaSection formik={formik} />
              </Grid>
              {/* Video Submit section */}
              <Grid item xs={12}>
                <Stack direction="row" gap={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={formik.handleSubmit}
                    disabled={loading}
                    disableElevation
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    {loading ? "uploading..." : "Upload Video"}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => formik.resetForm()}
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, lg: 175, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    Reset
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(UploadVideoForm);

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    // .required("Required*")
    .min(1, "Title should be at least 10 characters long")
    .max(50, "Title is too long"),
  thumbnail: Yup.mixed().required("Thumbnail is required"),
  video: Yup.mixed().required("Video file is required"),
  product: Yup.object().required("Select a product against video"),
});
