const reduceObject = (firstObj, secondObj) => {
  const updatedObject = Object.entries(firstObj)
    // eslint-disable-next-line no-unused-vars
    .filter(([key, value]) => value !== "") // Remove properties with empty string values
    .filter(([key, value]) => {
      return JSON.stringify(value) !== JSON.stringify(secondObj[key]);
    })
    .reduce((result, [key, value]) => {
      result[key] = value;
      return result;
    }, {});

  return updatedObject;
};

export default reduceObject;
