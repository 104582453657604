import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "store/slices/authSlice";
import reduceObject from "utils/helpers/reduceObject";
import StyledTextField from "utils/helpers/styledTextField";
import * as Yup from "yup";

function GeneralSettings() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth.data);

  const initialValues = useMemo(
    () => ({
      fullname: user?.fullname ?? "",
      email: user?.email ?? "",
      phone: user?.phone ?? "",
    }),
    [user?.email, user?.fullname, user?.phone]
  );

  const handleSubmit = useCallback(
    (values) => {
      const data = {
        fullname: values.fullname,
        email: values.email,
        phone: values.phone,
      };

      let formValues = { ...data };
      let userValues = { ...user };
      let updatedValues = reduceObject(formValues, userValues);
      dispatch(updateProfile(updatedValues));
    },
    [dispatch, user]
  );
  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Stack direction="row" gap={3} p={{ xs: 1, md: 1.5 }}>
                <Grid container spacing={3}>
                  <Grid item xs={6} lg={6}>
                    <Stack direction="column" gap={1}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Name
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type="text"
                        {...formik.getFieldProps("fullname")}
                        error={Boolean(
                          formik.touched.fullname && formik.errors.fullname
                        )}
                        helperText={
                          formik.touched.fullname && !!formik.errors.fullname
                            ? formik.errors.fullname
                            : "Enter a descriptive name of the user here"
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Stack direction="column" gap={1}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Phone No
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type="phone"
                        {...formik.getFieldProps("phone")}
                        error={Boolean(
                          formik.touched.phone && formik.errors.phone
                        )}
                        helperText={
                          formik.touched.phone && !!formik.errors.phone
                            ? formik.errors.phone
                            : "Enter the valid phone number here"
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Stack direction="column" gap={1}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Email
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type="text"
                        {...formik.getFieldProps("email")}
                        error={Boolean(
                          formik.touched.email && formik.errors.email
                        )}
                        helperText={
                          formik.touched.email && !!formik.errors.email
                            ? formik.errors.email
                            : "Enter the valid email here"
                        }
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" gap={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={
                    formik.isSubmitting || !formik.isValid || !formik.dirty
                  }
                  onClick={formik.handleSubmit}
                  disableElevation
                  sx={{
                    fontWeight: "bold",
                    minWidth: { md: 100, xl: 250 },
                    height: { xs: 50, xl: 55 },
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={formik.resetForm}
                  sx={{
                    fontWeight: "bold",
                    minWidth: { md: 100, lg: 175, xl: 250 },
                    height: { xs: 50, xl: 55 },
                  }}
                >
                  Reset
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default memo(GeneralSettings);

const validationSchema = Yup.object().shape({
  fullname: Yup.string()
    .trim()
    .required("Required*")
    .min(5, "Name should be at least 5 characters long")
    .max(100, "Name is too long"),
  email: Yup.string()
    .trim()
    .email("Email is invalid")
    .required("Required*")
    .max(80, "Email is too long")
    .matches(/^.+@.+\.(com|co)$/, "Email is invalid"),
  phone: Yup.string()
    .required("Required*")
    .matches(
      /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/,
      "Invalid phone number"
    ),
});
