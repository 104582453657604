import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getPage,
  resetPageState,
  resetPagesState,
  submitPage,
  updatePage,
} from "store/slices/pagesSlice";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import parseEditorValue from "utils/helpers/parseEditorValue";
import reduceObject from "utils/helpers/reduceObject";
import StyledTextField from "utils/helpers/styledTextField";
import * as Yup from "yup";
import ContentSection from "./ContentSection";
import MediaSection from "./MediaSection";

function PagesForm() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const page = useSelector((state) => state.pages.selectedPage ?? {});
  const [loading, setLoading] = useState(false);

  const initialValues = useMemo(
    () => ({
      id: page?.id ?? "",
      title: page?.title ?? "",
      slug: page?.slug ?? "",
      content: page?.content ? parseEditorValue(page?.content) : "",
      image: page?.image ?? "",
    }),
    [page]
  );

  const handleSubmit = (values) => {
    setLoading(true);
    if (params?.id)
      dispatch(
        updatePage({
          id: params?.id,
          data: reduceObject(values, {
            title: page?.title,
            slug: page?.slug,
            content: page?.content,
          }),
        })
      )
        .unwrap()
        .then(() => {
          dispatch(resetPagesState());
          navigate("/pages");
        });
    else
      dispatch(submitPage(values))
        .unwrap()
        .then(() => {
          dispatch(resetPagesState());
          navigate("/pages");
        });
  };

  useEffect(() => {
    if (params?.id) {
      const id = setTimeout(() => {
        dispatch(getPage(params?.id))
          .unwrap()
          .then(() => setLoading(false));
      }, 500);
      return () => {
        id && clearTimeout(id);
        dispatch(resetPageState());
      };
    }
  }, [dispatch, params?.id]);

  return (
    <Box px={1}>
      <Typography
        variant="h1"
        fontWeight="600"
        color="primary.main"
        fontSize={{ lg: "2.6rem", sm: "2rem" }}
        my={2}
      >
        {params?.id ? "Update Page" : "Add Page"}
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            {/* Basic Information */}
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <StyledMuiCard
                  title="Basic Information"
                  subTitle="Please enter the basic information of the auction such as name and description"
                >
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={6} lg={6}>
                        <Stack direction="column" gap={1}>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            fontSize="1.25rem"
                            color="text.secondary"
                          >
                            Title
                          </Typography>
                          <StyledTextField
                            fullWidth
                            name="title"
                            type="text"
                            value={formik.values.title}
                            onChange={(e) => {
                              formik.setFieldValue("title", e.target.value);
                              !params?.id &&
                                formik.setFieldValue(
                                  "slug",
                                  e.target.value
                                    .replace(/\s+/g, "-")
                                    .toLowerCase()
                                );
                            }}
                            onBlur={formik.handleBlur}
                            error={Boolean(
                              formik.touched.title && formik.errors.title
                            )}
                            helperText={
                              formik.touched.title && !!formik.errors.title
                                ? formik.errors.title
                                : "Enter a descriptive title of the page"
                            }
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Stack direction="column" gap={1}>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            fontSize="1.25rem"
                            color="text.secondary"
                          >
                            Page Slug
                          </Typography>
                          <StyledTextField
                            fullWidth
                            disabled={!params?.id}
                            {...formik.getFieldProps("slug")}
                            error={Boolean(
                              formik.touched.slug && formik.errors.slug
                            )}
                            helperText={
                              formik.touched.slug &&
                              !!formik.errors.slug &&
                              formik.errors.slug
                            }
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </CardContent>
                </StyledMuiCard>
              </Grid>

              {/* Content Section */}
              <Grid item xs={12} lg={12}>
                <ContentSection formik={formik} />
              </Grid>
              {/*Auction Media Section */}
              <Grid item xs={12} lg={12}>
                <MediaSection id={page?.id ?? ""} formik={formik} />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" gap={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={formik.handleSubmit}
                    disableElevation
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    disabled={formik.isSubmitting || loading}
                    onClick={() => formik.resetForm()}
                    variant="outlined"
                    color="primary"
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, lg: 175, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    Reset
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(PagesForm);

const validationSchema = Yup.object().shape({
  id: Yup.string(),
  title: Yup.string()
    .trim()
    .required("Required*")
    .min(2, "Title should be at least 6 characters long")
    .max(50, "Title is too long"),
  slug: Yup.string().when("id", {
    is: (id) => id,
    then: () =>
      Yup.string()
        .trim()
        .required("Required*")
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9-]*$/, "Invalid slug")
        .min(2, "Slug should be at least 2 characters long")
        .max(50, "Slug is too long"),
    otherwise: () => Yup.string().trim().required("Required*"),
  }),
  content: Yup.string()
    .trim()
    .required("Required*")
    .min(81, "Content must be at least 80 characters long"),
  image: Yup.mixed(),
});
