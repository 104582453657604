import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { memo } from "react";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import LongDescriptionEditor from "./LongDescriptionEditor";
import ShortDescriptionEditor from "./ShortDescriptionEditor";

function Description({ formik }) {
  return (
    <StyledMuiCard
      title="Details"
      subTitle="Enter a short and long description here."
    >
      <Box display="flex">
        <CardContent sx={{ width: "50%" }}>
          <Stack
            direction="column"
            justifyContent="center"
            gap={2}
            p={{ xs: 1, md: 1.5 }}
          >
            <Box py={1}>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Short Description
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                pt={1}
                fontSize="1rem"
              >
                Enter details with specifications. Tip: Use bullet points for
                easy viewing
              </Typography>
            </Box>
            <Box>
              <Card>
                <ShortDescriptionEditor formik={formik} />
              </Card>
              {formik.errors.shortDescription && (
                <Typography color="error.light" pt={1} fontSize="1rem">
                  {formik.errors.shortDescription}
                </Typography>
              )}
            </Box>
          </Stack>
        </CardContent>
        <CardContent sx={{ width: "50%" }}>
          <Stack
            direction="column"
            justifyContent="center"
            gap={2}
            p={{ xs: 1, md: 1.5 }}
          >
            <Box py={1}>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Long Description
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                pt={1}
                fontSize="1rem"
              >
                Enter details with specifications. Tip: Use bullet points for
                easy viewing
              </Typography>
            </Box>
            <Box>
              <Card>
                <LongDescriptionEditor formik={formik} />
              </Card>
              {formik.errors.longDescription && (
                <Typography color="error.light" pt={1} fontSize="1rem">
                  {formik.errors.longDescription}
                </Typography>
              )}
            </Box>
          </Stack>
        </CardContent>
      </Box>
    </StyledMuiCard>
  );
}
Description.propTypes = {
  formik: PropTypes.object.isRequired,
};
export default memo(Description);
