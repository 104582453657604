import BlogsDataGrid from "components/Blogs/BlogsDataGrid";
import BlogsForm from "components/Blogs/BlogsForm";
import PropTypes from "prop-types";
import { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { store } from "store/configureStore";
import {
  getBlogs,
  resetBlogState,
  resetBlogsState,
} from "store/slices/blogsSlice";

function Blogs({ create, update }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetBlogState());
    return () => dispatch(resetBlogsState());
  }, [dispatch]);

  if (create || update) return <BlogsForm />;

  return <BlogsDataGrid />;
}

Blogs.propTypes = {
  create: PropTypes.bool,
  update: PropTypes.bool,
};

Blogs.defaultProps = {
  create: false,
  update: false,
};

export default memo(Blogs);

export const loadBlogs = () => {
  store.dispatch(getBlogs());
  return null;
};
