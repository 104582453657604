import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { memo } from "react";
import {
  BUTTON_HOVER,
  BUTTON_HOVER_BORDER_COLOR,
  FILTERS_BORDER_COLOR,
} from "styles/colors";
import CardTitle from "../CardTitle";
import BidderActivity from "./BidderActivity";

const bidderActivity = () => {
  const bidder = [
    { title: "Stokes Lincoln", auctions: 12, amount: "1,00,000.00" },
    { title: "Forrest Rohan", auctions: 13, amount: "1,00,000.00" },
    { title: "Dr. Jorge Bins", auctions: 10, amount: "1,00,000.00" },
    { title: "Maryse Lehner", auctions: 14, amount: "1,00,000.00" },
    { title: "Ophelia Mann", auctions: 16, amount: "1,00,000.00" },
  ];
  return (
    <Card
      sx={{ p: "1.2rem", boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" }}
    >
      <Box sx={containerStyles} mb={1}>
        <Box display="contents" alignItems="baseline">
          <CardTitle title="Bidder Activity" color="#707070" />
          <Link href="#">View all</Link>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={6} sm={6} fontSize={"0.875rem"}>
          User Details
        </Grid>
        <Grid item lg={3} sm={3} fontSize={"0.875rem"}>
          Auctions
        </Grid>
        <Grid item lg={3} sm={3} fontSize={"0.875rem"}>
          Amount
        </Grid>
      </Grid>
      {bidder.map((item, index) => {
        return (
          <BidderActivity
            title={item.title}
            auctions={item.auctions}
            amount={item.amount}
            key={item.auctions}
            index={index}
          />
        );
      })}
    </Card>
  );
};
export default memo(bidderActivity);
const containerStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: "50px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
    },
    "&:hover": {
      backgroundColor: BUTTON_HOVER,
      "& fieldset": {
        borderColor: BUTTON_HOVER_BORDER_COLOR,
      },
    },
  },
};
