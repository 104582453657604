import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Form, Formik } from "formik";
import parsePhoneNumber from "libphonenumber-js";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "store/configureStore";
import {
  createUser,
  getUser,
  resetUserState,
  updateUser,
} from "store/slices/usersSlice";
import { USER_ORIGIN_SOURCE } from "utils/constants/constants";
import USER_ROLE from "utils/constants/userRole";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import reduceObject from "utils/helpers/reduceObject";
import StyledTextField from "utils/helpers/styledTextField";
import * as Yup from "yup";
import UpdatePasswordForm from "./UpdatePasswordForm";

function UserForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams().id;
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { user, loading } = useSelector((state) => state?.users);
  const [showPassword, setShowPassword] = useState(false);
  const userRole = useSelector((state) => state?.auth?.userRole);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const initialValues = useMemo(
    () => ({
      id: user?.id ?? "",
      name: user?.fullname ?? "",
      email: user?.email ?? "",
      phone: user?.phone ?? "",
      role: user?.role ?? "",
      refCode: user?.refCode ?? "",
      password: "",
      confirmPassword: "",
    }),
    [user]
  );

  const handleSubmit = useCallback(
    (values) => {
      if (id) {
        const data = {
          fullname: values.name,
          email: values.email,
          phone: values.phone,
          role: values.role,
        };

        let formValues = { ...data };
        let userValues = { ...user };
        let updatedValues = reduceObject(formValues, userValues);

        if (Object.keys(updatedValues).length === 0) {
          return navigate("/users");
        } else {
          dispatch(updateUser({ id, updatedValues }))
            .unwrap()
            .then(() => {
              navigate("/users");
            });
        }
      } else {
        dispatch(
          createUser({
            fullname: values.name,
            email: values.email,
            phone: values.phone,
            role: values.role,
            password: values.password,
            origin: {
              source: isSmall
                ? USER_ORIGIN_SOURCE.WEB_MOBILE
                : USER_ORIGIN_SOURCE.WEB,
            },
          })
        )
          .unwrap()
          .then(() => {
            navigate("/users");
          });
      }
    },
    [dispatch, id, isSmall, navigate, user]
  );

  useEffect(() => {
    return () => dispatch(resetUserState());
  }, [dispatch]);

  return (
    <Box px={1}>
      <Typography
        variant="h1"
        fontWeight="600"
        color="primary.main"
        fontSize={{ lg: "2.6rem", sm: "2rem" }}
        my={2}
      >
        {id ? "Update" : "Create"} User
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <StyledMuiCard
              title="User Information"
              subTitle="Please enter the basic information of user such as name , email, password and role."
            >
              <CardContent>
                <Grid container spacing={2} p={{ xs: 1, md: 1.5 }}>
                  <Grid item xs={6} lg={6}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Full Name
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type="text"
                        size={isSmall ? "small" : "large"}
                        {...formik.getFieldProps("name")}
                        error={Boolean(
                          formik.touched.name && formik.errors.name
                        )}
                        helperText={
                          formik.touched.name && !!formik.errors.name
                            ? formik.errors.name
                            : "Enter a full name of the user here"
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Email
                      </Typography>
                      <StyledTextField
                        fullWidth
                        size={isSmall ? "small" : "large"}
                        type="email"
                        {...formik.getFieldProps("email")}
                        error={!!formik.touched.email && !!formik.errors.email}
                        helperText={
                          formik.touched.email && formik.errors.email
                            ? formik.errors.email
                            : "Enter the valid email address of the user here."
                        }
                      />
                    </Box>
                  </Grid>

                  {id && (
                    <Grid item xs={6} lg={6}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          fontSize="1.25rem"
                          color="text.secondary"
                        >
                          Ref Code
                        </Typography>
                        <StyledTextField
                          fullWidth
                          disabled
                          type="text"
                          size={isSmall ? "small" : "large"}
                          {...formik.getFieldProps("refCode")}
                          error={Boolean(
                            formik.touched.refCode && formik.errors.refCode
                          )}
                          helperText={
                            formik.touched.refCode && !!formik.errors.refCode
                              ? formik.errors.refCode
                              : "Enter a reference code"
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Box>
                    </Grid>
                  )}

                  <Grid item xs={6} lg={6}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Phone
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type="tel"
                        size={isSmall ? "small" : "large"}
                        {...formik.getFieldProps("phone")}
                        error={!!formik.touched.phone && !!formik.errors.phone}
                        helperText={
                          formik.touched.phone && formik.errors.phone
                            ? formik.errors.phone
                            : "Enter the valid phone number including country code."
                        }
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} lg={6}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Role
                      </Typography>
                      <StyledTextField
                        select
                        fullWidth
                        type="text"
                        disabled={id}
                        size={isSmall ? "small" : "large"}
                        {...formik.getFieldProps("role")}
                        error={Boolean(
                          formik.touched.role && formik.errors.role
                        )}
                        helperText={
                          formik.touched.role && !!formik.errors.role
                            ? formik.errors.role
                            : "Please define the role of the user here"
                        }
                      >
                        <MenuItem value="user">User</MenuItem>
                        {userRole === USER_ROLE.SUPER_ADMIN && (
                          <MenuItem value="admin">Admin</MenuItem>
                        )}
                        <MenuItem value="influencer">Influencer</MenuItem>
                      </StyledTextField>
                    </Box>
                  </Grid>
                  {!id && (
                    <>
                      <Grid item xs={6} lg={6}>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            fontSize="1.25rem"
                            color="text.secondary"
                          >
                            Password
                          </Typography>
                          <StyledTextField
                            fullWidth
                            type={showPassword ? "text" : "password"}
                            {...formik.getFieldProps("password")}
                            error={
                              !!formik.touched.password &&
                              !!formik.errors.password
                            }
                            helperText={
                              formik.touched.password && formik.errors.password
                            }
                            autoComplete="new-password"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  <IconButton
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            fontSize="1.25rem"
                            color="text.secondary"
                          >
                            Confirm Password
                          </Typography>
                          <StyledTextField
                            fullWidth
                            type={showConfirmPassword ? "text" : "password"}
                            {...formik.getFieldProps("confirmPassword")}
                            error={
                              !!formik.touched.confirmPassword &&
                              !!formik.errors.confirmPassword
                            }
                            helperText={
                              formik.touched.confirmPassword &&
                              formik.errors.confirmPassword
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  <IconButton
                                    onClick={() =>
                                      setShowConfirmPassword(
                                        !showConfirmPassword
                                      )
                                    }
                                    edge="end"
                                  >
                                    {showConfirmPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Box textAlign="right" mt={2} mr={1}>
                  <Button
                    disableElevation
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    {id ? "Update" : "Create"}
                  </Button>
                </Box>
              </CardContent>
            </StyledMuiCard>
          </Form>
        )}
      </Formik>
      {id && userRole === USER_ROLE.SUPER_ADMIN && (
        <UpdatePasswordForm userId={id} />
      )}
    </Box>
  );
}

export default memo(UserForm);

export function loadUser({ params }) {
  const userId = params?.id;
  store.dispatch(getUser(userId ?? ""));
  return null;
}

const phoneValidation = (value) => {
  try {
    const phoneNumber = parsePhoneNumber(value);

    if (phoneNumber && phoneNumber.isValid()) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Required*")
    .min(3, "Name is too short")
    .max(50, "Name is too long"),
  email: Yup.string()
    .email("Email is invalid")
    .trim()
    .required("Required*")
    .max(80, "Email is too long"),
  role: Yup.string().required("Required*"),
  refCode: Yup.string(),
  phone: Yup.string()
    .test("phone", "Invalid phone number", phoneValidation)
    .required("Required*"),
  password: Yup.string().when("id", {
    is: (id) => !id,
    then: () =>
      Yup.string()
        .trim()
        .required("Required*")
        .min(8, "Password must be at least 8 characters")
        .matches(
          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
          "Password must contain at least one uppercase and one lowercase letter and one number and a special character"
        ),
    otherwise: () => Yup.string().notRequired(),
  }),
  confirmPassword: Yup.string().when("id", {
    is: (id) => !id,
    then: () =>
      Yup.string()
        .required("Required*")
        .oneOf([Yup.ref("password"), null], "Passwords do not match"),
    otherwise: () => Yup.string().notRequired(),
  }),
});
