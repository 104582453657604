import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "UI",
  initialState: {
    isSidebarOpen: true,
    isSidebarOpenedByUser: false,
    isSessionExpiryDialogOpen: false,
    showToast: false,
    toastSeverity: "success",
    toastMessage: "",
    isConfirmDeletionDialogOpen: false,
    deleteId: "",
    isSelectProductCategoryDialogOpen: false,
    isPushProductDialogOpen: false,
    isExtendTimerDialogOpen: false,
    isImageValidationDialogOpen: false,
    imageValidationError: "",
    isObsDataDialogOpen: false,
    isSubscriptionUpdateDialogOpen: false,
  },
  reducers: {
    setSideBarOpen: (state) => {
      state.isSidebarOpen = true;
    },
    setSideBarOpenedByUser: (state) => {
      state.isSidebarOpenedByUser = true;
    },
    setSideBarClose: (state) => {
      state.isSidebarOpen = false;
      state.isSidebarOpenedByUser = false;
    },
    closeToast: (state) => {
      state.showToast = false;
      state.toastMessage = "";
      state.toastSeverity = "success";
    },
    showToast: (state, action) => {
      state.showToast = true;
      state.toastMessage = action.payload.message;
      if (action.payload.severity)
        state.toastSeverity = action.payload.severity;
    },
    setSessionExpiryDialogOpen: (state) => {
      state.isSessionExpiryDialogOpen = true;
    },
    setSessionExpiryDialogClose: (state) => {
      state.isSessionExpiryDialogOpen = false;
    },
    setConfirmDeletionDialogOpen: (state) => {
      state.isConfirmDeletionDialogOpen = true;
    },
    setDeleteId: (state, action) => {
      if (action) {
        state.deleteId = action.payload;
      }
    },
    resetDeleteDialogState: (state) => {
      state.deleteId = "";
      state.isConfirmDeletionDialogOpen = false;
    },
    setSelectProductCategoryDialogState: (state, action) => {
      state.isSelectProductCategoryDialogOpen = action.payload;
    },
    setPushProductDialogState: (state, action) => {
      state.isPushProductDialogOpen = action.payload;
    },
    setIsExtendTimerDialogOpen: (state, action) => {
      state.isExtendTimerDialogOpen = action.payload;
    },
    setIsImageValidationDialogOpen: (state, action) => {
      state.isImageValidationDialogOpen = action.payload;
      if (!action.payload) state.imageValidationError = "";
    },
    setImageValidationError: (state, action) => {
      state.imageValidationError = action.payload;
    },
    setObsDataDialogState: (state, action) => {
      state.isObsDataDialogOpen = action.payload;
    },
    setSubscriptionUpdateDialogState: (state, action) => {
      state.isSubscriptionUpdateDialogOpen = action.payload;
    },
  },
});

export const {
  setSideBarOpen,
  setSideBarClose,
  closeToast,
  showToast,
  setSessionExpiryDialogOpen,
  setSessionExpiryDialogClose,
  setConfirmDeletionDialogOpen,
  setDeleteId,
  resetDeleteDialogState,
  setSelectProductCategoryDialogState,
  setSideBarOpenedByUser,
  setPushProductDialogState,
  setIsExtendTimerDialogOpen,
  setIsImageValidationDialogOpen,
  setImageValidationError,
  setObsDataDialogState,
  setSubscriptionUpdateDialogState,
} = uiSlice.actions;

export default uiSlice.reducer;
