export const PRODUCT_FORM_STATUSES = {
  BASIC: "basic",
  MEDIA: "media",
  REPORTS: "reports",
  COMPLETED: "completed",
};

export const PRODUCT_VEHICLE_TYPES = {
  ATV: "atv",
  BUS: "bus",
  CONVERTIBLE: "convertible",
  COUPE: "coupe",
  ELECTRIC_BIKE: "electric-bike",
  ELECTRIC_SCOOTER: "electric-scooter",
  HATCHBACK: "hatchback",
  LIMOUSINE: "limousine",
  MINIVAN: "minivan",
  MOTORCYCLE: "motorcycle",
  MPV: "mpv",
  PICKUP_TRUCK: "pickup-truck",
  RV: "rv",
  SEDAN: "sedan",
  SUV: "suv",
  TRUCK: "truck",
  VAN: "van",
  OTHERS: "others",
};
