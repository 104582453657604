import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import BannerImageForm from "components/SubBanners/BannerForm/BannerImageForm";
import { Form, Formik } from "formik";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "store/configureStore";
import {
  addBanner,
  getBanner,
  resetBannerState,
  updateBanner,
  updateBannerImage,
} from "store/slices/bannersSlice";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import reduceObject from "utils/helpers/reduceObject";
import StyledTextField from "utils/helpers/styledTextField";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";

function BannerForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams().id;
  const device = useParams().device;
  const { banner, loading } = useSelector((state) => state.banners);

  const initialValues = useMemo(
    () => ({
      bannerName: banner?.name ?? "",
      title: banner?.title ?? "",
      subTitle: banner?.subTitle ?? "",
      url: banner?.url ?? "",
      bannerImage: banner?.image ?? "",
      linkId: banner?.linkId ?? "",
      type: banner?.type ?? "",
    }),
    [
      banner?.image,
      banner?.linkId,
      banner?.name,
      banner?.subTitle,
      banner?.title,
      banner?.type,
      banner?.url,
    ]
  );

  const validateFormData = useCallback(
    (values) => {
      const formData = new FormData();
      formData.append("name", values.bannerName);
      device === "web" &&
        values.title &&
        formData.append("title", values.title);
      device === "web" &&
        values.subTitle &&
        formData.append("subTitle", values.subTitle);
      device === "web" && formData.append("url", values.url);
      device === "mobile" && formData.append("linkId", values.linkId);
      device === "mobile" && formData.append("type", values.type);
      formData.append("bannerSetId", id);
      formData.append("bannerImage", values.bannerImage);
      if (!banner) {
        dispatch(addBanner({ formData }))
          .unwrap()
          .then(() => {
            toast.success("Banner added successfully");
            navigate("/banner-set");
          })
          .catch(() => {
            toast.error("Something went wrong");
          });
      }
      const data = {
        name: values.bannerName,
        url: values.url,
        title: values.title,
        subTitle: values.subTitle,
        linkId: values.linkId,
        type: values.type,
      };
      let formValues = { ...data };
      let bannerValues = { ...banner };

      let updatedValues = reduceObject(formValues, bannerValues);
      if (banner && updatedValues) {
        if (Object.keys(updatedValues).length === 0) {
          return navigate(`/banner-set`);
        } else {
          dispatch(updateBanner({ id, data: updatedValues }))
            .unwrap()
            .then(() => {
              toast.success("Updated successfully");
              navigate(`/banner-set`);
            })
            .catch(() => {
              toast.error("Something went wrong");
            });
        }
      }

      const imgFormData = new FormData();
      imgFormData.append("bannerImage", values.bannerImage);
      if (banner && values.bannerImage instanceof Object) {
        dispatch(updateBannerImage({ id, data: imgFormData }))
          .unwrap()
          .then(() => {
            toast.success("Updated successfully");
            navigate(`/banner-set`);
          })
          .catch(() => {
            toast.error("Something went wrong");
          });
      }

      navigate("/banner-set");
    },
    [banner, device, dispatch, id, navigate]
  );

  const handleSubmit = useCallback(
    (values) => {
      validateFormData(values);
    },
    [validateFormData]
  );

  useEffect(() => {
    return () => dispatch(resetBannerState());
  }, [dispatch]);

  return (
    <Box px={1}>
      <Typography
        variant="h1"
        fontWeight="600"
        color="primary.main"
        fontSize={{ lg: "2.6rem", sm: "2rem" }}
        my={2}
      >
        {banner ? "Update Banner" : "Add Banner"}
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={
          device === "web" ? webValidationSchema : mobileValidationSchema
        }
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <StyledMuiCard
              title="Add Banner"
              subTitle="Please enter the basic information of the banners"
            >
              <CardContent>
                <Grid container spacing={2} p={{ xs: 1, md: 1.5 }}>
                  <Grid item xs={6} lg={6}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Banner Name
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type="text"
                        {...formik.getFieldProps("bannerName")}
                        error={Boolean(
                          formik.touched.bannerName && formik.errors.bannerName
                        )}
                        helperText={
                          formik.touched.bannerName &&
                          !!formik.errors.bannerName
                            ? formik.errors.bannerName
                            : "Enter a descriptive name"
                        }
                      />
                    </Box>
                  </Grid>
                  {device === "web" && (
                    <>
                      <Grid item xs={6} lg={6}>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            fontSize="1.25rem"
                            color="text.secondary"
                          >
                            Url
                          </Typography>
                          <StyledTextField
                            fullWidth
                            type="text"
                            {...formik.getFieldProps("url")}
                            error={Boolean(
                              formik.touched.url && formik.errors.url
                            )}
                            helperText={
                              formik.touched.url && !!formik.errors.url
                                ? formik.errors.url
                                : "Enter a valid URL"
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            fontSize="1.25rem"
                            color="text.secondary"
                          >
                            Banner Title
                          </Typography>
                          <StyledTextField
                            fullWidth
                            type="text"
                            {...formik.getFieldProps("title")}
                            error={Boolean(
                              formik.touched.title && formik.errors.title
                            )}
                            helperText={
                              formik.touched.title && !!formik.errors.title
                                ? formik.errors.title
                                : "Enter a descriptive title"
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            fontSize="1.25rem"
                            color="text.secondary"
                          >
                            Banner Subtitle
                          </Typography>
                          <StyledTextField
                            fullWidth
                            type="text"
                            {...formik.getFieldProps("subTitle")}
                            error={Boolean(
                              formik.touched.subTitle && formik.errors.subTitle
                            )}
                            helperText={
                              formik.touched.subTitle &&
                              !!formik.errors.subTitle
                                ? formik.errors.subTitle
                                : "Enter a descriptive subTitle"
                            }
                          />
                        </Box>
                      </Grid>
                    </>
                  )}
                  {device === "mobile" && (
                    <Grid item xs={6} lg={6}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          fontSize="1.25rem"
                          color="text.secondary"
                        >
                          Link Id
                        </Typography>
                        <StyledTextField
                          fullWidth
                          type="text"
                          {...formik.getFieldProps("linkId")}
                          error={Boolean(
                            formik.touched.linkId && formik.errors.linkId
                          )}
                          helperText={
                            formik.touched.linkId && !!formik.errors.linkId
                              ? formik.errors.linkId
                              : "Enter a valid Link Id"
                          }
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
                {device === "mobile" && (
                  <Grid container spacing={2} p={{ xs: 1, md: 1.5 }}>
                    <Grid item xs={6} lg={6}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          fontSize="1.25rem"
                          color="text.secondary"
                        >
                          Link Type
                        </Typography>
                        <StyledTextField
                          fullWidth
                          select
                          type="text"
                          {...formik.getFieldProps("type")}
                          error={Boolean(
                            formik.touched.type && formik.errors.type
                          )}
                          helperText={
                            formik.touched.type && !!formik.errors.type
                              ? formik.errors.type
                              : "Select Link Type"
                          }
                        >
                          <MenuItem value="Product">Product</MenuItem>
                          <MenuItem value="Category">Category</MenuItem>
                          <MenuItem value="Video">Video</MenuItem>
                          <MenuItem value="Page">Page</MenuItem>
                        </StyledTextField>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </StyledMuiCard>
            <Grid item xs={12} lg={12} mt={3}>
              <BannerImageForm formik={formik} />
            </Grid>
            <Grid item xs={12} mt={3}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={formik.handleSubmit}
                disabled={loading}
                sx={{
                  fontWeight: "bold",
                  width: 100,
                  height: 40,
                }}
              >
                {id ? "Update" : "Add"}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(BannerForm);

const webValidationSchema = Yup.object().shape({
  bannerName: Yup.string()
    .trim()
    .required("Required*")
    .min(5, "Name should be at least 5 characters long")
    .max(50, "Name is too long"),
  title: Yup.string()
    .trim()
    .min(10, "Name should be at least 5 characters long")
    .max(30, "Name is too long"),
  subTitle: Yup.string()
    .trim()
    .min(10, "Name should be at least 5 characters long")
    .max(30, "Name is too long"),
  url: Yup.string()
    .required("Required")
    .trim()
    .min(5, "URl should be at least 5 characters long")
    .max(50, "URl is too long"),
  bannerImage: Yup.string().required("Image is required"),
});

const mobileValidationSchema = Yup.object().shape({
  bannerName: Yup.string()
    .trim()
    .required("Required*")
    .min(5, "Name should be at least 5 characters long")
    .max(50, "Name is too long"),

  linkId: Yup.string()
    .required("Required*")
    .min(5, "Link Id should be at least 5 characters long")
    .max(50, "Link Id is too long"),
  type: Yup.string().required("Required*"),
  bannerImage: Yup.string().required("Image is required"),
});

export function loadBanner({ params }) {
  const bannerId = params?.id;
  store.dispatch(getBanner(bannerId ?? ""));
  return null;
}
