import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import NotificationsMenu from "components/Header/Notifications/NotificationsMenu";
import { memo, useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingState } from "store/slices/notificationsSlice";
import { NOTIFICATION_EVENT } from "utils/constants/notifications";

function Notifications() {
  const socket = useRef(null);
  const dispatch = useDispatch();
  const userId = useSelector((state) => state?.auth?.data?.user?.id ?? "");
  const unreadNotificationsCount = useSelector(
    (state) => state?.notifications?.unreadNotificationsCount
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const getNotifications = useCallback(
    (page = 1) => {
      dispatch(setLoadingState(true));
      socket.current?.emit(NOTIFICATION_EVENT.ALL_NOTIFICATIONS, {
        userId,
        page,
      });
    },
    [dispatch, userId]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOpenNotifications = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
      getNotifications();
    },
    [getNotifications]
  );

  // const socketInitializer = useCallback(async () => {
  //   if (isTokenExpired()) {
  //     await getNewAccessToken();
  //   }

  //   socket.current = io(`${process.env.REACT_APP_SOCKET_URL}/notifications`, {
  //     reconnection: true,
  //     reconnectionAttempts: 3,
  //     reconnectionDelay: 1000 * 60,
  //     reconnectionDelayMax: 1000 * 90,
  //     transports: ["websocket"],
  //     auth: {
  //       token: localStorage.getItem("token"),
  //     },
  //   });
  //   socket.current.on("connect", () => {
  //     socket.current.emit(NOTIFICATION_EVENT.UNREAD_NOTIFICATIONS, {
  //       userId,
  //     });

  //     socket.current.on(NOTIFICATION_EVENT.UNREAD_NOTIFICATIONS, (data) => {
  //       dispatch(setUnreadNotificationsCount(data.count));
  //     });

  //     socket.current.on(NOTIFICATION_EVENT.ALL_NOTIFICATIONS, (data) => {
  //       dispatch(setNotificationsData(data));
  //       socket.current.emit(NOTIFICATION_EVENT.UNREAD_NOTIFICATIONS, {
  //         userId,
  //       });
  //     });
  //   });
  // }, [dispatch, userId]);

  // useEffect(() => {
  //   !socket.current && socketInitializer();
  //   return () => {
  //     if (socket.current) {
  //       socket.current.disconnect();
  //       socket.current = null;
  //     }
  //   };
  // }, [socketInitializer]);

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton
          size="large"
          onClick={handleOpenNotifications}
          aria-haspopup="true"
          aria-controls={open ? "notification-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          sx={{
            py: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Badge badgeContent={unreadNotificationsCount} max={99} color="error">
            <NotificationsIcon
              sx={{
                color: "primary.main",
                width: "2.5rem",
                height: "2.5rem",
              }}
            />
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        getNotifications={getNotifications}
      />
    </>
  );
}

export default memo(Notifications);
