import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setProductFormStatus } from "store/slices/productsSlice";
import { PRODUCT_FORM_STATUSES } from "utils/constants/product";

function ProductFormStatusesButtons() {
  const dispatch = useDispatch();
  const id = useParams()?.id;
  const status = useSelector((state) => state.products.productFormStatus);

  const handleChange = useCallback(
    (_, value) => {
      dispatch(setProductFormStatus(value));
    },
    [dispatch]
  );

  return (
    <Box
      mt={2}
      mb={4}
      width="100%"
      display="flex"
      justifyContent={{ xs: "center", lg: "flex-start" }}
    >
      <Tabs
        value={status}
        onChange={handleChange}
        aria-label="Product status filters"
        sx={{
          backgroundColor: "white",
          "& .MuiTab-root": {
            textTransform: "capitalize",
            minWidth: 150,
            fontSize: "1rem",
          },
        }}
      >
        <Tab label="Basic" value={PRODUCT_FORM_STATUSES.BASIC} />
        <Tab label="Media" value={PRODUCT_FORM_STATUSES.MEDIA} disabled={!id} />
        <Tab
          label="Reports"
          value={PRODUCT_FORM_STATUSES.REPORTS}
          disabled={!id}
        />
      </Tabs>
    </Box>
  );
}

export default memo(ProductFormStatusesButtons);
