const { Alert, Snackbar } = require("@mui/material");
const { useSelector, useDispatch } = require("react-redux");
const { closeToast } = require("store/slices/uiSlice");

export const ToastContainer = () => {
  const open = useSelector((state) => state.UI.showToast);
  const toastSeverity = useSelector((state) => state.UI.toastSeverity);
  const toastMessage = useSelector((state) => state.UI.toastMessage);
  const dispatch = useDispatch();
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={5000}
      onClose={open ? () => dispatch(closeToast()) : null}
    >
      <Alert severity={toastSeverity} sx={{ width: "100%" }}>
        {toastMessage}
      </Alert>
    </Snackbar>
  );
};
