import CardContent from "@mui/material/CardContent";
import styled from "@mui/material/styles/styled";
import { memo } from "react";

const StyledCard = styled(CardContent)(`
  padding: 16px;
  &:last-child {
    padding-bottom: 16px;
  }
`);

export default memo(StyledCard);
