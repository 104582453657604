import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadAuctionImage } from "services/auctionService";
import USER_ROLE from "utils/constants/userRole";
import { getErrorMessage, getResponseData } from "utils/helpers/httpRequests";
import { toast } from "utils/hooks/useToast";
import { httpMain } from "utils/httpRequest/http";

export const getAuctions = createAsyncThunk(
  "auctions/getAuctions",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().auctions;
    const url = "/auctions/admin";
    try {
      const response = await httpMain.get(url, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          sortBy: "-createdAt",
          ...data,
        },
      });
      const responseData = getResponseData(response);
      responseData &&
        responseData?.results.forEach((item, index) => {
          const pageStart = (responseData?.page - 1) * responseData?.limit;
          item.serialNumber = pageStart + index + 1;
        });

      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateAuctions = createAsyncThunk(
  "auctions/updateAuctions",
  async (values = {}, { rejectWithValue }) => {
    const { auctionId, ...updatedValues } = values;
    delete updatedValues["auctionImage"];
    try {
      const response = await httpMain.patch(
        `/auctions/${auctionId}`,
        updatedValues
      );
      if (typeof values.auctionImage === "object") {
        await uploadAuctionImage(response?.data?.data?.id, values.auctionImage);
        toast.success(response?.data?.message);
      }
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteAuctions = createAsyncThunk(
  "auctions/deleteAuctions",
  async (auctionId, { getState, rejectWithValue }) => {
    const { results, page, limit } = getState().auctions;
    try {
      await httpMain.delete(`/auctions/${auctionId}`);
      toast.success("Deleted successfully");
      let returnedauctions = results.filter(
        (product) => product.id !== auctionId
      );
      if (returnedauctions) {
        returnedauctions = returnedauctions.map((item, index) => {
          const pageStart = (page - 1) * limit;
          return {
            ...item,
            serialNumber: pageStart + index + 1,
          };
        });
      }
      return returnedauctions;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getAuctionById = createAsyncThunk(
  "auctions/getAuctionById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await httpMain.get(`/auctions/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);
export const submitAuction = createAsyncThunk(
  "auctions/submitAuction",
  async (data, { rejectWithValue }) => {
    try {
      const response = await httpMain.post(`/auctions`, data.auctionData);
      if (response?.data?.status === 200) {
        await uploadAuctionImage(response?.data?.data?.id, data.auctionImage);
        toast.success(response?.data?.message);
      }
      return response?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ??
          error?.message ??
          "Something went wrong, please try again later"
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAuctionProducts = createAsyncThunk(
  "auctions/getAuctionProducts",
  async (params, { getState, rejectWithValue }) => {
    const { userRole } = getState().auth;
    const { page, limit } = getState().auctions.products;
    const url = [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN].includes(userRole)
      ? "/products/admin"
      : `/products`;
    try {
      const response = await httpMain.get(url, {
        params: {
          page: params.page ?? page,
          limit: params.limit ?? limit,
          sortBy: "-createdAt",
          ...params,
        },
      });
      const responseData = getResponseData(response);

      responseData &&
        responseData?.results.forEach((item, index) => {
          const pageStart = (responseData?.page - 1) * responseData?.limit;
          item.serialNumber = pageStart + index + 1;
        });

      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: true,
  auctionLoading: false,
  auction: null,
  auctionId: null,
  results: [],
  page: 1,
  limit: 20,
  totalPages: 0,
  totalResults: 0,
  error: "",
  auctionCsv: null,
  auctionError: null,
  products: {
    loading: true,
    error: "",
    results: [],
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
  },
};

const auctionsSlice = createSlice({
  name: "auctions",
  initialState,
  reducers: {
    resetState: () => initialState,
    resetAuction: (state) => {
      state.loading = false;
      state.auction = null;
      state.auctionError = null;
    },
    resetAuctionState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuctions.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAuctions.fulfilled, (state, action) => {
        state.loading = false;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
        state.results = action.payload.results;
        state.totalPages = action.payload.totalPages;
        state.totalResults = action.payload.totalResults;
      })
      .addCase(getAuctions.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(submitAuction.pending, (state) => {
        state.auctionLoading = true;
      })
      .addCase(submitAuction.fulfilled, (state, action) => {
        state.auctionLoading = false;
        state.auctionId = action.payload.data.id;
      })
      .addCase(submitAuction.rejected, (state, action) => {
        state.auctionLoading = false;
        state.auctionError = action.payload;
      })
      .addCase(getAuctionById.pending, (state) => {
        state.loading = true;
        state.auctionError = false;
      })
      .addCase(getAuctionById.fulfilled, (state, action) => {
        state.auction = action.payload;
        state.loading = false;
      })
      .addCase(getAuctionById.rejected, (state, action) => {
        state.loading = false;
        state.auctionError = action.payload;
      })
      .addCase(updateAuctions.pending, (state) => {
        state.auctionLoading = true;
        state.productError = "";
      })
      .addCase(updateAuctions.fulfilled, (state, action) => {
        state.auctionLoading = false;
        state.product = action.payload;
      })
      .addCase(updateAuctions.rejected, (state, action) => {
        state.auctionLoading = false;
        state.productError = action.payload;
      })
      .addCase(deleteAuctions.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteAuctions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.results = action.payload;
      })
      .addCase(deleteAuctions.rejected, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(getAuctionProducts.pending, (state) => {
        state.products.loading = true;
        state.products.error = "";
      })
      .addCase(getAuctionProducts.fulfilled, (state, action) => {
        state.products.loading = false;
        state.products.page = action.payload.page;
        state.products.limit = action.payload.limit;
        state.products.results = action.payload.results;
        state.products.totalPages = action.payload.totalPages;
        state.products.totalResults = action.payload.totalResults;
      })
      .addCase(getAuctionProducts.rejected, (state, action) => {
        state.products.loading = false;
        state.products.error = action.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState, resetAuction, resetAuctionState } =
  auctionsSlice.actions;

// Exporting default reducer
export default auctionsSlice.reducer;
