import RemoveCircle from "@mui/icons-material/RemoveCircle";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProductGalleryImagePlaceholder from "assets/icons/ProductGalleryImagePlaceholder.svg";
import ProductVideoPlaceholder from "assets/icons/ProductVideoPlaceholder.svg";
import addFilePlaceholder from "assets/icons/addFilePlaceholder.svg";
import addImagePlaceholder from "assets/icons/addImagePlaceholder.svg";
import PropTypes from "prop-types";
import { memo, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateProductFormData } from "services/Product";
import {
  setImageValidationError,
  setIsImageValidationDialogOpen,
} from "store/slices/uiSlice";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import { getErrorMessage } from "utils/helpers/httpRequests";
import validateImageAspectRatio from "utils/helpers/validateImageAspectRatio";
import { toast } from "utils/hooks/useToast";

function Media({ formik, isSubmitting, setIsSubmitting }) {
  const dispatch = useDispatch();
  const mainImageRef = useRef(null);
  const galleryRef = useRef(null);
  const videoRef = useRef(null);
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const mainImageHandler = (e) => {
    const image = e.target.files[0];
    if (image) {
      // function params
      // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
      validateImageAspectRatio(image, 500, 500, false, true, 1 / 1, "1 / 1")
        .then((img) => formik.setFieldValue("mainImage", img))
        .catch((error) => {
          mainImageRef.current.value = "";
          dispatch(setIsImageValidationDialogOpen(true));
          dispatch(setImageValidationError(error.message));
        });
    }
  };

  const handleGallery = async (e) => {
    if (formik.values?.gallery?.length + e.target.files?.length > 6) {
      dispatch(setIsImageValidationDialogOpen(true));
      dispatch(setImageValidationError("You can upload a maximum of 6 images"));
    } else {
      const images = [];

      for (const image of e.target.files) {
        try {
          // function params
          // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
          const validatedImage = await validateImageAspectRatio(
            image,
            500,
            500,
            false,
            true,
            1 / 1,
            "1 / 1"
          );
          images.push(validatedImage);
        } catch (error) {
          galleryRef.current.value = "";
          dispatch(setIsImageValidationDialogOpen(true));
          dispatch(setImageValidationError(error.message));
          break; // Exit the function on error
        }
      }
      formik.setFieldValue("gallery", [...formik.values.gallery, ...images]);
    }
  };

  const handleRemoveGalleryImage = (index) => {
    const image = formik.values?.gallery[index];
    if (typeof image === "string") {
      const formData = new FormData();
      formData.append("removeGallery[0]", image);
      setIsSubmitting(() => true);
      updateProductFormData(formik.values.id, formData)
        .then(() => {
          const newGallery = formik.values?.gallery.filter(
            (_image) => _image !== image
          );
          formik.setFieldValue("gallery", newGallery);
        })
        .catch((error) => {
          toast.error(getErrorMessage(error));
        })
        .finally(() => {
          setIsSubmitting(() => false);
        });
    } else {
      formik.setFieldValue(
        "gallery",
        formik.values?.gallery.toSpliced(index, 1)
      );
      galleryRef.current.value = "";
    }
  };

  const handleVideo = (e) => {
    const video = e.target.files[0];
    if (video) {
      formik.setFieldValue("video", video);
    }
  };

  return (
    <StyledMuiCard
      title="Media Section"
      subTitle="Upload images and videos relevant to the product"
    >
      <CardContent>
        <Box display="flex" flexWrap="wrap" gap={{ xs: 3, lg: 4 }}>
          {/* Product Main Image */}
          <Stack direction="column" gap={2}>
            <Typography variant="h4" color="text.secondary" fontWeight={600}>
              Main Image
            </Typography>
            <Box>
              <img
                src={
                  formik.values?.mainImage &&
                  typeof formik.values?.mainImage === "string"
                    ? formik.values?.mainImage
                    : formik.values?.mainImage &&
                      typeof formik.values?.mainImage === "object"
                    ? URL.createObjectURL(formik.values?.mainImage)
                    : addImagePlaceholder
                }
                alt="Product Main Media"
                width={isMedium ? "200px" : "395px"}
                style={{ cursor: "pointer", aspectRatio: "1/1" }}
                onClick={() => mainImageRef.current.click()}
              />
              <input
                type="file"
                ref={mainImageRef}
                style={{ display: "none" }}
                hidden
                accept="image/x-png,image/png,image/jpeg,image/jpg"
                onChange={mainImageHandler}
              />
              <Typography
                variant="subtitle1"
                color={formik?.errors?.mainImage ? "error" : "text.secondary"}
              >
                {formik?.errors?.mainImage ?? "Add image size minimum 500*500"}
              </Typography>
            </Box>
          </Stack>

          <Stack direction="column" gap={3}>
            {/* Product Gallery Images */}
            <Stack direction="column">
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight={600}
                pb={2}
              >
                Gallery Images
              </Typography>
              <Box display="flex" flexDirection="row" gap={4}>
                {formik.values?.gallery?.length < 6 && (
                  <img
                    ref={galleryRef}
                    src={addFilePlaceholder}
                    alt="Add Product Gallery Placeholder"
                    height="150px"
                    width="150px"
                    style={{ cursor: "pointer" }}
                    onClick={() => galleryRef.current.click()}
                  />
                )}
                {!formik.values?.gallery?.length
                  ? [...Array(4)].map((e, i) => (
                      <Box key={i}>
                        <GalleryImagePlaceholder />
                      </Box>
                    ))
                  : formik.values?.gallery.map((image, index) => (
                      <Stack
                        direction="row"
                        key={index}
                        alignItems="flex-start"
                      >
                        <img
                          src={
                            typeof image === "string"
                              ? image
                              : typeof image === "object" &&
                                URL.createObjectURL(image)
                          }
                          alt=""
                          height="auto"
                          width="150px"
                        />
                        {formik.values?.gallery.length > 1 && (
                          <IconButton
                            disabled={isSubmitting}
                            onClick={() => handleRemoveGalleryImage(index)}
                          >
                            <RemoveCircle />
                          </IconButton>
                        )}
                      </Stack>
                    ))}
              </Box>
              <input
                type="file"
                ref={galleryRef}
                style={{ display: "none" }}
                id="upload-product-gallery-images"
                hidden
                multiple
                accept="image/x-png,image/png,image/jpeg,image/jpg"
                onChange={handleGallery}
              />
              <Typography
                variant="subtitle1"
                color={formik?.errors?.gallery ? "error" : "text.secondary"}
                mt={0.25}
              >
                {formik?.errors?.gallery ??
                  "Video should be in MP4 format and the size should be less than 350MB"}
              </Typography>
            </Stack>

            {/* Product Video */}
            <Stack direction="column" gap={2}>
              <Typography variant="h4" color="text.secondary" fontWeight={600}>
                Product Video
              </Typography>
              <Box>
                <Box display="flex" flexDirection="row" gap={4}>
                  <img
                    src={addFilePlaceholder}
                    alt="Add Product Video Placeholder"
                    height="150px"
                    width="150px"
                    style={{ cursor: "pointer" }}
                    onClick={() => videoRef.current.click()}
                  />
                  {!formik.values?.video ? (
                    <img
                      src={ProductVideoPlaceholder}
                      alt="Product Video Placeholder"
                      height="150px"
                      width="150px"
                    />
                  ) : (
                    <Stack direction="row" alignItems="center">
                      <video
                        src={
                          typeof formik.values?.video === "string"
                            ? formik.values?.video
                            : typeof formik.values.video === "object" &&
                              URL.createObjectURL(formik.values?.video)
                        }
                        alt="Product Video"
                        height="150px"
                        width="150px"
                        controls
                      />
                      {/* <Box height="100%">
                        <IconButton
                          disabled={isSubmitting}
                          onClick={handleDeleteVideo}
                        >
                          <RemoveCircle />
                        </IconButton>
                      </Box> */}
                    </Stack>
                  )}
                </Box>
                <input
                  type="file"
                  ref={videoRef}
                  style={{ display: "none" }}
                  id="update-product-video"
                  hidden
                  accept="video/mp4,video/quicktime"
                  onChange={handleVideo}
                  disabled={isSubmitting}
                />
                <Typography
                  variant="subtitle1"
                  color={formik?.errors?.video ? "error" : "text.secondary"}
                  mt={0.25}
                >
                  {formik?.errors?.video ??
                    "Video should be in MP4 format and the size should be less than 350MB"}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
}

Media.propTypes = {
  formik: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setIsSubmitting: PropTypes.func.isRequired,
};

export default memo(Media);

function GalleryImagePlaceholder() {
  return (
    <img
      src={ProductGalleryImagePlaceholder}
      alt="Product Gallery Placeholder"
      height="auto"
      width="150px"
    />
  );
}
