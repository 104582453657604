import Box from "@mui/material/Box";
import CategoryDataGrid from "components/Category/CategoryDataGrid";
import CategoryForm from "components/Category/CategoryForm";
import CategoryHeaderComponents from "components/Category/CategoryHeaderComponents/index";
import PageHeader from "layout/PageHeader";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { store } from "store/configureStore";
import {
  getCategories,
  getCategory,
  getSubCategories,
  resetState,
  setCategory,
} from "store/slices/categoriesSlice";

function Categories({ create, edit, addSubCategory }) {
  const dispatch = useDispatch();
  useEffect(() => () => dispatch(resetState()), [dispatch]);

  if (create || edit || addSubCategory) {
    return <CategoryForm />;
  }

  return (
    <Box pt={3} pb={2} px={1}>
      <PageHeader title="Categories" />
      <CategoryHeaderComponents />
      <CategoryDataGrid />
    </Box>
  );
}

Categories.propTypes = {
  create: PropTypes.bool,
  edit: PropTypes.bool,
  addSubCategory: PropTypes.bool,
};

Categories.defaultProps = {
  create: false,
  edit: false,
  addSubCategory: false,
};

export default Categories;

export function loadSubCategories({ request, params }) {
  const url = new URL(request.url);
  const searchTerm = url.searchParams;
  const name = searchTerm.get("name");
  const value = searchTerm.get("value");
  if (name && value) {
    return store.dispatch(getCategories({ name: name, value: value }));
  }

  let id = "";
  if (params.lvl2 || params.lvl3 || params.lvl4) {
    id = params.lvl4 ?? params.lvl3 ?? params.lvl2;
  }
  return store.dispatch(getSubCategories(id));
}

export function loadCategory({ params }) {
  let category = store
    .getState()
    .categories.results.find((c) => c.id === params.id);

  if (category) {
    return store.dispatch(setCategory(category));
  } else {
    return store.dispatch(getCategory(params.id));
  }
}
