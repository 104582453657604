import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import AuthPageEllipse from "assets/icons/AuthPageEllipse.svg";
import AuthPageRectangles from "assets/images/AuthPageRectangles.png";
import AuthSideContent from "components/authentication/AuthSideContent";
import LoginForm from "components/authentication/LoginForm";

export default function Login() {
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));

  return (
    <Grid container component="main" backgroundColor="#ed4928">
      <Grid
        item
        xs={6}
        position="relative"
        sx={{
          backgroundImage: `url("${AuthPageRectangles}")`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Box
          fontWeight="bold"
          fontSize="1.625rem"
          color="white"
          sx={{
            position: "absolute",
            top: isMedium ? "1rem" : isLarge ? "1.25rem" : "1.75rem",
            left: isMedium ? "1rem" : isLarge ? "1.25rem" : "1.75rem",
          }}
        >
          YallaBid
        </Box>
        <AuthSideContent />
      </Grid>
      <Grid item xs={6} position="relative" overflow="hidden" bgcolor="white">
        <img
          src={AuthPageEllipse}
          alt="Ellipse"
          height={isMedium ? 175 : isLarge ? 225 : 300}
          width={isMedium ? 175 : isLarge ? 225 : 300}
          style={{
            position: "absolute",
            top: isMedium ? -100 : isLarge ? -115 : -150,
            right: isMedium ? -100 : isLarge ? -115 : -150,
          }}
        />
        <LoginForm />
      </Grid>
    </Grid>
  );
}
