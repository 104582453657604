import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import linearProgressClasses from "@mui/material/LinearProgress/linearProgressClasses";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import ArrowDown from "assets/icons/ArrowDown.svg";
import ArrowUp from "assets/icons/ArrowUp.svg";
import Auction from "assets/icons/Auction.svg";
import bidders from "assets/icons/bidders.svg";
import deposit from "assets/icons/keyNumbers/deposit.svg";
import products from "assets/icons/products.svg";
import revenue_generated from "assets/icons/revenue_generated.svg";
import visitors from "assets/icons/visitors.svg";
import NumberFormatter from "components/NumberFormatter";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { FILTER_TEXT_COLOR, FOOTER_ICON_COLOR } from "styles/colors";
import CardTitle from "../CardTitle";

const KeyNumberTile = ({ target, title, currentMonth, lastMonth }) => {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const imageIcon = useCallback((title) => {
    switch (title) {
      case "No of Bids":
        return Auction;
      case "Active Listing":
        return products;
      case "Bidders":
        return bidders;
      case "GMV":
        return revenue_generated;
      case "Visitors/Viewers":
        return visitors;
      case "Deposit":
        return deposit;
      default:
        return;
    }
  }, []);

  const imageBackground = useCallback((title) => {
    switch (title) {
      case "No of Bids":
        return "#fdeaea";
      case "Active Listing":
        return "#fdeaea";
      case "Bidders":
        return "#e9fbf1";
      case "GMV":
        return "#efedee";
      case "Visitors/Viewers":
        return "#fff3e8";
      case "Deposit":
        return "#e0f9fd";
      default:
        return;
    }
  }, []);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: "7.4px",
    borderRadius: 5,
    mt: "8px",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#0ea552",
    },
  }));

  const difference = useMemo(() => {
    if (!isNaN(currentMonth) && !isNaN(lastMonth)) {
      return Math.abs(currentMonth - lastMonth);
    } else {
      return 0;
    }
  }, [currentMonth, lastMonth]);

  const Progress = useMemo(() => {
    return target !== 0 && currentMonth !== 0
      ? `${Math.round((100 / parseInt(target)) * parseInt(currentMonth))}%`
      : "0%";
  }, [currentMonth, target]);
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <CardTitle title={title} />
          <NumberFormatter
            sx={{
              fontSize: { md: "2rem", lg: "2.2rem", xxl: "2.625rem" },
              fontWeight: "600",
              color: "#3f3f3f",
              height: { md: "55px", lg: "45px", xxl: "50px" },
            }}
            number={currentMonth}
          />
          <Typography
            sx={{
              fontSize: "0.75rem",
              lineHeight: "20px",
              color: FILTER_TEXT_COLOR,
            }}
          >
            This month
          </Typography>
        </Box>
        <Box
          borderRadius="50%"
          minWidth={{
            sm: "70px",
            md: isOpen ? "60px" : "70px",
            lg: "60px",
            xxl: "70px",
          }}
          height="100%"
          minHeight={{
            sm: "70px",
            md: isOpen ? "60px" : "70px",
            lg: "60px",
            xxl: "70px",
          }}
          width="auto"
          sx={{
            backgroundImage: `url(${imageIcon(title)})`,
            backgroundColor: imageBackground(title),
            backgroundRepeat: "no-repeat",
            backgroundSize: {
              sm: "40px",
              md: isOpen ? "30px" : "40px",
              lg: "30px",
              xxl: "40px",
            },
            backgroundPosition: "center",
          }}
        ></Box>
      </Box>
      <Box display={!isOpen ? "flex" : "content"} gap={0.7}>
        <Box display="flex" gap={0.7} alignItems="center">
          <img
            src={currentMonth > lastMonth ? ArrowUp : ArrowDown}
            alt="arrow"
          />
          <NumberFormatter
            number={difference}
            color={currentMonth > lastMonth ? "#0ea552" : FOOTER_ICON_COLOR}
          />
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "0.75rem",
              color: FILTER_TEXT_COLOR,
              lineHeight: 2.06,
            }}
          >
            vs last month
          </Typography>
        </Box>
      </Box>
      <Box width="100%" height="7px" mr={1} mt={1}>
        <BorderLinearProgress
          variant="determinate"
          value={currentMonth !== 0 ? (currentMonth * 100) / target : 0}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" mt={1}>
        <Typography sx={{ color: FILTER_TEXT_COLOR, fontSize: "0.75rem" }}>
          {Progress}
        </Typography>
        <NumberFormatter
          sx={{ color: FILTER_TEXT_COLOR, fontSize: "0.75rem" }}
          number={target}
        />
      </Box>
    </Box>
  );
};
export default memo(KeyNumberTile);

KeyNumberTile.propTypes = {
  title: PropTypes.string.isRequired,
  currentMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  target: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  lastMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

KeyNumberTile.defaultProps = {
  title: "N/A",
  target: 0,
  thisMonth: "N/A",
  lastMonth: "N/A",
};
