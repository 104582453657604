import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MUIStyledTextField from "components/MUIStyledComponents/MUIStyledTextField";
import PropTypes from "prop-types";
import isValidNumber from "utils/helpers/isValidNumber";

export default function CommissionForm({ formik }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} lg={4}>
        <Stack direction="column" gap={2} px={0} py={1.5}>
          <Stack direction="column" gap={1}>
            <Typography variant="h4" color="text.secondary" fontWeight={600}>
              Commission Charge
            </Typography>
            <MUIStyledTextField
              fullWidth
              name="commission"
              value={formik.values.commission}
              onChange={(e) => {
                isValidNumber(e.target.value) &&
                  formik.setFieldValue("commission", +e.target.value);
              }}
              onBlur={formik.handleBlur}
              InputProps={{
                startAdornment: (
                  <Typography
                    color="text.disabled"
                    sx={{
                      fontSize: "1.25rem",
                      borderRight: "1px solid #00000038",
                      paddingRight: "1rem",
                    }}
                  >
                    AED
                  </Typography>
                ),
              }}
              error={Boolean(
                formik.touched.commission && formik.errors.commission
              )}
              helperText={
                formik.touched.commission && !!formik.errors.commission
                  ? formik.errors.commission
                  : "Enter a monthly charges of the subscription in AED"
              }
              sx={{
                "& .MuiOutlinedInput-input": {
                  fontSize: "1.25rem",
                  textAlign: "right",
                  padding: "14px",
                },
              }}
            />
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={6} lg={4}>
        <Stack direction="column" gap={2} px={2} py={1.5}>
          <Stack direction="column" gap={1}>
            <Typography variant="h4" color="text.secondary" fontWeight={600}>
              Platform Share
            </Typography>
            <MUIStyledTextField
              fullWidth
              name="yalaBidShare"
              value={formik.values.yalaBidShare}
              onChange={(e) => {
                if (e.target.value === "") {
                  formik.setFieldValue("yalaBidShare", "");
                  formik.setFieldValue("listerShare", "");
                } else if (isValidNumber(e.target.value)) {
                  formik.setFieldValue("yalaBidShare", +e.target.value);
                  formik.setFieldValue(
                    "listerShare",
                    100 - +e.target.value >= 0 ? 100 - +e.target.value : ""
                  );
                }
              }}
              onBlur={formik.handleBlur}
              InputProps={{
                startAdornment: (
                  <Typography
                    color="text.disabled"
                    sx={{
                      fontSize: "1.25rem",
                      borderRight: "1px solid #00000038",
                      paddingRight: "1rem",
                    }}
                  >
                    AED
                  </Typography>
                ),
              }}
              error={Boolean(
                formik.touched.yalaBidShare && formik.errors.yalaBidShare
              )}
              helperText={
                formik.touched.yalaBidShare && !!formik.errors.yalaBidShare
                  ? formik.errors.yalaBidShare
                  : "Enter the commission share value of platform"
              }
              sx={{
                "& .MuiOutlinedInput-input": {
                  fontSize: "1.25rem",
                  textAlign: "right",
                  padding: "14px",
                },
              }}
            />
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={6} lg={4}>
        <Stack direction="column" gap={2} px={0} py={1.5}>
          <Stack direction="column" gap={1}>
            <Typography variant="h4" color="text.secondary" fontWeight={600}>
              Lister Share
            </Typography>
            <MUIStyledTextField
              fullWidth
              name="listerShare"
              value={formik.values.listerShare}
              disabled={true}
              // onChange={(e) => {
              //   if (e.target.value === "") {
              //     formik.setFieldValue("listerShare", "");
              //     return;
              //   }
              //   isValidNumber(e.target.value) &&
              //     formik.setFieldValue("listerShare", +e.target.value);
              // }}
              // onBlur={formik.handleBlur}
              InputProps={{
                startAdornment: (
                  <Typography
                    color="text.disabled"
                    sx={{
                      fontSize: "1.25rem",
                      borderRight: "1px solid #00000038",
                      paddingRight: "1rem",
                    }}
                  >
                    AED
                  </Typography>
                ),
              }}
              // error={Boolean(
              //   formik.touched.listerShare && formik.errors.listerShare
              // )}
              helperText={
                // formik.touched.listerShare && !!formik.errors.listerShare
                // ? formik.errors.listerShare :
                "lister commission will be automatically calculated against platform share"
              }
              sx={{
                "& .MuiOutlinedInput-input": {
                  fontSize: "1.25rem",
                  textAlign: "right",
                  padding: "14px",
                },
              }}
            />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}

CommissionForm.propTypes = {
  formik: PropTypes.object.isRequired,
};
