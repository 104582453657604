import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import queryString from "query-string";
import { memo, useCallback, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

function BidStatusesButtons() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const [value, setValue] = useState("ALL_BIDS");

  const handleChange = useCallback(
    (_, value) => {
      setValue(value);
      // Necessary for performance
      // eslint-disable-next-line no-unused-vars
      const { bidCancel, ...rest } = query;
      let params = { ...rest };
      switch (value) {
        case "bidCancel":
          params.bidCancel = "true";
          break;
        default:
          break;
      }
      navigate({
        pathname: "/bids",
        search: createSearchParams(params).toString(),
      });
    },
    [navigate, query]
  );

  return (
    <Stack
      pt={1}
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      gap={3}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Bids status filters"
        sx={{
          "& .MuiTabs-flexContainer": {
            flexWrap: "wrap",
          },
          "& .MuiTab-root": { textTransform: "capitalize", bgcolor: "white" },
        }}
      >
        <Tab label="All Bids" value="ALL_BIDS" />
        <Tab label="Bid Cancel" value="bidCancel" />
      </Tabs>
    </Stack>
  );
}

export default memo(BidStatusesButtons);
