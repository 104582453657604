import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import ReactApexChart from "react-apexcharts";
import {
  STATS_GREEN,
  STATS_PRIMARY_GREY,
  STATS_PRIMARY_ORANGE,
} from "styles/colors";

function RadialChart() {
  const data = {
    series: [50, 20, 10],
    options: {
      chart: {
        height: 300,
        type: "radialBar",
      },
      stroke: {
        lineCap: "round",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              label: "Total",
              formatter: function (w) {
                return w.globals?.seriesTotals.reduce((total, num) => {
                  return total + num;
                }, 0);
              },
            },
          },
        },
      },
      labels: ["Auction Completed", "Pending Auctions", "No result"],
      colors: [STATS_PRIMARY_ORANGE, STATS_GREEN, STATS_PRIMARY_GREY],
    },
  };

  return (
    <Box id="chart">
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="radialBar"
        height={285}
      />
      <Box display="flex" alignItems="center">
        <Typography
          mr={1}
          sx={{
            height: 15,
            width: 15,
            backgroundColor: STATS_PRIMARY_ORANGE,
            borderRadius: "50%",
          }}
        />
        Auction Completed
      </Box>
      <Box display="flex" alignItems="center">
        <Typography
          mr={1}
          sx={{
            height: 15,
            width: 15,
            backgroundColor: STATS_GREEN,
            borderRadius: "50%",
          }}
        />
        Pending Auctions
      </Box>
      <Box display="flex" alignItems="center">
        <Typography
          mr={1}
          sx={{
            height: 15,
            width: 15,
            backgroundColor: STATS_PRIMARY_GREY,
            borderRadius: "50%",
          }}
        />
        No Result
      </Box>
    </Box>
  );
}

export default memo(RadialChart);
