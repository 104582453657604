import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import WritingIcon from "assets/icons/WritingIcon.svg";
import deleteIcon from "assets/icons/deleteIcon.svg";
import CurrencyFormatter from "components/CurrencyFormatter";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledGrid from "components/StyledGrid";
import StyledSwitch from "components/ToggleButton";
import { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateSubscriptionStatus } from "services/pageServices";
import {
  deleteSubscription,
  getSubscriptions,
} from "store/slices/subscriptionsSlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import { toast } from "utils/hooks/useToast";

function DataGrid() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { results, loading, totalPages } = useSelector(
    (state) => state.subscriptions
  );

  const handleUpdatePage = useCallback(
    (id) => {
      navigate(`${id}/update`);
    },
    [navigate]
  );

  const handleClickOpen = useCallback(
    (subscriptionId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(subscriptionId));
    },
    [dispatch]
  );

  const handleActiveStatus = useCallback(
    (subscription) => {
      updateSubscriptionStatus(subscription?.id, !subscription.active).then(
        () => {
          toast.success("Subscription status updated successfully");
          dispatch(getSubscriptions());
        }
      );
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        headerAlign: "center",
        align: "center",
        minWidth: 50,
        flex: 0.1,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "title",
        headerName: "Title",
        minWidth: 200,
        flex: 1.5,
        sortable: false,
        renderCell: (params) => (
          <Box>
            <Typography textTransform="capitalize">
              {params?.row?.subType}
            </Typography>
          </Box>
        ),
      },
      {
        field: "subscriptionRole",
        headerName: "Role",
        minWidth: 150,
        flex: 0.5,
        sortable: false,
        renderCell: (params) => (
          <Box>
            <Typography textTransform="capitalize">
              {params?.row?.subscriptionRole}
            </Typography>
          </Box>
        ),
      },
      {
        field: "chargesType",
        headerName: "Type",
        minWidth: 150,
        flex: 0.5,
        sortable: false,
        renderCell: (params) => (
          <Box>
            <Typography textTransform="capitalize">
              {params?.row?.charges?.chargesType}
            </Typography>
          </Box>
        ),
      },
      {
        field: "charges",
        headerName: "Charges",
        minWidth: 150,
        flex: 0.5,
        sortable: false,
        renderCell: (params) => (
          <CurrencyFormatter number={params?.row?.charges?.price ?? 0} />
        ),
      },
      {
        field: "minDeposit",
        headerName: "Minimum Deposit",
        minWidth: 200,
        flex: 0.5,
        renderCell: (params) => (
          <CurrencyFormatter number={params?.row?.minDeposit ?? 0} />
        ),
      },
      {
        field: "active",
        headerName: "Active",
        headerAlign: "center",
        align: "center",
        minWidth: 150,
        flex: 0.5,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => (
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <StyledSwitch
                checked={params?.row?.active}
                onChange={() => handleActiveStatus(params?.row)}
              />
            }
          />
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "right",
        align: "right",
        minWidth: 150,
        flex: 0.25,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <Typography variant="h6" pr={2}>
            Actions
          </Typography>
        ),
        renderCell: (params) => (
          <Box display="flex" sx={actionStyles}>
            <Tooltip title="Update">
              <Button
                variant="text"
                className="button-styles"
                onClick={() => handleUpdatePage(params.row.id)}
              >
                <img src={WritingIcon} alt="delete" className="icon-size" />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                variant="text"
                sx={{
                  minWidth: "20px",
                }}
                onClick={() => handleClickOpen(params?.id)}
              >
                <img src={deleteIcon} alt="edit" className="icon-size" />
              </Button>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [handleActiveStatus, handleUpdatePage, handleClickOpen]
  );

  return (
    <>
      <StyledGrid
        columns={columns}
        loading={loading}
        rows={results}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteSubscription} />
    </>
  );
}

export default memo(DataGrid);

const actionStyles = {
  pr: 1,
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "25px", width: "25px" },
};
