function convertToTitleCase(sentence) {
  // Split the sentence into an array of words
  let words = sentence.toLowerCase().split(" ");

  // Capitalize the first letter of each word
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Join the modified words back into a single string
  let titleCaseSentence = words.join(" ");

  return titleCaseSentence;
}

export default convertToTitleCase;
