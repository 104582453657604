import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import streamingHttpClient from "utils/httpRequest/streamingHttpClient";

export const getVideos = createAsyncThunk(
  "getVideos",
  // async (dataProvidedInFunctionParameter, { getState, dispatch }) => {}
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().videos;
    const url = "/recordedvideo";

    try {
      const response = await streamingHttpClient.get(url, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          ...data,
        },
      });
      if (response?.data?.data?.results) {
        response.data.data.results.forEach((item, index) => {
          const pageStart =
            (response.data.data.page - 1) * response.data.data.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addFeaturedVideo = createAsyncThunk(
  "addFeaturedVideo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await streamingHttpClient.post(
        `/${data?.id}/featured`,
        null,
        {
          params: {
            featured: data.featured,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeFeaturedVideo = createAsyncThunk(
  "removeFeaturedVideo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await streamingHttpClient.post(
        `/${data?.id}/featured`,
        null,
        {
          params: {
            featured: data.featured,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const responseFacebook = createAsyncThunk(
  "responseFacebook",
  async (data, { dispatch, rejectWithValue }) => {
    let accessToken = data.accessToken;
    try {
      const response = await streamingHttpClient.patch(
        `/facebook-streaming/views`,
        {
          token: accessToken,
        }
      );
      if (
        response &&
        response?.data &&
        response?.data?.status === 200 &&
        response?.data?.isSuccess
      ) {
        dispatch(getVideos());
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const saveUploadVideo = createAsyncThunk(
  "saveUploadVideo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_STREAMING_BASE_URL + `/vod/save`,
        data,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data ?? error?.response?.message);
    }
  }
);

export const getVideoBySlug = createAsyncThunk(
  "videos/getVideoBySlug",
  async (slug, { getState, rejectWithValue }) => {
    const results = getState().videos.results;
    const _video = results.find((video) => video.slug === slug);
    if (_video) {
      return _video;
    }
    const url = `/${slug}/slug`;
    try {
      const response = await streamingHttpClient.get(url, {
        slug: slug,
      });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data ?? error.message ?? "");
    }
  }
);

export const getSignature = createAsyncThunk(
  "getSignature",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_STREAMING_BASE_URL + `/vod/token`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return response?.data?.data?.signature;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message ?? error?.response?.message
      );
    }
  }
);

export const updateVideo = createAsyncThunk(
  "videos/updateVideo",
  async (updatedValues = {}, { rejectWithValue }) => {
    const id = updatedValues._id;
    const url = `/${id}`;
    try {
      const response = await streamingHttpClient.patch(
        url,
        updatedValues?.formData ? updatedValues?.formData : updatedValues?.data
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data ?? error.message ?? "");
    }
  }
);

export const getVideo = createAsyncThunk("getVideo", async () => {});

export const addVideo = createAsyncThunk("addVideo", async () => {});

export const deleteVideo = createAsyncThunk(
  "deleteVideo",
  async (videoId, { getState, rejectWithValue }) => {
    const { results } = getState().videos;
    try {
      const response = await streamingHttpClient.delete(`/${videoId}`);
      if (response.status === 200) {
        const returnedData = results.filter((data) => data.id !== videoId);
        return returnedData;
      }
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const videosSlice = createSlice({
  name: "videos",
  initialState: {
    videoUploading: false,
    loading: true,
    video: {},
    results: [],
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
    videoUploadError: "",
    error: null,
  },
  reducers: {
    // Actions
    resetState: (state) => {
      state.loading = true;
      state.video = {};
      state.results = [];
      state.page = 1;
      state.limit = 20;
      state.totalPages = 0;
      state.totalResults = 0;
      state.error = null;
    },
    resetVideoUploadState: (state) => {
      state.videoUploading = false;
      state.videoUploadError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVideos.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getVideos.fulfilled, (state, action) => {
        if (action.payload) {
          state.results = action.payload.results;
          state.totalResults = action.payload.totalResults;
          state.totalPages = action.payload.totalPages;
          state.page = action.payload.page;
          state.limit = action.payload.limit;
        } else {
          state.results = [];
          state.totalResults = 0;
          state.totalPages = 0;
        }
        state.loading = false;
      })
      .addCase(getVideos.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(deleteVideo.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
      })
      .addCase(deleteVideo.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSignature.pending, (state) => {
        state.videoUploading = true;
      })
      .addCase(getSignature.fulfilled, (state, action) => {
        state.videoUploading = true;
        state.groupBuyProduct = action.payload;
      })
      .addCase(getSignature.rejected, (state, action) => {
        state.videoUploading = false;
        state.videoUploadError = action.payload;
      })
      .addCase(saveUploadVideo.pending, (state) => {
        state.videoUploading = true;
      })
      .addCase(saveUploadVideo.fulfilled, (state, action) => {
        state.videoUploading = false;
        state.groupBuyProduct = action.payload;
      })
      .addCase(saveUploadVideo.rejected, (state, action) => {
        state.videoUploading = false;
        state.videoUploadError = action.payload;
      })
      .addCase(getVideoBySlug.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getVideoBySlug.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        if (action.payload) state.video = action.payload;
      })
      .addCase(getVideoBySlug.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateVideo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateVideo.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState } = videosSlice.actions;

// Exporting default reducer
export default videosSlice.reducer;
