import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

export default function AddVideo({ formik }) {
  const fixedOptions = [];

  return (
    <>
      <Box py={2}>
        <Typography
          variant="h3"
          fontWeight="bold"
          fontSize="1.25rem"
          color="text.secondary"
        >
          Video Links
        </Typography>
      </Box>
      <Autocomplete
        multiple
        id="tags-filled"
        options={fixedOptions.map((option) => option.title)}
        value={formik.values.videos}
        freeSolo
        onChange={(_, value) => {
          formik.setFieldValue("videos", value);
        }}
        onBlur={formik.handleBlur}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ "& .MuiOutlinedInput-root": { alignItems: "start" } }}
            multiline
            rows={3}
            fullWidth
            size="large"
            placeholder="Video url..."
            error={Boolean(formik.touched.videos && formik.errors.videos)}
            helperText={
              formik.touched.videos && !!formik.errors.videos
                ? formik.errors.videos
                : "Paste youtube video url"
            }
          />
        )}
      />
    </>
  );
}

AddVideo.propTypes = {
  formik: PropTypes.object.isRequired,
};
