import { httpMain } from "utils/httpRequest/http";

export const getChassisDetails = (chassisNumber = "") => {
  return httpMain.get(`/chassis/${chassisNumber}/vin`);
};

export const updateProductFormData = (productId = "", formData) => {
  return httpMain.post(`/products/${productId}`, formData);
};

export const uploadMainImage = (productId = "", image = null) => {
  const formData = new FormData();
  formData.append("mainImage", image);
  return httpMain.post(`/products/${productId}`, formData);
};

export const removeMainImage = (productId = "", image = "") => {
  const formData = new FormData();
  formData.append("removeMainImage", image);
  return httpMain.post(`/products/${productId}`, formData);
};

export const uploadGalleryImages = (productId = "", images = []) => {
  const formData = new FormData();
  images.forEach((image) => {
    if (typeof image === "object") formData.append(`gallery`, image);
  });
  return httpMain.post(`/products/${productId}`, formData);
};

export const removeGalleryImage = (productId = "", imageUrl = "") => {
  const formData = new FormData();
  formData.append("removeGallery[0]", imageUrl);
  return httpMain.post(`/products/${productId}`, formData);
};

export const uploadVideo = (productId = "", video = null) => {
  let formData = new FormData();
  formData.append("videoUrl", video);
  return httpMain.post(`/products/${productId}`, formData);
};

export const removeVideo = (productId = "", video = "") => {
  let formData = new FormData();
  formData.append("removeVideo", video);
  return httpMain.patch(`/products/${productId}`, formData);
};

export const uploadReports = (productId = "", pdfs = []) => {
  const formData = new FormData();
  pdfs.forEach((pdf) => {
    if (typeof pdf === "object") formData.append(`reports`, pdf);
  });
  return httpMain.post(`/products/${productId}`, formData);
};

export const removeReports = (productId = "", pdfUrl = "") => {
  const formData = new FormData();
  formData.append("removeReports[0]", pdfUrl);
  return httpMain.post(`/products/${productId}`, formData);
};
