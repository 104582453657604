import CancelIcon from "@mui/icons-material/Cancel";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MUIStyledTextField from "components/MUIStyledComponents/MUIStyledTextField";
import { Form, Formik } from "formik";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCategories, setProductCategory } from "store/slices/filtersSlice";
import { setSelectProductCategoryDialogState } from "store/slices/uiSlice";
import * as Yup from "yup";

function SelectProductCategoryDialog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOpen = useSelector(
    (state) => state.UI.isSelectProductCategoryDialogOpen
  );
  const loading = useSelector((state) => state.filters.loading.categories);
  const categories = useSelector((state) => state.filters.categories);

  const handleClose = useCallback(() => {
    dispatch(setSelectProductCategoryDialogState(false));
  }, [dispatch]);

  const initialValues = useMemo(
    () => ({
      category: null,
    }),
    []
  );

  const handleSubmit = useCallback(
    (values) => {
      dispatch(setProductCategory(values.category));
      dispatch(setSelectProductCategoryDialogState(false));
      navigate("create");
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    const id = setTimeout(() => {
      if (!categories.length && loading) {
        dispatch(getCategories());
      }
    }, 500);
    return () => clearTimeout(id);
  }, [dispatch, loading, categories]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": { minWidth: { lg: "1000px" } },
      }}
      PaperProps={{
        elevation: 0,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={5}
        bgcolor="primary.main"
        sx={{
          padding: "0.25rem 0 0.25rem 2rem",
        }}
      >
        <Stack direction="column">
          <Typography variant="h5" component="h1" color="white">
            Select Category
          </Typography>
          <Typography variant="body2" color="white">
            Select the product category to add relevant details of the products
          </Typography>
        </Stack>
        <IconButton onClick={handleClose} size="large">
          <CancelIcon sx={{ color: "white", fontSize: "2.5rem" }} />
        </IconButton>
      </Stack>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Stack direction="row" p={4} gap={2}>
              <Box width="100%">
                <Autocomplete
                  name="category"
                  options={categories}
                  value={formik.values.category}
                  onChange={(_, category) => {
                    formik.setFieldValue("category", category);
                  }}
                  getOptionLabel={(option) => option.name}
                  onBlur={formik.handleBlur}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <MUIStyledTextField
                      fullWidth
                      autoFocus
                      {...params}
                      label="Select Category"
                      error={Boolean(
                        formik.touched.category && formik.errors.category
                      )}
                      helperText={
                        formik.touched.category && !!formik.errors.category
                          ? formik.errors.category
                          : "Select the category to add product"
                      }
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                />
              </Box>
              <Button
                type="submit"
                size="large"
                variant="outlined"
                sx={{
                  height: "3.5rem",
                  minWidth: 250,
                }}
              >
                Continue
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

const validationSchema = Yup.object({
  category: Yup.object().required("Required*"),
});

export default memo(SelectProductCategoryDialog);
