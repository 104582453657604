import { initializeApp } from "firebase/app";

export const firebaseConfig = {
  apiKey: "AIzaSyA82BoPkG_AsATMEtwkq5Aeh1SYG27bLNU",
  authDomain: "yalabid-auth.firebaseapp.com",
  projectId: "yalabid-auth",
  storageBucket: "yalabid-auth.appspot.com",
  messagingSenderId: "1016487338255",
  appId: "1:1016487338255:web:7e6c4e6019b597f6bede7a",
  measurementId: "G-XG50RVR5FE",
};

export const firebaseApp = initializeApp(firebaseConfig);
