import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import ImagePlaceholder from "assets/icons/ProductAddImagePlaceholder.svg";
import PropTypes from "prop-types";
import { memo, useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import StyledMuiCard from "utils/helpers/StyledMuiCard";

function VideoThumbnail({ formik }) {
  const thumbnailInputRef = useRef(null);
  const loading = useSelector((state) => state.videos.loading);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const [thumbnail, setThumbnail] = useState("");

  const videoThumbnailHandler = useCallback(
    (e) => {
      setThumbnail(URL.createObjectURL(e.target.files[0]));
      formik.setFieldValue("previewThumbnail", e.target.files[0]);
      e.target.value = "";
    },
    [formik]
  );

  return (
    <StyledMuiCard
      title="Basic Information"
      subTitle="Upload thumbnail relevant to the video"
    >
      <CardContent>
        <Stack direction="column" gap={2}>
          <Typography
            variant="h3"
            fontWeight="bold"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Thumbnail
          </Typography>
          <Box display="flex" gap={4}>
            <Box>
              <img
                src={ImagePlaceholder}
                alt="Category Main Media"
                height={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                width={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                style={{ cursor: "pointer", objectFit: "contain" }}
                onClick={() => {
                  thumbnailInputRef.current.click();
                }}
              />
              <input
                type="file"
                ref={thumbnailInputRef}
                style={{ display: "none" }}
                id="update-video-thumbnail"
                hidden
                accept="image/x-png,image/png,image/jpeg,image/jpg"
                onChange={videoThumbnailHandler}
              />
              {Boolean(formik.errors.videoThumbnail) && (
                <Typography color="#ef5350">
                  {formik.errors.videoThumbnail}*
                </Typography>
              )}
            </Box>
            <Box>
              <img
                src={thumbnail ? thumbnail : formik.values.videoThumbnail}
                alt="Video Thumbnail"
                height={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                width={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                style={{ objectFit: "contain" }}
              />
            </Box>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
            disabled={loading}
            sx={{
              fontWeight: "bold",
              width: 100,
              height: 40,
            }}
          >
            {loading ? "Updating" : "Update"}
          </Button>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

VideoThumbnail.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(VideoThumbnail);
