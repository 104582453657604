import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import { memo, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";
import AuctionProductsDataGrid from "./AuctionProductsDataGrid";

function DeleteAuctionProducts() {
  const params = useParams();
  const [error, setError] = useState("");

  const initialValues = useMemo(
    () => ({
      selectedProducts: [],
    }),
    []
  );

  const handleSubmit = useCallback(
    (values) => {
      let deleteProducts = values.selectedProducts.map((product) => {
        return product?.id || product?._id;
      });

      if (deleteProducts.length === 0) {
        setError("Please select a product");
      } else if (params?.id && deleteProducts.length > 0) {
        setError(" ");
        toast.success("Bulk operation not implemented yet");
        // dispatch(updateAuctions({ deleteProducts, auctionId: params.id }))
        // .unwrap()
        // .then(() => {
        //   dispatch(resetAuctionState());
        //   navigate("/products/collections");
        // });
      }
    },
    [params?.id]
  );

  return (
    <Box pt={2}>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="h4"
                fontWeight="600"
                lineHeight={1}
                color="primary.main"
                sx={{
                  fontSize: {
                    md: "1.5rem",
                    lg: "2rem",
                    xxl: "2.625rem",
                    margin: "2rem 0 1rem",
                  },
                }}
              >
                Products in Auctions
              </Typography>
              {/* <Stack direction="row" gap={2}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  onClick={formik.handleSubmit}
                  disableElevation
                  sx={{
                    minWidth: { md: 90, xl: 150 },
                    height: { xs: 40, xl: 45 },
                  }}
                >
                  Remove Products
                </Button>
              </Stack> */}
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <Stack direction="row" gap={3} py={{ xs: 1, md: 1.5 }}>
                  <Grid item xs={12} lg={12}>
                    <AuctionProductsDataGrid formik={formik} />
                  </Grid>
                </Stack>
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Typography color="red">{error}</Typography>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(DeleteAuctionProducts);

const validationSchema = Yup.object().shape({
  selectedProducts: Yup.array().required("Required"),
});
