import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MUIStyledTextField from "components/MUIStyledComponents/MUIStyledTextField";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getChassisDetails } from "services/Product";
import { PRODUCT_VEHICLE_TYPES } from "utils/constants/product";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import convertToTitleCase from "utils/helpers/convertToTitleCase";
import { getErrorMessage, getResponseData } from "utils/helpers/httpRequests";
import isValidNumber from "utils/helpers/isValidNumber";
import splitCamelCase from "utils/helpers/splitCamelCase";
import { toast } from "utils/hooks/useToast";

function BasicInformation({ formik }) {
  const categories = useSelector(
    (state) => state.filters.productFlattenedCategories
  );
  const specifications = useSelector(
    (state) => state.filters.category?.specifications ?? []
  );
  const [isFetching, setIsFetching] = useState(false);

  const handleCategoryChange = useCallback(
    (_, category) => {
      if (category === null) {
        formik.setFieldValue("category", "");
        formik.setFieldValue("brandId", "");
      } else {
        formik.setFieldValue("category", category.value);
        formik.setFieldValue("brandId", category.categoryTree[0]);
      }
    },
    [formik]
  );

  const categoryValue = useMemo(
    () =>
      categories.find(
        (category) => category.value === formik.values.category
      ) ?? null,
    [categories, formik.values.category]
  );

  const handleVin = () => {
    setIsFetching(true);
    getChassisDetails(formik.values.vin)
      .then((res) => {
        const response = getResponseData(res);
        const specs = [
          "vin",
          "make",
          "model",
          "engine",
          "bodyStyle",
          "year",
          "transmission",
          "country",
        ];
        const values = {};
        const errors = {};
        specs.forEach((spec) => {
          if (Object.prototype.hasOwnProperty.call(formik.values, spec)) {
            formik.setFieldValue(spec, response?.[spec] ?? "");
            values[spec] = response?.[spec] ?? "";
          }
        });
        formik.setValues((prev) => ({
          ...prev,
          ...values,
        }));
        formik.setErrors((prev) => ({
          ...prev,
          ...errors,
        }));
      })
      .catch((err) => {
        const error = getErrorMessage(err);
        toast.error(error);
      })
      .finally(() => {
        setIsFetching(() => false);
      });
  };

  return (
    <StyledMuiCard
      title="Basic Information"
      subTitle="Please enter the basic information of the product"
    >
      <CardContent>
        <Stack direction="column" gap={2} px={2}>
          {Object.prototype.hasOwnProperty.call(formik.values, "vin") && (
            <Stack direction="column" gap={1}>
              <Typography variant="h4" color="text.secondary" fontWeight={600}>
                Chassis Number
              </Typography>
              <Stack direction="row" gap={2}>
                <Box width="100%">
                  <MUIStyledTextField
                    fullWidth
                    {...formik.getFieldProps("vin")}
                    error={Boolean(formik.touched.vin && formik.errors.vin)}
                    helperText={
                      formik.errors.vin ??
                      "Please enter the chassis number and press continue button get details of the vehicle"
                    }
                  />
                </Box>
                <Button
                  size="large"
                  variant="outlined"
                  onClick={handleVin}
                  disabled={Boolean(
                    isFetching || formik.errors?.vin || !formik.values.vin
                  )}
                  sx={{
                    height: "3.5rem",
                    minWidth: 250,
                  }}
                >
                  {isFetching ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    "Continue"
                  )}
                </Button>
              </Stack>
            </Stack>
          )}

          <Stack direction="column" gap={1}>
            <Typography variant="h4" color="text.secondary" fontWeight={600}>
              Name
            </Typography>
            <MUIStyledTextField
              fullWidth
              {...formik.getFieldProps("productName")}
              error={Boolean(
                formik.touched.productName && formik.errors.productName
              )}
              helperText={
                formik.errors.productName
                  ? formik.errors.productName
                  : "Enter a descriptive name to help customer find your product"
              }
            />
          </Stack>

          <Stack direction="column" gap={1}>
            <Typography variant="h4" color="text.secondary" fontWeight={600}>
              Category
            </Typography>
            <Autocomplete
              options={categories}
              value={categoryValue}
              onChange={handleCategoryChange}
              getOptionLabel={(option) => option.label}
              onBlur={formik.handleBlur}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <MUIStyledTextField
                  fullWidth
                  {...params}
                  name="category"
                  error={Boolean(
                    formik.touched.category && formik.errors.category
                  )}
                  helperText={
                    formik.touched.category && !!formik.errors.category
                      ? formik.errors.category
                      : "Select the product category"
                  }
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
            />
          </Stack>

          <Grid container spacing={2}>
            {specifications.map((specification) => {
              if (specification?.name === "vin") return null;
              return (
                <Grid item xs={12} md={6} lg={4} key={specification._id}>
                  <Stack direction="column" gap={1} key={specification._id}>
                    <Typography
                      variant="h4"
                      color="text.secondary"
                      fontWeight={600}
                      textTransform="capitalize"
                    >
                      {splitCamelCase(specification?.name ?? "")}
                    </Typography>
                    {specification?.name === "bodyType" ? (
                      <Autocomplete
                        options={Object.values(PRODUCT_VEHICLE_TYPES)}
                        value={formik.values[specification?.name] ?? ""}
                        onChange={(_, value) =>
                          formik.setFieldValue(specification?.name, value ?? "")
                        }
                        getOptionLabel={(option) => option}
                        onBlur={formik.handleBlur}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        renderInput={(params) => (
                          <MUIStyledTextField
                            fullWidth
                            {...params}
                            name="bodyType"
                            error={Boolean(
                              formik.touched[specification?.name] &&
                                formik.errors[specification?.name]
                            )}
                            helperText={
                              formik.touched[specification?.name] &&
                              formik.errors[specification?.name]
                                ? formik.errors[specification?.name]
                                : "Enter the field value"
                            }
                          />
                        )}
                        renderOption={(props, option) => (
                          <li
                            {...props}
                            key={option}
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {option}
                          </li>
                        )}
                        sx={{
                          "& .MuiAutocomplete-input": {
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    ) : (
                      <MUIStyledTextField
                        fullWidth
                        type={specification?.dataType}
                        name={specification?.name}
                        value={formik.values[specification?.name] ?? ""}
                        onChange={(e) => {
                          if (specification?.name === "year")
                            return (
                              isValidNumber(e.target.value) &&
                              formik.setFieldValue(
                                specification?.name,
                                e.target.value
                              )
                            );
                          if (specification?.name === "country")
                            return formik.setFieldValue(
                              specification?.name,
                              convertToTitleCase(e.target.value)
                            );
                          formik.setFieldValue(
                            specification?.name,
                            e.target.value
                          );
                        }}
                        onBlur={formik.handleBlur}
                        error={Boolean(
                          formik.touched[specification?.name] &&
                            formik.errors[specification?.name]
                        )}
                        helperText={
                          formik.touched[specification?.name] &&
                          formik.errors[specification?.name]
                            ? formik.errors[specification?.name]
                            : "Enter the field value"
                        }
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                              display: "none",
                            },
                        }}
                      />
                    )}
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

BasicInformation.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(BasicInformation);
