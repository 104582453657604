import Typography from "@mui/material/Typography";
import { memo } from "react";
import ProductList from "./ProductList";

function AddAuctionProducts() {
  return (
    <>
      <Typography
        variant="h4"
        fontWeight="600"
        sx={{
          fontSize: {
            md: "1.5rem",
            lg: "2rem",
            xxl: "2.625rem",
            margin: "2rem 0 1rem",
          },
        }}
        color="#ed4928"
        lineHeight={1}
      >
        Add Auction Products
      </Typography>
      <ProductList />
    </>
  );
}

export default memo(AddAuctionProducts);
