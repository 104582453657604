import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import productPlaceholder from "assets/images/imagePlaceholder.png";
import dayjs from "dayjs";
import parse from "html-react-parser";
import PageHeader from "layout/PageHeader";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuctions, resetState } from "store/slices/liveStreamingSlice";
import { STATS_PRIMARY_ORANGE } from "styles/colors";
import parseEditorValue from "utils/helpers/parseEditorValue";
import statusBadges from "utils/helpers/statusBadge";

import IconButton from "@mui/material/IconButton";
import StyledGrid from "components/StyledGrid";

let localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

function LiveStreaming() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const loading = useSelector((state) => state.liveStreaming.loading.auctions);
  const { results, totalPages } = useSelector(
    (state) => state.liveStreaming.auctions
  );

  useEffect(() => {
    const id = setTimeout(() => {
      dispatch(getAuctions());
    }, 100);
    return () => {
      clearTimeout(id);
      dispatch(resetState());
    };
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 60,
        flex: 0.2,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        sortable: false,
      },
      {
        field: "image",
        headerName: "Image",
        headerAlign: "center",
        align: "center",
        minWidth: 120,
        flex: 0.25,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => (
          <Box>
            <img
              src={
                params?.row?.auctionImage
                  ? params?.row?.auctionImage
                  : productPlaceholder
              }
              height={
                isTablet
                  ? "70px"
                  : isLaptop
                  ? "80px"
                  : isLed
                  ? "100px"
                  : "113px"
              }
              width={
                isTablet
                  ? "70px"
                  : isLaptop
                  ? "80px"
                  : isLed
                  ? "100px"
                  : "113px"
              }
              style={{
                borderRadius: "10px",
                marginTop: 6,
                objectFit: "contain",
              }}
              alt="auction_image"
            />
          </Box>
        ),
      },
      {
        field: "title",
        headerName: "Name/Details",
        minWidth: 300,
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => (
          <Box pr={2}>
            <Typography
              sx={{
                textTransform: "capitalize",
                color: "#000000",
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "-webkit-box !important",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                whiteSpace: "normal",
              }}
            >
              {params?.row?.title}
            </Typography>
            <Box display="flex">
              <Typography color={STATS_PRIMARY_ORANGE} mr={1}>
                Auction ID #
              </Typography>
              <Typography>{params?.row?.lotNumber ?? "N/A"}</Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "description",
        headerName: "Description",
        minWidth: 300,
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <Typography variant="h6" pl={1.3}>
            Description
          </Typography>
        ),
        renderCell: (params) => (
          <Box pl={2}>{parse(parseEditorValue(params?.row?.description))}</Box>
        ),
      },
      {
        field: "productCount",
        headerName: "Vehicles",
        minWidth: 150,
        flex: 0.5,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        sortable: true,
      },
      {
        field: "startDate",
        headerName: "Date",
        minWidth: 200,
        flex: 0.5,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        renderCell: (params) => (
          <Typography>
            {dayjs(params?.row?.startDate).format("DD/MM/YY [At] h:mm A")}
          </Typography>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        type: "actions",
        minWidth: 150,
        flex: 0.5,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Chip
            sx={{
              color: statusBadges(params?.row?.auctionStatus).color,
              bgcolor: statusBadges(params?.row?.auctionStatus).bgColor,
              borderRadius: "5px",
              height: "26px",
              textTransform: "capitalize",
            }}
            variant="contained"
            label={params?.row?.auctionStatus}
          />
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        align: "center",
        minWidth: 150,
        flex: 0.25,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <Typography variant="h6" pr={2}>
            Actions
          </Typography>
        ),
        renderCell: (params) => (
          <Box display="flex" sx={actionStyles}>
            <Tooltip title="View Live Auction" arrow placement="top">
              <IconButton
                variant="text"
                className="button-styles"
                onClick={() => navigate(`${params?.id}`)}
              >
                <VisibilityIcon
                  color={
                    params?.row?.auctionStatus === "active" ? "primary" : ""
                  }
                />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [isTablet, isLaptop, isLed, navigate]
  );

  return (
    <Stack direction="column" pt={3} px={2} gap={2}>
      <PageHeader title="Live Streaming" />
      <StyledGrid
        rows={results}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
    </Stack>
  );
}

export default LiveStreaming;

const actionStyles = {
  pr: 1,
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "25px", width: "25px" },
};
