import { httpPages } from "utils/httpRequest/http";

export const uploadPageImage = (pageId = "", image = null) => {
  const formData = new FormData();
  formData.append("cmsImage", image);
  return httpPages.post(`/cms-pages/${pageId}`, formData);
};

export const removePageImage = (pageId = "", imageUrl = null) => {
  const formData = new FormData();
  formData.append("removeImage", imageUrl);
  return httpPages.post(`/cms-pages/${pageId}`, formData);
};

export const updatePageStatus = (pageId = "", status = false) => {
  return httpPages.patch(`/cms-pages/${pageId}`, { active: status });
};

export const updateSubscriptionStatus = (pageId = "", status = false) => {
  return httpPages.patch(`/subscriptions/${pageId}`, { active: status });
};
