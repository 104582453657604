import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import placeholderImg from "assets/images/imagePlaceholder.png";
import DataGridSearch from "components/DataGridSearch";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledGrid from "components/StyledGrid";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteCategory,
  getCategories,
  getSubCategories,
} from "store/slices/categoriesSlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import useQueryParams from "utils/hooks/useQueryParams";
import PlusIcon from "../../assets/icons/PlusIcon.png";
import WritingIcon from "../../assets/icons/WritingIcon.svg";
import deleteIcon from "../../assets/icons/deleteIcon.svg";
import eyeIcon from "../../assets/icons/eyeIcon.svg";

function CategoryDataGrid() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const { results, loading, totalPages } = useSelector(
    (state) => state.categories
  );

  const viewSubCategories = useCallback(
    (id) => {
      navigate(id);
    },
    [navigate]
  );

  const addSubCategory = useCallback(
    (id) => {
      navigate(`/categories/${id}/subcategory`);
    },
    [navigate]
  );
  const updateCategory = useCallback(
    (id) => {
      navigate(`/categories/${id}/update`);
    },
    [navigate]
  );

  const handleClickOpen = useCallback(
    (categoryId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(categoryId));
    },
    [dispatch]
  );

  useEffect(() => {
    const id = setTimeout(() => {
      if (!Object.entries(params).length) {
        dispatch(getCategories(queryParams));
      } else {
        let id = "";
        if (params.lvl2 || params.lvl3 || params.lvl4) {
          id = params.lvl4 ?? params.lvl3 ?? params.lvl2;
        }
        if (id) {
          dispatch(getSubCategories(id));
        }
      }
    }, 100);
    return () => clearTimeout(id);
  }, [dispatch, params, queryParams]);

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        headerAlign: "center",
        align: "center",
        minWidth: 50,
        flex: 0.1,
        filterable: false,
        disableColumnMenu: true,
        renderHeader: () => (
          <Typography variant="h6" textAlign="center">
            Sr
          </Typography>
        ),
        renderCell: (params) => (
          <Typography>{params.row.serialNumber}</Typography>
        ),
      },
      {
        field: "image",
        headerName: "Image",
        headerAlign: "center",
        align: "center",
        minWidth: 150,
        maxWidth: 175,
        flex: 0.25,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => (
          <img
            src={params.row.mainImage ? params.row.mainImage : placeholderImg}
            height={
              isTablet
                ? "80px"
                : isLaptop
                ? "90px"
                : isDesktop
                ? "95px"
                : "113px"
            }
            width={
              isTablet
                ? "80px"
                : isLaptop
                ? "90px"
                : isDesktop
                ? "95px"
                : "113px"
            }
            style={{
              borderRadius: "10px",
              marginTop: 4,
              objectFit: "contain",
            }}
            alt={params.row.name}
          />
        ),
      },
      {
        field: "name",
        headerName: "Name",
        minWidth: 150,
        flex: 2,
        renderHeader: () => (
          <Typography variant="h6" pl={2}>
            Name
          </Typography>
        ),
        renderCell: (params) => (
          <Typography variant="body1" pl={2}>
            {params.row.name}
          </Typography>
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 175,
        maxWidth: 300,
        headerAlign: "right",
        align: "right",
        flex: 0.25,
        renderHeader: () => (
          <Typography variant="h6" pr={2}>
            Actions
          </Typography>
        ),
        renderCell: (params) => (
          <Box display="flex" sx={actionStyles}>
            {params.row.subCategories.length > 0 && (
              <Tooltip title="View Sub Category">
                <Button
                  variant="text"
                  className="button-styles"
                  onClick={() => viewSubCategories(params.id)}
                >
                  <img src={eyeIcon} alt="view" className="icon-size" />
                </Button>
              </Tooltip>
            )}
            <Tooltip title="Update">
              <Button
                variant="text"
                className="button-styles"
                onClick={() => updateCategory(params.id)}
              >
                <img src={WritingIcon} alt="delete" className="icon-size" />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                variant="text"
                sx={{
                  minWidth: "20px",
                }}
                onClick={() => handleClickOpen(params?.id)}
              >
                <img src={deleteIcon} alt="edit" className="icon-size" />
              </Button>
            </Tooltip>
            <Tooltip title="Add Sub Category">
              <Button
                variant="text"
                className="button-styles"
                sx={{
                  borderRadius: "50%",
                }}
                onClick={() => addSubCategory(params.id)}
              >
                <img src={PlusIcon} alt="view" className="icon-size" />
              </Button>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [
      isTablet,
      isLaptop,
      isDesktop,
      viewSubCategories,
      updateCategory,
      handleClickOpen,
      addSubCategory,
    ]
  );

  return (
    <Stack direction="column" spacing={1}>
      {/* TODO: Add search functionality */}
      <DataGridSearch
        searchKeyParam="name"
        searchKeyParamValue="name"
        searchQueryParam="value"
      />
      <StyledGrid
        columns={columns}
        loading={loading}
        rows={results}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteCategory} />
    </Stack>
  );
}

export default memo(CategoryDataGrid);

const actionStyles = {
  pr: 1,
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "25px", width: "25px" },
};
