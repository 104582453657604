import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";

function AddCategoryActionButton() {
  const params = useParams();
  const navigate = useNavigate();

  const id = params?.lvl4 ?? params?.lvl3 ?? params?.lvl2 ?? "";

  return (
    <Box py={1} sx={containerStyles}>
      <Button
        startIcon={<AddIcon />}
        size="large"
        variant="contained"
        onClick={() =>
          navigate(id ? `/categories/${id}/subCategory` : `/categories/create`)
        }
      >
        Add New {id && "Sub Category"}
      </Button>
    </Box>
  );
}

export default AddCategoryActionButton;

const containerStyles = {
  display: "flex",
};
