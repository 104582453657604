import queryString from "query-string";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import convertStringNumbersToNumbers from "utils/helpers/converStringNumbersToNumbers";

const useQueryParams = () => {
  const { search } = useLocation();
  const params = useMemo(() => {
    const parsedParams = queryString.parse(search);
    convertStringNumbersToNumbers(parsedParams);
    return parsedParams;
  }, [search]);
  return params;
};
export default useQueryParams;
