import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import DataGridSearch from "components/DataGridSearch";
import queryString from "query-string";
import { memo, useCallback, useLayoutEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

function ProductStatusesButtons() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const [value, setValue] = useState("ALL_PRODUCTS");

  useLayoutEffect(() => {
    if (query.status) {
      setValue(query?.status && query.status);
    }
    if (query.featured) {
      setValue(query.featured === "true" && "featured");
    }
  }, [query.status, query.feature, query.featured]);

  const handleChange = useCallback(
    (_, value) => {
      setValue(value);
      // Necessary for performance
      // eslint-disable-next-line no-unused-vars
      const { status, featured, ...rest } = query;
      let params = { ...rest };
      switch (value) {
        case "active":
          params.status = "active";
          break;
        case "draft":
          params.status = "draft";
          break;
        case "sold":
          params.status = "sold";
          break;
        case "auction":
          params.status = "auction";
          break;
        case "canceled":
          params.status = "canceled";
          break;
        case "featured":
          params.featured = "true";
          break;
        default:
          break;
      }
      navigate({
        pathname: "/products",
        search: createSearchParams(params).toString(),
      });
    },
    [navigate, query]
  );

  return (
    <Stack
      pt={1}
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      gap={3}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Product status filters"
        sx={{
          "& .MuiTabs-flexContainer": {
            flexWrap: "wrap",
          },
          "& .MuiTab-root": { textTransform: "capitalize", bgcolor: "white" },
        }}
      >
        <Tab label="All Products" value="ALL_PRODUCTS" />
        <Tab label="Active" value="active" />
        <Tab label="Draft" value="draft" />
        <Tab label="Auction" value="auction" />
        <Tab label="Featured" value="featured" />
        <Tab label="Sold" value="sold" />
      </Tabs>
      <Box textAlign="right">
        <DataGridSearch
          searchKeyParam="name"
          searchKeyParamValue="productName"
          searchQueryParam="value"
        />
      </Box>
    </Stack>
  );
}

export default memo(ProductStatusesButtons);
