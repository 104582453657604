import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/httpRequests";
import { toast } from "utils/hooks/useToast";
import { httpPages } from "utils/httpRequest/http";

export const getServices = createAsyncThunk(
  "services/getServices",
  async (params = {}, { rejectWithValue }) => {
    try {
      const response = await httpPages.get("/services/admin", {
        params: {
          ...params,
          page: params.page ?? 1,
          limit: params.limit ?? 20,
        },
      });
      const responseData = getResponseData(response);

      responseData &&
        responseData?.results.forEach((item, index) => {
          const pageStart = (responseData?.page - 1) * responseData?.limit;
          item.serialNumber = pageStart + index + 1;
        });

      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getService = createAsyncThunk(
  "services/getService",
  async (id, { getState, fulfillWithValue, rejectWithValue }) => {
    const results = getState().pages.results;
    const service = results.find((data) => data.id === id);
    if (service) {
      return fulfillWithValue(service);
    }
    try {
      const response = await httpPages.get(`/services/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteService = createAsyncThunk(
  "services/deleteService",
  async (id = "", { getState, rejectWithValue }) => {
    const results = getState().services.results;
    try {
      const response = await httpPages.delete(`/services/${id}`);
      if (response.status === 200) {
        const returnedData = results.filter((data) => data.id !== id);
        toast.success("Service Deleted Successfully");
        return returnedData;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const CreateService = createAsyncThunk(
  "services/CreateService",
  async (data = {}, { rejectWithValue }) => {
    try {
      const response = await httpPages.post(`/services`, data);
      const responseData = getResponseData(response);
      toast.success("Service Created Successfully");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateService = createAsyncThunk(
  "services/updateService",
  async (data = {}, { rejectWithValue }) => {
    try {
      const { id = "", payload = {} } = data;
      const response = await httpPages.patch(`/services/${id}`, payload);
      return response?.data;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: {
    services: true,
    service: true,
  },
  service: null,
  results: [],
  page: 1,
  limit: 20,
  totalPages: 0,
  totalResults: 0,
  error: {
    services: "",
    service: "",
  },
};

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    // Actions
    resetServicesState: () => initialState,
    resetServiceState: (state) => {
      state.loading.service = true;
      state.service = null;
      state.error.service = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServices.pending, (state) => {
        state.loading.services = true;
        state.error.services = "";
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.loading.services = false;
        state.page = action.payload?.page;
        state.limit = action.payload?.limit;
        state.results = action.payload?.results;
        state.totalPages = action.payload?.totalPages;
        state.totalResults = action.payload?.totalResults;
      })
      .addCase(getServices.rejected, (state, action) => {
        state.loading.services = false;
        state.error.services = action.payload;
      })
      .addCase(getService.pending, (state) => {
        state.loading.service = true;
        state.error.service = "";
      })
      .addCase(getService.fulfilled, (state, action) => {
        state.loading.service = false;
        state.service = action.payload;
      })
      .addCase(getService.rejected, (state, action) => {
        state.loading.service = false;
        state.error.service = action.payload;
      })
      .addCase(deleteService.pending, (state) => {
        state.loading.services = true;
        state.error.services = "";
      })
      .addCase(deleteService.fulfilled, (state, action) => {
        state.loading.services = false;
        state.results = action.payload;
        state.error.services = "";
      })
      .addCase(deleteService.rejected, (state, action) => {
        state.loading.services = false;
        state.error.service = action.payload;
      })
      .addCase(CreateService.pending, (state) => {
        state.loading.service = true;
        state.error.service = "";
      })
      .addCase(CreateService.fulfilled, (state, action) => {
        state.loading.service = false;
        state.page = action.payload;
      })
      .addCase(CreateService.rejected, (state, action) => {
        state.loading.service = false;
        state.error.service = action.payload;
      })
      .addCase(updateService.pending, (state) => {
        state.loading.service = true;
        state.error.service = "";
      })
      .addCase(updateService.fulfilled, (state, action) => {
        state.loading.service = false;
        state.service = action.payload;
      })
      .addCase(updateService.rejected, (state, action) => {
        state.loading.service = false;
        state.error.service = action.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetServicesState, resetServiceState } = servicesSlice.actions;

// Exporting default reducer
export default servicesSlice.reducer;
