import dayjs from "dayjs";
import { refreshUserTokens } from "store/slices/authSlice";
import { setSessionExpiryDialogOpen } from "store/slices/uiSlice";
import { getErrorData } from "utils/helpers/httpRequests";

const interceptorConfiguration = async (instance) => {
  instance.interceptors.request.use(
    (config) => {
      if (
        localStorage.getItem("token") &&
        localStorage.getItem("refresh-token") !== "undefined"
      ) {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
          "token"
        )}`;
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const store = (await import("store/configureStore"))?.store;
      //Do something with response error
      const config = error?.config;
      const errorData = getErrorData(error);
      if (!config?.sent && ["401", 401, "403", 403].includes(errorData?.code)) {
        const auth = store.getState()?.auth;

        // Check if the user is logged in
        if (auth?.isLoggedIn) {
          const tokens = auth?.data?.tokens;

          const accessTokenExpiry = dayjs(
            tokens?.access?.expires ?? ""
          ).subtract(5, "minutes");

          const refreshTokenExpiry = dayjs(
            tokens?.refresh?.expires ?? ""
          ).subtract(5, "minutes");

          const isAccessTokenExpired = dayjs().isAfter(accessTokenExpiry);
          const isRefreshTokenExpired = dayjs().isAfter(refreshTokenExpiry);

          if (isRefreshTokenExpired) {
            // If the refresh token is expired, open session expiry dialog
            store?.dispatch(setSessionExpiryDialogOpen(true));
          } else if (isAccessTokenExpired) {
            // If the access token is expired, refresh it
            try {
              config.sent = true;
              const responseData = await store
                ?.dispatch(refreshUserTokens())
                .unwrap();
              const token = responseData?.access?.token;
              if (token) {
                config.headers = {
                  ...config.headers,
                  Authorization: `Bearer ${token}`,
                };
              }
              return instance(config);
            } catch (e) {
              return Promise.reject(error);
            }
          }
        }
      }
      return Promise.reject(error);
    }
  );
};

export default interceptorConfiguration;
