import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import productPlaceholder from "assets/images/imagePlaceholder.png";
import CurrencyFormatter from "components/CurrencyFormatter";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledGrid from "components/StyledGrid";
import StyledSwitch from "components/ToggleButton";
import dayjs from "dayjs";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addFeaturedProduct,
  deleteProduct,
  getProducts,
  removeFeaturedProduct,
} from "store/slices/productsSlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import statusBadges from "utils/helpers/statusBadge";
import useQueryParams from "utils/hooks/useQueryParams";
import WritingIcon from "../../assets/icons/WritingIcon.svg";
import deleteIcon from "../../assets/icons/deleteIcon.svg";
let localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

function ProductDataGrid() {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const navigate = useNavigate();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const { results, loading, totalPages } = useSelector(
    (state) => state.products
  );

  const fetchData = useCallback(() => {
    dispatch(getProducts(params));
  }, [dispatch, params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = useCallback(
    (row) => {
      if (row.featured === false) {
        dispatch(addFeaturedProduct(row?.id))
          .unwrap()
          .then(() => fetchData());
      } else {
        dispatch(removeFeaturedProduct(row?.id))
          .unwrap()
          .then(() => fetchData());
      }
    },
    [dispatch, fetchData]
  );

  const handleClickOpen = useCallback(
    (productId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(productId));
    },
    [dispatch]
  );

  const UpdateProductRow = useCallback(
    (productId) => {
      navigate(`/products/edit/${productId}`);
    },
    [navigate]
  );

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 60,
        flex: 0.1,
        headerAlign: "center",
        align: "center",
        filterable: false,
      },
      {
        field: "image",
        headerName: "Image",
        headerAlign: "center",
        align: "center",
        minWidth: 115,
        flex: 0.4,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => (
          <Box>
            <img
              src={
                params?.row?.mainImage
                  ? params?.row?.mainImage
                  : productPlaceholder
              }
              height={
                isTablet
                  ? "70px"
                  : isLaptop
                  ? "80px"
                  : isLed
                  ? "100px"
                  : "113px"
              }
              width={
                isTablet
                  ? "70px"
                  : isLaptop
                  ? "80px"
                  : isLed
                  ? "100px"
                  : "113px"
              }
              style={{
                borderRadius: "10px",
                marginTop: 6,
                objectFit: "contain",
              }}
              alt="product_image"
            />
          </Box>
        ),
      },
      {
        field: "productName",
        headerName: "Name/Details",
        minWidth: 300,
        flex: 1.25,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => (
          <Typography
            sx={{
              textTransform: "capitalize",
              color: "#000000",
              textOverflow: "ellipsis",
              overflow: "hidden",
              // Addition lines for 2 line or multiline ellipsis
              display: "-webkit-box !important",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              whiteSpace: "normal",
            }}
          >
            {params?.row?.productName}
          </Typography>
        ),
      },
      {
        field: "reservePrice",
        headerName: "Bid Value",
        minWidth: 175,
        flex: 0.4,
        renderCell: (params) => (
          <CurrencyFormatter
            number={
              params.row?.currentBid?.bidAmount ??
              params?.row?.reservePrice ??
              0
            }
          />
        ),
      },
      {
        field: "bidStart",
        headerName: "Time & Date",
        minWidth: 175,
        flex: 0.5,
        editable: true,
        renderCell: (params) => (
          <Typography>
            {dayjs(params?.row?.bidStart).format("DD/MM/YY [At] h:mm A")}
          </Typography>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        minWidth: 125,
        flex: 0.3,
        headerAlign: "center",
        align: "center",
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Chip
            sx={{
              color: statusBadges(params?.row?.status).color,
              bgcolor: statusBadges(params?.row?.status).bgColor,
              borderRadius: "5px",
              height: "26px",
              textTransform: "capitalize",
            }}
            variant="contained"
            label={params?.row?.status}
          />
        ),
      },
      {
        field: "featured",
        headerName: "Featured",
        headerAlign: "center",
        align: "center",
        minWidth: 125,
        flex: 0.25,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => (
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <StyledSwitch
                checked={params?.row?.featured}
                onChange={() => handleChange(params.row)}
              />
            }
          />
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "right",
        align: "right",
        minWidth: 175,
        flex: 0.25,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <Typography variant="h6" pr={2}>
            Actions
          </Typography>
        ),
        renderCell: (params) => (
          <Box display="flex" sx={actionStyles}>
            <Tooltip title="Edit product" arrow placement="top">
              <Button
                variant="text"
                className="button-styles"
                onClick={() => UpdateProductRow(params.id)}
              >
                <img src={WritingIcon} alt="edit" className="icon-size" />
              </Button>
            </Tooltip>
            <Tooltip title="Delete product" arrow placement="top">
              <Button
                variant="text"
                sx={{
                  minWidth: "20px",
                }}
                onClick={() => handleClickOpen(params?.id)}
              >
                <img src={deleteIcon} alt="edit" className="icon-size" />
              </Button>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [isTablet, isLaptop, isLed, handleChange, UpdateProductRow, handleClickOpen]
  );
  return (
    <>
      <StyledGrid
        rows={results}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteProduct} />
    </>
  );
}

export default memo(ProductDataGrid);

const actionStyles = {
  pr: 1,
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "22px", width: "22px" },
  "& .product-price": { fontSize: "1rem" },
};
