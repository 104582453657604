import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import USER_ROLE from "utils/constants/userRole";
import { getErrorMessage, getResponseData } from "utils/helpers/httpRequests";
import { toast } from "utils/hooks/useToast";
import { httpAuth } from "utils/httpRequest/http";

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().users;
    try {
      const response = await httpAuth.get(`/users/admin`, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          ...data,
        },
      });
      const responseData = getResponseData(response);

      responseData &&
        responseData.result &&
        responseData?.results.forEach((item, index) => {
          const pageStart = (responseData?.page - 1) * responseData?.limit;
          item.serialNumber = pageStart + index + 1;
        });

      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getAdminUsers = createAsyncThunk(
  "users/getAdminUsers",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().users;
    try {
      const response = await httpAuth.get(`/users`, {
        params: {
          ...data,
          page: data.page ?? page,
          limit: data.limit ?? limit,
          role: USER_ROLE.ADMIN,
        },
      });
      const responseData = getResponseData(response);
      responseData &&
        responseData?.results.forEach((item, index) => {
          const pageStart = (responseData?.page - 1) * responseData?.limit;
          item.serialNumber = pageStart + index + 1;
        });

      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getUser = createAsyncThunk(
  "users/getUser",
  async (id, { rejectWithValue }) => {
    // const { results } = getState().users;
    // const user = results.find((user) => user.id === id);
    // if (user) return fulfillWithValue(user);
    try {
      const response = await httpAuth.get(`/users/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async (data = {}, { rejectWithValue }) => {
    try {
      const response = await httpAuth.post(`/register`, data);
      toast.success("User created successfully");
      return getResponseData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (values = {}, { rejectWithValue }) => {
    const { id, updatedValues } = values;
    try {
      const response = await httpAuth.patch(`/users/${id}`, updatedValues);
      toast.success("User updated successfully");
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  "users/updateUserPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await httpAuth.patch(`/users/admin`, data);
      toast.success("Password Updated successfully");
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (id, { getState, rejectWithValue }) => {
    try {
      let { results: users, page, limit } = getState().users;
      await httpAuth.delete(`/users/${id}`);
      toast.success("Deleted successfully");
      let _users = users.filter((user) => user.id !== id);
      if (_users) {
        _users = _users.map((item, index) => {
          const pageStart = (page - 1) * limit;
          return {
            ...item,
            serialNumber: pageStart + index + 1,
          };
        });
        return _users;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: true,
  results: [],
  user: null,
  page: 1,
  limit: 20,
  totalPages: 0,
  totalResults: 0,
  error: "",
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetState: () => initialState,
    resetUserState: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.results = action.payload?.results;
        state.page = action.payload?.page;
        state.limit = action.payload?.limit;
        state.totalResults = action.payload?.totalResults;
        state.totalPages = action.payload?.totalPages;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getUser.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.loading = false;
        state.error = "";
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(updateUserPassword.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updateUserPassword.fulfilled, (state) => {
        state.loading = false;
        state.error = "";
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.results = action.payload;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getAdminUsers.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getAdminUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.results = action.payload?.results;
        state.page = action.payload?.page;
        state.limit = action.payload?.limit;
        state.totalResults = action.payload?.totalResults;
        state.totalPages = action.payload?.totalPages;
      })
      .addCase(getAdminUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState, resetUserState } = usersSlice.actions;

// Exporting default reducer
export default usersSlice.reducer;
