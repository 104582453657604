import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ThumbnailPlaceholder from "assets/images/ThumbnailPlaceholder.svg";
import PropTypes from "prop-types";
import { memo, useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setImageValidationError,
  setIsImageValidationDialogOpen,
} from "store/slices/uiSlice";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import validateImageAspectRatio from "utils/helpers/validateImageAspectRatio";

function VideoMediaSection({ formik }) {
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const thumbnailInputRef = useRef(null);
  const [video, setVideo] = useState(formik.values.video ?? []);
  const [thumbnail, setThumbnail] = useState(formik.values.thumbnail ?? []);

  const thumbnailHandler = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        // function params
        // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
        validateImageAspectRatio(image, 720, 1280, true, true, 9 / 16, "9 / 16")
          .then((img) => {
            formik.setFieldValue("thumbnail", img);
            setThumbnail(URL.createObjectURL(img));
            thumbnailInputRef.current.value = "";
          })
          .catch((error) => {
            thumbnailInputRef.current.value = "";
            dispatch(setIsImageValidationDialogOpen(true));
            dispatch(setImageValidationError(error.message));
          });
      }
    },
    [dispatch, formik]
  );

  const videoHandler = useCallback(
    (e) => {
      const file = e.target.files[0];
      if (file.size > 350 * 1024 * 1024) {
        formik.setErrors({
          video: "File size exceeds 350MB. Please select a smaller video.",
        });
        e.target.value = "";
        return;
      }
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);
      video.onloadedmetadata = () => {
        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;

        // Check if the video is in portrait orientation
        if (videoHeight < videoWidth) {
          formik.setErrors({
            video:
              "Video should be in portrait orientation. Please select a portrait video.",
          });
          e.target.value = "";
        } else {
          formik.setFieldValue("video", file);
          setVideo(URL.createObjectURL(file));
          e.target.value = "";
        }
      };
    },
    [formik]
  );

  return (
    <StyledMuiCard
      title="Media Section"
      subTitle="Upload image and video relevant to the Video"
    >
      <CardContent>
        <Box display="flex" flexWrap="wrap" gap={4}>
          {/* Category Thumbnail */}
          <Stack direction="column" gap={2}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Video Thumbnail
            </Typography>
            <Box>
              <img
                ref={thumbnailInputRef}
                src={formik.values.thumbnail ? thumbnail : ThumbnailPlaceholder}
                alt="Video Thumbnail"
                height="400px"
                width="280px"
                style={{ cursor: "pointer" }}
                onClick={() => thumbnailInputRef.current.click()}
              />
              <input
                type="file"
                style={{ display: "none" }}
                hidden
                ref={thumbnailInputRef}
                onChange={thumbnailHandler}
                accept="image/x-png,image/png,image/jpeg,image/jpg/*"
              />
              {Boolean(formik.errors.thumbnail) && (
                <Typography color="#ef5350">
                  {formik.errors.thumbnail}*
                </Typography>
              )}
            </Box>
          </Stack>

          {/* Category Video file */}
          <Stack direction="column" gap={2}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Video
            </Typography>
            <Box display="flex" flexDirection="column" gap={2}>
              <video
                ref={videoRef}
                controls
                src={formik.values.video && video}
                alt="video"
                height="400px"
                width="280px"
                style={{ cursor: "pointer" }}
                onClick={() => videoRef.current.click()}
              />
              <input
                type="file"
                style={{ display: "none" }}
                hidden
                ref={videoRef}
                accept="video/mp4,video/x-m4v,video/*"
                onChange={videoHandler}
              />
              {Boolean(formik.errors.video) && (
                <Typography color="#ef5350">{formik.errors.video}*</Typography>
              )}
            </Box>
          </Stack>
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
}

VideoMediaSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(VideoMediaSection);
