import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSessionExpiryDialogClose } from "store/slices/uiSlice";

function SessionExpiryDialog() {
  const isOpen = useSelector((state) => state.UI.isSessionExpiryDialogOpen);
  const dispatch = useDispatch();

  const handleClose = () => {
    localStorage.clear();
    window.location.href = "/login";
    dispatch(setSessionExpiryDialogClose());
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Login Session Expired</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your login session has expired. Please login again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} autoFocus>
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(SessionExpiryDialog);
