import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { RecaptchaVerifier, getAuth } from "firebase/auth";
import { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactCodeInput from "react-verification-code-input";
import { sendPhoneOTP, verifyPhoneOTP } from "services/authServices";
import { firebaseApp } from "utils/lib/FirebaseAppSettings";

const auth = getAuth(firebaseApp);

function PhoneVerification() {
  const phoneNumber = useSelector(
    (state) => state.auth?.data?.user?.phone ?? ""
  );
  const isPhoneOTPSent = useSelector(
    (state) => state.auth?.isPhoneOTPSent ?? false
  );
  const [phoneOTP, setPhoneOTP] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [time, setTime] = useState(30);
  const [error, setError] = useState("");

  const initializeRecaptchaVerifier = useCallback(() => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        },
        auth
      );
    }
  }, []);

  const sendOTP = useCallback(() => {
    sendPhoneOTP(auth, phoneNumber, setError, setIsSubmitting);
  }, [phoneNumber]);

  const resendOTP = useCallback(() => {
    initializeRecaptchaVerifier();
    setTime(30);
    setPhoneOTP("");
    setError("");
    sendOTP();
  }, [initializeRecaptchaVerifier, sendOTP]);

  const handlePhoneCodeVerification = useCallback(
    async (e) => {
      e.preventDefault();
      verifyPhoneOTP(phoneOTP, setError, setIsSubmitting);
    },
    [phoneOTP]
  );

  useEffect(() => {
    initializeRecaptchaVerifier();
  }, [initializeRecaptchaVerifier]);

  useEffect(() => {
    if (!isPhoneOTPSent) {
      const id = setTimeout(() => {
        sendOTP();
      }, 1000);
      return () => id && clearTimeout(id);
    }
  }, [isPhoneOTPSent, sendOTP]);

  useEffect(() => {
    let interval;
    if (time > 0) {
      interval = setInterval(() => {
        setTime((time) => time - 1);
      }, 1000);
    }
    return () => interval && clearInterval(interval);
  }, [time]);

  return (
    <>
      <Box id="recaptcha-container" sx={{ all: "unset !important" }} />
      <form onSubmit={handlePhoneCodeVerification}>
        <Stack direction="column" gap={0.5}>
          <Box>
            <ReactCodeInput
              type="number"
              onChange={setPhoneOTP}
              className="code-input"
            />
            <Typography
              variant="body1"
              color="text.secondary"
              fontSize={{ lg: "1.15rem" }}
            >
              {`Please enter the verification code sent on ${phoneNumber}`}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography color="text.secondary" fontSize={{ lg: "1.15rem" }}>
              {`Didn’t receive code?`}
            </Typography>
            <Button
              variant="text"
              onClick={time > 0 ? null : resendOTP}
              disabled={time > 0}
              sx={{
                fontWeight: "bold",
                textTransform: "none",
                fontSize: { lg: "1.15rem" },
              }}
            >
              Resend Code
              {time > 0 && ` in ${time} seconds`}
            </Button>
          </Box>
          {error && (
            <Typography color="error" fontSize={{ lg: "1.15rem" }} mb={1}>
              {error}
            </Typography>
          )}
          <Button
            fullWidth
            disabled={isSubmitting || phoneOTP.length < 6}
            type="submit"
            variant="contained"
            color="primary"
            sx={{ minHeight: { md: 44, xl: 56 } }}
          >
            Verify Phone
          </Button>
        </Stack>
      </form>
    </>
  );
}

export default memo(PhoneVerification);
