import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { memo } from "react";
import { useNavigate } from "react-router-dom";

function AddActions() {
  const navigate = useNavigate();

  return (
    <Button
      size="large"
      variant="contained"
      sx={{ width: "fit-content" }}
      startIcon={<AddIcon />}
      onClick={() => navigate("create")}
    >
      Add New
    </Button>
  );
}

export default memo(AddActions);
