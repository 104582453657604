export const getResponseData = (response) => {
  return response?.data?.data;
};

export const getErrorData = (error) =>
  error?.response?.data?.data ??
  error?.response?.data ??
  error?.response ??
  error;

export const getErrorMessage = (error) => {
  return (
    error?.response?.userMessage ??
    error?.response?.data?.userMessage ??
    "Something went wrong, please try again later"
  );
};
