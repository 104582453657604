import styled from "@mui/material/styles/styled";
import TextField from "@mui/material/TextField";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "transparent",
    border: "1px solid #00000038",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "1.25rem",
    paddingRight: "1rem",
    borderRadius: "5px",
  },
});

export default StyledTextField;
