import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import grey from "@mui/material/colors/grey";
import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function CircularTimeProgress({ socket, setIsExtendTimeButtonDisabled }) {
  const product = useSelector((state) => state.liveStreaming?.product ?? null);
  const [totalTime, setTotalTime] = useState("");
  const [time, setTime] = useState("");
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isXL = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const isXXL = useMediaQuery((theme) => theme.breakpoints.down("xxl"));
  const dimension = isSmall
    ? 140
    : isMedium
    ? 60
    : isLarge
    ? 75
    : isXL
    ? 100
    : isXXL
    ? 120
    : 140;

  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  useEffect(() => {
    if (time > 0) {
      const id = setInterval(() => {
        setTime((value) => value - 1);
      }, 1000);
      return () => {
        clearInterval(id);
      };
    }
  }, [time]);

  useEffect(() => {
    if (product) {
      const { auctionEnd = "", auctionStart = "" } = product;
      const duration = dayjs(auctionEnd).diff(dayjs(auctionStart), "second");
      const time = dayjs(auctionEnd).diff(dayjs(), "second");
      duration > 0 && setTotalTime(duration);
      time > 0 && setTime(time);
    }
  }, [product]);

  useEffect(() => {
    if (socket && product && parseInt(time) <= 1) {
      setIsExtendTimeButtonDisabled(true);
    } else {
      setIsExtendTimeButtonDisabled(false);
    }
  }, [socket, product, time, setIsExtendTimeButtonDisabled]);

  // Code if we want to remove the product automatically on time end
  // useEffect(() => {
  //   if (socket && product && time === 0) {
  //     dispatch(placeSuccessfulBid(product?.id ?? product?._id ?? ""))
  //       .unwrap()
  //       .then(() => {
  //         setTime("");
  //       });
  //   }
  // }, [socket, product, time, dispatch]);

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          padding: "5px",
          border: `2px solid ${grey[300]}`,
          borderRadius: "50%",
        }}
      >
        <CircularProgress
          variant="determinate"
          value={totalTime ? (time / totalTime) * 100 : 0}
          sx={{
            height: `${dimension}px !important`,
            width: `${dimension}px !important`,
            "& .MuiCircularProgress-circle": {
              strokeLinecap: "round",
            },
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {totalTime && time ? (
            <Typography variant="h5" component="div" color="text.secondary">
              {`${Math.round(minutes)}:${seconds.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}`}
            </Typography>
          ) : (
            <Typography variant="h5" component="div" color="text.secondary">
              00:00
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

CircularTimeProgress.propTypes = {
  socket: PropTypes.object,
  setIsExtendTimeButtonDisabled: PropTypes.func.isRequired,
};

export default CircularTimeProgress;
