import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ServiceForm from "components/Services/ServiceForm";
import ServiceTypeButtons from "components/Services/ServiceTypeButtons";
import ServicesDataGrid from "components/Services/ServicesDataGrid";
import PageHeader from "layout/PageHeader";
import PropTypes from "prop-types";
import { memo, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetServiceState } from "store/slices/servicesSlice";

function Subscriptions({ create, update }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    return () => dispatch(resetServiceState());
  }, [dispatch]);

  if (create || update) return <ServiceForm />;

  return (
    <Stack direction="column" py={3} px={1} spacing={2}>
      <PageHeader title="Services" />
      <Button
        sx={{
          width: 150,
          minHeight: 44,
        }}
        disableElevation
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => navigate("create")}
      >
        Add New
      </Button>
      <ServiceTypeButtons />
      <ServicesDataGrid />
    </Stack>
  );
}

Subscriptions.propTypes = {
  create: PropTypes.bool,
  update: PropTypes.bool,
};

Subscriptions.defaultProps = {
  create: false,
  update: false,
};

export default memo(Subscriptions);
