function isCamelCase(str) {
  // Use a regular expression to check for camelCase pattern
  let regex = /^[a-z][A-Za-z0-9]*$/;

  return regex.test(str);
}

const splitCamelCase = (input) => {
  // Check if the input is camelCase
  if (!isCamelCase(input)) {
    return input;
  }

  // Use a regular expression to match camelCase
  let regex = /([a-z])([A-Z])/g;

  // Replace the camel case with space-separated words
  let result = input.replace(regex, "$1 $2");

  // Capitalize the first letter of the resulting string
  result = result.charAt(0).toUpperCase() + result.slice(1);

  return result;
};

export default splitCamelCase;
