import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateProductFormData } from "services/Product";
import { resetProductState } from "store/slices/productsSlice";
import { getErrorMessage } from "utils/helpers/httpRequests";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";
import Reports from "./Reports";

function ProductReports() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams()?.id;
  const product = useSelector((state) => state.products.product);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = useMemo(
    () => ({
      id: product?.id ?? "",
      /* Reports */
      inspection:
        product?.reports.find((report) => report?.inspection)?.inspection ?? "",
      evaluation:
        product?.reports.find((report) => report?.evaluation)?.evaluation ?? "",
      valuation:
        product?.reports.find((report) => report?.valuation)?.valuation ?? "",
      /* Inspection Video */
      inspectionVideo: product?.inspectionVideo ?? "",
    }),
    [product]
  );

  const handleSubmit = useCallback(
    async (values) => {
      const formData = new FormData();
      if (typeof values.inspection === "object")
        formData.append(`inspection`, values.inspection);
      if (typeof values.evaluation === "object")
        formData.append(`evaluation`, values.evaluation);
      if (typeof values.valuation === "object")
        formData.append(`valuation`, values.valuation);
      if (typeof values.inspectionVideo === "object")
        formData.append(`inspectionVideo`, values.inspectionVideo);
      if (formData.entries().next().done) {
        toast.info("There is no changing to be saved");
        if (product?.draftStatus === "completed") {
          return navigate("/products");
        } else {
          return navigate("/products");
        }
      }
      try {
        setIsSubmitting(() => true);
        await updateProductFormData(id, formData);
        toast.success("Product reports updated successfully");
        dispatch(resetProductState());
        return navigate("/products");
      } catch (error) {
        const message = getErrorMessage(error);
        toast(message);
      } finally {
        setIsSubmitting(() => false);
      }
    },
    [dispatch, id, navigate, product?.draftStatus]
  );

  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            // formik.handleSubmit();
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Reports
                formik={formik}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
              />
            </Grid>

            {/* Action Buttons */}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                onClick={formik.handleSubmit}
                disabled={isSubmitting}
                sx={{
                  fontWeight: "bold",
                  minWidth: { md: 100, xl: 250 },
                  height: { xs: 50, xl: 55 },
                }}
              >
                {isSubmitting ? "Saving..." : "Save"}
              </Button>
              {formik.errors.id && formik.touched.id && (
                <Typography
                  variant="body1"
                  color="primary"
                  display="block"
                  mt={1}
                >
                  Product id is required
                </Typography>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default memo(ProductReports);

const validationSchema = Yup.object().shape({
  id: Yup.string().required("Required"),
  /* Reports */
  inspection: Yup.mixed(),
  evaluation: Yup.mixed(),
  valuation: Yup.mixed(),
  /* Inspection Video */
  inspectionVideo: Yup.mixed(),
});
