export const splitProductSpecString = (str) => {
  // Check if the string is in camelCase
  if (/[a-z][A-Z]/.test(str)) {
    // Split the string by camelCase
    return str.split(/(?=[A-Z])/).join(" ");
  }
  // Check if the string contains underscores
  else if (str.includes("_")) {
    // Split the string by underscores
    return str.split("_").join(" ");
  }
  // Check against switch case
  switch (str) {
    case "bodystyle":
      return "Body Style";
    case "bodyStyle":
      return "Body Style";
    case "body Style":
      return "Body Style";
    case "preciousstone":
      return "Precious Stone";
    case "DimondColor":
      return "Diamond Color";
    case "GoldColor":
      return "Gold Color";
    case "Pearlweight":
      return "Pearl Weight";
    case "Diamondweight":
      return "Diamond Weight";
    case "Externalmaterial":
      return "External Material";
    case "externalmaterial":
      return "External Material";
    case "BraceletMaterial":
      return "Bracelet Material";
    case "bezelmaterial":
      return "Bezel Material";
    case "includesbox":
      return "Include Box";
    case "braceletmaterial":
      return "Bracelet Material";
    default:
      return str;
  }
};
