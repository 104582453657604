import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Stack from "@mui/material/Stack";
import { memo } from "react";
// import { createSearchParams, useNavigate } from "react-router-dom";
// import { FILTER_TEXT_COLOR } from "styles/colors";
// import useQueryParams from "utils/hooks/useQueryParams";
// import DateRange from "../Pickers/DateRange";
import AddActions from "./AddActions";
// import FilterByCategory from "./FilterByCategory";

function ProductFilters() {
  // const navigate = useNavigate();
  // const params = useQueryParams();
  // const [open, setOpen] = useState(false);

  // const toggle = () => {
  //   setOpen(!open);
  //   if (open === true) {
  //     navigate(`/products`);
  //   }
  // };

  // const handleChange = useCallback(
  //   (range) => {
  //     let _params = params;
  //     _params.bidStart = range.startDate.toDateString();
  //     _params.bidEnd = range.endDate.toDateString();
  //     navigate({
  //       search: createSearchParams(_params).toString(),
  //     });
  //   },
  //   [navigate, params]
  // );

  return (
    <Box sx={containerStyles}>
      <AddActions />
      {/* {open ? (
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="flex-end"
          gap={2}
        >
          <DateRange
            displayStaticWrapperAs="mobile"
            open={open}
            toggle={toggle}
            onChange={handleChange}
          />
          <FilterByCategory />
          <Button
            onClick={toggle}
            variant="text"
            className="Filter-toggle-btn"
            sx={{
              borderBottom: "2px solid #707070",
              borderRadius: 0,
              color: FILTER_TEXT_COLOR,
            }}
          >
            Clear All
          </Button>
        </Stack>
      ) : (
        <Box textAlign="right">
          <Button
            variant="text"
            size="small"
            onClick={toggle}
            sx={{
              height: 42,
              borderRadius: 0,
              borderBottom: "2px solid",
              borderColor: "primary.main",
            }}
          >
            Filter
          </Button>
        </Box>
      )} */}
    </Box>
  );
}

export default memo(ProductFilters);

const containerStyles = {
  display: "flex",
  flexDirection: { xs: "column", md: "row" },
  justifyContent: { md: "space-between" },
  alignItems: { md: "center" },
  gap: 2,
  "& .MuiFormControl-root": {
    borderColor: "#fff",
  },
};
