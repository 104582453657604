import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomAuctionDatagrid from "components/DetailedDataGrid/CustomAuctionDatagrid";
import { Form, Formik } from "formik";
import { memo, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";
import CustomProductSearch from "./CustomProductSearch";

function ProductList() {
  const params = useParams();
  const [error, setError] = useState("");

  const initialValues = useMemo(
    () => ({
      selectedProducts: [],
    }),
    []
  );

  const handleSubmit = useCallback(
    (values) => {
      let products = values.selectedProducts.map((product) => {
        let productArray = {
          productId: product?.id,
        };
        return productArray;
      });

      if (products.length === 0) {
        setError("Please select a product");
      } else if (params?.id && products.length > 0) {
        setError(" ");
        toast.error("Bulk operation not implemented yet");
        // dispatch(updateAuctions({ products, id: params.id }))
        //   .unwrap()
        //   .then(() => {
        //     dispatch(updateAuctions());
        //     navigate("/auctions");
        //   });
      }
    },
    [params?.id]
  );
  return (
    <Box>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <Stack direction="row" gap={3} p={{ xs: 1, md: 1.5 }}>
                  <Grid item xs={12} lg={12}>
                    <CustomProductSearch
                      searchKeyParam="name"
                      searchKeyParamValue="productName"
                      searchQueryParam="value"
                    />
                    <CustomAuctionDatagrid formik={formik} />
                  </Grid>
                </Stack>
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Typography color="red">{error}</Typography>
                </Grid>
              )}
              {/* <Grid item xs={12}>
                <Stack direction="row" gap={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={formik.handleSubmit}
                    disableElevation
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    Upload
                  </Button>
                </Stack>
              </Grid> */}
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(ProductList);

const validationSchema = Yup.object().shape({
  selectedProducts: Yup.array().required("Required"),
});
