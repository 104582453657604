import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { memo } from "react";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import StyledTextField from "utils/helpers/styledTextField";

function BasicInformationSection({ formik }) {
  return (
    <StyledMuiCard
      title="Basic Information"
      subTitle="You can upload your video ad or video. Please enter title,select thumbnail,
       upload the video and select the products from below list to showcase along with video"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={3}
          p={{ xs: 1, md: 1.5 }}
        >
          <Stack direction="column" gap={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Video Title
                </Typography>
                <StyledTextField
                  fullWidth
                  type="text"
                  {...formik.getFieldProps("title")}
                  error={Boolean(formik.touched.title && formik.errors.title)}
                  helperText={
                    formik.touched.title && !!formik.errors.title
                      ? formik.errors.title
                      : "Enter a descriptive title to help customer find your video"
                  }
                />
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

BasicInformationSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(BasicInformationSection);
