import PagesDataGrid from "components/Pages/PagesDataGrid";
import PagesForm from "components/Pages/PagesForm";
import PropTypes from "prop-types";
import { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { store } from "store/configureStore";
import {
  getPages,
  resetPageState,
  resetPagesState,
} from "store/slices/pagesSlice";

function Pages({ create, update }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetPageState());
    return () => dispatch(resetPagesState());
  }, [dispatch]);

  if (create || update) return <PagesForm />;

  return <PagesDataGrid />;
}

Pages.propTypes = {
  create: PropTypes.bool,
  update: PropTypes.bool,
};

Pages.defaultProps = {
  create: false,
  update: false,
};

export default memo(Pages);

export const loadPages = () => {
  store.dispatch(getPages());
  return null;
};
