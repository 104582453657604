import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import WritingIcon from "assets/icons/WritingIcon.svg";
import deleteIcon from "assets/icons/deleteIcon.svg";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledDataGrid from "components/StyledGrid";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import { deleteUser, getUsers } from "store/slices/usersSlice";
import USER_ROLE from "utils/constants/userRole";
import useQueryParams from "utils/hooks/useQueryParams";

export default function DataGrid() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    results: users,
    loading,
    totalPages,
  } = useSelector((state) => state.users);

  const handleClickOpen = useCallback(
    (userId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(userId));
    },
    [dispatch]
  );

  const fetchUsers = useCallback(() => {
    dispatch(getUsers(params));
  }, [dispatch, params]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchUsers();
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, fetchUsers]);

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 50,
        flex: 0.1,
        filterable: false,
      },
      {
        field: "userName",
        headerName: "Name",
        minWidth: 200,
        flex: 1,
        editable: true,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.fullname}
          </Typography>
        ),
      },
      {
        field: "role",
        headerName: "Role",
        minWidth: 100,
        flex: 0.25,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.role === USER_ROLE.SUPER_ADMIN
              ? "Super Admin"
              : params?.row?.role}
          </Typography>
        ),
      },
      {
        field: "phone",
        headerName: "Phone",
        minWidth: 200,
        flex: 0.5,
        sortable: false,
        editable: true,
        renderCell: (params) => <Typography>{params?.row?.phone}</Typography>,
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 250,
        flex: 0.5,
        sortable: false,
        editable: true,
        renderCell: (params) => <Typography>{params?.row?.email}</Typography>,
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "right",
        align: "right",
        minWidth: 175,
        flex: 0.25,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <Typography variant="h6" pr={2}>
            Actions
          </Typography>
        ),
        renderCell: (params) => (
          <Box display="flex" pr={1}>
            {params?.row?.role !== USER_ROLE.SUPER_ADMIN && (
              <IconButton
                size="small"
                onClick={() => navigate(`${params.id}/update`)}
              >
                <img src={WritingIcon} alt="edit" />
              </IconButton>
            )}
            <IconButton
              size="small"
              onClick={() => handleClickOpen(params?.id)}
            >
              <img src={deleteIcon} alt="edit" />
            </IconButton>
          </Box>
        ),
      },
    ],
    [handleClickOpen, navigate]
  );
  return (
    <>
      {users ? (
        <StyledDataGrid
          rows={users}
          columns={columns}
          loading={loading}
          totalPages={totalPages}
        />
      ) : (
        <Typography display="flex" justifyContent="center" mt={4}>
          No record found
        </Typography>
      )}
      <ConfirmDelete deleteAction={deleteUser} />
    </>
  );
}
