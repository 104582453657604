import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Logo from "assets/icons/YallaBid_Logo.svg";
import settings from "assets/icons/sidebar/settings.svg";
import settingsfill from "assets/icons/sidebar/settingsfill.svg";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import SideBarListItems from "utils/constants/SideBarListItems";

const openedMixin = (theme) => ({
  [theme.breakpoints.up("xl")]: {
    width: 275,
  },
  [theme.breakpoints.down("xl")]: {
    width: 275,
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingBottom: "10px",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  [theme.breakpoints.up("xl")]: {
    width: 275,
  },
  [theme.breakpoints.down("xl")]: {
    maxWidth: 275,
  },
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideBar({ handleDrawerOpen, handleDrawerClose }) {
  const path = useLocation();
  const userRole = useSelector((state) => state.auth?.userRole);
  const isSidebarOpen = useSelector((state) => state.UI.isSidebarOpen);
  const isOpenedByUser = useSelector((state) => state.UI.isSidebarOpenedByUser);
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isOpen = isSidebarOpen || isOpenedByUser;

  let pathLocation = "/" + path?.pathname?.split("/").slice(1, 2).join(" ");
  if (pathLocation === "/") {
    pathLocation = "/dashboard";
  }

  return (
    <Box display="flex" height="100vh">
      {isMedium ? (
        <SwipeableDrawer
          anchor="left"
          open={isOpen}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
          sx={{
            zIndex: 1250,
            "& .MuiDrawer-paper": {
              backgroundColor: "#fff",
              color: "rgba(138, 138, 138, 0.4)",
              border: "none",
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            },
          }}
          // sx={{
          //   visibility: "hidden",
          // }}
        >
          <DataList
            path={path}
            isOpen={isOpen}
            userRole={userRole}
            pathLocation={pathLocation}
            handleDrawerClose={handleDrawerClose}
          />
        </SwipeableDrawer>
      ) : (
        <Drawer
          variant="permanent"
          open={isOpen}
          PaperProps={{
            sx: {
              backgroundColor: "#fff",
              color: "rgba(138, 138, 138, 0.4)",
              left: "auto",
              border: "none",
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            },
          }}
        >
          <DataList
            path={path}
            isOpen={isOpen}
            userRole={userRole}
            pathLocation={pathLocation}
            handleDrawerClose={handleDrawerClose}
          />
        </Drawer>
      )}
    </Box>
  );
}
SideBar.propTypes = {
  handleDrawerClose: PropTypes.func,
  handleDrawerOpen: PropTypes.func,
};

const DataList = ({
  handleDrawerClose,
  userRole,
  path,
  pathLocation,
  isOpen,
}) => {
  const isXl = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <Box
        sx={{
          ...(!isOpen && { display: "none" }),
        }}
      >
        <DrawerHeader height="6.5rem">
          <Box padding="30px 20px 0 20px" textAlign="center">
            <a href="/dashboard">
              <img src={Logo} alt="logo" />
            </a>
          </Box>
          <IconButton
            onClick={handleDrawerClose}
            sx={{ marginTop: 5, display: isXl && "none" }}
          >
            <ChevronLeftIcon
              sx={{
                color: "black",
              }}
            />
          </IconButton>
        </DrawerHeader>
      </Box>
      <Box
        sx={{
          flexGrow: "1",
          overflowY: "auto",
          overflowX: "hidden",
          marginTop: isOpen ? "12px" : 12,
          "&::-webkit-scrollbar": {
            width: 5,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "white",
            borderRadius: 2,
          },
        }}
      >
        <List
          sx={{
            "& .Mui-disabled": {
              opacity: "1 !important",
            },
          }}
        >
          {SideBarListItems.map(
            (route) =>
              route?.roles?.includes(userRole) && (
                <ListItem
                  key={route?.path}
                  disablePadding
                  sx={{
                    display: "block",
                  }}
                >
                  <ListItemButton
                    dense
                    disabled={route?.disabled}
                    to={route?.path}
                    component={NavLink}
                    sx={{
                      minHeight: 48,
                      justifyContent: isOpen ? "initial" : "center",
                      px: 3.5,
                      color:
                        route.path === pathLocation ? "#cf3414" : "secondary",
                      borderRight:
                        route.path === pathLocation
                          ? "3px solid #cf3414"
                          : undefined,
                    }}
                    end
                  >
                    {route.icon && (
                      <Tooltip
                        disableHoverListener={isOpen}
                        title={route.title}
                        placement="right-end"
                      >
                        <ListItemIcon
                          to={route?.path}
                          sx={{
                            minWidth: 0,
                            mr: isOpen ? 1 : "auto",
                            justifyContent: "center",
                            color: route?.path === pathLocation && "#cf3414",
                          }}
                        >
                          {typeof route.icon === "string" ? (
                            <img
                              src={
                                route?.path === pathLocation && route.activeIcon
                                  ? route.activeIcon
                                  : route.icon
                              }
                              alt="icon"
                            />
                          ) : (
                            <route.icon
                              sx={{
                                color:
                                  route?.path === pathLocation
                                    ? "#cf3414"
                                    : "#d0d0d0",
                              }}
                            />
                          )}
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    <ListItemText
                      primary={
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          lineHeight="10px"
                          fontSize="1.625rem"
                        >
                          {route.title}
                        </Typography>
                      }
                      sx={{ opacity: isOpen ? 1 : 0 }}
                    />
                  </ListItemButton>
                  <Collapse in={isOpen} unmountOnExit timeout="auto">
                    {route.children && (
                      <List id="children" sx={{ py: 0 }}>
                        {route.children?.map(
                          (_route, _index) =>
                            _route?.roles?.includes(userRole) && (
                              <ListItem
                                key={_index}
                                disablePadding
                                sx={{ display: "block" }}
                              >
                                <ListItemButton
                                  component={NavLink}
                                  to={`${route.path + _route.path}`}
                                  disabled={_route?.disabled}
                                  sx={{
                                    justifyContent: isOpen
                                      ? "initial"
                                      : "center",
                                    py: 0,
                                  }}
                                  style={({ isActive }) =>
                                    isActive
                                      ? {
                                          color: "#cf3414",
                                          borderRight: "2px solid #cf3414",
                                        }
                                      : undefined
                                  }
                                >
                                  <ListItemText
                                    inset
                                    primary={_route.title}
                                    sx={{
                                      opacity: isOpen ? 1 : 0,
                                      justifyContent: "center",
                                      paddingLeft: "37px",
                                      "& .MuiTypography-root": {
                                        fontSize: "1.313rem",
                                      },
                                    }}
                                  />
                                </ListItemButton>
                              </ListItem>
                            )
                        )}
                      </List>
                    )}
                  </Collapse>
                </ListItem>
              )
          )}
        </List>
      </Box>
      <Box>
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              dense
              to="/settings"
              component={NavLink}
              sx={{
                minHeight: 48,
                justifyContent: isOpen ? "initial" : "center",
                px: 3,
              }}
              style={({ isActive }) =>
                isActive
                  ? {
                      color: "#cf3414",
                      borderRight: "2px solid #cf3414",
                    }
                  : undefined
              }
            >
              <Tooltip
                disableHoverListener={isOpen}
                title="Settings"
                placement="right-end"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isOpen ? 1 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={
                      "/settings" === path.pathname ? settingsfill : settings
                    }
                    alt="settings"
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    lineHeight="10px"
                    fontSize="1.625rem"
                  >
                    Settings
                  </Typography>
                }
                sx={{ opacity: isOpen ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

DataList.propTypes = {
  handleDrawerClose: PropTypes.func,
  isOpen: PropTypes.bool,
  path: PropTypes.object,
  pathLocation: PropTypes.string,
  userRole: PropTypes.string,
};
