import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CurrencyFormatter from "components/CurrencyFormatter";
import PropTypes from "prop-types";
import { memo } from "react";

function ProductCard({ number, product, setSelectedProduct, isDialogCard }) {
  const handleClick = () => {
    setSelectedProduct && setSelectedProduct(product);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      onClick={handleClick}
      bgcolor={!setSelectedProduct && "#99a0a30f"}
      sx={{
        cursor: setSelectedProduct && "pointer",
        borderRadius: "10px",
      }}
    >
      <img
        src={product?.mainImage}
        height="150px"
        width="150px"
        alt={product?.title ?? "N/A"}
        style={{ objectFit: "cover", borderRadius: "10px" }}
      />
      <Stack
        direction="column"
        justifyContent="center"
        spacing={1}
        width="100%"
        overflow="hidden"
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          color="text.secondary"
        >
          {product?.productName ?? "N/A"}
        </Typography>
        <Stack direction="row" justifyContent="space-between" pt={1}>
          <Stack direction="column">
            <Typography
              lineHeight={1}
              variant="subtitle2"
              color="text.secondary"
            >
              Current Bid
            </Typography>
            <CurrencyFormatter
              fontWeight="bold"
              color="text.secondary"
              fontSize={{ xs: "0.9rem", lg: "1.1rem" }}
              number={
                product?.currentBid?.bidAmount ?? product?.reservePrice ?? 0
              }
            />
            {!isDialogCard && (
              <Box>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "0.9rem",
                    lineHeight: 1,
                    padding: "6px 10px 6px",
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                  }}
                >
                  Run #{number ?? 0}
                </Button>
              </Box>
            )}
          </Stack>
          <Stack direction="column" pr={1}>
            <Typography
              lineHeight={1}
              variant="subtitle2"
              color="text.secondary"
            >
              Lot#
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              fontWeight="bold"
              fontSize={{ xs: "0.9rem", lg: "1.1rem" }}
            >
              {product?.lotNumber ?? "N/A"}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

ProductCard.propTypes = {
  number: PropTypes.number.isRequired,
  product: PropTypes.object,
  setSelectedProduct: PropTypes.func,
  isDialogCard: PropTypes.bool,
};

ProductCard.defaultProps = {
  number: 0,
  product: {},
  isDialogCard: false,
};

export default memo(ProductCard);
