import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateProductFormData } from "services/Product";
import { resetProductState } from "store/slices/productsSlice";
import { getErrorMessage } from "utils/helpers/httpRequests";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";
import Media from "./Media";

function ProductMedia() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams()?.id;
  const product = useSelector((state) => state.products.product);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = useMemo(
    () => ({
      id: product?.id ?? "",
      /* Media */
      mainImage: product?.mainImage ?? "",
      gallery: product?.gallery ?? [],
      video: product?.videoUrl ?? "",
    }),
    [product]
  );

  const handleSubmit = useCallback(
    async (values) => {
      const formData = new FormData();
      if (typeof values.mainImage === "object")
        formData.append(`mainImage`, values.mainImage);
      values.gallery.forEach((image) => {
        if (typeof image === "object") formData.append(`gallery`, image);
      });
      if (typeof values.video === "object")
        formData.append(`videoUrl`, values.video);
      if (formData.entries().next().done) {
        if (product?.draftStatus === "completed") {
          return navigate("/products");
        } else {
          return navigate("/products/edit/" + id);
        }
      }
      try {
        setIsSubmitting(() => true);
        await updateProductFormData(id, formData);
        toast.success("Product media updated successfully");
        dispatch(resetProductState());
        if (product?.draftStatus === "completed") {
          return navigate("/products");
        } else {
          return navigate("/products/edit/" + id);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        toast(message);
      } finally {
        setIsSubmitting(() => false);
      }
    },
    [dispatch, id, navigate, product?.draftStatus]
  );

  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            // formik.handleSubmit();
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Media
                formik={formik}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
              />
            </Grid>

            {/* Action Buttons */}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                onClick={formik.handleSubmit}
                disabled={isSubmitting}
                sx={{
                  fontWeight: "bold",
                  minWidth: { md: 100, xl: 250 },
                  height: { xs: 50, xl: 55 },
                }}
              >
                {isSubmitting ? "Saving..." : "Save"}
              </Button>
              {formik.errors.id && formik.touched.id && (
                <Typography
                  variant="body1"
                  color="primary"
                  display="block"
                  mt={1}
                >
                  Product id is required
                </Typography>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default memo(ProductMedia);

const validationSchema = Yup.object().shape({
  id: Yup.string().required("Required"),
  /* Media */
  mainImage: Yup.mixed()
    .required("Required")
    .test(
      "fileFormat",
      "Unsupported file format. Only JPG, JPEG, and PNG are allowed.",
      (value) => {
        if (value && typeof value === "object") {
          const allowedFormats = ["image/jpg", "image/jpeg", "image/png"];
          return allowedFormats.includes(value.type);
        }
        return true;
      }
    ),
  gallery: Yup.array()
    .required("Required")
    .min(5, "Minimum 5 images are required"),
  video: Yup.string(),
});
