import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpMain } from "utils/httpRequest/http";

export const getSellWithUsById = createAsyncThunk(
  "sellWithUs/getSellWithUsById",
  async (sellId, { rejectWithValue }) => {
    const url = `/sell/${sellId}`;
    try {
      const response = await httpMain.get(url);
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data ?? error.message ?? "");
    }
  }
);

export const updateSellWithUs = createAsyncThunk(
  "sellWithUs/updateSellWithUs",
  async (data = {}, { rejectWithValue }) => {
    console.log("data---", data);
    const sellId = data.sellId;
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    const url = `/sell/${sellId}`;
    try {
      const response = await httpMain.patch(url, formData);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data ?? error.message ?? "");
    }
  }
);

const sellWithUsSlice = createSlice({
  name: "sellWithUs",
  initialState: {
    loading: true,
    results: [],
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
    error: null,
  },
  reducers: {
    // Actions
    resetState: (state) => {
      state.loading = true;
      state.results = [];
      state.page = 1;
      state.limit = 20;
      state.totalPages = 0;
      state.totalResults = 0;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSellWithUsById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSellWithUsById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.results = action.payload;
      })
      .addCase(getSellWithUsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateSellWithUs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSellWithUs.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateSellWithUs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState } = sellWithUsSlice.actions;

// Exporting default reducer
export default sellWithUsSlice.reducer;
