import axios from "axios";
import interceptorConfiguration from "./interceptorConfiguration";

const streamingHttpClient = axios.create({
  baseURL: process.env.REACT_APP_STREAMING_BASE_URL,
});

interceptorConfiguration(streamingHttpClient);

export default streamingHttpClient;
