import { httpMain } from "utils/httpRequest/http";

export const uploadBlogImage = (blogId = "", image = null) => {
  const formData = new FormData();
  formData.append("cmsImage", image);
  return httpMain.post(`/cms-pages/${blogId}`, formData);
};

export const removeBlogImage = (blogId = "", imageUrl = null) => {
  const formData = new FormData();
  formData.append("removeImage", imageUrl);
  return httpMain.post(`/cms-pages/${blogId}`, formData);
};

export const uploadBlogBannerImage = (blogId = "", image = null) => {
  const formData = new FormData();
  formData.append("bannerImage", image);
  return httpMain.post(`/cms-pages/${blogId}`, formData);
};

export const removeBlogBannerImage = (blogId = "", imageUrl = null) => {
  const formData = new FormData();
  formData.append("removeBannerImage", imageUrl);
  return httpMain.post(`/cms-pages/${blogId}`, formData);
};

export const updateBlogStatus = (blogId = "", status = false) => {
  return httpMain.patch(`/cms-pages/${blogId}`, { active: status });
};
