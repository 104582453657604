import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { memo } from "react";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import ContentEditor from "./ContentEditor";

function ContentSection({ formik }) {
  return (
    <StyledMuiCard
      title="Page Content"
      subTitle="Enter page content. Tip: Use bullet points for easy viewing"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={2}
          p={{ xs: 1, md: 1.5 }}
        >
          <Box>
            <Card>
              <ContentEditor formik={formik} />
            </Card>
            {formik.errors.content && (
              <Typography color="error.light" pt={1} fontSize="1rem">
                {formik.errors.content}
              </Typography>
            )}
          </Box>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

ContentSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(ContentSection);
