import PropTypes from "prop-types";
import { memo, useEffect, useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function ContentEditor({ formik }) {
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current.getEditor().getLength() <= 1) {
      formik.setErrors({
        content: "Required*",
      });
    } else if (
      editorRef.current.getEditor().getLength() > 1 &&
      editorRef.current.getEditor().getLength() < 81
    )
      formik.setErrors({
        content: "Content must be at least 80 characters long",
      });
  }, [formik]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: "1" }, { header: "2" }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link"],
        ],
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    }),
    []
  );

  return (
    <ReactQuill
      ref={editorRef}
      theme="snow"
      value={formik.values.content}
      onChange={(value) => {
        formik.setFieldValue("content", value.trimStart());
      }}
      placeholder="Write content here..."
      formats={[
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
      ]}
      modules={modules}
    />
  );
}

ContentEditor.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(ContentEditor, arePropsEqual);

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.formik.values.content === nextProps.formik.values.content &&
    prevProps.formik.errors.content === nextProps.formik.errors.content
  );
}
