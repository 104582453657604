import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AuctionPerformance from "components/Dashboard/AuctionPerformance";
import AuctionedItems from "components/Dashboard/AuctionedItems";
import BidderActivity from "components/Dashboard/BidderActivity";
import KeyNumbers from "components/Dashboard/KeyNumbers";
import LineChart from "components/Dashboard/LineChart/LineChart";
import Pledges from "components/Dashboard/Pledges";
import UpcomingAuctions from "components/Dashboard/upcomingAuctions";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSessionExpiryDialogOpen } from "store/slices/uiSlice";

export default function Dashboard() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth?.data?.tokens) {
      const refreshToken = auth?.data?.tokens?.refresh;
      if (dayjs(refreshToken?.expires).isBefore(dayjs())) {
        dispatch(setSessionExpiryDialogOpen());
      }
    }
  }, [auth, dispatch]);

  return (
    <Box sx={{ flexGrow: 1, py: 3, px: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          fontWeight="600"
          mb={1}
          sx={{ fontSize: { md: "1.5rem", lg: "2rem" } }}
        >
          DASHBOARD
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={6} lg={4} sm={6} md={6} xl={isOpen ? 4 : 2} xxl={2}>
          <KeyNumbers
            title={"No of Bids"}
            currentMonth={"27.4K"}
            lastMonth={"55.5K"}
            target={"60K"}
          />
        </Grid>
        <Grid item xs={6} lg={4} sm={6} md={6} xl={isOpen ? 4 : 2} xxl={2}>
          <KeyNumbers
            title={"Active Listing"}
            currentMonth={"55.5K"}
            lastMonth={"55.5K"}
            target={"60K"}
          />
        </Grid>
        <Grid item xs={6} lg={4} sm={6} md={6} xl={isOpen ? 4 : 2} xxl={2}>
          <KeyNumbers
            title={"Bidders"}
            currentMonth={"15K"}
            lastMonth={"55.5K"}
            target={"60K"}
          />
        </Grid>
        <Grid item xs={6} lg={4} sm={6} md={6} xl={isOpen ? 4 : 2} xxl={2}>
          <KeyNumbers
            title={"GMV"}
            currentMonth={"500M"}
            lastMonth={"55.5K"}
            target={"60K"}
          />
        </Grid>
        <Grid item xs={6} lg={4} sm={6} md={6} xl={isOpen ? 4 : 2} xxl={2}>
          <KeyNumbers
            title={"Visitors/Viewers"}
            currentMonth={"100K"}
            lastMonth={"55.5K"}
            target={"60K"}
          />
        </Grid>
        <Grid item xs={6} lg={4} sm={6} md={6} xl={isOpen ? 4 : 2} xxl={2}>
          <KeyNumbers
            title={"Deposit"}
            currentMonth={"700M"}
            lastMonth={"55.5K"}
            target={"60K"}
          />
        </Grid>
      </Grid>
      <Grid sx={{ mt: 1 }} container spacing={3}>
        <Grid item sm={12} md={12} lg={12} xl={8}>
          <LineChart />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xl={4}>
          <UpcomingAuctions />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}
          lg={6}
          xl={isOpen ? 4 : 2.8}
          xxxl={isOpen ? 3 : 2.8}
          xxl={isOpen ? 4 : 2.8}
        >
          <AuctionedItems />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}
          lg={6}
          xl={isOpen ? 4 : 2.7}
          xxxl={isOpen ? 2.5 : 2.7}
          xxl={isOpen ? 4 : 2.7}
        >
          <Pledges />
        </Grid>
        <Grid
          item
          sm={6}
          md={6}
          lg={6}
          xl={isOpen ? 4 : 2.5}
          xxxl={isOpen ? 2.5 : 2.5}
          xxl={isOpen ? 4 : 2.5}
        >
          <AuctionPerformance />
        </Grid>
        <Grid
          item
          sm={6}
          md={6}
          lg={6}
          xl={isOpen ? 12 : 4}
          xxxl={isOpen ? 4 : 4}
          xxl={isOpen ? 12 : 4}
        >
          <BidderActivity />
        </Grid>
      </Grid>
    </Box>
  );
}
