import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import NotificationsContainer from "components/Header/Notifications/NotificationsContainer";
import PropTypes from "prop-types";
import { memo } from "react";

function NotificationsMenu({ anchorEl, handleClose, getNotifications }) {
  // const {
  //   loading = false,
  //   results,
  //   page = 1,
  //   totalPages = 0,
  // } = useSelector((state) => state?.notifications);
  const loading = false;
  const results = [];
  const page = 1;
  const totalPages = 0;

  const open = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      id="notification-menu"
      open={open}
      onClose={handleClose}
      PaperProps={{
        elevation: 1,
        sx: {
          maxHeight: "600px",
          width: "500px",
          overflowY: "auto",
          scrollBehavior: "smooth",
          py: 1,
          px: 2,
          "&::-webkit-scrollbar": {
            width: 10,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            minHeight: 50,
            backgroundColor: "primary.light",
            borderRadius: 1,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <NotificationsContainer />
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : (
        !loading &&
        !results?.length && (
          <Typography
            variant="body1"
            color="text.secondary"
            textAlign="center"
            lineHeight={1}
          >
            No notifications to show
          </Typography>
        )
      )}
      {page < totalPages && !loading && (
        <Button
          fullWidth
          size="large"
          onClick={() => getNotifications(page + 1)}
          sx={{ mt: 1 }}
        >
          Load More
        </Button>
      )}
      {page === totalPages && !loading && (
        <Typography
          variant="body1"
          color="text.secondary"
          textAlign="center"
          sx={{ mt: 2, mb: 1 }}
        >
          No more notifications
        </Typography>
      )}
    </Menu>
  );
}

NotificationsMenu.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  getNotifications: PropTypes.func.isRequired,
};

export default memo(NotificationsMenu);
