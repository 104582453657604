import { CURRENCY } from "utils/constants/constants";

const formatCurrency = (number = 0, locale = "en-US") =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currency: CURRENCY,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);

export default formatCurrency;
