import CalendarToday from "@mui/icons-material/CalendarToday";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { DateRangePicker } from "materialui-daterange-picker";
import propTypes from "prop-types";
import { useState } from "react";

const DateRange = ({ onChange }) => {
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        endIcon={
          <CalendarToday
            sx={{
              color: "primary.main",
            }}
          />
        }
        variant="outlined"
        sx={{
          height: "44px",
          color: "primary.main",
          minWidth: 170,
          maxWidth: 180,
          textTransform: "none",
          fontSize: "1rem",
          background: "white",
        }}
      >
        Select by Date
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <DateRangePicker
            inputFormat="DD/MM/YYYY"
            displayStaticWrapperAs="mobile"
            disableFuture={true}
            open={open}
            toggle={toggle}
            onChange={onChange}
          />
        </Box>
      </Modal>
    </>
  );
};

export default DateRange;

DateRange.propTypes = {
  onChange: propTypes.func,
};

const modalStyle = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  // p: 2,
};
