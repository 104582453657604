import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MUIStyledTextField from "components/MUIStyledComponents/MUIStyledTextField";
import CheckboxList from "components/Subscriptions/CheckBoxList";
import { Form, Formik } from "formik";
import PageHeader from "layout/PageHeader";
import { memo, useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "store/configureStore";
import { getCategories } from "store/slices/filtersSlice";
import {
  CreateService,
  getService,
  resetServiceState,
  updateService,
} from "store/slices/servicesSlice";
import { CARD_HEADER_BACKGROUND } from "styles/colors";
import {
  AMOUNT_TYPE,
  EVENT_TYPE,
  SERVICE_TYPE,
} from "utils/constants/services";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import reduceDepthObject from "utils/helpers/reduceDepthObjects";
import * as Yup from "yup";

function ServiceForm() {
  const id = useParams()?.id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.services.loading.service);
  const service = useSelector((state) => state.services.service);
  const categories = useSelector((state) => state.filters.flattenedCategories);
  const categoriesLoading = useSelector(
    (state) => state.filters.loading.categories
  );

  const initialValues = useMemo(
    () => ({
      title: service?.title ?? "",
      price: service?.price ?? "",
      category: service?.category
        ? categories.find((category) => category?.value === service?.category)
            ?.value
        : "",
      serviceRole: service?.serviceRole ?? "",
      amountTypes: service?.amountTypes ?? AMOUNT_TYPE.AMOUNT,
      eventType: service?.eventType ?? "",
      display: service?.display ?? false,
      required: service?.required ?? false,
      active: service?.active ?? false,
    }),
    [categories, service]
  );

  const handleSubmit = useCallback(
    (values) => {
      const data = reduceDepthObject(values, initialValues);
      if (id) {
        dispatch(updateService({ id, payload: data }))
          .unwrap()
          .then(() => {
            navigate("/services");
          });
      } else {
        dispatch(CreateService(data))
          .unwrap()
          .then(() => {
            navigate("/services");
          });
      }
    },
    [dispatch, id, initialValues, navigate]
  );

  const handleCancelation = useCallback(() => {
    return navigate("/services");
  }, [navigate]);

  useLayoutEffect(() => {
    if (!categories.length && categoriesLoading) {
      dispatch(getCategories());
    }
  }, [dispatch, categories, categoriesLoading]);

  useEffect(() => () => dispatch(resetServiceState()), [dispatch]);

  return (
    <Stack px={1} py={3} direction="column" spacing={2}>
      <PageHeader title="Services" />
      <Typography
        sx={{
          fontSize: "2.5rem",
          fontWeight: "bold",
          color: "primary.main",
          lineHeight: 1,
        }}
      >
        {id ? "Edit" : "Create"} Service
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Stack direction="column" spacing={3}>
              <StyledMuiCard
                title="Service Information"
                subTitle="Please enter the basic information of the services such as name, listing fee etc"
              >
                <CardContent>
                  <Grid container spacing={3} px={2} py={1}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Stack direction="column" gap={1}>
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          color="text.secondary"
                        >
                          Service Name
                        </Typography>
                        <MUIStyledTextField
                          fullWidth
                          {...formik.getFieldProps("title")}
                          error={Boolean(
                            formik.touched.title && formik.errors.title
                          )}
                          helperText={
                            formik.touched.title && !!formik.errors.title
                              ? formik.errors.title
                              : "Enter a proper service name"
                          }
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack direction="column" gap={1}>
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          color="text.secondary"
                        >
                          Amount
                        </Typography>
                        <MUIStyledTextField
                          fullWidth
                          name="price"
                          type="number"
                          value={formik.values.price}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          InputProps={{
                            endAdornment: (
                              <Select
                                {...formik.getFieldProps("amountTypes")}
                                sx={{
                                  "& .MuiSelect-select": {
                                    textTransform: "capitalize",
                                    bgcolor: CARD_HEADER_BACKGROUND,
                                    color: "text.secondary",
                                    fontWeight: 500,
                                    fontSize: "1.2rem",
                                    minWidth: 40,
                                    textAlign: "center",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: 0,
                                    boxShadow: "none",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      border: 0,
                                    },
                                }}
                              >
                                {Object.values(AMOUNT_TYPE).map((option) => (
                                  <MenuItem
                                    sx={{
                                      fontSize: 16,
                                      textTransform: "capitalize",
                                      textAlign: "center",
                                    }}
                                    key={option}
                                    value={option}
                                  >
                                    {option === AMOUNT_TYPE.AMOUNT
                                      ? "AED"
                                      : "%"}
                                  </MenuItem>
                                ))}
                              </Select>
                            ),
                          }}
                          error={Boolean(
                            formik.touched.price && formik.errors.price
                          )}
                          helperText={
                            formik.touched.price && !!formik.errors.price
                              ? formik.errors.price
                              : "Enter a amount of the service in AED or provide value in %"
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              pr: 0,
                            },
                            "& .MuiOutlinedInput-input": {
                              fontSize: "1.25rem",
                              textAlign: "right",
                              padding: "14px",
                            },
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                              {
                                display: "none",
                              },
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                          }}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack direction="column" gap={1}>
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          color="text.secondary"
                        >
                          Category
                        </Typography>
                        <Autocomplete
                          options={categories}
                          value={
                            categories.find(
                              (category) =>
                                category.value === formik.values.category
                            ) ?? null
                          }
                          onChange={(_, category) =>
                            formik.setFieldValue(
                              "category",
                              category?.value ?? ""
                            )
                          }
                          getOptionLabel={(option) => option.label}
                          onBlur={formik.handleBlur}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          renderInput={(params) => (
                            <MUIStyledTextField
                              fullWidth
                              {...params}
                              name="category"
                              error={Boolean(
                                formik.touched.category &&
                                  formik.errors.category
                              )}
                              helperText={
                                formik.touched.category &&
                                !!formik.errors.category
                                  ? formik.errors.category
                                  : "Select the category on which service is applicable"
                              }
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option.value}>
                              {option.label}
                            </li>
                          )}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack direction="column" gap={1}>
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          color="text.secondary"
                        >
                          Service Role
                        </Typography>
                        <Autocomplete
                          options={Object.values(SERVICE_TYPE)}
                          value={formik.values.serviceRole}
                          onChange={(_, serviceRole) =>
                            formik.setFieldValue(
                              "serviceRole",
                              serviceRole ?? ""
                            )
                          }
                          getOptionLabel={(option) => option}
                          onBlur={formik.handleBlur}
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          renderInput={(params) => (
                            <MUIStyledTextField
                              fullWidth
                              {...params}
                              name="serviceRole"
                              error={Boolean(
                                formik.touched.serviceRole &&
                                  formik.errors.serviceRole
                              )}
                              helperText={
                                formik.touched.serviceRole &&
                                !!formik.errors.serviceRole
                                  ? formik.errors.serviceRole
                                  : "Select the package for the service"
                              }
                            />
                          )}
                          renderOption={(props, option) => (
                            <li
                              {...props}
                              key={option}
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {option}
                            </li>
                          )}
                          sx={{
                            "& .MuiAutocomplete-input": {
                              textTransform: "capitalize",
                            },
                          }}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack direction="column" gap={1}>
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          color="text.secondary"
                        >
                          Event
                        </Typography>
                        <Autocomplete
                          options={Object.values(EVENT_TYPE)}
                          value={formik.values.eventType}
                          onChange={(_, eventType) =>
                            formik.setFieldValue("eventType", eventType ?? "")
                          }
                          getOptionLabel={(option) => option}
                          onBlur={formik.handleBlur}
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          renderInput={(params) => (
                            <MUIStyledTextField
                              fullWidth
                              {...params}
                              name="eventType"
                              error={Boolean(
                                formik.touched.eventType &&
                                  formik.errors.eventType
                              )}
                              helperText={
                                formik.touched.eventType &&
                                !!formik.errors.eventType
                                  ? formik.errors.eventType
                                  : "Select the event of the service"
                              }
                            />
                          )}
                          renderOption={(props, option) => (
                            <li
                              {...props}
                              key={option}
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {option}
                            </li>
                          )}
                          sx={{
                            "& .MuiAutocomplete-input": {
                              textTransform: "capitalize",
                            },
                          }}
                        />
                      </Stack>
                    </Grid>

                    {/* checkbox list  */}
                    <Grid item xs={12} lg={12}>
                      <Stack direction="column" gap={1}>
                        <CheckboxList formik={formik} />
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyledMuiCard>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  "& .MuiButton-root": {
                    height: 50,
                    width: 225,
                  },
                }}
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disableElevation
                  disabled={id && loading}
                  onClick={formik.handleSubmit}
                >
                  Save
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={id && loading}
                  onClick={handleCancelation}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
}

export default memo(ServiceForm);

const validationSchema = Yup.object().shape({
  title: Yup.string().trim().required("Required").max(50, "Too Long!"),
  price: Yup.number()
    .required("Required")
    .positive("Value must be greater than 0")
    .max(10000, "Too High!"),
  category: Yup.string().required("Required"),
  serviceRole: Yup.string().required("Required"),
  amountTypes: Yup.string().required("Required"),
  eventType: Yup.string().required("Required"),
  display: Yup.boolean(),
  required: Yup.boolean(),
  active: Yup.boolean(),
});

export function loadService({ params }) {
  store.dispatch(getService(params?.id ?? ""));
  return null;
}
