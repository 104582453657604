import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import auctionStatuses from "utils/constants/auctionStatuses";
import flatCategoriesArray from "utils/helpers/flatCategoriesArray";
import { getErrorMessage, getResponseData } from "utils/helpers/httpRequests";
import { httpAuth, httpMain } from "utils/httpRequest/http";

export const getCategories = createAsyncThunk(
  "filters/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await httpMain.get(`/categories`);
      const responseData = getResponseData(response);
      const categories = responseData?.results;
      const flattenedCategories = flatCategoriesArray(categories);
      return { categories, flattenedCategories };
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getCategoryBySlug = createAsyncThunk(
  "filters/getCategoryBySlug",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await httpMain.get(`/categories/slug`, {
        params: {
          slug,
        },
      });
      const responseData = getResponseData(response);
      const category = responseData;
      const flattenedCategories = flatCategoriesArray(
        category?.subCategories ?? []
      );
      return { category, flattenedCategories };
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getUsers = createAsyncThunk(
  "filters/getUsers",
  async (data, { rejectWithValue }) => {
    try {
      const response = await httpAuth.get(`/users`, {
        params: {
          page: data.page ?? 1,
          limit: data.limit ?? 10,
          role: data.role ?? "user",
          ...data,
        },
      });
      return getResponseData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getAuctions = createAsyncThunk(
  "filters/getAuctions",
  async (data = {}, { rejectWithValue }) => {
    try {
      const response = await httpMain.get(`/auctions/admin`, {
        params: {
          page: data.page ?? 1,
          limit: data.limit ?? 10,
          auctionStatus: auctionStatuses.SCHEDULED,
          ...data,
        },
      });
      return getResponseData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getServices = createAsyncThunk(
  "filters/getServices",
  async (data = {}, { rejectWithValue }) => {
    try {
      const response = await httpMain.get(`/services`, {
        params: {
          ...data,
        },
      });
      return getResponseData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: {
    users: true,
    auctions: true,
    categories: true,
    category: true,
    services: true,
  },
  error: "",
  users: [],
  auctions: [],
  categories: [],
  category: null,
  flattenedCategories: [],
  productFlattenedCategories: [],
  services: [],
  queryParams: null,
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    resetState: () => initialState,
    addQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    appendUser: (state, action) => {
      state.users = [...state.users, action.payload];
    },
    setProductCategory: (state, action) => {
      const category = action.payload;
      state.category = category;
      state.loading.category = false;
      state.productFlattenedCategories = flatCategoriesArray(
        category?.subCategories ?? []
      );
    },
    resetProductFormFilters: (state) => {
      state.loading.users = true;
      state.loading.auctions = true;
      state.loading.category = true;
      state.loading.services = true;
      state.error = "";
      state.users = [];
      state.auctions = [];
      state.services = [];
      state.category = null;
      state.productFlattenedCategories = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.loading.categories = true;
        state.error = "";
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.loading.categories = false;
        state.categories = action?.payload?.categories;
        state.flattenedCategories = action?.payload?.flattenedCategories;
      })
      .addCase(getCategories.rejected, (state) => {
        state.loading.categories = false;
        state.error = "";
      })
      .addCase(getCategoryBySlug.pending, (state) => {
        state.loading.category = true;
        state.error = "";
      })
      .addCase(getCategoryBySlug.fulfilled, (state, action) => {
        state.loading.category = false;
        state.category = action?.payload?.category;
        state.productFlattenedCategories = action?.payload?.flattenedCategories;
      })
      .addCase(getCategoryBySlug.rejected, (state) => {
        state.loading.category = false;
        state.error = "";
      })
      .addCase(getUsers.pending, (state) => {
        state.loading.users = true;
        state.error = "";
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading.users = false;
        state.users = action.payload?.results;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading.users = false;
        state.error = action?.payload;
      })
      .addCase(getAuctions.pending, (state) => {
        state.loading.auctions = true;
        state.error = "";
      })
      .addCase(getAuctions.fulfilled, (state, action) => {
        state.loading.auctions = false;
        state.auctions = action.payload?.results;
      })
      .addCase(getAuctions.rejected, (state, action) => {
        state.loading.auctions = false;
        state.error = action?.payload;
      })
      .addCase(getServices.pending, (state) => {
        state.loading.services = true;
        state.error = "";
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.loading.services = false;
        state.services = action.payload;
      })
      .addCase(getServices.rejected, (state, action) => {
        state.loading.services = false;
        state.error = action?.payload;
      });
  },
});

export const {
  resetState,
  addQueryParams,
  appendUser,
  setProductCategory,
  resetProductFormFilters,
} = filtersSlice.actions;
export default filtersSlice.reducer;
