import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { useMediaQuery } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Notifications from "components/Header/Notifications";
import PropTypes from "prop-types";
import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { logout } from "store/slices/authSlice";
import { SEARCH_ICON_COLOR } from "styles/colors";
import { toast } from "utils/hooks/useToast";

function Header({ handleDrawerOpen }) {
  const path = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const userName = useSelector((state) => state.auth.fullName ?? "");
  const isSidebarOpen = useSelector((state) => state.UI.isSidebarOpen);
  const isOpenedByUser = useSelector((state) => state.UI.isSidebarOpenedByUser);
  const [searchType, setSearchType] = useState(SEARCH_OPTIONS.PRODUCT);
  const [searchValue, setSearchValue] = useState("");
  const isOpen = isMedium ? false : isSidebarOpen || isOpenedByUser;

  const handleSelect = useCallback((selectValue) => {
    setSearchType(selectValue.target.value);
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      let _params = {};
      let url = "";

      switch (searchType) {
        case SEARCH_OPTIONS.PRODUCT:
          _params.name = "productName";
          url = "/products";
          break;
        case SEARCH_OPTIONS.VIDEO:
          _params.name = "title";
          url = "/videos";
          break;
        default:
          _params.name = "productName";
          url = "/dashboard";
          break;
      }

      _params.value = searchValue;

      return navigate({
        pathname: url,
        search: createSearchParams(_params).toString(),
      });
    },
    [navigate, searchType, searchValue]
  );

  const handleLogout = useCallback(() => {
    toast.success("You have been logged out");
    dispatch(logout());
    navigate("/");
  }, [dispatch, navigate]);

  return (
    <AppBar
      open={isOpen}
      sx={{
        backgroundColor: "#f9f9f9",
        marginTop: "15px",
        position: "absolute",
        padding: "0 !important",
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;",
      }}
    >
      <Toolbar sx={{ backgroundColor: "#fff", padding: "0.5rem" }}>
        <IconButton
          aria-label="open drawer"
          edge="start"
          sx={{
            ...(isOpen && { display: "none" }),
          }}
          onClick={handleDrawerOpen}
          size="large"
          color="primary.main"
        >
          <MenuIcon />
        </IconButton>
        <Search>
          <SearchIconWrapper>
            <SearchIcon sx={{ color: SEARCH_ICON_COLOR }} />
          </SearchIconWrapper>
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <StyledInputBase
              fullWidth
              placeholder="What are you looking for today?"
              onChange={(e) => {
                if (!e.target.value) {
                  navigate({
                    pathname: path.pathname,
                  });
                }
                setSearchValue(e.target.value);
              }}
              value={searchValue}
              sx={{ color: "#000" }}
            />
          </form>
          <Select
            sx={styledContainer}
            onChange={handleSelect}
            value={searchType}
            IconComponent={ExpandMoreIcon}
          >
            {Object.values(SEARCH_OPTIONS).map((option) => (
              <MenuItem sx={{ fontSize: 16 }} key={option} dense value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Search>
        <Notifications />
        <Box display="flex" alignItems="center">
          <Box
            sx={{
              margin: "0 12px 0 12px",
            }}
          >
            <Avatar
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              sx={{
                width: "3rem",
                height: "3rem",
                textTransform: "capitalize",
              }}
            >
              {userName.split(" ")[0][0]}
            </Avatar>
          </Box>
          <Box>
            <Typography color="#5e5873" fontWeight="bold" fontSize="1rem">
              {userName?.split(" ")[0] ?? "N/A"}
            </Typography>
          </Box>
          <Box ml={2}>
            <Select sx={styledAuthDetails} IconComponent={ExpandMoreIcon}>
              <MenuItem onClick={() => navigate("/settings")}>Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Select>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default memo(Header);

Header.propTypes = {
  handleDrawerOpen: PropTypes.func,
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    [theme.breakpoints.up("xxl")]: {
      marginRight: "1.6%",
      width: `calc(100% - 338px)`,
    },
    [theme.breakpoints.down("xxl")]: {
      marginRight: "2.1%",
      maxWidth: ` calc(100% - 20.5rem)`,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: SEARCH_ICON_COLOR,
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.7, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const styledContainer = {
  color: SEARCH_ICON_COLOR,
  height: "auto",
  borderLeft: "1px solid #e3eaf0 !important",
  borderRadius: "5px",
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
    boxShadow: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
};

const styledAuthDetails = {
  color: SEARCH_ICON_COLOR,
  height: "auto",
  padding: 0,
  borderRadius: "0px",
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
    boxShadow: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
};

const Search = styled("div")({
  position: "relative",
  flexGrow: "1",
  height: "3rem",
  display: "flex",
  justifyContent: "space-between",
  borderRadius: "3px",
  backgroundColor: "#f9f9f9",
  marginLeft: 0,
});

const SEARCH_OPTIONS = {
  PRODUCT: "Products",
  VIDEO: "Videos",
};
