import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import BidStatusesButtons from "components/Bids/BidStatusesButtons";
import DataGrid from "components/Bids/DataGrid";
import DateFilters from "components/Bids/DateFilters";
import { memo } from "react";

const Bids = () => {
  return (
    <Box px={1} py={2}>
      <Typography
        variant="h4"
        fontWeight="600"
        mb={1}
        sx={{ fontSize: { md: "1.5rem", lg: "2rem" } }}
      >
        Bids
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <BidStatusesButtons />
        <DateFilters />
      </Box>
      <DataGrid />
    </Box>
  );
};

export default memo(Bids);
