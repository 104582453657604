import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadBlogBannerImage, uploadBlogImage } from "services/blogServices";
import { getErrorMessage, getResponseData } from "utils/helpers/httpRequests";
import { toast } from "utils/hooks/useToast";
import { httpMain } from "utils/httpRequest/http";

export const getBlogs = createAsyncThunk(
  "blogs/getBlogs",
  async (params = {}, { rejectWithValue }) => {
    try {
      const response = await httpMain.get("/cms-pages/admin", {
        params: {
          ...params,
          pageType: "blog",
          page: params.page ?? 1,
          limit: params.limit ?? 20,
        },
      });
      const responseData = getResponseData(response);

      responseData &&
        responseData?.results.forEach((item, index) => {
          const pageStart = (responseData?.page - 1) * responseData?.limit;
          item.serialNumber = pageStart + index + 1;
        });

      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getBlog = createAsyncThunk(
  "blogs/getBlog",
  async (id, { getState, fulfillWithValue, rejectWithValue }) => {
    const results = getState().blogs.results;
    const blog = results.find((data) => data.id === id);
    if (blog) {
      return fulfillWithValue(blog);
    }
    try {
      const response = await httpMain.get(`/cms-pages/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const submitBlog = createAsyncThunk(
  "blogs/submitBlog",
  async (data = {}, { rejectWithValue }) => {
    try {
      const { image, bannerImage, ...rest } = data;
      const response = await httpMain.post(`/cms-pages`, {
        ...rest,
        pageType: "blog",
      });

      const responseData = getResponseData(response);

      if (!responseData?.id) {
        throw new Error("Something went wrong!");
      }
      if (image && typeof image === "object") {
        await uploadBlogImage(responseData?.id, image);
      }

      if (bannerImage && typeof bannerImage === "object") {
        await uploadBlogBannerImage(responseData?.id, bannerImage);
      }

      toast.success("Blog Created Successfully");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteBlog = createAsyncThunk(
  "blogs/deleteBlog",
  async (blogId, { getState, rejectWithValue }) => {
    const results = getState().blogs.results;
    try {
      const response = await httpMain.delete(`/cms-pages/${blogId}`);
      if (response.status === 200) {
        const returnedData = results.filter((data) => data.id !== blogId);
        toast.success("Blog Deleted Successfully");
        return returnedData;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateBlog = createAsyncThunk(
  "blogs/updateBlog",
  async (values = {}, { rejectWithValue }) => {
    try {
      const { id = "", data = {} } = values;
      const { image, bannerImage, ...rest } = data;
      let response = {};
      if (Object.keys(rest).length !== 0) {
        response = await httpMain.patch(`/cms-pages/${id}`, {
          ...rest,
        });
        response = getResponseData(response);
      }

      if (image && typeof image === "object") {
        await uploadBlogImage(id, image);
      }

      if (bannerImage && typeof bannerImage === "object") {
        await uploadBlogBannerImage(id, bannerImage);
      }
      toast.success("Blog Updated successfully");
      return response;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: true,
  blogLoading: true,
  selectedBlog: null,
  results: [],
  page: 1,
  limit: 20,
  totalPages: 0,
  totalResults: 0,
  error: "",
  blogError: "",
};

const blogsSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    // Actions
    resetBlogsState: () => initialState,
    resetBlogState: (state) => {
      state.loading = false;
      state.selectedBlog = null;
      state.blogError = "";
    },
    setBlog: (state, action) => {
      state.selectedBlog = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBlogs.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.results = action.payload.results;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
        state.loading = false;
      })
      .addCase(getBlogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteBlog.pending, (state) => {
        state.loading = true;
        state.blogError = "";
      })
      .addCase(deleteBlog.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
        state.blogError = "";
      })
      .addCase(deleteBlog.rejected, (state, action) => {
        state.loading = false;
        state.blogError = action.payload;
      })
      .addCase(submitBlog.pending, (state) => {
        state.blogLoading = true;
        state.blogError = "";
      })
      .addCase(submitBlog.fulfilled, (state, action) => {
        state.blogLoading = false;
        state.blog = action.payload;
        state.blogError = "";
      })
      .addCase(submitBlog.rejected, (state, action) => {
        state.blogLoading = false;
        state.blogError = action.payload;
      })
      .addCase(updateBlog.pending, (state) => {
        state.blogLoading = true;
        state.blogError = "";
      })
      .addCase(updateBlog.fulfilled, (state, action) => {
        state.blogLoading = false;
        state.blog = action.payload;
      })
      .addCase(updateBlog.rejected, (state, action) => {
        state.blogLoading = false;
        state.blogError = action.payload;
      })
      .addCase(getBlog.pending, (state) => {
        state.blogLoading = true;
        state.blogError = "";
      })
      .addCase(getBlog.fulfilled, (state, action) => {
        state.blogLoading = false;
        state.selectedBlog = action.payload;
      })
      .addCase(getBlog.rejected, (state, action) => {
        state.blogLoading = false;
        state.blogError = action.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetBlogsState, setBlog, resetBlogState } = blogsSlice.actions;

// Exporting default reducer
export default blogsSlice.reducer;
