import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/httpRequests";
import { toast } from "utils/hooks/useToast";
import { httpMain } from "utils/httpRequest/http";

export const getBids = createAsyncThunk(
  "bids/getBids",
  async (params = {}, { rejectWithValue }) => {
    try {
      const response = await httpMain.get("/bid/admin", {
        params: {
          ...params,
          page: params.page ?? 1,
          limit: params.limit ?? 20,
          sortBy: "-createdAt",
          // bidCancel: true,
        },
      });
      const responseData = getResponseData(response);

      responseData &&
        responseData?.results.forEach((item, index) => {
          const pageStart = (responseData?.page - 1) * responseData?.limit;
          item.serialNumber = pageStart + index + 1;
        });

      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const bidderCancelation = createAsyncThunk(
  "pages/updatePage",
  async (id = "", { rejectWithValue }) => {
    try {
      const response = await httpMain.get(`/bid/${id}/cancel`);
      toast.success("Bid Updated successfully");
      return response?.data;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const listerCancelation = createAsyncThunk(
  "pages/updatePage",
  async (id = "", { rejectWithValue }) => {
    try {
      const response = await httpMain.get(`/bid/${id}/lister-cancelation`);
      toast.success("Bid Updated successfully");
      return response?.data;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: true,
  pageLoading: true,
  selectedPage: null,
  results: [],
  page: 1,
  limit: 20,
  totalPages: 0,
  totalResults: 0,
  error: "",
  pageError: "",
};

const bidsSlice = createSlice({
  name: "bids",
  initialState,
  reducers: {
    // Actions
    resetBidsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBids.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getBids.fulfilled, (state, action) => {
        state.results = action.payload.results;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
        state.loading = false;
      })
      .addCase(getBids.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetBidsState } = bidsSlice.actions;

// Exporting default reducer
export default bidsSlice.reducer;
