import { memo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

function ProtectedRoute() {
  const token = useSelector((state) => state.auth.token);
  const userRole = useSelector((state) => state.auth?.userRole);
  const isEmailVerified = useSelector(
    (state) => state.auth.data?.user?.isEmailVarified
  );
  const isPhoneVerified = useSelector(
    (state) => state.auth.data?.user?.isPhoneVarified
  );
  const isVerified = isEmailVerified && isPhoneVerified;

  if (token && !isVerified) {
    return <Navigate to="/verification" />;
  }

  if (userRole && isVerified) {
    return <Outlet />;
  }

  return <Navigate to="/login" />;
}
export default memo(ProtectedRoute);
