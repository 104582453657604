import SubscriptionsDataGrid from "components/Subscriptions/SubscriptionsDataGrid";
import SubscriptionsForm from "components/Subscriptions/SubscriptionsForm";
import PropTypes from "prop-types";
import { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { store } from "store/configureStore";
import {
  getSubscriptions,
  resetSubscriptionsState,
} from "store/slices/subscriptionsSlice";

function Subscriptions({ create, update }) {
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(resetSubscriptionsState()), [dispatch]);

  if (create || update) return <SubscriptionsForm />;

  return <SubscriptionsDataGrid />;
}

Subscriptions.propTypes = {
  create: PropTypes.bool,
  update: PropTypes.bool,
};

Subscriptions.defaultProps = {
  create: false,
  update: false,
};

export default memo(Subscriptions);

export const loadSubscriptions = () => {
  store.dispatch(getSubscriptions());
  return null;
};
