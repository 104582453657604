import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid } from "@mui/x-data-grid";
import productPlaceholder from "assets/images/imagePlaceholder.png";
import CurrencyFormatter from "components/CurrencyFormatter";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAuctionProducts } from "store/slices/auctionsSlice";
import {
  getAuctionProducts as getProducts,
  updateProduct,
} from "store/slices/productsSlice";
import { STATS_NUMBER_FORMATTER_COLOR } from "styles/colors";
import statusBadges from "utils/helpers/statusBadge";
import { toast } from "utils/hooks/useToast";

function CustomAuctionDataGrid({ formik }) {
  const dispatch = useDispatch();
  const params = useParams();

  const { results, loading, totalPages, page } = useSelector(
    (state) => state.products
  );
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  const handlePagination = useCallback(
    (_, page) => {
      dispatch(getProducts({ page }));
    },
    [dispatch]
  );

  useEffect(() => {
    const id = setTimeout(() => {
      dispatch(getProducts());
    }, [500]);
    return () => clearTimeout(id);
  }, [dispatch]);

  const onSelectionModelChange = (ids) => {
    const selectedRowsData = ids.map((id) =>
      results.find((row) => row.id === id)
    );
    formik.setFieldValue("selectedProducts", selectedRowsData);
  };

  const addProductAuction = useCallback(
    (productId) => {
      dispatch(updateProduct({ productId, data: { auctionId: params.id } }))
        .unwrap()
        .then(() => {
          toast.success("Product added to auction successfully");
          dispatch(getAuctionProducts({ auctionId: params.id }));
        });
    },
    [dispatch, params]
  );

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 50,
        flex: 0.1,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        sortable: false,
      },
      {
        field: "image",
        headerName: "Image",
        type: "actions",
        minWidth: 115,
        flex: 0.1,
        renderCell: (params) => (
          <Box>
            <img
              src={
                params?.row?.mainImage
                  ? params?.row?.mainImage
                  : productPlaceholder
              }
              height={isTablet ? "80px" : isLed ? "85px" : "113px"}
              width={isTablet ? "80px" : isLed ? "85px" : "113px"}
              style={{
                borderRadius: "10px",
                marginTop: 4,
                objectFit: "contain",
              }}
              alt={params?.row?.title}
            />
          </Box>
        ),
      },
      {
        field: "Name/Details",
        headerName: "Name/Details",
        minWidth: 300,
        flex: 1,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params) => (
          <Box>
            <Typography
              sx={{
                textTransform: "capitalize",
                color: "#000000",
                overflow: "hidden",
              }}
            >
              {params?.row?.productName}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography
                sx={{
                  height: "12px",
                  width: "12px",
                  margin: "5px 6px 3px 0",
                  backgroundColor: statusBadges(params?.row?.status).color,
                  borderRadius: "20px",
                }}
              />
              <Typography
                textTransform="capitalize"
                sx={{ color: statusBadges(params?.row?.status).color }}
              >
                {params?.row?.status}
              </Typography>
            </Box>
            <Box display="flex" color="primary.main">
              Lot #
              <Typography
                sx={{ color: STATS_NUMBER_FORMATTER_COLOR, marginLeft: 0.5 }}
              >
                {params?.row?.lotNumber}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "reservePrice",
        headerName: "Bid Value",
        minWidth: 125,
        flex: 0.25,
        editable: true,
        renderCell: (params) => (
          <CurrencyFormatter number={params?.row?.reservePrice ?? 0} />
        ),
      },
      {
        field: "bidStart",
        headerName: "Date & Time",
        minWidth: 225,
        flex: 0.3,
        headerAlign: "center",
        align: "center",
        editable: true,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Typography>
            {dayjs(params?.row?.bidStart).format("DD/MM/YY [At] h:mm A")}
          </Typography>
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        align: "center",
        minWidth: 150,
        flex: 0.25,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => (
          <Button
            sx={{
              minWidth: 150,
              maxWidth: 160,
              minHeight: 40,
            }}
            variant="contained"
            onClick={() => addProductAuction(params?.id)}
          >
            Add Auction
          </Button>
        ),
      },
    ],
    [isTablet, isLed, addProductAuction]
  );

  const customPagination = useCallback(() => {
    return (
      <Pagination
        variant="outlined"
        color="primary"
        sx={{ marginY: 4 }}
        size={isTablet ? "small" : "large"}
        count={totalPages}
        page={page}
        disabled={totalPages === 1}
        onChange={handlePagination}
      />
    );
  }, [isTablet, totalPages, page, handlePagination]);

  const customNoRecordFound = useCallback(() => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No record found
      </Stack>
    );
  }, []);

  return (
    <Box sx={containerStyles}>
      <DataGrid
        container
        rows={results}
        columns={columns}
        loading={loading}
        pagination
        autoHeight
        disableRowSelectionOnClick
        disableColumnMenu
        rowsPerPageOptions={[10, 15, 20]}
        slots={{
          pagination: customPagination,
          noRowsOverlay: customNoRecordFound,
        }}
        onRowSelectionModelChange={onSelectionModelChange}
        isCellEditable={() => false}
        getRowHeight={() => "auto"}
        getRowSpacing={(params) => ({
          top: params.isFirstVisible ? 0 : 6,
          bottom: params.isLastVisible ? 0 : 6,
        })}
        getRowId={(row) => row?._id ?? row?.id}
        sx={{
          "& .MuiDataGrid-row": { minHeight: "100px !important" },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
            {
              outline: "none",
            },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "1.25rem",
          },
        }}
      />
    </Box>
  );
}

CustomAuctionDataGrid.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(CustomAuctionDataGrid);

const containerStyles = {
  width: "100%",
  "& .MuiDataGrid-root": {
    border: "none",
    fontSize: "1rem",
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  },
  "& .MuiPaginationItem-root.Mui-selected": {
    border: "none",
  },
  "& .MuiPaginationItem-outlinedPrimary": {
    border: "none",
  },
  "& .MuiPaginationItem-previousNext": {
    backgroundColor: "#078ecf1c",
  },
  "& .MuiDataGrid-cell": {
    color: "primary",
    borderBottom: "0px !important",
  },
  "& .MuiDataGrid-row": {
    backgroundColor: "#fff !important",
    boxShadow: "0px 0px 15px -15px rgba(0,0,0,1)",
    borderRadius: "10px",
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "#fff !important",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "0px !important",
    my: 1,
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnSeparator": {
    visibility: "hidden",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
};
