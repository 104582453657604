import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Divider from "@mui/material/Divider";
import MobileDateTime from "components/Pickers/MobileDateTime";
import {
  getAuctionById,
  getAuctionProducts,
  resetAuction,
  resetAuctionState,
  submitAuction,
  updateAuctions,
} from "store/slices/auctionsSlice";
import { resetState } from "store/slices/productsSlice";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import parseEditorValue from "utils/helpers/parseEditorValue";
import reduceObject from "utils/helpers/reduceObject";
import StyledTextField from "utils/helpers/styledTextField";
import * as Yup from "yup";
import AddAuctionProducts from "../AddAuctionProducts";
import AuctionDescriptionSection from "./AuctionDescriptionSection";
import AuctionMediaSection from "./AuctionMediaSection";
import DeleteAuctionProducts from "./DeleteAuctionProducts";

function AuctionForm() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auctions = useSelector((state) => state.auctions.auction);
  const loading = useSelector((state) => state.auctions.auctionLoading);

  useEffect(() => {
    params?.id && dispatch(getAuctionById(params.id));
  }, [dispatch, params.id]);

  useEffect(() => {
    if (!params.id) {
      dispatch(resetAuction());
    }
  }, [dispatch, params.id]);

  useEffect(
    () => () => {
      dispatch(resetAuctionState());
      dispatch(resetState());
    },
    [dispatch]
  );

  useEffect(() => {
    if (params?.id) {
      dispatch(getAuctionProducts({ auctionId: params.id }));
    }
  }, [dispatch, params.id]);

  const initialValues = useMemo(
    () => ({
      id: auctions?.id,
      title: auctions?.title ?? "",
      description: auctions?.description
        ? parseEditorValue(auctions?.description)
        : "",
      startDate: auctions?.startDate ?? "",
      endDate: auctions?.endDate ?? "",
      auctionImage: auctions?.auctionImage ?? "",
    }),
    [auctions]
  );

  const handleSubmit = useCallback(
    (values) => {
      if (params?.id) {
        let formValues = { ...values };
        let auctionValues = { ...auctions };

        auctionValues = {
          ...auctionValues,
          auctionImage: auctionValues.auctionImage,
        };

        let updatedValues = reduceObject(formValues, auctionValues);

        dispatch(updateAuctions({ auctionId: params.id, ...updatedValues }))
          .unwrap()
          .then(() => {
            dispatch(resetAuctionState());
            navigate("/auctions");
          });
      } else {
        let auctionData = {
          title: values?.title,
          description: values?.description,
          startDate: values?.startDate,
          endDate: values?.endDate,
        };
        dispatch(
          submitAuction({ auctionData, auctionImage: values?.auctionImage })
        )
          .unwrap()
          .then((response) => {
            dispatch(resetAuctionState());
            navigate(`/auctions/update/${response?.data?.id}`);
          });
      }
    },
    [auctions, dispatch, navigate, params.id]
  );

  return (
    <Box px={1}>
      <Typography
        variant="h1"
        fontWeight="600"
        color="primary.main"
        fontSize={{ lg: "2.6rem", sm: "2rem" }}
        my={2}
      >
        {params?.id ? "Update Auction" : "Add Auction"}
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <StyledMuiCard
                  title="Basic Information"
                  subTitle="Enter the basic information to create the new auction"
                >
                  <CardContent>
                    <Stack direction="row" gap={3} p={{ xs: 1, md: 1.5 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Auction Name
                            </Typography>
                            <StyledTextField
                              fullWidth
                              type="text"
                              {...formik.getFieldProps("title")}
                              error={Boolean(
                                formik.touched.title && formik.errors.title
                              )}
                              helperText={
                                formik.touched.title && !!formik.errors.title
                                  ? formik.errors.title
                                  : "Enter a descriptive title of the auction here"
                              }
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={3} lg={3}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Auction Start Date
                            </Typography>
                            <MobileDateTime
                              fullWidth
                              name="startDate"
                              value={dayjs(formik.values.startDate)}
                              error={Boolean(
                                formik.touched.startDate &&
                                  formik.errors.startDate
                              )}
                              onBlur={formik.handleBlur}
                              onChange={(value) =>
                                formik.setFieldValue(
                                  "startDate",
                                  value.toString(),
                                  true
                                )
                              }
                              helperText={
                                formik.touched.startDate &&
                                !!formik.errors.startDate
                                  ? formik.errors.startDate
                                  : "Please enter the start date"
                              }
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={3} lg={3}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Auction End Date
                            </Typography>
                            <MobileDateTime
                              fullWidth
                              name="endDate"
                              value={dayjs(formik.values.endDate)}
                              error={Boolean(
                                formik.touched.endDate && formik.errors.endDate
                              )}
                              onBlur={formik.handleBlur}
                              onChange={(value) =>
                                formik.setFieldValue(
                                  "endDate",
                                  value.toString(),
                                  true
                                )
                              }
                              helperText={
                                formik.touched.endDate &&
                                !!formik.errors.endDate
                                  ? formik.errors.endDate
                                  : "Please enter the end date"
                              }
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  </CardContent>
                </StyledMuiCard>
              </Grid>
              {/*Auction Description Section */}
              <Grid item xs={12} lg={12}>
                <AuctionDescriptionSection formik={formik} />
              </Grid>
              {/*Auction Media Section */}
              <Grid item xs={12} lg={12}>
                <AuctionMediaSection formik={formik} />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" gap={2}>
                  <Button
                    disabled={loading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={formik.handleSubmit}
                    disableElevation
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    {params?.id ? "Update" : `Submit`}
                  </Button>
                  <Button
                    disabled={formik.isSubmitting}
                    onClick={() => formik.resetForm()}
                    variant="outlined"
                    color="primary"
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, lg: 175, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    Reset
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {params?.id && (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            {/*Remove Auction Products Section */}
            <Divider variant="middle" sx={{ my: 2 }} />
            <DeleteAuctionProducts />
            <Divider variant="middle" />
            <AddAuctionProducts />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default memo(AuctionForm);

const validationSchema = Yup.object().shape({
  id: Yup.string(),
  title: Yup.string()
    .trim()
    .required("Required*")
    .min(10, "Title should be at least 10 characters long")
    .max(100, "Title is too long"),
  startDate: Yup.date().when("id", {
    is: (id) => !id,
    then: () =>
      Yup.date()
        .required("Required*")
        .min(new Date(), "Start date must be greater than current date."),
    otherwise: () => Yup.date().required("Required"),
  }),
  endDate: Yup.string()
    .required("End Date is required")
    .test(
      "is-valid",
      "End time should not be greater than 4 hours from the start time",
      function (value) {
        const { startDate } = this.parent;
        const startTimeObj = new Date(startDate);
        const endTimeObj = new Date(value);
        const timeDiff = endTimeObj - startTimeObj;
        const maxDiff = 4 * 60 * 60 * 1000;

        return timeDiff <= maxDiff;
      }
    ),
  description: Yup.string()
    .trim()
    .required("Required*")
    .min(10, "Description must be at least 10 characters long")
    .max(200, "Description is too long"),
  auctionImage: Yup.mixed().required("Auction Banner Image is required"),
});
