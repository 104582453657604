import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

function AuthRoute() {
  const token = useSelector((state) => state.auth.token);

  if (token) {
    return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
}

export default AuthRoute;
