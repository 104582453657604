import { httpMain } from "utils/httpRequest/http";

export const updateSellWithUsFormData = (sellId = "", formData) => {
  return httpMain.patch(`/sell/${sellId}`, formData);
};

export const uploadSellWithUsGalleryImages = (sellId = "", images = []) => {
  const formData = new FormData();
  images.forEach((image) => {
    if (typeof image === "object") formData.append(`sellWithUsGallery`, image);
  });
  return httpMain.patch(`/sell/${sellId}`, formData);
};
