export const removeDefaultDatabaseProperties = (obj) => {
  // Create a new object to store the filtered properties
  let newObj = {};

  // List of default properties to be removed
  const defaultProperties = [
    "id",
    "_id",
    "createdAt",
    "updatedAt",
    "vin",
    "chassis number",
  ];

  // Iterate through the keys of the original object
  for (let key in obj) {
    // Check if the key is not a default property
    if (!defaultProperties.includes(key)) {
      // Add the property to the new object
      newObj[key] = obj[key];
    }
  }
  return newObj;
};
