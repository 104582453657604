export const getSubscriptionFormValues = (data = {}, isUpdate = false) => ({
  ...(data?.subType && { subType: data?.subType }),
  ...(data?.minDeposit && { minDeposit: data?.minDeposit }),
  ...(data?.depositMultiplier && {
    depositMultiplier: data?.depositMultiplier,
  }),
  ...(data?.bidCommission && { bidCommission: data?.bidCommission }),
  ...(data?.subscriptionRole && { subscriptionRole: data?.subscriptionRole }),
  ...(data?.auction && { auction: data?.auction }),
  ...(data?.view && { view: data?.view }),
  ...(data?.watchlist && { watchlist: data?.watchlist }),
  ...(data?.alerts && { alerts: data?.alerts }),
  ...((data?.commission || data?.yalaBidShare || data?.listerShare) && {
    bidCancelation: {
      ...(data?.commission && { commission: data?.commission }),
      ...(data?.yalaBidShare && { yalaBidShare: data?.yalaBidShare }),
      ...(data?.listerShare && { listerShare: data?.listerShare }),
    },
  }),
  ...((data?.price || data?.chargesType) && {
    charges: {
      ...(data?.price && { price: data?.price }),
      ...(data?.chargesType && { chargesType: data?.chargesType }),
      currency: "AED",
    },
  }),
  bidEvent: data?.bidEvent,
  ...(isUpdate && { updateAll: data?.updateAll }),
});
