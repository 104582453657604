import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { memo } from "react";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import DescriptionEditor from "./DescriptionEditor";

function ProductDetails({ formik }) {
  return (
    <StyledMuiCard
      title="Product Details"
      subTitle="Please enter the basic information of the product such as name and description"
    >
      <CardContent>
        <Stack direction="column" p={2}>
          <Stack direction="column" gap={0.5} pb={2}>
            <Typography variant="h4" color="text.secondary" fontWeight={600}>
              Description
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Enter product details with specifications. Tip: Use bullet points
              for easy viewing
            </Typography>
          </Stack>
          <DescriptionEditor formik={formik} />
          {formik.errors.description && formik.touched.description && (
            <Typography
              variant="subtitle1"
              color={formik?.errors?.description ? "error" : "text.secondary"}
            >
              {formik.errors.description}
            </Typography>
          )}
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

ProductDetails.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(ProductDetails, arePropsEqual);

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.formik.values.description ===
      nextProps.formik.values.description &&
    prevProps.formik.errors.description ===
      nextProps.formik.errors.description &&
    prevProps.formik.touched.description ===
      nextProps.formik.touched.description
  );
}
