import { showToast } from "store/slices/uiSlice";

export const toast = {
  success: (message = "") =>
    window.dispatch(
      showToast({
        message,
      })
    ),
  error: (message = "") =>
    window.dispatch(
      showToast({
        severity: "error",
        message,
      })
    ),
  warning: (message = "") =>
    window.dispatch(
      showToast({
        severity: "warning",
        message,
      })
    ),
  info: (message = "") =>
    window.dispatch(
      showToast({
        severity: "info",
        message,
      })
    ),
};
