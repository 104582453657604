import RemoveCircle from "@mui/icons-material/RemoveCircle";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ProductGalleryImagePlaceholder from "assets/icons/ProductGalleryImagePlaceholder.svg";
import addFilePlaceholder from "assets/icons/addFilePlaceholder.svg";
import AddVideos from "components/SellWithUs/AddVideos";
import PropTypes from "prop-types";
import { memo, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateSellWithUsFormData } from "services/sellWithUsService";
import {
  setImageValidationError,
  setIsImageValidationDialogOpen,
} from "store/slices/uiSlice";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import { getErrorMessage } from "utils/helpers/httpRequests";
import { toast } from "utils/hooks/useToast";

function Media({ formik }) {
  const dispatch = useDispatch();
  const galleryRef = useRef(null);

  const handleGallery = async (e) => {
    if (formik.values?.sellWithUsGallery?.length + e.target.files?.length > 6) {
      dispatch(setIsImageValidationDialogOpen(true));
      dispatch(setImageValidationError("You can upload a maximum of 8 images"));
    } else {
      const images = [];

      for (const image of e.target.files) {
        try {
          // function params
          // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
          // const validatedImage = await validateImageAspectRatio(
          //   image,
          //   500,
          //   500,
          //   false,
          //   true,
          //   1 / 1,
          //   "1 / 1"
          // );
          images.push(image);
        } catch (error) {
          galleryRef.current.value = "";
          dispatch(setIsImageValidationDialogOpen(true));
          dispatch(setImageValidationError(error.message));
          break; // Exit the function on error
        }
      }
      formik.setFieldValue("sellWithUsGallery", [
        ...formik.values.sellWithUsGallery,
        ...images,
      ]);
    }
  };

  const handleRemoveGalleryImage = (index) => {
    const image = formik.values?.sellWithUsGallery[index];
    if (typeof image === "string") {
      const formData = new FormData();
      formData.append("removeImages[0]", image);
      updateSellWithUsFormData(formik.values.id, formData)
        .then(() => {
          const newGallery = formik.values?.sellWithUsGallery.filter(
            (_image) => _image !== image
          );
          formik.setFieldValue("sellWithUsGallery", newGallery);
        })
        .catch((error) => {
          toast.error(getErrorMessage(error));
        })
        .finally(() => {});
    } else {
      formik.setFieldValue(
        "sellWithUsGallery",
        formik.values?.sellWithUsGallery.toSpliced(index, 1)
      );
      galleryRef.current.value = "";
    }
  };

  return (
    <StyledMuiCard
      title="Media Section"
      subTitle="Upload images related to sell with us"
    >
      <CardContent sx={{ px: 4 }}>
        <Box display="flex" flexWrap="wrap" gap={{ xs: 3, lg: 4 }}>
          <Stack direction="column" gap={3}>
            {/* Gallery Images */}
            <Stack direction="column">
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight={600}
                pb={2}
              >
                Gallery Images
              </Typography>
              <Box display="flex" flexDirection="row" gap={4}>
                {formik.values?.sellWithUsGallery?.length < 6 && (
                  <img
                    ref={galleryRef}
                    src={addFilePlaceholder}
                    alt="Add  Gallery Placeholder"
                    height="150px"
                    width="150px"
                    style={{ cursor: "pointer" }}
                    onClick={() => galleryRef.current.click()}
                  />
                )}
                {!formik.values?.sellWithUsGallery?.length
                  ? [...Array(4)].map((e, i) => (
                      <Box key={i}>
                        <GalleryImagePlaceholder />
                      </Box>
                    ))
                  : formik.values?.sellWithUsGallery.map((image, index) => (
                      <Stack
                        direction="row"
                        key={index}
                        alignItems="flex-start"
                      >
                        <img
                          src={
                            typeof image === "string"
                              ? image
                              : typeof image === "object" &&
                                URL.createObjectURL(image)
                          }
                          style={{ objectFit: "cover" }}
                          alt=""
                          height="150px"
                          width="150px"
                        />
                        <IconButton
                          onClick={() => handleRemoveGalleryImage(index)}
                        >
                          <RemoveCircle />
                        </IconButton>
                      </Stack>
                    ))}
              </Box>
              <input
                type="file"
                ref={galleryRef}
                style={{ display: "none" }}
                id="upload-product-gallery-images"
                hidden
                multiple
                accept="image/x-png,image/png,image/jpeg,image/jpg"
                onChange={handleGallery}
              />
              <Typography
                variant="subtitle1"
                color={
                  formik?.errors?.sellWithUsGallery ? "error" : "text.secondary"
                }
                mt={0.25}
              >
                {formik?.errors?.sellWithUsGallery}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <AddVideos formik={formik} />
      </CardContent>
    </StyledMuiCard>
  );
}

Media.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(Media);

function GalleryImagePlaceholder() {
  return (
    <img
      src={ProductGalleryImagePlaceholder}
      alt="Product Gallery Placeholder"
      height="auto"
      width="150px"
    />
  );
}
