import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAuctionProducts } from "store/slices/auctionsSlice";
import { deleteAuctionProduct } from "store/slices/productsSlice";
import { resetDeleteDialogState } from "store/slices/uiSlice";

function ConfirmDelete() {
  const dispatch = useDispatch();
  const params = useParams();
  const productId = useSelector((state) => state.UI.deleteId);
  const isOpen = useSelector((state) => state.UI.isConfirmDeletionDialogOpen);

  const handleClose = useCallback(() => {
    dispatch(resetDeleteDialogState());
  }, [dispatch]);

  const handleDelete = useCallback(() => {
    dispatch(deleteAuctionProduct({ productId, data: { auctionId: null } }))
      .unwrap()
      .then(() => {
        dispatch(getAuctionProducts({ auctionId: params?.id }));
      });
    dispatch(resetDeleteDialogState());
  }, [dispatch, params?.id, productId]);

  return (
    <Dialog open={isOpen} keepMounted onClose={handleClose}>
      <DialogTitle>Are you sure you want to delete this?</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>No</Button>
        <Button onClick={handleDelete}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ConfirmDelete);
