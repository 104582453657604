export const NOTIFICATION_TYPE = {
  BID: "bid",
  AUCTION: "auction",
  TRANSACTION: "transaction",
};

export const NOTIFICATION_EVENT = {
  ALL_NOTIFICATIONS: "allNotifications",
  UNREAD_NOTIFICATIONS: "unreadNotifications",
};
