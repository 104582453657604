import { httpMain } from "utils/httpRequest/http";

export const uploadCategoryImage = (
  categoryId = "",
  image = null,
  type = ""
) => {
  const formData = new FormData();
  formData.append(type, image);
  return httpMain.post(`/categories/${categoryId}`, formData);
};
