import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { memo } from "react";
import { useSelector } from "react-redux";
import { STATS_NUMBER_FORMATTER_COLOR } from "styles/colors";

const AuctionDetails = ({ day, date, title, description }) => {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);

  return (
    <Grid container display="flex" mt={1} spacing={0.4}>
      <Grid item md={2}>
        <Typography
          color={STATS_NUMBER_FORMATTER_COLOR}
          fontSize={{ xl: isOpen ? "0.851rem" : "0.875rem" }}
          fontWeight="bold"
          textAlign="center"
        >
          {day}
        </Typography>
        <Typography
          color={STATS_NUMBER_FORMATTER_COLOR}
          fontSize={{ xl: isOpen ? "0.851rem" : "0.875rem" }}
          fontWeight="bold"
          textAlign="center"
        >
          {date}
        </Typography>
      </Grid>
      <Grid item md={10} borderLeft="1px solid" borderColor="#d8d6de">
        <Box ml={1}>
          <Typography
            fontSize={{ xl: isOpen ? "0.851rem" : "0.875rem" }}
            color="#707070"
            fontWeight="600"
          >
            {title}
          </Typography>
          <Typography
            fontSize={{ xl: isOpen ? "0.851rem" : "0.875rem" }}
            color="#707070"
          >
            {description}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
export default memo(AuctionDetails);

AuctionDetails.propTypes = {
  day: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

AuctionDetails.defaultProps = {
  dat: "N/A",
  date: 0,
  title: "N/A",
  description: "N/A",
};
