import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import WritingIcon from "assets/icons/WritingIcon.svg";
import deleteIcon from "assets/icons/deleteIcon.svg";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledDataGrid from "components/StyledGrid";
import StyledSwitch from "components/ToggleButton";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteBanner,
  getBanners,
  updateBannerStatus,
} from "store/slices/bannersSlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import useQueryParams from "utils/hooks/useQueryParams";
import eyeIcon from "../../assets/icons/eyeIcon.svg";

export default function DataGrid() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { results, loading, totalPages } = useSelector(
    (state) => state.banners
  );

  const get_banners = useCallback(() => {
    dispatch(getBanners(params));
  }, [dispatch, params]);

  const onChange = useCallback(
    (row) => {
      dispatch(
        updateBannerStatus({
          id: row.id,
          active: !row.active,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      get_banners();
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, get_banners]);

  const handleClickOpen = useCallback(
    (bannerSetId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(bannerSetId));
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 50,
        flex: 0.1,
        filterable: false,
      },
      {
        field: "bannerName",
        headerName: "Name",
        minWidth: 200,
        flex: 1.5,
        sortable: false,
        editable: true,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.bannerName}
          </Typography>
        ),
      },
      {
        field: "location",
        headerName: "Location",
        minWidth: 150,
        flex: 0.5,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.location}
          </Typography>
        ),
      },
      {
        field: "device",
        headerName: "Platform",
        minWidth: 150,
        flex: 0.5,
        sortable: false,
        editable: true,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.device}
          </Typography>
        ),
      },
      {
        field: "type",
        headerName: "Type",
        minWidth: 150,
        flex: 0.4,
        sortable: false,
        editable: true,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.type}
          </Typography>
        ),
      },
      {
        field: "active",
        headerName: "Active",
        type: "actions",
        headerAlign: "center",
        align: "center",
        minWidth: 100,
        flex: 0.1,
        sortable: false,
        editable: true,
        renderCell: (params) => (
          <FormControlLabel
            control={
              <StyledSwitch
                checked={params?.row?.active}
                onChange={() => onChange(params.row)}
              />
            }
          />
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        headerAlign: "right",
        align: "right",
        minWidth: 250,
        sortable: false,
        flex: 0.4,
        renderHeader: () => (
          <Typography variant="h6" pr={2}>
            Actions
          </Typography>
        ),
        renderCell: (params) => (
          <Box display="flex" gap={1} pr={1}>
            <IconButton
              size="small"
              onClick={() =>
                navigate(`${params.id}/banners/${params.row.device}`)
              }
            >
              <img src={eyeIcon} alt="delete" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => navigate(`${params.id}/update`)}
            >
              <img src={WritingIcon} alt="edit" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => handleClickOpen(params?.id)}
            >
              <img src={deleteIcon} alt="delete" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() =>
                navigate(
                  `${params.id}/banners/${params.row.device}/create/${params.row.device}`
                )
              }
            >
              <AddIcon />
            </IconButton>
          </Box>
        ),
      },
    ],
    [onChange, navigate, handleClickOpen]
  );
  return (
    <>
      <StyledDataGrid
        rows={results}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteBanner} />
    </>
  );
}
