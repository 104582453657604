import CloseIcon from "@mui/icons-material/Close";
import ExpandLess from "@mui/icons-material/ExpandLess";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import WritingIcon from "assets/icons/WritingIcon.svg";
import DeleteIcon from "assets/icons/deleteIcon.svg";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import SpecificationsForm from "./SpecificationsForm";

function Specification({ formik, index, specification }) {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleDelete = () => {
    formik.setFieldValue("specifications", [
      ...formik.values.specifications.filter((_, i) => i !== index),
    ]);
  };

  const handleSave = useCallback(
    (specification) => {
      formik.setFieldValue(
        "specifications",
        formik.values.specifications.map((spec, i) => {
          if (i === index) {
            return specification;
          }
          return spec;
        })
      );
      setIsEditing(false);
    },
    [formik, index]
  );

  return (
    <>
      <Box p={1} border="1px solid #00000038" borderRadius="5px">
        {isEditing ? (
          <Box textAlign="right">
            <Tooltip title="Cancel">
              <IconButton size="large" onClick={handleEdit}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="body1"
              fontSize="1.15rem"
              textTransform="capitalize"
            >
              {specification?.name ?? "N/A"}
            </Typography>
            <Box display="flex">
              <Tooltip title="Edit">
                <IconButton size="small" onClick={handleEdit}>
                  {isEditing ? (
                    <ExpandLess />
                  ) : (
                    <img src={WritingIcon} alt="edit" />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton size="small" onClick={handleDelete}>
                  <img src={DeleteIcon} alt="delete" />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        )}
        <Collapse
          in={isEditing}
          timeout="auto"
          unmountOnExit
          sx={{
            "& .MuiCollapse-wrapper": {
              px: 2,
            },
          }}
        >
          <SpecificationsForm
            index={index}
            formik={formik}
            specification={specification}
            handleSave={handleSave}
          />
        </Collapse>
      </Box>
    </>
  );
}

Specification.propTypes = {
  specification: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default Specification;
