import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { memo } from "react";
import {
  BUTTON_HOVER,
  BUTTON_HOVER_BORDER_COLOR,
  FILTERS_BORDER_COLOR,
} from "styles/colors";
import CardTitle from "../CardTitle";
import PerformanceDetails from "./PerformanceDetails";

const Performance = () => {
  const performance = [
    { title: "Lamborghini Urus - 2022", views: "1.5K", lastBid: "10K" },
    { title: "BMW 750 I XDRIVE", views: "1.4K", lastBid: "9K" },
    { title: "ROLLS-ROYCE GHOST", views: "1.7K", lastBid: "11K" },
    { title: "FORD MUSTANG GT", views: "1.3K", lastBid: "13K" },
    { title: "MERCEDES-BENZ E 550", views: "1.6K", lastBid: "20K" },
    { title: "LEXUS ES 350", views: "1.1K", lastBid: "12K" },
  ];
  return (
    <Card
      sx={{ p: "1.2rem", boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" }}
    >
      <Box sx={containerStyles}>
        <CardTitle title="Auction Performance" color="#707070" />
      </Box>
      {performance.map((item, index) => {
        return (
          <PerformanceDetails
            title={item.title}
            views={item.views}
            lastBid={item.lastBid}
            key={item.views}
            index={index}
          />
        );
      })}
    </Card>
  );
};
export default memo(Performance);
const containerStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: "50px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
    },
    "&:hover": {
      backgroundColor: BUTTON_HOVER,
      "& fieldset": {
        borderColor: BUTTON_HOVER_BORDER_COLOR,
      },
    },
  },
};
