import ArticleIcon from "@mui/icons-material/Article";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import PagesIconFilled from "assets/icons/sidebar/Pages-Filled.svg";
import PagesIcon from "assets/icons/sidebar/Pages.svg";
import ServicesIconFilled from "assets/icons/sidebar/Services-Filled.svg";
import ServicesIcon from "assets/icons/sidebar/Services.svg";
import SubscriptionsIconFilled from "assets/icons/sidebar/Subscription-Filled.svg";
import SubscriptionsIcon from "assets/icons/sidebar/Subscription.svg";
import auctions from "assets/icons/sidebar/auctions.svg";
import auctionsfill from "assets/icons/sidebar/auctionsfill.svg";
import banners from "assets/icons/sidebar/banners.svg";
import bannersfill from "assets/icons/sidebar/bannersfill.svg";
import categories from "assets/icons/sidebar/categories.svg";
import categoriesfill from "assets/icons/sidebar/categoriesfill.svg";
import dashboard from "assets/icons/sidebar/dashboard.svg";
import dashboardfill from "assets/icons/sidebar/dashboardfill.svg";
import liveStreamingFill from "assets/icons/sidebar/live-streamfill.svg";
import liveStreaming from "assets/icons/sidebar/live-streaming.svg";
import products from "assets/icons/sidebar/products.svg";
import productsfill from "assets/icons/sidebar/productsfill.svg";
import users from "assets/icons/sidebar/users.svg";
import usersfill from "assets/icons/sidebar/usersfill.svg";
import videos from "assets/icons/sidebar/videos.svg";
import videosfill from "assets/icons/sidebar/videosfill.svg";
import USER_ROLE from "./userRole";

const SideBarListItems = [
  {
    roles: [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN],
    title: "Dashboard",
    path: "/dashboard",
    icon: dashboard,
    activeIcon: dashboardfill,
  },
  {
    roles: [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN],
    title: "Products",
    path: "/products",
    icon: products,
    activeIcon: productsfill,
  },
  {
    roles: [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN],
    title: "Auctions",
    path: "/auctions",
    icon: auctions,
    activeIcon: auctionsfill,
  },
  {
    roles: [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN],
    title: "Categories",
    path: "/categories",
    icon: categories,
    activeIcon: categoriesfill,
  },
  {
    roles: [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN, USER_ROLE.INFLUENCER],
    title: "Videos",
    path: "/videos",
    icon: videos,
    activeIcon: videosfill,
  },
  // {
  //   roles: [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN],
  //   title: "Video Upload",
  //   path: "/upload",
  //   icon: <DriveFolderUploadIcon />,
  // },
  {
    roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
    title: "Live Streaming",
    path: "/live-streaming",
    icon: liveStreaming,
    activeIcon: liveStreamingFill,
  },
  {
    roles: [USER_ROLE.SUPER_ADMIN],
    title: "Users",
    path: "/users",
    icon: users,
    activeIcon: usersfill,
  },
  {
    roles: [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN],
    title: "Banners",
    path: "/banner-set",
    icon: banners,
    activeIcon: bannersfill,
  },
  {
    roles: [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN],
    title: "Bids",
    path: "/bids",
    icon: auctions,
    activeIcon: auctionsfill,
  },
  {
    roles: [USER_ROLE.SUPER_ADMIN],
    title: "Subscriptions",
    path: "/subscriptions",
    icon: SubscriptionsIcon,
    activeIcon: SubscriptionsIconFilled,
  },
  {
    roles: [USER_ROLE.SUPER_ADMIN],
    title: "Services",
    path: "/services",
    icon: ServicesIcon,
    activeIcon: ServicesIconFilled,
  },
  {
    roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
    title: "Pages",
    path: "/pages",
    icon: PagesIcon,
    activeIcon: PagesIconFilled,
  },
  {
    roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.INFLUENCER],
    title: "Blogs",
    path: "/blogs",
    icon: ArticleOutlinedIcon,
    activeIcon: ArticleIcon,
  },
  // {
  //   roles: [USER_ROLE.ADMIN],
  //   title: "Chat",
  //   path: "/chat",
  //   icon: <ChatIcon />,
  // },
  ...(process.env.REACT_APP_SELL_WITH_US_PAGE === "true"
    ? [
        {
          roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
          title: "Sell With Us",
          path: "/sell-with-us",
          icon: ArticleOutlinedIcon,
          activeIcon: ArticleIcon,
        },
      ]
    : []),
];

export default SideBarListItems;
