/* eslint-disable jsx-a11y/img-redundant-alt */
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AuctionBlack from "assets/icons/AuctionBlack.svg";
import BMW from "assets/icons/auctionPerformance/BMW.svg";
import fordMustang from "assets/icons/auctionPerformance/Ford_Mustang.svg";
import Lamborgini from "assets/icons/auctionPerformance/lamborgini.svg";
import lexus from "assets/icons/auctionPerformance/lexus.svg";
import mercedes from "assets/icons/auctionPerformance/mercedes.svg";
import rollsRoyce from "assets/icons/auctionPerformance/rollsRoyce.svg";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";
const PerformanceDetails = ({ title, views, lastBid, index }) => {
  const imageIcon = useCallback((title) => {
    switch (title) {
      case "Lamborghini Urus - 2022":
        return Lamborgini;
      case "BMW 750 I XDRIVE":
        return BMW;
      case "ROLLS-ROYCE GHOST":
        return rollsRoyce;
      case "FORD MUSTANG GT":
        return fordMustang;
      case "MERCEDES-BENZ E 550":
        return mercedes;
      case "LEXUS ES 350":
        return lexus;
      default:
        return;
    }
  }, []);

  return (
    <Grid
      container
      mt={1}
      alignItems="center"
      spacing={1}
      sx={{
        background: index % 2 === 0 ? "#fff5eb" : "#e9fbf1",
      }}
    >
      <Grid item sm={2} lg={2} xl={2}>
        <Box
          height="40px"
          width="40px"
          borderRadius="50%"
          mr={2}
          sx={{
            backgroundImage: `url(${imageIcon(title)})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contained",
            backgroundPosition: "center",
          }}
        />
      </Grid>
      <Grid item sm={10} lg={10} xl={10}>
        <Box ml={1}>
          <Typography fontSize="0.875rem">{title}</Typography>
          <Box display="flex" alignItems="center">
            <VisibilityIcon fontSize="5px" />
            <Typography
              mr={2}
              fontSize={{ xxxl: "0.625rem", xxl: "0.5rem", sm: "0.5rem" }}
              textAlign="center"
            >
              {views + " Views"}
            </Typography>
            <img
              src={AuctionBlack}
              height="15px"
              width="auto"
              alt="image"
              color="black"
            />
            <Typography
              fontSize={{ xxxl: "0.625rem", xxl: "0.5rem", sm: "0.5rem" }}
              textAlign="center"
            >
              {lastBid + " Last Bid"}{" "}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
export default memo(PerformanceDetails);

PerformanceDetails.propTypes = {
  title: PropTypes.string.isRequired,
  views: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lastBid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number.isRequired,
};

PerformanceDetails.defaultProps = {
  title: "N/A",
  views: "N/A",
  lastBid: "N/A",
  index: 0,
};
