import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "utils/hooks/useToast";
import { httpMain } from "utils/httpRequest/http";

export const getBanners = createAsyncThunk(
  "banners/getBanners",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().banners;
    try {
      const response = await httpMain.get(`/banner-sets/admin`, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          ...data,
        },
      });
      if (response?.data?.data?.results) {
        response.data.data.results.forEach((item, index) => {
          const pageStart =
            (response.data.data.page - 1) * response.data.data.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data ?? error.message ?? "Something went wrong"
      );
    }
  }
);

export const updateBannerStatus = createAsyncThunk(
  "banners/updateBannerStatus",
  async (data, { rejectWithValue }) => {
    try {
      await httpMain.patch(`/banner-sets/${data.id}/banner-set`, {
        active: data.active,
      });
      toast.success("Update successfully");
      return data.id;
    } catch (error) {
      toast.error("Not yet updated");
      return rejectWithValue(
        error?.response?.data ?? error.message ?? "Something went wrong"
      );
    }
  }
);

export const deleteBanner = createAsyncThunk(
  "banners/deleteBanner",
  async (bannerId, { getState, rejectWithValue }) => {
    const { results, page, limit } = getState().banners;
    try {
      await httpMain.delete(`/banner-sets/${bannerId}`);
      toast.success("Deleted successfully");
      let returnedResult = results.filter((banner) => banner.id !== bannerId);
      if (returnedResult) {
        returnedResult = returnedResult.map((item, index) => {
          const pageStart = (page - 1) * limit;
          return {
            ...item,
            serialNumber: pageStart + index + 1,
          };
        });
        return returnedResult;
      }
    } catch (error) {
      toast.error("Something went wrong");
      return rejectWithValue(
        error?.response?.data ?? error.message ?? "Something went wrong"
      );
    }
  }
);

export const addBannerSet = createAsyncThunk(
  "banners/addBannerSet",
  async (values = {}, { rejectWithValue }) => {
    const data = values.data;
    try {
      const response = await httpMain.post(`/banner-sets`, data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data ?? error.message ?? "Something went wrong"
      );
    }
  }
);

export const getBannerSet = createAsyncThunk(
  "banners/getBannerSet",
  async (bannerId, { getState, rejectWithValue }) => {
    try {
      const banners = getState()?.banners?.results;
      const bannerSet = banners.find((bannerSet) => bannerSet.id === bannerId);
      if (bannerSet) {
        const bannerPromise = new Promise((resolve) => {
          setTimeout(() => {
            resolve(bannerSet);
          }, 100);
        });
        return bannerPromise.then((bannerSet) => {
          return bannerSet;
        });
      }
      const response = await httpMain.get(`/banner-sets/${bannerId}`);
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data ?? error.message ?? "");
    }
  }
);

export const updateBannerSet = createAsyncThunk(
  "banners/updateBannerSet",
  async (values = {}, { rejectWithValue }) => {
    const { id, ...data } = values;
    try {
      const response = await httpMain.patch(`/banner-sets/${id}`, data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data ?? error.message ?? "");
    }
  }
);

export const getSubBanners = createAsyncThunk(
  "banners/getSubBanners",
  async (data, { rejectWithValue, getState }) => {
    const { page, limit } = getState().banners;
    try {
      const response = await httpMain.get(`/banner-sets/${data.id}/admin`);
      if (response?.data?.data?.banners) {
        response?.data?.data?.banners.forEach((item, index) => {
          const pageStart = (page - 1) * limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data ?? error.message ?? "Something went wrong"
      );
    }
  }
);

export const getBanner = createAsyncThunk(
  "banners/getBanner",
  async (id, { getState, rejectWithValue }) => {
    try {
      const banners = getState()?.banners?.results;
      const banner = banners.find((banner) => banner.id === id);
      if (banner) {
        const bannerPromise = new Promise((resolve) => {
          setTimeout(() => {
            resolve(banner);
          }, 100);
        });
        return bannerPromise.then((banner) => {
          return banner;
        });
      }
      const response = await httpMain.get(`/banners/${id}`);
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data ?? error.message ?? "Something went wrong"
      );
    }
  }
);

export const addBanner = createAsyncThunk(
  "banners/addBanner",
  async (data = {}, { rejectWithValue }) => {
    try {
      const response = await httpMain.post(`/banners`, data.formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data ?? error.message ?? "Something went wrong"
      );
    }
  }
);

export const updateBannerImage = createAsyncThunk(
  "banners/updateBannerImage",
  async (values = {}, { rejectWithValue }) => {
    const { id, data } = values;
    try {
      const response = await httpMain.post(`/banners/${id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data ?? error.message ?? "Something went wrong"
      );
    }
  }
);

export const updateBanner = createAsyncThunk(
  "banners/updateBanner",
  async (values = {}, { rejectWithValue }) => {
    const { id, data } = values;
    try {
      const response = await httpMain.patch(`/banners/${id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data ?? error.message ?? "Something went wrong"
      );
    }
  }
);

export const updateSubBannerStatus = createAsyncThunk(
  "banners/updateSubBannerStatus",
  async (data, { rejectWithValue }) => {
    try {
      await httpMain.patch(`/banners/${data.id}`, {
        status: data.status,
      });
      toast.success("Update successfully");
      return data.id;
    } catch (error) {
      toast.error("Not yet updated");
      return rejectWithValue(
        error?.response?.data ?? error.message ?? "Something went wrong"
      );
    }
  }
);

export const deleteSubBanner = createAsyncThunk(
  "banners/deleteSubBanner",
  async (bannerId, { getState, rejectWithValue }) => {
    const { results } = getState().banners;
    try {
      await httpMain.delete(`/banners/${bannerId}`);
      toast.success("Deleted successfully");
      return results.filter((banner) => banner.id !== bannerId);
    } catch (error) {
      toast.error("Something went wrong");
      return rejectWithValue(
        error?.response?.data ?? error.message ?? "Something went wrong"
      );
    }
  }
);

const bannersSlice = createSlice({
  name: "banners",
  initialState: {
    loading: true,
    results: [],
    bannerSet: [],
    banner: null,
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
    error: null,
    bannerSetError: null,
  },
  reducers: {
    // Actions
    resetState: (state) => {
      state.loading = true;
      state.results = [];
      state.bannerSet = null;
      state.banner = null;
      state.page = 1;
      state.limit = 20;
      state.totalPages = 0;
      state.totalResults = 0;
      state.error = null;
      state.bannerSetError = null;
    },
    resetBannerSetState: (state) => {
      state.loading = false;
      state.bannerSet = null;
      state.bannerSetError = null;
    },
    resetBannerState: (state) => {
      state.loading = false;
      state.banner = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBanners.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBanners.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.results = action.payload.results;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(getBanners.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(updateBannerStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBannerStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        let idIndex = state.results.findIndex(
          (element) => element.id === action.payload
        );
        if (idIndex !== -1) {
          state.results[idIndex].active = !state.results[idIndex].active;
        }
      })
      .addCase(updateBannerStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(deleteBanner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBanner.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.results = action.payload;
      })
      .addCase(deleteBanner.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(addBannerSet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addBannerSet.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(addBannerSet.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getBannerSet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBannerSet.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.bannerSet = action.payload;
      })
      .addCase(getBannerSet.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(updateBannerSet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBannerSet.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateBannerSet.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getSubBanners.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSubBanners.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.results = action.payload.banners;
      })
      .addCase(getSubBanners.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getBanner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBanner.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.banner = action.payload;
      })
      .addCase(getBanner.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(addBanner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addBanner.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(addBanner.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(updateBanner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBanner.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateBanner.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(updateBannerImage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBannerImage.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateBannerImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(updateSubBannerStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSubBannerStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        let idIndex = state.results.findIndex(
          (element) => element.id === action.payload
        );
        if (idIndex !== -1) {
          state.results[idIndex].status = !state.results[idIndex].status;
        }
      })
      .addCase(updateSubBannerStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(deleteSubBanner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSubBanner.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.results = action.payload;
      })
      .addCase(deleteSubBanner.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState, resetBannerSetState, setBanner, resetBannerState } =
  bannersSlice.actions;

// Exporting default reducer
export default bannersSlice.reducer;
