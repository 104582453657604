import TextField from "@mui/material/TextField";
import styled from "@mui/material/styles/styled";
import { PRIMARY_LIGHT } from "styles/colors";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: PRIMARY_LIGHT,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
});

export default StyledTextField;
